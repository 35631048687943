import React from 'react';
import './filter-spinner-icon.css';

type Props = {
    width: number;
    height: number;
    fill: string;
}

const FilterSpinnerIcon = ({
  width, height, fill,
}: Props) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 16 16"
    fill={fill}
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      className="gear"
      d="M7.993 2.66675V0.666748L5.32633 3.33341L7.993 6.00008V4.00008C10.1997 4.00008 11.993 5.79342 11.993 8.00008C11.993 8.67342 11.8263 9.31342 11.5263 9.86675L12.4997 10.8401C13.0197 10.0201 13.3263 9.04675 13.3263 8.00008C13.3263 5.05341 10.9397 2.66675 7.993 2.66675ZM7.993 12.0001C5.78633 12.0001 3.993 10.2067 3.993 8.00008C3.993 7.32675 4.15967 6.68675 4.45967 6.13341L3.48633 5.16008C2.96633 5.98008 2.65967 6.95341 2.65967 8.00008C2.65967 10.9467 5.04633 13.3334 7.993 13.3334V15.3334L10.6597 12.6667L7.993 10.0001V12.0001Z"
      fill="#006FD6"
    />
  </svg>

);

export default FilterSpinnerIcon;
