import React from 'react';

type Props = {
  width: number;
  height: number;
  fill?: string;
}

const RefreshIcon = ({
  width, height, fill,
}: Props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 24 24"
    style={{
      width,
      height,
    }}
  >
    <path
      data-name="Path 884"
      d="M0 0h24v24H0z"
      style={{
        fill: 'none',
      }}
    />
    <path
      data-name="Path 885"
      d="M17.65 6.35A8 8 0 1 0 19.73 14h-2.08A6 6 0 1 1 12 6a5.915 5.915 0 0 1 4.22 1.78L13 11h7V4z"
      style={{
        fill,
      }}
    />

  </svg>
);

RefreshIcon.defaultProps = {
  fill: '#216fcc',
};

export default RefreshIcon;
