import { Frequency, ScheduleRecipientTypes, ScheduleStatus } from 'core/constants/schedule-builder';
import { APIRequestStatus } from 'core/constants/redux';
import { ObjModel } from 'core/models';
import { ISelectFilterOptions } from 'core/models/filter';
import { FeatureContext } from 'core/constants/common';
import { IScheduleDailyFrequency } from 'core/models/schedule-reports';
import moment from 'moment-timezone';

export interface IScheduleBuilderState {
    scheduleId: string
    requestProcessing: IRequestProcessing;
    availableSlots: Array<[number, number]>
    scheduleLimits: IScheduleBuilderLimits
    scheduleList: IScheduleListResponse
    isScheduleFilterOpen: boolean
    scheduleReportNames: Array<ISelectFilterOptions>
    scheduleOwnerNames: Array<ISelectFilterOptions>
    scheduleListPayload: IScheduleListPayload
    scheduleHistory: Array<ScheduleAuditEntry>
}

export interface IScheduleBuilderPayload {
  ScheduleId : string,
  StartDate: string;
  EndDate: string;
  Status: ScheduleStatus;
  FileType: string;
  Slot: number;
  ScheduleMode: ScheduleRecipientTypes;
  Source: {
    Id: string;
    Type: string;
  };
  Frequency: {
    Repeat: number;
    FrequencyProps: any;
  };
  Receivers: ObjModel.Obj;
  FeatureContext: FeatureContext
}

interface User {
    Available: number;
    Total: number;
}

interface Tenant {
    Available: number;
    Total: number;
}

export interface IScheduleBuilderLimits {
  User : User,
  Tenant : Tenant
}

export interface IScheduleListResponse{
  Data: Array<IScheduleListItem>,
  HasNextPage: boolean,
  TotalRows: number,
}

export interface IScheduleListPayload {
  Page: Page,
  Sort: Sort,
  Filters: ObjModel.Obj
}

export interface Page {
  PageIndex: number;
  PageSize: number;
}

export interface Sort{
  Field: string,
  Direction: string,
}

export interface IScheduleListItem {
  Id: string;
  ReportName: string;
  Status: ScheduleStatus;
  LastRunOn: string;
  Owner: string;
  LastModifiedOn: string;
  StartDate: string;
  EndDate: string;
  FileType: string;
  Slot: number;
   Source: {
    Id: string;
    Type: string;
  };
  Frequency: {
    Type: string;
    Value: any;
  }
}

export interface IScheduleInfo{
  StartDate: string;
  EndDate: string;
  Status: ScheduleStatus;
  FileType: string;
  Slot: number;
  Source: {
    Id: string;
    Type: string;
  };
  Frequency: {
    Type: Frequency;
    Value: any;
  };
  ScheduleId: string;
  ScheduleMode: ScheduleRecipientTypes;
  Receivers: ReceiverType;
  IsStale: boolean;
  FeatureContext: FeatureContext;
  OwnerId: string;
}

export interface ScheduleAuditEntry {
    ScheduleId: string,
    ModifiedOn: string,
    Changes: Array<Change>,
    ModifiedBy: string,
    _id: string,
}

export interface Change {
    Field: string,
    OldValue: ObjModel.Obj | Array<string> | string,
    NewValue: ObjModel.Obj | Array<string> | string,
    ModifiedBy: string,
    ModifiedOn: string,
  }

export interface ReceiverType {
    id: string,
    values: string[]
}

export interface IScheduleBuilderForm {
    frequency: number,
    availableSlots: ObjModel.Obj,
    selectedSlot: number,
    freqDailyList: Array<IScheduleDailyFrequency>,
    recurringWeek: number,
    isMonthlyAbsolute: boolean,
    monthlyAbsoluteDate: number,
    monthlyRelativeOccurrence: number,
    monthlyRelativeDayName: string,
    startDate: moment.Moment,
    endDate: moment.Moment,
    scheduleMode: ScheduleRecipientTypes,
    receivers: ObjModel.Obj,
    isStale: boolean,
    exportFileType: string,
    featureContext: FeatureContext,
    ownerId: string,
}

export interface IRequestProcessing {
  [ScheduleBuilderActionTypes.SAVE_SCHEDULE_CONFIG]: APIRequestStatus;
  [ScheduleBuilderActionTypes.UPDATE_SCHEDULE_INFO]: APIRequestStatus;
  [ScheduleBuilderActionTypes.GET_SCHEDULE_LIST]: APIRequestStatus;
  [ScheduleBuilderActionTypes.GET_SCHEDULE_REPORT_NAMES]: APIRequestStatus;
  [ScheduleBuilderActionTypes.GET_SCHEDULE_OWNER_NAMES]: APIRequestStatus;
  [ScheduleBuilderActionTypes.DEACTIVATE_SCHEDULE]: APIRequestStatus;
  [ScheduleBuilderActionTypes.GET_SCHEDULE_HISTORY]: APIRequestStatus;
}

export enum ScheduleBuilderActionTypes {
     SAVE_SCHEDULE_CONFIG = '[SB] SAVE_SCHEDULE_CONFIG',
     UPDATE_SCHEDULE_INFO = '[SB] UPDATE_SCHEDULE_INFO',
     GET_AVAILABLE_SLOTS = '[SB] GET_AVAILABLE_SLOTS',
     GET_SCHEDULE_LIST = '[SB] GET_SCHEDULE_LIST',
     TOGGLE_SCHEDULE_FILTER_DRAWER = '[SB] TOGGLE_SCHEDULE_FILTER_DRAWER',
     GET_SCHEDULE_REPORT_NAMES = '[SB] GET_SCHEDULE_REPORT_NAMES',
     GET_SCHEDULE_OWNER_NAMES = '[SB] GET_SCHEDULE_OWNER_NAMES',
     SET_LIST_FILTERS = '[SB] SET_LIST_FILTERS',
     DEACTIVATE_SCHEDULE = '[SB] DEACTIVATE_SCHEDULE',
     SAVE_SCHEDULED_REPORT_ID = '[SB] SAVE_SCHEDULED_REPORT_ID',
     GET_SCHEDULE_HISTORY = '[SB] GET_SCHEDULE_HISTORY',
}
