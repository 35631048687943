import { FeatureList, routePaths } from 'core/constants/common';
import { ObjModel } from 'core/models';

const FeatureDefinitions: ObjModel.ObjGeneric<IFeatureDefinitions> = {
  [FeatureList.sieraReports]: {
    route: routePaths.sieraReports,
    isSieraOnly: true,
    isReportingDisabled: true,
    isAdminOnly: false,
  },
  [FeatureList.reportBuilder]: {
    route: routePaths.reportBuilder,
    isSieraOnly: true,
    isReportingDisabled: true,
    isAdminOnly: true,
  },
  [FeatureList.reportEdit]: {
    route: routePaths.reportEdit,
    isSieraOnly: true,
    isReportingDisabled: true,
    isAdminOnly: true,
  },
  [FeatureList.externalReports]: {
    route: routePaths.externalReports,
    isSieraOnly: false,
    isReportingDisabled: true,
    isAdminOnly: false,
  },
  [FeatureList.sieraDashboards]: {
    route: routePaths.sieraDashboards,
    isSieraOnly: true,
    isReportingDisabled: true,
    isAdminOnly: false,
  },
  [FeatureList.dashboardBuilder]: {
    route: routePaths.dashboardBuilder,
    isSieraOnly: true,
    isReportingDisabled: true,
    isAdminOnly: true,
  },
  [FeatureList.createProfile]: {
    route: routePaths.createProfile,
    isSieraOnly: false,
    isReportingDisabled: true,
    isAdminOnly: true,
  },
  [FeatureList.editProfile]: {
    route: routePaths.editProfile,
    isSieraOnly: false,
    isReportingDisabled: true,
    isAdminOnly: true,
  },
  [FeatureList.reportListing]: {
    route: routePaths.baseRoute,
    isSieraOnly: false,
    isReportingDisabled: true,
    isAdminOnly: false,
  },
  [FeatureList.customAnalytics]: {
    route: routePaths.customAnalytics,
    isSieraOnly: true,
    isReportingDisabled: true,
    isAdminOnly: true,
  },
};

export interface IFeatureDefinitions {
    route: string;
    isSieraOnly: boolean,
    isReportingDisabled: boolean
    isAdminOnly: boolean
}

export default FeatureDefinitions;
