import React from 'react';

type Props = {
  width?: number;
  height?: number;
  fill?: string;
}

const PinnedProfileIcon = ({
  width, height, fill,
}: Props) => (
  <svg width={width} height={height} viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clipPath="url(#clip0_1279_54924)">
      <path d="M11.6663 3.33317V7.49984C11.6663 8.43317 11.9747 9.29984 12.4997 9.99984H7.49967C8.04134 9.28317 8.333 8.4165 8.333 7.49984V3.33317H11.6663ZM14.1663 1.6665H5.833C5.37467 1.6665 4.99967 2.0415 4.99967 2.49984C4.99967 2.95817 5.37467 3.33317 5.833 3.33317H6.66634V7.49984C6.66634 8.88317 5.54967 9.99984 4.16634 9.99984V11.6665H9.14134V17.4998L9.97467 18.3332L10.808 17.4998V11.6665H15.833V9.99984C14.4497 9.99984 13.333 8.88317 13.333 7.49984V3.33317H14.1663C14.6247 3.33317 14.9997 2.95817 14.9997 2.49984C14.9997 2.0415 14.6247 1.6665 14.1663 1.6665Z" fill={fill} />
    </g>
    <circle cx="14.0005" cy="10" r="5" fill="#F9FAFB" />
    <path d="M14.0002 6C11.7922 6 10.0002 7.792 10.0002 10C10.0002 12.208 11.7922 14 14.0002 14C16.2082 14 18.0002 12.208 18.0002 10C18.0002 7.792 16.2082 6 14.0002 6ZM12.1402 12.6C12.6642 12.224 13.3042 12 14.0002 12C14.6962 12 15.3362 12.224 15.8602 12.6C15.3362 12.976 14.6962 13.2 14.0002 13.2C13.3042 13.2 12.6642 12.976 12.1402 12.6ZM16.4562 12.048C15.7802 11.52 14.9282 11.2 14.0002 11.2C13.0722 11.2 12.2202 11.52 11.5442 12.048C11.0802 11.492 10.8002 10.78 10.8002 10C10.8002 8.232 12.2322 6.8 14.0002 6.8C15.7682 6.8 17.2002 8.232 17.2002 10C17.2002 10.78 16.9202 11.492 16.4562 12.048Z" fill={fill} />
    <path d="M14.0003 7.6001C13.2283 7.6001 12.6003 8.2281 12.6003 9.0001C12.6003 9.7721 13.2283 10.4001 14.0003 10.4001C14.7723 10.4001 15.4003 9.7721 15.4003 9.0001C15.4003 8.2281 14.7723 7.6001 14.0003 7.6001ZM14.0003 9.6001C13.6683 9.6001 13.4003 9.3321 13.4003 9.0001C13.4003 8.6681 13.6683 8.4001 14.0003 8.4001C14.3323 8.4001 14.6003 8.6681 14.6003 9.0001C14.6003 9.3321 14.3323 9.6001 14.0003 9.6001Z" fill={fill} />
    <defs>
      <clipPath id="clip0_1279_54924">
        <rect width="20" height="20" fill={fill} />
      </clipPath>
    </defs>
  </svg>

);

PinnedProfileIcon.defaultProps = {
  fill: 'var(--navSectionIcon)',
  width: 20,
  height: 20,
};

export default PinnedProfileIcon;
