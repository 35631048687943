import { CustomAnalyticsAction } from 'redux-v2/customAnalytics/custom-analytics.state';
import { APIRequestStatus } from 'core/constants/redux';

export interface ICustomAnalyticsPayload {
  Page: Page,
  Filters: Partial<Filters>,
  Sort: Array<Sort>
}
export interface ICustomAnalyticsResponse {
    Data: Array<CustomAnalyticsData>,
    HasNextPage: boolean,
    TotalRows: number
}
export interface CustomAnalyticsData {
    Id: string,
    Name: string,
    Description: string,
    ProductLine: string,
    ProductLineIcon?: string,
    Dataset: string,
    DatasetIcon?: string,
    OwnerName: string,
    OwnerId: string,
    ModifiedOn: string,
}
interface Page {
    PageIndex : number,
    PageSize : number,
}
export interface Filters {
    CreatedBy: Array<string>,
    ProductLine: Array<string>,
    Dataset: Array<string>,
    Search: string,
}
interface Sort {
    Field: string,
    Direction: Direction,
}
export const enum Direction {
    ASC = 'ASC',
    DESC = 'DESC',
}

export interface ICustomAnalyticsListing {
    reportData: ICustomAnalyticsResponse;
    dashboardData: ICustomAnalyticsResponse;
    selectedTab: string;
    reportCustomAnalyticsPayload: ICustomAnalyticsPayload;
    dashboardCustomAnalyticsPayload: ICustomAnalyticsPayload;
    createdByListOfReports: Array<IOwnerFilterOptions>;
    createdByListOfDashboards: Array<IOwnerFilterOptions>;
    selectedReports: Array<CustomAnalyticsData>;
    selectedDashboards: Array<CustomAnalyticsData>;
    loadingRows: Array<CustomAnalyticsData>;
}

export interface ICustomAnalyticsReportIds {
    ReportIds: Array<string>;
}

export interface ICustomAnalyticsDashboardIds {
    DashboardIds: Array<string>;
}

export interface IOwnerResponse {
    DisplayName: string;
    Value: string;
    OwnerUpdatedOn: string;
}

export interface IOwnerFilterOptions {
    label: string;
    value?: string;
    ownerUpdatedOn?: string;
}

export interface IRequestProcessing {
  [CustomAnalyticsAction.REQUEST_CUSTOM_ANALYTICS_LIMITS]: APIRequestStatus;
  [CustomAnalyticsAction.REQUEST_CUSTOM_ANALYTICS_REPORTS_LIST]: APIRequestStatus;
  [CustomAnalyticsAction.REQUEST_CUSTOM_ANALYTICS_DASHBOARDS_LIST]: APIRequestStatus;
  [CustomAnalyticsAction.GET_CREATED_BY_LIST_REPORTS]: APIRequestStatus;
  [CustomAnalyticsAction.GET_CREATED_BY_LIST_DASHBOARD]: APIRequestStatus;
  [CustomAnalyticsAction.GET_PRODUCT_LINE_LIST_REPORTS]: APIRequestStatus;
  [CustomAnalyticsAction.GET_DATASET_LIST_REPORTS]: APIRequestStatus;
  [CustomAnalyticsAction.UPDATE_REPORT_OWNER]: APIRequestStatus;
  [CustomAnalyticsAction.UPDATE_DASHBOARD_OWNER]: APIRequestStatus;
}
