import React from 'react';
import { ToastContainer, ToastContainerProps } from 'react-toastify';
import StyledToastComponent from 'components/common/Toast/toast.styled';
import 'react-toastify/dist/ReactToastify.css';
import { useSelector } from 'react-redux';
import authSelector from 'redux-v2/auth-store/auth-store.selector';
import { toastTimer } from 'components/feature/Report/ReportSidebar/common/constants';
import { ToastPosition } from 'core/constants/common';
import { CloseIcon } from '../svg';

const Toast = (props: ToastContainerProps) => {
  const { isV1Report } = useSelector(authSelector);

  return (
    <StyledToastComponent isV1Report={isV1Report}>
      <ToastContainer
        position={ToastPosition.TopCenter}
        autoClose={toastTimer}
        closeButton={(
          <div className="se-icon-cn">
            <CloseIcon width={16} height={16} opacity={0.5} />
          </div>
        )}
        closeOnClick
        hideProgressBar
        {...props}
      />
    </StyledToastComponent>
  );
};

export default Toast;
