import React from 'react';

type Props = {
  width: number;
  height: number;
  fill?: string;
}

const LineChart = ({
  width, height, fill,
}: Props) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M2 2V22C2 22.1105 2.08954 22.2 2.2 22.2H22.2"
      stroke={fill}
      strokeWidth="1.6"
      strokeLinecap="round"
      className="icon-stroke"
    />
    <path
      d="M21.7031 8.15149L16.4133 13.4413C16.0228 13.8318 15.3896 13.8318 14.9991 13.4413L12.2501 10.6923C11.8596 10.3018 11.2264 10.3018 10.8359 10.6923L5.54606 15.9821"
      stroke={fill}
      strokeWidth="1.6"
      strokeLinecap="round"
      className="icon-stroke"
    />
  </svg>

);

LineChart.defaultProps = {
  fill: 'var(--labelColor)',
};

export default LineChart;
