import React from 'react';

type Props = {
  width: number;
  height: number;
  fill?: string;
}

const ChartsIcon = ({
  width, height, fill,
}: Props) => (
  <svg width={width} height={height} viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect
      x="2.7"
      y="2.7"
      width="14.6"
      height="14.6"
      rx="0.7"
      stroke={fill}
      strokeWidth="1.4"
      className="icon-stroke-chart"
    />
    <rect
      x="5.5"
      y="8.2002"
      width="1.8"
      height="6.2"
      fill={fill}
      className="rect-stroke"
    />
    <rect
      x="9.1001"
      y="5.3999"
      width="1.8"
      height="9"
      fill={fill}
      className="rect-stroke"
    />
    <rect
      x="12.6499"
      y="11"
      width="1.8"
      height="3.4"
      fill={fill}
      className="rect-stroke"
    />
  </svg>

);

ChartsIcon.defaultProps = {
  fill: 'var(--secondaryColor)',
};

export default ChartsIcon;
