import styled from 'styled-components';

const StyledInputFieldComponent = styled.div`
    .se-input-field-label{
      font-family: SemiBold;
      font-size: 13px;
      color: var(--secondaryColor);
      padding-bottom:6px;
      
      span {
        font-family: Regular;
        font-size: 13px;
        color: var(--searchBoxTextColor);
        padding-left: 4px;
      }
    }
    .se-input-field-component{
        width: 100%;
        font-family: Regular;
        font-size: 13px;
        color: var(--secondaryColor);
        border-radius: 2px;
        background-color:var(--white);
        border: 1px solid var(--borderColor);
        min-height: 32px;
        padding: 0 8px 0 11px;
        box-sizing: border-box;
        outline: none;

        :focus-visible {
            border-color: var(--border);
        }

        ::placeholder {
            color: var(--searchBoxTextColor);
        }

        :hover {
            border-color: var(--border);
        }

    }
    .danger {
        border-color: var(--dangerColor) !important;
    }

    .se-input-field-error {
        font: 12px Regular;
        color: var(--dangerColor);
        margin-top: 2px;
    }
`;
export default StyledInputFieldComponent;
