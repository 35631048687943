export enum ListingSectionId {
    Report = 'reportv2',
    Dashboard = 'dashboardv2',
}

export enum SectionItemId {
    AllReports = 'all_reports',
    AllDashboards = 'all_dashboards',
    PinnedReports = 'pinned_reports',
    HomeDashboard = 'home_dashboard',
    PinnedDashboard = 'pinned_dashboard',
    BookmarkedReports = 'bookmarked_reports',
    ScheduledReports = 'scheduled_reports',
}

export enum CardType {
    ReportCard = 'Report Card',
    DashboadCard = 'Dashboard Card',
}

export enum ContextTypes {
    V1='V1Report',
    Siera='Siera',
    Marvin='isMarvin',
    Embedded='Embedded'
}
