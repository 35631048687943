import React from 'react';

type Props = {
  width?: number;
  height?: number;
  fill?: string;
};

const EmptyDataPinnedIcon = ({ width, height, fill }: Props) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 80 85"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M43.5817 8.08105H15.212V71.9128H63.8459V28.0276H43.5817V8.08105ZM55.7393 63.932H23.3174V55.9536H55.7393V63.932ZM55.7393 39.9957V47.9741H23.3174V39.9957H55.7393Z"
      fill="#DFE3E8"
    />
    <path
      d="M23.5294 56H55.5282V64H23.5294V56ZM23.5294 40H55.5282V48H23.5294V40ZM47.5288 0H15.5288C13.4064 0.00920669 11.3738 0.855704 9.87496 2.35462C8.37615 3.85354 7.53293 5.88308 7.52945 8V72C7.52367 74.1121 8.3578 76.1402 9.84897 77.6399C11.3401 79.1395 13.3667 79.9882 15.4842 80H63.5276C65.6507 79.9914 67.6841 79.145 69.1835 77.6458C70.6828 76.1465 71.5262 74.1163 71.5294 71.9988V24L47.5288 0ZM63.5276 71.9988H15.5288V8H43.5285V27.9994H63.5276V71.9988Z"
      fill="#919EAB"
    />
    <path
      d="M66 85C73.732 85 80 78.732 80 71C80 63.268 73.732 57 66 57C58.268 57 52 63.268 52 71C52 78.732 58.268 85 66 85Z"
      fill="white"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M69.334 68.5V64.334H70.167C70.3877 64.3332 70.5991 64.2452 70.7551 64.0891C70.9112 63.9331 70.9992 63.7217 71 63.501C70.9995 63.2801 70.9116 63.0685 70.7555 62.9122C70.5994 62.7559 70.3878 62.6678 70.167 62.667H61.834C61.6133 62.6678 61.4019 62.7558 61.2458 62.9118C61.0898 63.0679 61.0018 63.2793 61.001 63.5C61.0018 63.7207 61.0898 63.9321 61.2458 64.0881C61.4019 64.2442 61.6133 64.3322 61.834 64.333H62.667V68.5C62.667 69.163 62.4036 69.7989 61.9348 70.2678C61.4659 70.7366 60.83 71 60.167 71V72.667H65.142V78.5L65.975 79.333L66.808 78.5V72.667H71.833V71C71.1701 70.9997 70.5345 70.7362 70.0659 70.2674C69.5972 69.7986 69.334 69.1629 69.334 68.5Z"
      fill={fill}
    />
  </svg>

);

EmptyDataPinnedIcon.defaultProps = {
  width: 80,
  height: 80,
  fill: 'var(--navSectionIcon)',
};

export default EmptyDataPinnedIcon;
