export enum AuthStoreActions {
  SET_AUTH = 'SET_AUTH',
  SET_MARVIN_AUTH = 'SET_MARVIN_AUTH',
  SET_V1_REPORT_CONTEXT = 'SET_V1_REPORT_CONTEXT',
  SET_STATUS_ERROR_CODE = 'SET_STATUS_ERROR_CODE',
  SET_ACTIVE_INTERVAL = 'SET_ACTIVE_INTERVAL',
  SET_ISEMBEDDED_CONTEXT = 'SET_ISEMBEDDED_CONTEXT',
  SET_SETTINGS = 'SET_SETTINGS',
  SET_MOBILE_AUTH_INFO='SET_MOBILE_AUTH_INFO',
}

export interface IAccessibilityMap{
  Sales: boolean,
  Service: boolean
}
