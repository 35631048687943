import React from 'react';

type Props = {
  width?: number;
  height?: number;
  fill?: string;
}

const OptionPieChartIcon = ({
  width, height, fill,
}: Props) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect
      width="16"
      height="16"
      fill="transparent"
    />
    <path
      d="M1.79951 8.50062C1.79951 5.65034 3.89232 3.28738 6.62499 2.86706C6.63097 2.86614 6.63406 2.86634 6.63491 2.86642C6.63522 2.86644 6.63548 2.86647 6.63572 2.86651C6.63619 2.86659 6.63656 2.86669 6.63697 2.86682C6.63799 2.86717 6.64438 2.8695 6.65404 2.87872C6.67525 2.89895 6.69965 2.9412 6.69965 3.00049V7.50062C6.69965 8.49473 7.50553 9.30062 8.49965 9.30062H12.9998C13.0591 9.30062 13.1013 9.32501 13.1215 9.34623C13.1308 9.3559 13.1331 9.36229 13.1334 9.36332C13.1335 9.36365 13.1336 9.36396 13.1337 9.36431C13.1338 9.36462 13.1338 9.36496 13.1338 9.36538C13.1339 9.36623 13.1341 9.36933 13.1332 9.3753C12.8154 11.4413 11.3856 13.1445 9.47276 13.8502C8.85899 14.0767 8.19471 14.2008 7.49965 14.2008C6.26847 14.2008 5.13062 13.8113 4.19946 13.1489L3.7357 13.8007L4.19946 13.1489C2.74536 12.1144 1.79951 10.4179 1.79951 8.50062Z"
      stroke={fill}
      strokeWidth="1.6"
    />
    <path
      d="M14.1324 6.6253C14.1333 6.63128 14.1331 6.63437 14.133 6.63522C14.133 6.63613 14.1328 6.63666 14.1326 6.63727C14.1323 6.63829 14.1299 6.64468 14.1207 6.65433C14.1005 6.67555 14.0583 6.69994 13.999 6.69994H9.49951C9.38905 6.69994 9.29951 6.6104 9.29951 6.49994V2.00049C9.29951 1.9412 9.32391 1.89895 9.34512 1.87872C9.35478 1.86951 9.36116 1.86717 9.36218 1.86683C9.3628 1.86663 9.36332 1.8665 9.36424 1.86642C9.36509 1.86635 9.36818 1.86615 9.37415 1.86707C11.8208 2.24344 13.756 4.17862 14.1324 6.6253Z"
      stroke={fill}
      strokeWidth="1.6"
    />
  </svg>

);

OptionPieChartIcon.defaultProps = {
  width: 16,
  height: 16,
  fill: 'var(--secondaryColor)',
};

export default OptionPieChartIcon;
