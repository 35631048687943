import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import {
  DashboardContext,
  IBatchRequestPayload,
  IDashboardConfig, IDashboardData, IDashboardItem, IDashboardPanelPayload, IFilterItem, IFilterListResponse,
  IFiltersOnchange, IPanel, IReportWidget, IRequestPanelDataSuccessPayload, ITitleSection, IWidgetEditInfo, IWidgetSettings,
} from 'core/models/dashboard';
import { APIRequestStatus } from 'core/constants/redux';
import SieraResponse from 'core/models/siera-response';
import { AuthResponseModel, ObjModel } from 'core/models';
import { LoadingStatus } from 'core/constants/dashboard';
import { statusCodes } from 'core/constants/report';
import { ILoadMoreFilterPayloadRequest, ISearchFilterPayloadRequest } from 'core/models/report-builder/report-builder-redux';
import { IFilterResponse } from 'core/models/filter';
import { FilterType } from 'core/constants/filter';
import { isValidArray, isValidArrayAndNotEmpty } from 'components/feature/Report/ReportSidebar/common/helpers';
import { ReportType } from 'core/models/report-builder/report-builder';
import InitialDashboardStoreState from './dashboard-store-initial-state';
import {
  DashboardAction, IDashboardState, IValidateURLPayload,
} from './dashboard-store.state';
import {
  dashboardAccessControlRequest,
  deleteDashboardItemRequest,
  filterLoadRequest,
  getAllReportsRequest,
  getDashboardListRequest,
  getFiltersListInfoRequest,
  getWidgetReportDataRequest,
  saveDashboardConfigurationRequest,
  saveTempDrilldownRequest,
  saveTempViewRequest,
  setPersonalizedDashboardRequest,
} from './dashboard-store-requests';
import {
  generateEmbedUrlConfig,
  getAddWidgetInfo,
  getDashboardBuilderSaveConfigPayload,
  getFilterLoadSuccess,
  getFiltersOnConfigSuccess,
  getInitialisedFilterStoreForEdit,
  getIsDashboardBuilderSaveFormDisable,
  getReportSections,
  getStateOnFilterChange,
  getStateOnRequestDashboardConfigSuccess,
  getStateOnRequestPanelDataFailure,
  getStateOnRequestPanelDataLoad,
  getStateOnRequestPanelDataSuccess,
  getStateOnResetRedirectionStatus,
  getStateOnSaveTempDrilldownFailure,
  getStateOnSaveTempDrilldownRequest,
  getStateOnSaveTempDrilldownSuccess,
  getStateOnSaveTempViewFailure,
  getStateOnSaveTempViewRequest,
  getStateOnSaveTempViewSuccess,
  getStateOnUpdateDashboardConfig,
  getUpdatedAvailableFilters,
  getUpdatedFilterChangeData,
  getUpdatedFilterInfo,
  getUpdatedFilterJsonOnAdd,
  getUpdatedFilterJsonOnDelete,
  getUpdatedFilterStoreOnWidgetDelete,
  getWidgetData,
  isPanelAppliedPartitionedColumn,
  setDataTypeForFilterItem,
  setInitalPayloadData,
  transformDashboardConfigToPanelPayload,
  updatePanelPayloadOnClose,
} from './dashboard.reducer.util';

const dashboardStoreSlice = createSlice({
  name: 'dashboardStoreSlice',
  initialState: InitialDashboardStoreState,
  reducers: {
    [DashboardAction.REQUEST_PANEL_DATA]: (state:IDashboardState, action:PayloadAction<Array<string>>) => {
      state.dashboardData = getStateOnRequestPanelDataLoad(state, action.payload);
    },
    [DashboardAction.REQUEST_PANEL_DATA_SUCCESS]: (state:IDashboardState, action:PayloadAction<IRequestPanelDataSuccessPayload>) => {
      const transformedData = getStateOnRequestPanelDataSuccess(state, action.payload);
      state.filterStore = transformedData.filterStore;
      state.dashboardData = transformedData.dashboardData;
    },
    [DashboardAction.REQUEST_PANEL_DATA_FAILURE]: (state: IDashboardState, action: PayloadAction<IBatchRequestPayload>) => {
      state.dashboardData = getStateOnRequestPanelDataFailure(state, action.payload?.target, action.payload?.status);
    },
    [DashboardAction.TRANSFORM_DASHBOARD_CONFIG_TO_PAYLOAD]: (state:IDashboardState, action:PayloadAction<IDashboardConfig>) => {
      state.panelPayloads = transformDashboardConfigToPanelPayload(
        state,
        action.payload,
        false,
        state.filterStore.dynamicGlobalFilters,
      );
    },
    [DashboardAction.SET_EDIT_MODE_FOR_DASHBOARD]: (state:IDashboardState, action:PayloadAction<boolean>) => {
      state.isEditMode = action.payload;
    },
    [DashboardAction.DASHBOARD_FILTERS_ONCHANGE]: (state:IDashboardState, action:PayloadAction<IFiltersOnchange>) => {
      state.filterStore = getUpdatedFilterChangeData(state, action.payload);
      state.panelPayloads = transformDashboardConfigToPanelPayload(
        state,
        state.dashboardConfig,
        false,
        getStateOnFilterChange(state.filterStore.dynamicGlobalFilters, action.payload),
      );
    },
    [DashboardAction.DASHBOARD_APPLY_FILTERS]: (state:IDashboardState) => {
      state.filterStore.staticGlobalFilters = state.filterStore.dynamicGlobalFilters;
      state.loadFilterdAnimation = true;
    },
    [DashboardAction.UPDATE_DASHBOARD_CONFIG]: (state:IDashboardState, action:PayloadAction<any>) => {
      state.dashboardConfig = getStateOnUpdateDashboardConfig(state, action.payload);
    },
    [DashboardAction.RESET_DASHBOARD_STATE]: (state:IDashboardState) => {
      state.dashboardList = InitialDashboardStoreState.dashboardList;
      state.dashboardConfig = {
        ...InitialDashboardStoreState.dashboardConfig,
        Panels: [],
      };
      state.filterStore = {
        ...InitialDashboardStoreState.filterStore,
        dynamicGlobalFilters: [],
      };
      state.requestProcessing = InitialDashboardStoreState.requestProcessing;
      state.configLoadingStatus = InitialDashboardStoreState.configLoadingStatus;
      state.panelPayloads = InitialDashboardStoreState.panelPayloads;
      state.dashboardData = InitialDashboardStoreState.dashboardData;
      state.loadFilterdAnimation = InitialDashboardStoreState.loadFilterdAnimation;
      state.accesscontrolLoading = InitialDashboardStoreState.accesscontrolLoading;
      state.dashboardBuilder = InitialDashboardStoreState.dashboardBuilder;
      state.isEditMode = InitialDashboardStoreState.isEditMode;
      state.dashboardStatusCode = InitialDashboardStoreState.dashboardStatusCode;
      state.triggerCalls = InitialDashboardStoreState.triggerCalls;
      state.filterLoaded = InitialDashboardStoreState.filterLoaded;
    },
    [DashboardAction.FILTERED_ANIMATION_COMPLETED]: (state:IDashboardState) => {
      state.loadFilterdAnimation = false;
      state.filterStore.updatedFilterIds = [];
    },

    [DashboardAction.RESET_REDIRECTION_STATUS]: (state:IDashboardState, action:PayloadAction<string>) => {
      state.dashboardData = getStateOnResetRedirectionStatus(state, action.payload);
    },
    [DashboardAction.UPDATE_WIDGET_SETTING]: (state:IDashboardState, action:PayloadAction<IWidgetSettings>) => {
      const widgetSettings = state.dashboardBuilder?.selectedReportWidget?.widgetSettings;
      const updatedWidgetSetting = { ...widgetSettings, ...action.payload };
      state.dashboardBuilder.selectedReportWidget.widgetSettings = updatedWidgetSetting;
    },
    [DashboardAction.RESET_EMBED_URL_STATE]: (state:IDashboardState) => {
      state.dashboardBuilder.requestProcessing[DashboardAction.VALIDATE_EMBED_URL] = APIRequestStatus.AboutToLoad;
      state.dashboardBuilder.embedURLSettings = { ...InitialDashboardStoreState.dashboardBuilder.embedURLSettings };
      state.dashboardBuilder.assortedInfo.widgetEditInfo = {
        id: '',
        widgetDetails: {},
      };
    },
    [DashboardAction.ADD_EDIT_EMBED_URL_CONFIG]: (state: IDashboardState) => {
      const { TitleSection, Panels } = state.dashboardConfig;
      const { Title, Description } = TitleSection;
      const { id } = state.dashboardBuilder.assortedInfo.widgetEditInfo;
      let newPanelConfig = {} as IPanel;
      if (id) {
        const panelInfo = generateEmbedUrlConfig(state);
        const indexOfModifiedPanel = Panels?.findIndex((panel: IPanel) => panel.Id === id);

        if (indexOfModifiedPanel >= 0) {
          newPanelConfig = {
            ...panelInfo,
            Id: id,
            Source: {
              ...panelInfo.Source,
              GlobalFiltersMapping: Panels[indexOfModifiedPanel].Source.GlobalFiltersMapping,
            },
            Target: {
              ...panelInfo.Target,
              GridLayout: Panels[indexOfModifiedPanel]?.Target?.GridLayout,
            },
          };
          state.dashboardConfig.Panels[indexOfModifiedPanel] = newPanelConfig;
        }
      } else {
        newPanelConfig = generateEmbedUrlConfig(state);
        state.dashboardConfig.Panels.push(newPanelConfig);
      }
      // to show loading state once panel is added
      const initialDataInfo: IDashboardData = {
        Raw: null,
        Visualization: null,
        loadingState: LoadingStatus.Success,
        saveTempViewLoadingStatus: null,
        saveTempDrilldownLoadingStatus: null,
        ddStateId: null,
        panelStatusCode: statusCodes.Success,
      };
      state.dashboardData[newPanelConfig.Id] = initialDataInfo;
      state.dashboardBuilder.assortedInfo.isFormDisabled = getIsDashboardBuilderSaveFormDisable(
        Title,
        Description,
        [...state.dashboardConfig.Panels, newPanelConfig],
      );
      state.dashboardBuilder.assortedInfo.addedWidget = newPanelConfig.Id;
      state.dashboardBuilder.assortedInfo.widgetEditInfo = {
        id: '',
        widgetDetails: {},
      };
    },
    [DashboardAction.UPDATE_FILTER]: (state:IDashboardState, action:PayloadAction<IFilterItem>) => {
      const status = action.payload?.Status;
      const updatedFilterObj = {
        ...state.filterStore.selectedFilter,
        ...action.payload,
        Metadata: {
          ...action?.payload?.Metadata,
          ...(status ? { Status: status } : {}),
        },
      };
      state.filterStore.selectedFilter = updatedFilterObj;
    },
    [DashboardAction.ADD_FILTER]: (state:IDashboardState, action:PayloadAction<{filterItem:IFilterItem, partitionColumnFilterDateLimit:number}>) => {
      state.filterStore = getUpdatedFilterJsonOnAdd(state.filterStore, action.payload.filterItem, action.payload.partitionColumnFilterDateLimit);
    },

    [DashboardAction.UPDATE_SELECTED_FILTER_ITEM]: (state:IDashboardState, action:PayloadAction<IFilterItem>) => {
      const isDropdownFilterType = action.payload?.Type === FilterType.LSQMetadataMultiSelect;
      const updatedFilterObj = {
        ...state.filterStore.selectedFilter,
        ...action.payload,
      };
      if (isDropdownFilterType && updatedFilterObj.DataType === '') {
        const dataTypeInfo = setDataTypeForFilterItem(updatedFilterObj, state.dashboardConfig.Panels, state.filterStore.reportMappedFilters);
        updatedFilterObj.DataType = dataTypeInfo;
      }
      state.filterStore.selectedFilter = updatedFilterObj;
    },
    [DashboardAction.DELETE_FILTER]: (state:IDashboardState, action:PayloadAction<IFilterItem>) => {
      state.filterStore = getUpdatedFilterJsonOnDelete(state.filterStore, action.payload);
    },
    [DashboardAction.SAVE_AND_APPLY_FILTERS]: (state:IDashboardState) => {
      const {
        panelPayloads, filterStore, dashboardConfig, loadFilterdAnimation, triggerCalls,
      } = getDashboardBuilderSaveConfigPayload(state);
      state.panelPayloads = panelPayloads;
      state.filterStore = filterStore;
      state.dashboardConfig = dashboardConfig;
      state.loadFilterdAnimation = loadFilterdAnimation;
      state.triggerCalls = triggerCalls;
    },
    [DashboardAction.RESET_SELECTED_FILTER]: (state:IDashboardState) => {
      state.filterStore.selectedFilter = {
        ID: '',
        Type: FilterType.EmptyFilterType,
      };
    },
    [DashboardAction.RESET_UPDATED_PANELS]: (state:IDashboardState) => {
      state.filterStore.updatePanelList = [];
      state.triggerCalls = false;
    },
    [DashboardAction.CLOSE_FILTER_PANEL]: (state:IDashboardState) => {
      state.filterStore.dynamicGlobalFilters = state.filterStore.staticGlobalFilters;
      state.panelPayloads = updatePanelPayloadOnClose(state.panelPayloads);
    },
    [DashboardAction.SEARCH_FILTER_DATA_LOAD_REQUEST]: (state:IDashboardState, action:PayloadAction<ISearchFilterPayloadRequest>) => {
      const { filterId, searchKey } = action.payload;
      const filters = state?.filterStore?.dynamicGlobalFilters;
      const filterIndex = isValidArray(filters) && filters.findIndex(
        (config) => config.ID === filterId,
      );

      if (filterIndex >= 0) {
        filters[filterIndex].filterData.OptionsMeta.SearchKey = searchKey;
        filters[filterIndex].filterData.OptionsMeta.Page.PageIndex = 0;
        filters[filterIndex].filterData.FilterOptions = [];
      }
      state.filterStore.dynamicGlobalFilters = filters;
    },
    [DashboardAction.LOAD_MORE_FILTER_DATA_LOAD_REQUEST]: (state:IDashboardState, action:PayloadAction<ILoadMoreFilterPayloadRequest>) => {
      const { filterId } = action.payload;
      const filters = state?.filterStore?.dynamicGlobalFilters;
      const filterIndex = isValidArray(filters) && filters.findIndex(
        (config) => config.ID === filterId,
      );
      if (filterIndex >= 0) {
        filters[filterIndex].filterData.OptionsMeta.Page.PageIndex += 1;
      }
      state.filterStore.dynamicGlobalFilters = filters;
    },
    [DashboardAction.SET_FILTER_LOAD_DONE]: (state:IDashboardState) => {
      state.filterLoaded = false;
    },
    [DashboardAction.CLEAR_MODIFIED_FILTER_ID]: (state:IDashboardState) => {
      state.filterStore.latestModifiedFilterId = null;
    },
    [DashboardAction.SET_REPORT_SEARCH_VALUE]: (state:IDashboardState, action:PayloadAction<string>) => {
      state.dashboardBuilder.assortedInfo.reportSearchKey = action.payload;
    },

    [DashboardAction.UPDATE_DASHBOARD_BUILDER_TITLE_SECTION]: (state:IDashboardState, action:PayloadAction<ITitleSection>) => {
      const { Title, Description } = action.payload;
      state.dashboardConfig.TitleSection = action.payload;
      state.dashboardBuilder.assortedInfo.isFormDisabled = getIsDashboardBuilderSaveFormDisable(
        Title,
        Description,
        state.dashboardConfig.Panels,
      );
    },

    [DashboardAction.UPDATE_DASHBOARD_BUILDER_PANEL_INFO]: (state:IDashboardState, action:PayloadAction<IDashboardPanelPayload>) => {
      const { Title, Description } = state.dashboardConfig.TitleSection;
      let newPanelConfig: IPanel = getAddWidgetInfo(
        action.payload.reportConfig,
        state.dashboardConfig.Panels.length,
        action.payload.widgetSettings,
      );
      if (action.payload.isPanelEdit) {
        const indexOfModifiedPanel = state.dashboardConfig.Panels.findIndex((panel: IPanel) => panel.Id === newPanelConfig.Id);
        if (indexOfModifiedPanel >= 0) {
          newPanelConfig = {
            ...newPanelConfig,
            Source: {
              ...newPanelConfig.Source,
              GlobalFiltersMapping: state.dashboardConfig.Panels[indexOfModifiedPanel].Source.GlobalFiltersMapping,
            },
            Target: {
              ...newPanelConfig.Target,
              GridLayout: {
                ...state.dashboardConfig.Panels[indexOfModifiedPanel]?.Target?.GridLayout,
                w: newPanelConfig.Target.GridLayout.w,
                h: newPanelConfig.Target.GridLayout.h,
              },
            },
          };
          if (newPanelConfig?.Target?.Type === state.dashboardConfig.Panels[indexOfModifiedPanel]?.Target?.Type) {
            newPanelConfig.Target.GridLayout.w = state.dashboardConfig.Panels[indexOfModifiedPanel]?.Target?.GridLayout.w;
            newPanelConfig.Target.GridLayout.h = state.dashboardConfig.Panels[indexOfModifiedPanel]?.Target?.GridLayout.h;
          }
          state.dashboardConfig.Panels[indexOfModifiedPanel] = newPanelConfig;
        }
      } else {
        state.dashboardConfig.Panels.push(newPanelConfig);
      }
      // to show loading state once panel is added
      const initialDataInfo: IDashboardData = {
        Raw: null,
        Visualization: null,
        loadingState: LoadingStatus.Loading,
        saveTempViewLoadingStatus: null,
        saveTempDrilldownLoadingStatus: null,
        ddStateId: null,
        panelStatusCode: statusCodes.Success,
      };
      state.dashboardData[newPanelConfig.Id] = initialDataInfo;
      state.dashboardBuilder.assortedInfo.isFormDisabled = getIsDashboardBuilderSaveFormDisable(
        Title,
        Description,
        [
          ...state.dashboardConfig.Panels,
          newPanelConfig,
        ],
      );
      state.dashboardBuilder.assortedInfo.addedWidget = newPanelConfig.Id;
      state.filterStore.panelMappedPartitionColumns = isPanelAppliedPartitionedColumn(state.filterStore.panelMappedPartitionColumns, action.payload.reportConfig?.Columns, newPanelConfig.Id, action.payload?.reportConfig?.ConfigMetadata);
    },

    [DashboardAction.ITEM_ADDED_SUCCESSFULLY]: (state:IDashboardState) => {
      state.dashboardBuilder.assortedInfo.addedWidget = '';
      state.dashboardBuilder.assortedInfo.widgetEditInfo = {
        id: '',
        widgetDetails: {},
      };
      state.dashboardBuilder.selectedReportWidget = {};
      state.dashboardBuilder.embedURLSettings = { ...InitialDashboardStoreState.dashboardBuilder.embedURLSettings };
    },
    [DashboardAction.SET_PAGE_CONTEXT]: (state:IDashboardState, action:PayloadAction<DashboardContext>) => {
      state.dashboardBuilder.assortedInfo.context = action.payload;
    },
    [DashboardAction.REMOVE_DASHBOARD_WIDGET]: (state:IDashboardState, action:PayloadAction<string>) => {
      const selectedPanelId = action.payload;
      const { Title, Description } = state.dashboardConfig.TitleSection;
      let selectedPanelObj = {} as IPanel;
      const updatedPanelConfig = [] as IPanel[];
      state.dashboardConfig.Panels.forEach((panel:IPanel) => {
        if (panel.Id === selectedPanelId) {
          selectedPanelObj = panel;
        } else {
          updatedPanelConfig.push(panel);
        }
      });
      state.dashboardConfig.Panels = updatedPanelConfig;
      state.dashboardBuilder.assortedInfo.isFormDisabled = getIsDashboardBuilderSaveFormDisable(
        Title,
        Description,
        updatedPanelConfig,
      );
      state.filterStore = getUpdatedFilterStoreOnWidgetDelete(state.filterStore, selectedPanelObj);
    },
    // api's
    [DashboardAction.GET_SELECTED_REPORT_WIDGET_INFO]: (state:IDashboardState) => {
      state.dashboardBuilder.requestProcessing[DashboardAction.GET_SELECTED_REPORT_WIDGET_INFO] = APIRequestStatus.Processing;
    },
    [DashboardAction.GET_SELECTED_REPORT_WIDGET_INFO_SUCCESS]: (state:IDashboardState, action:PayloadAction<IReportWidget>) => {
      state.dashboardBuilder.requestProcessing[DashboardAction.GET_SELECTED_REPORT_WIDGET_INFO] = APIRequestStatus.Success;
      state.dashboardBuilder.selectedReportWidget = getWidgetData(action.payload);
      state.filterStore.reportMappedFilters = getUpdatedAvailableFilters(state.filterStore.reportMappedFilters, action.payload.rawReportConfig);

      if (state.dashboardBuilder.assortedInfo.widgetEditInfo?.id) {
        state.dashboardBuilder.assortedInfo.widgetEditInfo = {
          id: action.payload.widgetSettings.uniquePanelId,
          widgetDetails: action.payload.widgetSettings,
        };
      }
    },
    [DashboardAction.GET_SELECTED_REPORT_WIDGET_INFO_FAILURE]: (state:IDashboardState) => {
      state.dashboardBuilder.requestProcessing[DashboardAction.GET_SELECTED_REPORT_WIDGET_INFO] = APIRequestStatus.Failure;
    },

    [DashboardAction.VALIDATE_EMBED_URL]: (state:IDashboardState) => {
      state.dashboardBuilder.requestProcessing[DashboardAction.VALIDATE_EMBED_URL] = APIRequestStatus.Processing;
    },
    [DashboardAction.VALIDATE_EMBED_URL_SUCCESS]: (state:IDashboardState, action:PayloadAction<IValidateURLPayload>) => {
      state.dashboardBuilder.requestProcessing[DashboardAction.VALIDATE_EMBED_URL] = APIRequestStatus.Success;
      state.dashboardBuilder.embedURLSettings = {
        ...state.dashboardBuilder.embedURLSettings,
        title: action.payload?.name,
        decodedUrl: action.payload?.embedUrl,
        source: action.payload?.source,
        sourceUrl: action.payload?.sourceUrl,
        isUrlError: false,
        isMailMergeError: false,
      };
    },
    [DashboardAction.VALIDATE_EMBED_URL_FAILURE]: (state:IDashboardState, action:PayloadAction<{urlError:boolean, mailMergeError:boolean}>) => {
      state.dashboardBuilder.requestProcessing[DashboardAction.VALIDATE_EMBED_URL] = APIRequestStatus.Failure;
      state.dashboardBuilder.embedURLSettings.isUrlError = action.payload.urlError;
      state.dashboardBuilder.embedURLSettings.isMailMergeError = action.payload.mailMergeError;
    },

    // getDashboardConfigRequest fetch api
    [DashboardAction.REQUEST_DASHBOARD_CONFIG]: (state:IDashboardState) => {
      state.requestProcessing[DashboardAction.REQUEST_DASHBOARD_CONFIG] = APIRequestStatus.Processing;
      state.configLoadingStatus = LoadingStatus.Loading;
    },
    [DashboardAction.REQUEST_DASHBOARD_CONFIG_SUCCESS]: (state:IDashboardState, action:PayloadAction<SieraResponse<IDashboardConfig>>) => {
      state.requestProcessing[DashboardAction.REQUEST_DASHBOARD_CONFIG] = APIRequestStatus.Success;
      state.dashboardData = setInitalPayloadData(action?.payload?.data);
      state.dashboardStatusCode = action?.payload?.status;
      state.dashboardConfig = getStateOnRequestDashboardConfigSuccess(
        action.payload.data,
        state.isEditMode,
      );
      state.configLoadingStatus = LoadingStatus.Success;
      state.filterStore.dynamicGlobalFilters = getFiltersOnConfigSuccess(action.payload?.data?.Filters);
      state.filterStore.staticGlobalFilters = getFiltersOnConfigSuccess(action.payload?.data?.Filters);

      state.panelPayloads = transformDashboardConfigToPanelPayload(
        state,
        action.payload.data,
        false,
        state.filterStore.dynamicGlobalFilters,
      );
    },
    [DashboardAction.REQUEST_DASHBOARD_CONFIG_FAILURE]: (state:IDashboardState) => {
      state.requestProcessing[DashboardAction.REQUEST_DASHBOARD_CONFIG] = APIRequestStatus.Failure;
      state.configLoadingStatus = LoadingStatus.Error;
    },
    [DashboardAction.UPDATE_DASHBOARD_CONFIG_FOR_CLONING]: (state:IDashboardState) => {
      state.dashboardConfig.Id = '';
      state.dashboardConfig.Type = ReportType.Custom; // to ensure all cloned dashboards are of type Custom
    },
    [DashboardAction.EDIT_DASHBOARD_WIDGET_INFO]: (state:IDashboardState, action:PayloadAction<IWidgetEditInfo>) => {
      state.dashboardBuilder.assortedInfo.widgetEditInfo = action.payload;
    },
    [DashboardAction.RESET_WIDGET_INFO]: (state: IDashboardState) => {
      state.dashboardBuilder.assortedInfo.addedWidget = '';
      state.dashboardBuilder.assortedInfo.widgetEditInfo = {
        id: '',
        widgetDetails: {},
      };
      state.dashboardBuilder.selectedReportWidget = {};
      state.dashboardBuilder.embedURLSettings = { ...InitialDashboardStoreState.dashboardBuilder.embedURLSettings };
    },
    [DashboardAction.DELETE_DASHBOARD]: (state: IDashboardState) => {
      state.requestProcessing[DashboardAction.DELETE_DASHBOARD] = APIRequestStatus.Processing;
    },

  },
  extraReducers: (builder) => {
    // getDashboardListRequest fetch api
    builder.addCase(getDashboardListRequest.pending, (state:IDashboardState) => {
      state.requestProcessing[DashboardAction.REQUEST_DASHBOARDS_LIST] = APIRequestStatus.Processing;
    });
    builder.addCase(getDashboardListRequest.fulfilled, (state:IDashboardState, action:PayloadAction<Array<IDashboardItem>>) => {
      state.requestProcessing[DashboardAction.REQUEST_DASHBOARDS_LIST] = APIRequestStatus.Success;
      state.dashboardList.dashboardItems = action.payload;
    });
    builder.addCase(getDashboardListRequest.rejected, (state:IDashboardState) => {
      state.requestProcessing[DashboardAction.REQUEST_DASHBOARDS_LIST] = APIRequestStatus.Failure;
    });

    // savetempViewRequest mutation call
    builder.addCase(saveTempViewRequest.pending, (state:IDashboardState, action) => {
      state.requestProcessing[DashboardAction.SAVE_TEMP_VIEW_REQUEST] = APIRequestStatus.Processing;
      state.dashboardData = getStateOnSaveTempViewRequest(state, action?.meta?.arg?.panelId);
    });

    builder.addCase(saveTempViewRequest.fulfilled, (state:IDashboardState, action) => {
      state.requestProcessing[DashboardAction.SAVE_TEMP_VIEW_REQUEST] = APIRequestStatus.Success;
      state.dashboardData = getStateOnSaveTempViewSuccess(state, action?.meta?.arg?.panelId);
    });
    builder.addCase(saveTempViewRequest.rejected, (state:IDashboardState, action) => {
      state.requestProcessing[DashboardAction.SAVE_TEMP_VIEW_REQUEST] = APIRequestStatus.Failure;
      state.dashboardData = getStateOnSaveTempViewFailure(state, action?.meta?.arg?.panelId);
    });

    // savetempDrilldownViewRequest mutation call
    builder.addCase(saveTempDrilldownRequest.pending, (state:IDashboardState, action:ObjModel.Obj) => {
      state.requestProcessing[DashboardAction.SAVE_TEMP_DRILLDOWN_REQUEST] = APIRequestStatus.Processing;
      state.dashboardData = getStateOnSaveTempDrilldownRequest(state, action?.meta?.arg?.panelId);
    });

    builder.addCase(saveTempDrilldownRequest.fulfilled, (state:IDashboardState, action:ObjModel.Obj) => {
      state.requestProcessing[DashboardAction.SAVE_TEMP_DRILLDOWN_REQUEST] = APIRequestStatus.Success;
      state.dashboardData = getStateOnSaveTempDrilldownSuccess(state, action?.meta?.arg?.panelId, action.payload);
    });
    builder.addCase(saveTempDrilldownRequest.rejected, (state:IDashboardState, action:ObjModel.Obj) => {
      state.requestProcessing[DashboardAction.SAVE_TEMP_DRILLDOWN_REQUEST] = APIRequestStatus.Failure;
      state.dashboardData = getStateOnSaveTempDrilldownFailure(state, action?.meta?.arg?.panelId);
    });

    // personalisation of dashboard
    builder.addCase(setPersonalizedDashboardRequest.pending, (state:IDashboardState) => {
      state.requestProcessing[DashboardAction.SET_PERSONALIZED_DASHBOARD_REQUEST] = APIRequestStatus.Processing;
    });
    builder.addCase(setPersonalizedDashboardRequest.fulfilled, (state:IDashboardState) => {
      state.requestProcessing[DashboardAction.SET_PERSONALIZED_DASHBOARD_REQUEST] = APIRequestStatus.Success;
    });
    builder.addCase(setPersonalizedDashboardRequest.rejected, (state:IDashboardState) => {
      state.requestProcessing[DashboardAction.SET_PERSONALIZED_DASHBOARD_REQUEST] = APIRequestStatus.Failure;
    });

    // dashboardAccessControlRequest call
    builder.addCase(dashboardAccessControlRequest.pending, (state:IDashboardState) => {
      state.requestProcessing[DashboardAction.DASHBOARD_ACCESS_CONTROL_REQUEST] = APIRequestStatus.Processing;
      state.accesscontrolLoading = LoadingStatus.Loading;
    });

    builder.addCase(dashboardAccessControlRequest.fulfilled, (state:IDashboardState, action:PayloadAction<AuthResponseModel.Auth>) => {
      state.requestProcessing[DashboardAction.DASHBOARD_ACCESS_CONTROL_REQUEST] = APIRequestStatus.Success;
      state.accesscontrolLoading = LoadingStatus.Success;
      state.dashboardStatusCode = action.payload.statusCodes || statusCodes.Success;
    });
    builder.addCase(dashboardAccessControlRequest.rejected, (state:IDashboardState) => {
      state.requestProcessing[DashboardAction.DASHBOARD_ACCESS_CONTROL_REQUEST] = APIRequestStatus.Failure;
      state.accesscontrolLoading = LoadingStatus.Error;
    });

    // deleteDashboardItemRequest mutation call
    builder.addCase(deleteDashboardItemRequest.pending, (state:IDashboardState) => {
      state.requestProcessing[DashboardAction.DELETE_DASHBOARD] = APIRequestStatus.Processing;
    });
    builder.addCase(deleteDashboardItemRequest.fulfilled, (state:IDashboardState) => {
      state.requestProcessing[DashboardAction.DELETE_DASHBOARD] = APIRequestStatus.Success;
    });
    builder.addCase(deleteDashboardItemRequest.rejected, (state:IDashboardState) => {
      state.requestProcessing[DashboardAction.DELETE_DASHBOARD] = APIRequestStatus.Failure;
    });

    // getFiltersListInfoRequest fetch call
    builder.addCase(getFiltersListInfoRequest.pending, (state:IDashboardState) => {
      state.requestProcessing[DashboardAction.GET_FILTERS_LIST_REQUEST] = APIRequestStatus.Processing;
    });
    builder.addCase(getFiltersListInfoRequest.fulfilled, (state:IDashboardState, action:PayloadAction<IFilterListResponse>) => {
      state.requestProcessing[DashboardAction.GET_FILTERS_LIST_REQUEST] = APIRequestStatus.Success;
      state.filterStore = getInitialisedFilterStoreForEdit(state.filterStore, action?.payload, state?.dashboardConfig?.Panels);
    });
    builder.addCase(getFiltersListInfoRequest.rejected, (state:IDashboardState) => {
      state.requestProcessing[DashboardAction.GET_FILTERS_LIST_REQUEST] = APIRequestStatus.Failure;
    });

    // dashboard builder api's
    // getAllReportsRequest fetch api
    builder.addCase(getAllReportsRequest.pending, (state:IDashboardState) => {
      state.dashboardBuilder.requestProcessing[DashboardAction.GET_REPORT_LIST_REQUEST] = APIRequestStatus.Processing;
    });
    builder.addCase(getAllReportsRequest.fulfilled, (state: IDashboardState, action: ObjModel.Obj) => {
      state.dashboardBuilder.requestProcessing[DashboardAction.GET_REPORT_LIST_REQUEST] = APIRequestStatus.Success;
      state.dashboardBuilder.dashboardBuilderInfo.reportList = getReportSections(action.payload, action?.meta?.arg);
    });
    builder.addCase(getAllReportsRequest.rejected, (state:IDashboardState) => {
      state.dashboardBuilder.requestProcessing[DashboardAction.GET_REPORT_LIST_REQUEST] = APIRequestStatus.Failure;
    });

    // getWidgetReportDataRequest fetch api
    builder.addCase(getWidgetReportDataRequest.pending, (state:IDashboardState) => {
      state.dashboardBuilder.requestProcessing[DashboardAction.GET_WIDGET_REPORT_DATA_REQUEST] = APIRequestStatus.Processing;
    });
    builder.addCase(getWidgetReportDataRequest.fulfilled, (state:IDashboardState) => {
      state.dashboardBuilder.requestProcessing[DashboardAction.GET_WIDGET_REPORT_DATA_REQUEST] = APIRequestStatus.Success;
    });
    builder.addCase(getWidgetReportDataRequest.rejected, (state:IDashboardState) => {
      state.dashboardBuilder.requestProcessing[DashboardAction.GET_WIDGET_REPORT_DATA_REQUEST] = APIRequestStatus.Failure;
    });

    // saveDashboardConfigurationRequest mutation api
    builder.addCase(saveDashboardConfigurationRequest.pending, (state:IDashboardState) => {
      state.dashboardBuilder.requestProcessing[DashboardAction.SAVE_DASHBOARD_CONFIG] = APIRequestStatus.Processing;
    });
    builder.addCase(saveDashboardConfigurationRequest.fulfilled, (state:IDashboardState, action:PayloadAction<any, string, ObjModel.Obj>) => {
      state.dashboardConfig.Id = action.payload;
      state.dashboardBuilder.requestProcessing[DashboardAction.SAVE_DASHBOARD_CONFIG] = APIRequestStatus.Success;
      state.dashboardBuilder.assortedInfo.dashboardId = action.payload;
    });
    builder.addCase(saveDashboardConfigurationRequest.rejected, (state:IDashboardState, action: PayloadAction<any>) => {
      if (action.payload?.status !== statusCodes.Forbidden) {
        state.dashboardBuilder.requestProcessing[DashboardAction.SAVE_DASHBOARD_CONFIG] = APIRequestStatus.Failure;
      } else {
        state.dashboardBuilder.requestProcessing[DashboardAction.SAVE_DASHBOARD_CONFIG] = APIRequestStatus.AboutToLoad;
      }
    });

    // filterDataLoadRequest  api
    builder.addCase(filterLoadRequest.pending, (state: IDashboardState, action: PayloadAction<any, string, ObjModel.Obj>) => {
      const { filterId, isLazyLoad } = action.meta.arg;
      const filtersLoading = state.dashboardBuilder.requestProcessing[DashboardAction.FILTER_DATA_LOAD_REQUEST];

      if (isLazyLoad) {
        filtersLoading[filterId] = APIRequestStatus.LazyLoading;
        state.dashboardBuilder.requestProcessing[
          DashboardAction.FILTER_DATA_LOAD_REQUEST
        ] = filtersLoading;
      } else {
        filtersLoading[filterId] = APIRequestStatus.Processing;
        state.dashboardBuilder.requestProcessing[
          DashboardAction.FILTER_DATA_LOAD_REQUEST
        ] = filtersLoading;
      }
    });
    builder.addCase(filterLoadRequest.fulfilled, (state: IDashboardState, action: PayloadAction<any, string, ObjModel.Obj>) => {
      const { status } = action.payload;
      const { filterId, isLazyLoad } = action.meta.arg;
      if (action.payload) {
        if (status === statusCodes.Forbidden) {
          state.filterStore = getUpdatedFilterInfo(state.filterStore, filterId);
          const filtersLoading = state.dashboardBuilder.requestProcessing[DashboardAction.FILTER_DATA_LOAD_REQUEST];
          filtersLoading[filterId] = APIRequestStatus.Failure;
          state.dashboardBuilder.requestProcessing[
            DashboardAction.FILTER_DATA_LOAD_REQUEST
          ] = filtersLoading;
        }
        const filterResponse = action.payload as IFilterResponse;
        if (isLazyLoad) {
          const filter: IFilterItem = isValidArrayAndNotEmpty(state.filterStore.dynamicGlobalFilters) && state.filterStore.dynamicGlobalFilters.find(
            (item: IFilterItem) => item.ID === filterId,
          );
          filterResponse.FilterResponse = filter?.filterData?.FilterResponse;
        }
        const filtersLoading = state.dashboardBuilder.requestProcessing[
          DashboardAction.FILTER_DATA_LOAD_REQUEST
        ];
        filtersLoading[filterId] = APIRequestStatus.Success;
        state.filterStore.dynamicGlobalFilters = [...getFilterLoadSuccess(state?.filterStore?.dynamicGlobalFilters, filterId, filterResponse)];
        state.filterStore.staticGlobalFilters = [...getFilterLoadSuccess(state?.filterStore?.staticGlobalFilters, filterId, filterResponse, state.filterLoaded)];
        state.panelPayloads = transformDashboardConfigToPanelPayload(
          state,
          state.dashboardConfig,
          false,
          [...getFilterLoadSuccess(state?.filterStore?.dynamicGlobalFilters, filterId, filterResponse)],
        );
        state.dashboardBuilder.requestProcessing[DashboardAction.FILTER_DATA_LOAD_REQUEST] = filtersLoading;
      } else {
        const filtersLoading = state.dashboardBuilder.requestProcessing[DashboardAction.FILTER_DATA_LOAD_REQUEST];
        filtersLoading[filterId] = APIRequestStatus.Failure;
        state.dashboardBuilder.requestProcessing[
          DashboardAction.FILTER_DATA_LOAD_REQUEST
        ] = filtersLoading;
      }
    });
    builder.addCase(filterLoadRequest.rejected, (state: IDashboardState, action: PayloadAction<any, string, ObjModel.Obj>) => {
      const filterId = action.meta.arg;
      const filtersLoading = state.dashboardBuilder.requestProcessing[DashboardAction.FILTER_DATA_LOAD_REQUEST];
      filtersLoading[filterId] = APIRequestStatus.Failure;
      state.dashboardBuilder.requestProcessing[
        DashboardAction.FILTER_DATA_LOAD_REQUEST
      ] = filtersLoading;
    });
  },

});

export const {
  [DashboardAction.REQUEST_PANEL_DATA]: requestPanelData,
  [DashboardAction.REQUEST_PANEL_DATA_SUCCESS]: requestPanelDataSuccess,
  [DashboardAction.REQUEST_PANEL_DATA_FAILURE]: requestPanelDataFailure,
  [DashboardAction.TRANSFORM_DASHBOARD_CONFIG_TO_PAYLOAD]: transformDashboardConfig,
  [DashboardAction.SET_EDIT_MODE_FOR_DASHBOARD]: setEditModeForDashboard,
  [DashboardAction.DASHBOARD_FILTERS_ONCHANGE]: onFilterChange,
  [DashboardAction.DASHBOARD_APPLY_FILTERS]: getStateOnApplyFilters,
  [DashboardAction.UPDATE_DASHBOARD_CONFIG]: updateDashboardConfig,
  [DashboardAction.RESET_DASHBOARD_STATE]: resetDashboardState,
  [DashboardAction.FILTERED_ANIMATION_COMPLETED]: filteredAnimationCompleted,
  [DashboardAction.RESET_REDIRECTION_STATUS]: resetRedirectionStatus,
  [DashboardAction.UPDATE_WIDGET_SETTING]: updateWidgetSetting,
  [DashboardAction.RESET_EMBED_URL_STATE]: resetEmbedUrlState,
  [DashboardAction.ADD_EDIT_EMBED_URL_CONFIG]: addEditEmbedUrlAction,
  [DashboardAction.UPDATE_FILTER]: updateFilter,
  [DashboardAction.ADD_FILTER]: AddFilter,
  [DashboardAction.UPDATE_SELECTED_FILTER_ITEM]: updateSelectedFilterItem,
  [DashboardAction.DELETE_FILTER]: deleteFilterItem,
  [DashboardAction.SAVE_AND_APPLY_FILTERS]: saveAndApplyFilter,
  [DashboardAction.RESET_SELECTED_FILTER]: resetSelectedFilter,
  [DashboardAction.RESET_UPDATED_PANELS]: resetUpdatedPanels,
  [DashboardAction.CLOSE_FILTER_PANEL]: onCloseFilterPanel,
  [DashboardAction.SEARCH_FILTER_DATA_LOAD_REQUEST]: searchDataLoadRequest,
  [DashboardAction.LOAD_MORE_FILTER_DATA_LOAD_REQUEST]: loadMoreDataLoadRequest,
  [DashboardAction.SET_FILTER_LOAD_DONE]: setDashboardFirstLoaded,
  [DashboardAction.CLEAR_MODIFIED_FILTER_ID]: clearModifiedFilterId,
  [DashboardAction.SET_REPORT_SEARCH_VALUE]: setReportSearchValue,
  [DashboardAction.UPDATE_DASHBOARD_BUILDER_TITLE_SECTION]: updateDashboardBuilderTitleSection,
  [DashboardAction.UPDATE_DASHBOARD_BUILDER_PANEL_INFO]: updateDashboardBuilderPanelInfo,
  [DashboardAction.ITEM_ADDED_SUCCESSFULLY]: itemAddedSuccessfully,
  [DashboardAction.SET_PAGE_CONTEXT]: setPageContextOfDashboard,
  [DashboardAction.REMOVE_DASHBOARD_WIDGET]: removeDashboardWidget,
  [DashboardAction.EDIT_DASHBOARD_WIDGET_INFO]: editDashboardWidgetInfo,
  [DashboardAction.RESET_WIDGET_INFO]: resetWidgetInfo,

  [DashboardAction.GET_SELECTED_REPORT_WIDGET_INFO]: getSelectedReportWidgetConfigRequest,
  [DashboardAction.GET_SELECTED_REPORT_WIDGET_INFO_SUCCESS]: getSelectedReportWidgetConfigSuccess,
  [DashboardAction.GET_SELECTED_REPORT_WIDGET_INFO_FAILURE]: getSelectedReportWidgetConfigFailure,
  [DashboardAction.VALIDATE_EMBED_URL]: validateEmbedRequestAction,
  [DashboardAction.VALIDATE_EMBED_URL_SUCCESS]: validateEmbedSuccessAction,
  [DashboardAction.VALIDATE_EMBED_URL_FAILURE]: validateEmbedFailureAction,
  [DashboardAction.REQUEST_DASHBOARD_CONFIG]: requestDashboardConfig,
  [DashboardAction.REQUEST_DASHBOARD_CONFIG_SUCCESS]: requestDashboardConfigSuccess,
  [DashboardAction.REQUEST_DASHBOARD_CONFIG_FAILURE]: requestDashboardConfigFailure,
  [DashboardAction.UPDATE_DASHBOARD_CONFIG_FOR_CLONING]: updateDashboardConfigForCloning,
  [DashboardAction.DELETE_DASHBOARD]: resetDeleteDashboardRequestStatus,

} = dashboardStoreSlice.actions;

export default dashboardStoreSlice;
