import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { APIRequestStatus } from 'core/constants/redux';
import { SectionItemId } from 'core/constants/report-listing';
import cloneDeep from 'lodash/cloneDeep';
import { ObjModel } from 'core/models';
import initialReportListingState from './report-listing-initial.state';
import {
  getAllDashboardSection,
  getFilteredReportByFilterValue,
  groupReportBasedOnTags,
} from './report-listing.util';
import {
  ActionInfoObject,
  IDashboardItem,
  IEditTagsData,
  IReportData,
  IReportListingState,
  IReportSection,
  ITagsData,
  ObjectActionType,
  ReportFilterValues,
  ReportListingActionTypes,
  ReportPinningFliter,
  TagByObject,
  Types,
} from './report-listing.state';
import {
  V1AccessControl,
  editTagsByObject,
  getAllDashboardsByGlobalSearch,
  getAllReports,
  getAllReportsByGlobalSearch,
  getDashboardList,
  getExternalReportDetails,
  getHomeDashoardsList,
  getPinnedDashboardList,
  getPinnedReports,
  getReportsByTag,
  getSavedReportDetails,
  getTagsByObject,
  pinUnpinDashboard,
  pinUnpinReport,
} from './report-listing.requests';

const reportListingSlice = createSlice({
  name: 'reportListingStore',
  initialState: initialReportListingState,
  reducers: {
    [ReportListingActionTypes.GET_EXTERNAL_REPORT_DETAILS_SUCCESS]: (state: IReportListingState, action:PayloadAction<IReportData>) => {
      state.externalReportDetails = action.payload;
    },
    [ReportListingActionTypes.SET_REPORT_FILTER_VALUE]: (state: IReportListingState, action: PayloadAction<{value: ReportFilterValues, sectionItem: string}>) => {
      state.filterValue = action.payload.value;
      let section: IReportSection[] = [];
      const selectedNavBarItem = action.payload.sectionItem;
      if (selectedNavBarItem === SectionItemId.AllReports) {
        section = state.allReportSections;
      } else if (selectedNavBarItem === SectionItemId.PinnedReports) {
        section = state.pinnedCategories;
      } else {
        section = state.tagCategoriesReports;
      }

      if (action.payload.value === ReportFilterValues.AllReports) {
        state.filteredReportSections = section;
        return;
      }

      // for siera or v1 reports
      const clonedAllReports = cloneDeep(section);
      const isInternal = action.payload.value === ReportFilterValues.SieraReports ? Types.Internal : Types.External;
      let newFilteredReports: IReportSection[] = [];
      clonedAllReports?.forEach((rep: IReportSection) => {
        const newSection = rep;
        newSection.Reports.Data = rep.Reports?.Data?.filter((data: IReportData) => data.Type === isInternal);
        if (newSection.Reports?.Data?.length !== 0) { newFilteredReports.push(newSection); }
      });
      // if empty array we want it has null, to show an empty screen
      if (newFilteredReports.length === 0) newFilteredReports = null;
      state.filteredReportSections = newFilteredReports;
    },
    [ReportListingActionTypes.SET_REPORT_ACTION_REPORTID]: (state: IReportListingState, action: PayloadAction<ActionInfoObject>) => {
      state.actionInfo = action.payload;
      const objectType = action.payload.ObjectType;
      const globalTags = action.payload.globalTags;
      let reportsSection = state.allReports;
      const allDashboards = state.allDashboards;
      const selectedNavBarItem = action.payload.sectionItem;
      if (selectedNavBarItem === SectionItemId.AllReports) {
        reportsSection = state?.allReports;
      } else if (selectedNavBarItem === SectionItemId.PinnedReports) {
        reportsSection = state?.pinnedCategories[0]?.Reports?.Data;
      } else {
        reportsSection = state?.tagCategoriesReports[0]?.Reports?.Data;
      }
      const editTagsList: IEditTagsData[] = [];
      const actionItem = objectType === ObjectActionType.Dashboard ? allDashboards?.find((item) => item.Id === action.payload.ObjectId) : reportsSection?.find((item) => (item.Id === action.payload.ObjectId));
      globalTags?.forEach((item) => {
        const isChecked = actionItem?.Tags?.includes(item?.Id) || false;
        editTagsList?.push({ ...item, checked: isChecked });
      });
      state.activeEditTagsList = editTagsList;
      state.appliedEditTagsList = editTagsList;
    },
    [ReportListingActionTypes.SELECT_OR_DESELECT_TAGS]: (state: IReportListingState, action: PayloadAction<{tagId: string, isChecked: boolean}>) => {
      const appliedEditTags: IEditTagsData[] = [];
      state.appliedEditTagsList.forEach((item) => {
        let updatedItem = item;
        if (item.Id === action.payload.tagId) {
          updatedItem = { ...item, checked: action.payload.isChecked };
        }
        appliedEditTags.push(updatedItem);
      });
      state.appliedEditTagsList = appliedEditTags;
    },

    [ReportListingActionTypes.TAG_ANIMATION_COMPLETE]: (state: IReportListingState) => {
      state.appliedEditTagsList = [];
      state.activeEditTagsList = [];
      state.requestProcessing[ReportListingActionTypes.EDIT_TAGS_BY_OBJECTID_REQUEST] = APIRequestStatus.Processing;
    },
    [ReportListingActionTypes.SET_REPORT_PINNED_FILTER_VALUE]: (state: IReportListingState, action: PayloadAction<ReportPinningFliter>) => {
      state.pinningFilterValue = action.payload;
    },
    [ReportListingActionTypes.SET_SELECTED_HOME_DASHBOARD]: (state: IReportListingState, action: PayloadAction<IDashboardItem>) => {
      state.selectedHomeDashboard = action.payload;
    },
    [ReportListingActionTypes.SET_REDIRECT_ALL_DASHBOARDS]: (state: IReportListingState, action: PayloadAction<boolean>) => {
      state.redirectAllDashboard = action.payload;
    },
    [ReportListingActionTypes.GET_EXTERNAL_REPORT_DETAILS_REQUEST]: (state: IReportListingState, action: PayloadAction<APIRequestStatus>) => {
      state.requestProcessing[ReportListingActionTypes.GET_EXTERNAL_REPORT_DETAILS_REQUEST] = action.payload;
    },
    [ReportListingActionTypes.PIN_UNPIN_REPORTS_REQUEST]: (state: IReportListingState) => {
      state.requestProcessing[ReportListingActionTypes.PIN_UNPIN_REPORTS_REQUEST] = APIRequestStatus.Processing;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getAllReports.pending, (state: IReportListingState) => {
      state.requestProcessing[ReportListingActionTypes.GET_ALL_REPORTS_REQUEST] = APIRequestStatus.Processing;
    });
    builder.addCase(getAllReports.fulfilled, (state: IReportListingState, action: PayloadAction<IReportData[], string, ObjModel.Obj>) => {
      let newRes: IReportSection[] = [];
      const globalTags = action.meta.arg.globalTags;
      if (action.payload) {
        newRes = groupReportBasedOnTags(action.payload, globalTags);
        state.allReportSections = [...newRes];
      } else {
        state.filteredReportSections = null;
        state.allReports = action.payload;
        state.requestProcessing[ReportListingActionTypes.GET_ALL_REPORTS_REQUEST] = APIRequestStatus.Success;
        return;
      }
      const selectedNavBarItem = action?.meta.arg?.sectionItem;
      state.filteredReportSections = getFilteredReportByFilterValue(
        state,
        state.filterValue,
        selectedNavBarItem,
      );
      state.requestProcessing[ReportListingActionTypes.GET_ALL_REPORTS_REQUEST] = APIRequestStatus.Success;
      state.requestProcessing[ReportListingActionTypes.EDIT_TAGS_BY_OBJECTID_REQUEST] = APIRequestStatus.Processing;
      state.allReports = action.payload;
    });
    builder.addCase(getAllReports.rejected, (state: IReportListingState) => {
      state.requestProcessing[ReportListingActionTypes.GET_ALL_REPORTS_REQUEST] = APIRequestStatus.Failure;
      state.filteredReportSections = null;
    });
    builder.addCase(getAllReportsByGlobalSearch.pending, (state: IReportListingState) => {
      state.globalSearchresults = [];
      state.requestProcessing[ReportListingActionTypes.GET_ALL_REPORTS_SEARCH_REQUEST] = APIRequestStatus.Processing;
    });
    builder.addCase(getAllReportsByGlobalSearch.fulfilled, (state: IReportListingState, action: PayloadAction<Array<IReportData>>) => {
      state.globalSearchresults = action.payload || [];
      state.requestProcessing[ReportListingActionTypes.GET_ALL_REPORTS_SEARCH_REQUEST] = APIRequestStatus.Success;
    });
    builder.addCase(getAllReportsByGlobalSearch.rejected, (state: IReportListingState) => {
      state.requestProcessing[ReportListingActionTypes.GET_ALL_REPORTS_SEARCH_REQUEST] = APIRequestStatus.Failure;
    });
    builder.addCase(getAllDashboardsByGlobalSearch.pending, (state: IReportListingState) => {
      state.globalSearchDashboardResults = [];
      state.requestProcessing[ReportListingActionTypes.GET_ALL_DASHBOARDS_SEARCH_REQUEST] = APIRequestStatus.Processing;
    });
    builder.addCase(getAllDashboardsByGlobalSearch.fulfilled, (state: IReportListingState, action: PayloadAction<IReportData[]>) => {
      if (action.payload) {
        state.globalSearchDashboardResults = action.payload;
      } else {
        state.globalSearchDashboardResults = [];
      }
      state.requestProcessing[ReportListingActionTypes.GET_ALL_DASHBOARDS_SEARCH_REQUEST] = APIRequestStatus.Success;
    });
    builder.addCase(getAllDashboardsByGlobalSearch.rejected, (state: IReportListingState) => {
      state.requestProcessing[ReportListingActionTypes.GET_ALL_DASHBOARDS_SEARCH_REQUEST] = APIRequestStatus.Failure;
    });
    builder.addCase(getExternalReportDetails.pending, (state: IReportListingState) => {
      state.requestProcessing[
        ReportListingActionTypes.GET_EXTERNAL_REPORT_DETAILS_REQUEST
      ] = APIRequestStatus.Processing;
    });
    builder.addCase(getExternalReportDetails.fulfilled, (state: IReportListingState) => {
      state.requestProcessing[
        ReportListingActionTypes.GET_EXTERNAL_REPORT_DETAILS_REQUEST
      ] = APIRequestStatus.Success;
    });
    builder.addCase(getExternalReportDetails.rejected, (state: IReportListingState) => {
      state.requestProcessing[
        ReportListingActionTypes.GET_EXTERNAL_REPORT_DETAILS_REQUEST
      ] = APIRequestStatus.Failure;
    });
    builder.addCase(getReportsByTag.pending, (state: IReportListingState) => {
      state.requestProcessing[ReportListingActionTypes.GET_ITEMS_BY_TAG_REQUEST] = APIRequestStatus.Processing;
      state.requestProcessing[ReportListingActionTypes.GET_ALL_REPORTS_REQUEST] = APIRequestStatus.Processing;
    });
    builder.addCase(getReportsByTag.fulfilled, (state: IReportListingState, action: PayloadAction<IReportData[], string, ObjModel.Obj>) => {
      const reportsByTags: IReportSection[] = [];
      const globalTags = action.meta.arg.globalTags;
      const selectedNavBarItem = action?.meta.arg?.sectionItem;
      const tagData = globalTags?.find((item: ITagsData) => item.Id === selectedNavBarItem);
      if (!action.payload) {
        state.tagCategoriesReports = null;
        state.filteredReportSections = null;
        state.requestProcessing[ReportListingActionTypes.GET_ITEMS_BY_TAG_REQUEST] = APIRequestStatus.Success;
        return;
      }

      reportsByTags.push({
        DisplayName: tagData?.Name,
        Reports: { Data: action.payload },
        SectionId: tagData?.Id,
        TotalCount: action.payload?.length,
        Dashboards: { Data: [] },
      });

      state.tagCategoriesReports = reportsByTags;
      state.filteredReportSections = getFilteredReportByFilterValue(state, state.filterValue, selectedNavBarItem);
      state.requestProcessing[ReportListingActionTypes.GET_ITEMS_BY_TAG_REQUEST] = APIRequestStatus.Success;
    });
    builder.addCase(getReportsByTag.rejected, (state: IReportListingState) => {
      state.requestProcessing[ReportListingActionTypes.GET_ITEMS_BY_TAG_REQUEST] = APIRequestStatus.Failure;
      state.requestProcessing[ReportListingActionTypes.GET_ALL_REPORTS_REQUEST] = APIRequestStatus.Failure;
      state.filteredReportSections = null;
    });
    builder.addCase(getDashboardList.pending, (state: IReportListingState) => {
      state.requestProcessing[ReportListingActionTypes.GET_DASHBOARDS_LIST_REQUEST] = APIRequestStatus.Processing;
    });
    builder.addCase(getDashboardList.fulfilled, (state: IReportListingState, action: PayloadAction<Array<IDashboardItem>, string, ObjModel.Obj>) => {
      state.requestProcessing[ReportListingActionTypes.GET_DASHBOARDS_LIST_REQUEST] = APIRequestStatus.Processing;
      state.allDashboards = action.payload;
      const globalTags = action.meta.arg.globalTags;
      const selectedNavBarItem = action.meta.arg.sectionItem;
      state.allDashboardsSection = getAllDashboardSection(state, action.payload, globalTags, selectedNavBarItem);
      state.requestProcessing[ReportListingActionTypes.GET_DASHBOARDS_LIST_REQUEST] = APIRequestStatus.Success;
    });
    builder.addCase(getDashboardList.rejected, (state: IReportListingState) => {
      state.requestProcessing[ReportListingActionTypes.GET_DASHBOARDS_LIST_REQUEST] = APIRequestStatus.Failure;
    });
    builder.addCase(editTagsByObject.pending, (state: IReportListingState) => {
      state.requestProcessing[ReportListingActionTypes.EDIT_TAGS_BY_OBJECTID_REQUEST] = APIRequestStatus.Processing;
    });
    builder.addCase(editTagsByObject.fulfilled, (state: IReportListingState) => {
      state.requestProcessing[ReportListingActionTypes.EDIT_TAGS_BY_OBJECTID_REQUEST] = APIRequestStatus.Success;
    });
    builder.addCase(editTagsByObject.rejected, (state: IReportListingState) => {
      state.requestProcessing[ReportListingActionTypes.EDIT_TAGS_BY_OBJECTID_REQUEST] = APIRequestStatus.Failure;
    });
    builder.addCase(getTagsByObject.pending, (state: IReportListingState) => {
      state.requestProcessing[ReportListingActionTypes.GET_TAGS_BY_OBJECT_REQUEST] = APIRequestStatus.Processing;
    });
    builder.addCase(getTagsByObject.fulfilled, (state: IReportListingState, action: PayloadAction<TagByObject>) => {
      state.globaltags = action.payload.TagMetaData;
      const editTagsList: IEditTagsData[] = [];
      const tagMetaData = action.payload.TagMetaData;
      const linkedTags = action.payload.Tags;

      tagMetaData.forEach((item) => {
        const isChecked = linkedTags?.includes(item.Id);
        editTagsList.push({ ...item, checked: isChecked });
      });
      state.activeEditTagsList = editTagsList;
      state.appliedEditTagsList = editTagsList;
      state.requestProcessing[ReportListingActionTypes.GET_TAGS_BY_OBJECT_REQUEST] = APIRequestStatus.Success;
    });
    builder.addCase(getTagsByObject.rejected, (state: IReportListingState) => {
      state.requestProcessing[ReportListingActionTypes.GET_TAGS_BY_OBJECT_REQUEST] = APIRequestStatus.Failure;
    });
    builder.addCase(getPinnedReports.pending, (state: IReportListingState) => {
      state.requestProcessing[ReportListingActionTypes.GET_PINNED_REPORTS_REQUEST] = APIRequestStatus.Processing;
      state.requestProcessing[ReportListingActionTypes.GET_ALL_REPORTS_REQUEST] = APIRequestStatus.Processing;
    });
    builder.addCase(getPinnedReports.fulfilled, (state: IReportListingState, action: PayloadAction<IReportData[], string, ObjModel.Obj>) => {
      if (!action.payload) {
        state.pinnedCategories = null;
        state.filteredReportSections = null;
        state.requestProcessing[ReportListingActionTypes.GET_ALL_REPORTS_REQUEST] = APIRequestStatus.Success;
        return;
      }
      const reportsByProfiles: IReportSection[] = [];
      reportsByProfiles.push({
        DisplayName: 'All Pinned Reports',
        Reports: { Data: action.payload },
        SectionId: SectionItemId.PinnedReports,
        TotalCount: action.payload?.length,
        Dashboards: { Data: [] },
      });
      state.pinnedCategories = reportsByProfiles;
      const selectedNavBarItem = action?.meta.arg?.sectionItem;
      state.filteredReportSections = getFilteredReportByFilterValue(state, state.filterValue, selectedNavBarItem);
      state.requestProcessing[ReportListingActionTypes.GET_ALL_REPORTS_REQUEST] = APIRequestStatus.Success;
    });
    builder.addCase(getPinnedReports.rejected, (state: IReportListingState) => {
      state.requestProcessing[ReportListingActionTypes.GET_PINNED_REPORTS_REQUEST] = APIRequestStatus.Failure;
      state.requestProcessing[ReportListingActionTypes.GET_ALL_REPORTS_REQUEST] = APIRequestStatus.Failure;
      state.filteredReportSections = null;
    });
    builder.addCase(pinUnpinReport.pending, (state: IReportListingState) => {
      state.requestProcessing[ReportListingActionTypes.PIN_UNPIN_REPORTS_REQUEST] = APIRequestStatus.Processing;
    });
    builder.addCase(pinUnpinReport.fulfilled, (state: IReportListingState) => {
      state.requestProcessing[ReportListingActionTypes.PIN_UNPIN_REPORTS_REQUEST] = APIRequestStatus.Success;
    });
    builder.addCase(pinUnpinReport.rejected, (state: IReportListingState) => {
      state.requestProcessing[ReportListingActionTypes.PIN_UNPIN_REPORTS_REQUEST] = APIRequestStatus.Failure;
    });
    builder.addCase(getHomeDashoardsList.pending, (state: IReportListingState) => {
      state.requestProcessing[ReportListingActionTypes.GET_HOME_DASHBOARDS_REQUEST] = APIRequestStatus.Processing;
    });
    builder.addCase(getHomeDashoardsList.fulfilled, (state: IReportListingState, action: PayloadAction<IDashboardItem[]>) => {
      state.homeDashboards = action.payload;
      let selectedDashboard = null;

      if (action.payload?.length > 0) {
        selectedDashboard = action.payload.find((item) => item.IsPersonalized);

        if (!selectedDashboard) {
          selectedDashboard = action.payload[0];
        }
      }
      state.selectedHomeDashboard = selectedDashboard;
      state.requestProcessing[ReportListingActionTypes.GET_HOME_DASHBOARDS_REQUEST] = APIRequestStatus.Success;
    });
    builder.addCase(getHomeDashoardsList.rejected, (state: IReportListingState) => {
      state.requestProcessing[ReportListingActionTypes.GET_HOME_DASHBOARDS_REQUEST] = APIRequestStatus.Failure;
    });
    builder.addCase(pinUnpinDashboard.pending, (state: IReportListingState) => {
      state.requestProcessing[ReportListingActionTypes.PIN_UNPIN_REPORTS_REQUEST] = APIRequestStatus.Processing;
    });
    builder.addCase(pinUnpinDashboard.fulfilled, (state: IReportListingState) => {
      state.requestProcessing[ReportListingActionTypes.PIN_UNPIN_REPORTS_REQUEST] = APIRequestStatus.Success;
    });
    builder.addCase(pinUnpinDashboard.rejected, (state: IReportListingState) => {
      state.requestProcessing[ReportListingActionTypes.PIN_UNPIN_REPORTS_REQUEST] = APIRequestStatus.Failure;
    });
    builder.addCase(getPinnedDashboardList.pending, (state: IReportListingState) => {
      state.requestProcessing[ReportListingActionTypes.GET_PINNED_DASHBOARDS_REQUEST] = APIRequestStatus.Processing;
    });
    builder.addCase(getPinnedDashboardList.fulfilled, (state: IReportListingState, action: PayloadAction<IDashboardItem[]>) => {
      if (!action.payload) {
        state.allDashboardsSection = null;
        state.requestProcessing[ReportListingActionTypes.GET_PINNED_DASHBOARDS_REQUEST] = APIRequestStatus.Success;
        return;
      }
      const renderDashboardSection: IReportSection[] = [];
      renderDashboardSection.push({
        DisplayName: 'All Pinned Dashboards',
        Reports: { Data: [] },
        SectionId: SectionItemId.PinnedReports,
        TotalCount: action.payload?.length,
        Dashboards: { Data: action.payload },
      });
      state.allDashboardsSection = renderDashboardSection;
      state.requestProcessing[ReportListingActionTypes.GET_PINNED_DASHBOARDS_REQUEST] = APIRequestStatus.Success;
    });
    builder.addCase(getPinnedDashboardList.rejected, (state: IReportListingState) => {
      state.requestProcessing[ReportListingActionTypes.GET_PINNED_DASHBOARDS_REQUEST] = APIRequestStatus.Failure;
    });
    builder.addCase(V1AccessControl.pending, (state: IReportListingState) => {
      state.requestProcessing[ReportListingActionTypes.V1REPORTS_ACCESS_CONTROLL_REQUEST] = APIRequestStatus.Processing;
    });
    builder.addCase(V1AccessControl.fulfilled, (state: IReportListingState) => {
      state.requestProcessing[ReportListingActionTypes.V1REPORTS_ACCESS_CONTROLL_REQUEST] = APIRequestStatus.Success;
    });
    builder.addCase(V1AccessControl.rejected, (state: IReportListingState) => {
      state.requestProcessing[ReportListingActionTypes.V1REPORTS_ACCESS_CONTROLL_REQUEST] = APIRequestStatus.Failure;
    });
    builder.addCase(getSavedReportDetails.pending, (state: IReportListingState) => {
      state.requestProcessing[ReportListingActionTypes.GET_SAVED_REPORT_DETAILS_REQUEST] = APIRequestStatus.Processing;
    });
    builder.addCase(getSavedReportDetails.fulfilled, (state: IReportListingState, action: PayloadAction<IReportData>) => {
      state.externalReportDetails = action.payload;
      state.requestProcessing[ReportListingActionTypes.GET_SAVED_REPORT_DETAILS_REQUEST] = APIRequestStatus.Success;
    });
    builder.addCase(getSavedReportDetails.rejected, (state: IReportListingState) => {
      state.requestProcessing[ReportListingActionTypes.GET_SAVED_REPORT_DETAILS_REQUEST] = APIRequestStatus.Failure;
    });
  },
});

export const {
  [ReportListingActionTypes.GET_EXTERNAL_REPORT_DETAILS_SUCCESS]: requestExternalReportDetailsSuccess,
  [ReportListingActionTypes.SET_REPORT_FILTER_VALUE]: setReportFilterValue,
  [ReportListingActionTypes.SET_REPORT_ACTION_REPORTID]: setReportActionReportId,
  [ReportListingActionTypes.SELECT_OR_DESELECT_TAGS]: selectOrDeselectAction,
  [ReportListingActionTypes.TAG_ANIMATION_COMPLETE]: tagAnimationComplete,
  [ReportListingActionTypes.SET_REPORT_PINNED_FILTER_VALUE]: setReportPinningFilterValue,
  [ReportListingActionTypes.SET_SELECTED_HOME_DASHBOARD]: setSelectedHomeDashboard,
  [ReportListingActionTypes.SET_REDIRECT_ALL_DASHBOARDS]: redirectAllDashboards,
  [ReportListingActionTypes.GET_EXTERNAL_REPORT_DETAILS_REQUEST]: setExternalUrlRequestState,
  [ReportListingActionTypes.PIN_UNPIN_REPORTS_REQUEST]: requestPinUnpinAction,
} = reportListingSlice.actions;

export default reportListingSlice;
