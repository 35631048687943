import React from 'react';

type Props = {
  width?: number;
  height?: number;
  fill?: string;
}

const ProfileUserIcon = ({
  width, height, fill,
}: Props) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_2760_65942)">
      <path
        d="M9.665 4.58294C10.6314 4.58294 11.4146 5.36608 11.4146 6.3325C11.4146 7.29892 10.6314 8.08206 9.665 8.08206C8.69858 8.08206 7.91544 7.29892 7.91544 6.3325C7.91544 5.36608 8.69858 4.58294 9.665 4.58294ZM9.665 12.0811C12.1394 12.0811 14.7471 13.2974 14.7471 13.8306V14.7471H4.58294V13.8306C4.58294 13.2974 7.19062 12.0811 9.665 12.0811ZM9.665 3C7.82379 3 6.3325 4.49129 6.3325 6.3325C6.3325 8.17371 7.82379 9.665 9.665 9.665C11.5062 9.665 12.9975 8.17371 12.9975 6.3325C12.9975 4.49129 11.5062 3 9.665 3ZM9.665 10.4981C7.44056 10.4981 3 11.6145 3 13.8306V16.33H16.33V13.8306C16.33 11.6145 11.8894 10.4981 9.665 10.4981Z"
        fill={fill}
      />
    </g>
    <defs>
      <clipPath
        id="clip0_2760_65942"
      >
        <rect
          width="20"
          height="20"
          fill="white"
        />
      </clipPath>
    </defs>
  </svg>

);

ProfileUserIcon.defaultProps = {
  fill: 'var(--secondaryColor)',
  width: 20,
  height: 20,
};

export default ProfileUserIcon;
