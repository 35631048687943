import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { LocalizationAction, LocalizationState, initialLocalizationState } from './localization.initial-state';

const localizationReducer = createSlice({
  name: 'localizationStore',
  initialState: initialLocalizationState,
  reducers: {
    [LocalizationAction.ADD_LOCALIZATION_OBJECT]: (state:LocalizationState, action:PayloadAction<LocalizationState['localizationObject']>) => {
      state.localizationObject = action.payload;
    },
    [LocalizationAction.SET_LANGUAGE_CODE]: (state:LocalizationState, action:PayloadAction<string>) => {
      state.language = action.payload;
    },
  },
});

export const {
  [LocalizationAction.ADD_LOCALIZATION_OBJECT]: addLocalizationObject,
  [LocalizationAction.SET_LANGUAGE_CODE]: setLanguageCode,
} = localizationReducer.actions;

export default localizationReducer;
