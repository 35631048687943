import { APIRequestStatus } from 'core/constants/redux';
import { LoadingStatus, ClickSource } from 'core/constants/dashboard';
import {
  DashboardContext,
  defaultDashboardConfig,
} from 'core/models/dashboard';

import {
  statusCodes,
} from 'core/constants/report';
import { DashboardAction, IDashboardState } from './dashboard-store.state';

const InitialDashboardStoreState: IDashboardState = {
  dashboardList: {
    dashboardItems: [],
    loadingStatus: LoadingStatus.Loading,
  },
  requestProcessing: {
    [DashboardAction.DELETE_DASHBOARD]: APIRequestStatus.AboutToLoad,
    [DashboardAction.GET_FILTERS_LIST_REQUEST]: APIRequestStatus.AboutToLoad,
    [DashboardAction.REQUEST_DASHBOARDS_LIST]: APIRequestStatus.AboutToLoad,
    [DashboardAction.SAVE_TEMP_VIEW_REQUEST]: APIRequestStatus.AboutToLoad,
    [DashboardAction.SAVE_TEMP_DRILLDOWN_REQUEST]: APIRequestStatus.AboutToLoad,
    [DashboardAction.SET_PERSONALIZED_DASHBOARD_REQUEST]: APIRequestStatus.AboutToLoad,
    [DashboardAction.DASHBOARD_ACCESS_CONTROL_REQUEST]: APIRequestStatus.AboutToLoad,
    [DashboardAction.REQUEST_DASHBOARD_CONFIG]: APIRequestStatus.AboutToLoad,
  },
  filterStore: {
    selectedFilter: null,
    dynamicGlobalFilters: [],
    staticGlobalFilters: [],
    updatedFilterIds: [],
    reportMappedFilters: {},
    updatePanelList: [],
    panelMappedPartitionColumns: {},
    latestModifiedFilterId: null,
  },
  configLoadingStatus: LoadingStatus.Loading,
  dashboardConfig: { ...defaultDashboardConfig },
  panelPayloads: [],
  dashboardData: {},

  loadFilterdAnimation: false,
  accesscontrolLoading: LoadingStatus.Loading,
  dashboardBuilder: {
    requestProcessing: {
      [DashboardAction.GET_REPORT_LIST_REQUEST]: APIRequestStatus.AboutToLoad,
      [DashboardAction.GET_REPORT_INFO]: APIRequestStatus.AboutToLoad,
      [DashboardAction.SAVE_DASHBOARD_CONFIG]: APIRequestStatus.AboutToLoad,
      [DashboardAction.GET_SELECTED_REPORT_WIDGET_INFO]:
        APIRequestStatus.AboutToLoad,
      [DashboardAction.REQUEST_DASHBOARD_CONFIG]: APIRequestStatus.AboutToLoad,
      [DashboardAction.VALIDATE_EMBED_URL]: APIRequestStatus.AboutToLoad,
      [DashboardAction.FILTER_DATA_LOAD_REQUEST]: {},
      [DashboardAction.GET_WIDGET_REPORT_DATA_REQUEST]: APIRequestStatus.AboutToLoad,
    },
    dashboardBuilderInfo: {
      reportList: [],
      reportConfig: undefined,
    },
    assortedInfo: {
      reportSearchKey: '',
      isFormDisabled: true,
      dashboardId: '',
      context: DashboardContext.Empty,
      addedWidget: '',
      widgetEditInfo: {
        id: '',
        widgetDetails: {},
      },
    },
    selectedReportWidget: {},
    embedURLSettings: {
      title: '',
      decodedUrl: '',
      source: ClickSource.Preview,
      sourceUrl: '',
      isUrlError: false,
      isMailMergeError: false,
    },
  },
  isEditMode: false,
  dashboardStatusCode: statusCodes.Success,
  triggerCalls: false,
  filterLoaded: true,
};

export default InitialDashboardStoreState;
