import React from 'react';
import LoadingComponent from 'components/common/LoadingSpinner/loading-component';
import StyledCircularLoadingScreen from './circular-loading.style';

interface IProps {
  width?: number;
  height?: number;
  rotatingColor?: string;
  position?: string;
  backgroundColor?: string;
  additionalParentClass?: string;
}

const CircularLoadingScreen = ({
  height,
  width,
  rotatingColor,
  position,
  backgroundColor,
  additionalParentClass,
}: IProps) => (
  <StyledCircularLoadingScreen position={position} backgroundColor={backgroundColor} className={additionalParentClass}>
    <LoadingComponent
      iconHeight={height}
      iconWidth={width}
      rotatingColor={rotatingColor}
    />
  </StyledCircularLoadingScreen>
);

CircularLoadingScreen.defaultProps = {
  width: 50,
  height: 50,
  rotatingColor: 'var(--border)',
  position: 'fixed',
  backgroundColor: 'rgba(234, 234, 234, 0.7)',
};

export default CircularLoadingScreen;
