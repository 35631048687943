import React from 'react';
import ErrorScreenComponent from '../ErrorScreenComponent/error-screen-component.component';
import useLocalize from '../Localization/useLocalize.hook';

const PageNotFound = () => {
  const err = String(useLocalize('err.noPage404'));
  const errDescription = String(useLocalize('cmn.errorMessage404'));
  const redirectToButtonContent = String(useLocalize('cmn.goHomePage'));
  return (
    <ErrorScreenComponent err={err} errDescription={errDescription} redirectToButtonContent={redirectToButtonContent} redirectTo="/" />
  );
};

export default PageNotFound;
