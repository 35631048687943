import React from 'react';

type Props = {
  width?: number;
  height?: number;
  fill?: string;
}

const LoadingSpinnerIcon = ({
  width, height, fill,
}: Props) => (
  <svg style={{ width, height }} viewBox="0 0 52 52" fill={fill} xmlns="http://www.w3.org/2000/svg">
    <path d="M26.0004 1.41669L26.0004 7.99564" stroke="black" strokeWidth="2" strokeLinecap="round" />
    <path d="M26.0004 44.0044L26.0004 50.5833" stroke="#7A7A7A" strokeWidth="2" strokeLinecap="round" />
    <path d="M19.532 1.85168L21.3807 8.75116" stroke="#0A0A0A" strokeWidth="2" strokeLinecap="round" />
    <path d="M30.6225 43.2486L32.4712 50.1481" stroke="#858585" strokeWidth="2" strokeLinecap="round" />
    <path d="M13.5001 4.34943L17.0715 10.5353" stroke="#141414" strokeWidth="2" strokeLinecap="round" />
    <path d="M34.9291 41.4647L38.5005 47.6506" stroke="#8F8F8F" strokeWidth="2" strokeLinecap="round" />
    <path d="M8.32275 8.32263L13.3735 13.3734" stroke="#1F1F1F" strokeWidth="2" strokeLinecap="round" />
    <path d="M38.6286 38.6268L43.6794 43.6775" stroke="#999999" strokeWidth="2" strokeLinecap="round" />
    <path d="M4.34653 13.5002L10.5324 17.0717" stroke="#292929" strokeWidth="2" strokeLinecap="round" />
    <path d="M41.4672 34.928L47.653 38.4995" stroke="#A3A3A3" strokeWidth="2" strokeLinecap="round" />
    <path d="M1.85303 19.5297L8.75247 21.3784" stroke="#333333" strokeWidth="2" strokeLinecap="round" />
    <path d="M43.2477 30.6212L50.1472 32.4699" stroke="#ADADAD" strokeWidth="2" strokeLinecap="round" />
    <path d="M1.41702 26.0001L7.99595 26.0001" stroke="#3D3D3D" strokeWidth="2" strokeLinecap="round" />
    <path d="M44.0048 26.0001L50.5837 26.0001" stroke="#B8B8B8" strokeWidth="2" strokeLinecap="round" />
    <path d="M1.85303 32.4706L8.75247 30.6219" stroke="#474747" strokeWidth="2" strokeLinecap="round" />
    <path d="M43.2477 21.3774L50.1472 19.5287" stroke="#C2C2C2" strokeWidth="2" strokeLinecap="round" />
    <path d="M4.34653 38.5001L10.5324 34.9286" stroke="#525252" strokeWidth="2" strokeLinecap="round" />
    <path d="M41.4672 17.0702L47.653 13.4988" stroke="#CCCCCC" strokeWidth="2" strokeLinecap="round" />
    <path d="M8.32275 43.6774L13.3735 38.6267" stroke="#5C5C5C" strokeWidth="2" strokeLinecap="round" />
    <path d="M38.6237 13.3721L43.6745 8.3213" stroke="#D6D6D6" strokeWidth="2" strokeLinecap="round" />
    <path d="M13.5001 47.6503L17.0715 41.4644" stroke="#666666" strokeWidth="2" strokeLinecap="round" />
    <path d="M34.9291 10.5339L38.5005 4.34804" stroke="#E0E0E0" strokeWidth="2" strokeLinecap="round" />
    <path d="M19.532 50.1478L21.3807 43.2484" stroke="#707070" strokeWidth="2" strokeLinecap="round" />
    <path d="M30.6208 8.75067L32.4696 1.8512" stroke="#EBEBEB" strokeWidth="2" strokeLinecap="round" />
  </svg>
);

LoadingSpinnerIcon.defaultProps = {
  width: 52,
  height: 52,
  fill: 'none',
};

export default LoadingSpinnerIcon;
