/** @format */

import React from 'react';

type Props = {
  width?: number;
  height?: number;
};

const EmptyDataDashboardIcon = ({ width, height }: Props) => (

  <svg width={width} height={height} viewBox="0 0 86 85" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M3.55371 3.47266V38.0536H33.0468V3.47266H3.55371Z" fill="#DFE3E8" />
    <path d="M4.1416 53.4727V75.6907H32.7416V53.4727H4.1416Z" fill="#DFE3E8" />
    <path d="M47.1416 3.47266V25.6907H75.7416V3.47266H47.1416Z" fill="#DFE3E8" />
    <path d="M46.5537 41.4727V76.0536H76.0468V41.4727H46.5537Z" fill="#DFE3E8" />
    <path d="M43.333 30V0H80V30H43.333ZM0 43.333V0H36.667V43.333H0ZM43.333 80V36.667H80V80H43.333ZM0 80V50H36.667V80H0ZM6.667 36.667H30V6.667H6.667V36.667ZM50 73.333H73.333V43.333H50V73.333ZM50 23.333H73.333V6.667H50V23.333ZM6.667 73.333H30V56.667H6.667V73.333Z" fill="#919EAB" />
    <path d="M72 85C79.732 85 86 78.732 86 71C86 63.268 79.732 57 72 57C64.268 57 58 63.268 58 71C58 78.732 64.268 85 72 85Z" fill="white" />
    <path d="M67.7708 75.1875H73.5V73.9375H67.7708V75.1875ZM67.7708 71.625H76.2292V70.375H67.7708V71.625ZM67.7708 68.0625H76.2292V66.8125H67.7708V68.0625ZM65.75 78.5C65.4167 78.5 65.125 78.375 64.875 78.125C64.625 77.875 64.5 77.5833 64.5 77.25V64.75C64.5 64.4167 64.625 64.125 64.875 63.875C65.125 63.625 65.4167 63.5 65.75 63.5H78.25C78.5833 63.5 78.875 63.625 79.125 63.875C79.375 64.125 79.5 64.4167 79.5 64.75V77.25C79.5 77.5833 79.375 77.875 79.125 78.125C78.875 78.375 78.5833 78.5 78.25 78.5H65.75ZM65.75 77.25H78.25V64.75H65.75V77.25ZM65.75 64.75V77.25V64.75Z" fill="#3BB345" />
  </svg>

);

EmptyDataDashboardIcon.defaultProps = {
  width: 80,
  height: 85,
};

export default EmptyDataDashboardIcon;
