import { createAsyncThunk } from '@reduxjs/toolkit';
import CommonDataService from 'services/common-data.service';
import { SettingsStoreActions } from './settings-store-state';

export const updatePlanSettings = createAsyncThunk(SettingsStoreActions.SET_SETTINGS_REQUEST,
  async () => new CommonDataService().getSettingsCode());

// to call fetch-settings api without using reducer, to avoid re-render at page container
export const fetchSettings = async () => {
  try {
    const result = await new CommonDataService().getSettingsCode();
    return result;
  } catch (error) {
    console.error(error);
    throw error;
  }
};
