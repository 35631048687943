import styled from 'styled-components';

const StyledContainer = styled.div<{
    errorWidth: number;
}>`
width: 100%;
height: 100%;

.se-error-dialog {
    width: 100%;
    height: 100%;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 5;
    background:var(--boxShadow);
    border: 0;
    display: flex;
    align-items: center;
    justify-content: center;

    .se-error-showcase {
        width: 400px;
        height: 208px;
        border-radius: 2px;
    }
}

.se-error-dialog.non-droppable {
   cursor: default;
}

.se-error-non-dialog {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    background: var(--white);
}

.se-icon {
    display: flex;
    align-items: center;
    margin-right: 4px;
}

.se-error-showcase {
    background:var(--white);
    padding: 12px;
    text-align: center;
    cursor: default;
    border-radius: 2px;
    box-sizing: border-box;

    .se-error-icon {
        margin-bottom: 28px;
    }

    .se-error-header {
        font-size: 16px;
        font-family: SemiBold;
        color: var(--secondaryColor);
        margin-bottom: 12px;
    }
    
    .se-error-message {
        padding: 0 20px;
        font-size: 13px;
        color: var(--labelColor);
        margin-bottom: 24px;
        max-width: ${(props) => (props.errorWidth ? props.errorWidth : 410)}px;
    }

    .se-error-actions {
        display: flex;
        align-items: center;
        justify-content: center;

        button {
            margin: 0 8px;
            padding: 8px 16px;
            display: flex;
            align-items: center;
            justify-content: center;
            font-size: 12px;
            font-family: SemiBold;
            color: var(--secondaryColor);
            border: 0;
            background: var(--backgroundColor);
            border-radius: 2px;

                :hover {
                    background: var(--paginationBorder);
                }
            }

            .se-return-button {
                color: var(--secondaryColor);
                background-color: var(--white);
                border: 1px solid var(--borderColor);
                :hover {
                    background: var(--clearFilterColor);
                }
            }
        }
    }

    .v1Reports {
        .se-error-header{
            color: var(--lsqDataHeader); 
            line-height: 21px;
        }
        .se-error-message{
            color: var(--lsqText);
            font-family: Regular;
            line-height: 17px;
        }

        .se-refresh-button{
            color: var(--lsqText);
            line-height: 16px
        }
    }
`;

export default StyledContainer;
