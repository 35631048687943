import React from 'react';

type Props = {
  width: number;
  height: number;
  fill1?: string;
  fill2?: string;
  fillOpacity?: string;
}

const ErrorIcon = ({
  width, height, fill1, fill2, fillOpacity,
}: Props) => (
  <svg width={width} height={height} viewBox="0 0 60 60" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M30 60C46.5685 60 60 46.5685 60 30C60 13.4315 46.5685 0 30 0C13.4315 0 0 13.4315 0 30C0 46.5685 13.4315 60 30 60Z"
      fill={fill1}
      fillOpacity={fillOpacity}
    />
    <path
      d="M31.6402 18.9922L31.2691 39.1094H28.6324L28.2808 18.9922H31.6402ZM29.9996 47.3516C29.4006 47.3516 28.8863 47.1432 28.4566 46.7266C28.0399 46.3099 27.8316 45.8021 27.8316 45.2031C27.8316 44.6042 28.0399 44.0964 28.4566 43.6797C28.8863 43.25 29.4006 43.0352 29.9996 43.0352C30.5855 43.0352 31.0933 43.25 31.523 43.6797C31.9527 44.0964 32.1675 44.6042 32.1675 45.2031C32.1675 45.8021 31.9527 46.3099 31.523 46.7266C31.0933 47.1432 30.5855 47.3516 29.9996 47.3516Z"
      fill={fill2}
    />
  </svg>

);

ErrorIcon.defaultProps = {
  fill1: 'rgba(173,186,199)',
  fill2: 'var(--searchBoxTextColor)',
  fillOpacity: '0.1',
};

export default ErrorIcon;
