import React from 'react';

type Props = {
  width?: number;
  height?: number;
  fill?: string;
};

const EditIcon = ({ width, height, fill }: Props) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_756_613)">
      <path
        d="M11.7167 7.51667L12.4833 8.28333L4.93333 15.8333H4.16667V15.0667L11.7167 7.51667ZM14.7167 2.5C14.5083 2.5 14.2917 2.58333 14.1333 2.74167L12.6083 4.26667L15.7333 7.39167L17.2583 5.86667C17.5833 5.54167 17.5833 5.01667 17.2583 4.69167L15.3083 2.74167C15.1417 2.575 14.9333 2.5 14.7167 2.5ZM11.7167 5.15833L2.5 14.375V17.5H5.625L14.8417 8.28333L11.7167 5.15833Z"
        fill={fill}
      />
    </g>
    <defs>
      <clipPath id="clip0_756_613">
        <rect width="20" height="20" fill="white" />
      </clipPath>
    </defs>
  </svg>
);

EditIcon.defaultProps = {
  fill: 'var(--searchBoxTextColor)',
  width: '16',
  height: '16',
};

export default EditIcon;
