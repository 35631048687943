import React from 'react';

type Props = {
    width: number,
    height: number,
    fill?: string,
}

const PrevIcon = ({ width, height, fill }: Props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    style={{
      height,
      width,
    }}
    viewBox="0 0 24 24"
    fill={fill}
  >
    <path
      d="M0 0h24v24H0V0z"
      fill="none"
    />
    <path
      d="M15.61 7.41L14.2 6l-6 6 6 6 1.41-1.41L11.03 12l4.58-4.59z"
    />
  </svg>
);

PrevIcon.defaultProps = {
  fill: '#var(--paginationPrev)',
};

export default PrevIcon;
