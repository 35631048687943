import React from 'react';

type Props = {
  width: number;
  height: number;
  fill?: string;
}

const DonutChart = ({
  width, height, fill,
}: Props) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_950_17000)">
      <path
        d="M17.2862 8.59636C16.4483 7.76647 15.3617 7.18713 14.1483 6.98001C13.6038 6.88709 13.1529 6.44831 13.1529 5.89602V3.72247C13.1529 3.17019 13.6017 2.71724 14.1513 2.77146C16.4543 2.99865 18.5288 4.00342 20.1088 5.51964M17.2862 8.59636C18.361 9.66087 19.0268 11.1376 19.0268 12.7699C19.0268 16.014 16.397 18.6438 13.1529 18.6438C10.2482 18.6438 7.83597 16.5354 7.36305 13.7655C7.2701 13.2211 6.8313 12.7699 6.27902 12.7699H4.10547C3.55318 12.7699 3.10024 13.2189 3.15447 13.7685C3.65575 18.8488 7.94087 22.8173 13.1529 22.8173C18.7019 22.8173 23.2003 18.319 23.2003 12.7699C23.2003 9.92052 22.0142 7.34816 20.1088 5.51964M17.2862 8.59636L20.1088 5.51964"
        stroke={fill}
        strokeWidth="1.6"
        className="icon-stroke"
      />
      <path
        d="M9.42071 2C9.42071 1.44771 8.97094 0.993771 8.42247 1.05855C4.56971 1.51359 1.5136 4.56971 1.05855 8.42247C0.993771 8.97094 1.44771 9.42071 2 9.42071H3.49783C4.05012 9.42071 4.48724 8.96839 4.5981 8.42735C4.99187 6.50554 6.50554 4.99187 8.42735 4.5981C8.96839 4.48724 9.42071 4.05012 9.42071 3.49783V2Z"
        stroke={fill}
        strokeWidth="1.6"
        className="icon-stroke"
      />
    </g>
    <defs>
      <clipPath id="clip0_950_17000">
        <rect
          width={width}
          height={height}
          fill="white"
        />
      </clipPath>
    </defs>
  </svg>

);

DonutChart.defaultProps = {
  fill: 'var(--labelColor)',
};

export default DonutChart;
