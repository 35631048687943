/* eslint-disable no-shadow */
export enum APIRequestStatus {
    Processing = 1,
    Success,
    Failure,
    LazyLoading,
    AboutToLoad,
}

export enum ReduxActionCallBackTypes {
    Async,
    Sync,
}
