/** @format */

import React from 'react';

type Props = {
  width?: number;
  height?: number;
};

const EmptyHomeDashboardIcon = ({ width, height }: Props) => (

  <svg width={width} height={height} viewBox="0 0 86 85" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M3.55371 3.47095V38.0519H33.0468V3.47095H3.55371Z" fill="#DFE1E5" />
    <path d="M4.1416 53.4709V75.689H32.7416V53.4709H4.1416Z" fill="#DFE1E5" />
    <path d="M47.1416 3.47095V25.689H75.7416V3.47095H47.1416Z" fill="#DFE1E5" />
    <path d="M46.5537 41.4709V76.0519H76.0468V41.4709H46.5537Z" fill="#DFE1E5" />
    <path d="M43.333 30V0H80V30H43.333ZM0 43.333V0H36.667V43.333H0ZM43.333 80V36.667H80V80H43.333ZM0 80V50H36.667V80H0ZM6.667 36.667H30V6.667H6.667V36.667ZM50 73.333H73.333V43.333H50V73.333ZM50 23.333H73.333V6.667H50V23.333ZM6.667 73.333H30V56.667H6.667V73.333Z" fill="#939DA9" />
    <path d="M72 85C79.732 85 86 78.732 86 71C86 63.268 79.732 57 72 57C64.268 57 58 63.268 58 71C58 78.732 64.268 85 72 85Z" fill="white" />
    <path d="M72 65.5318L76.5 69.7671V77.1176H74.7V71.4706H69.3V77.1176H67.5V69.7671L72 65.5318ZM72 63L63 71.4706H65.7V79H71.1V73.3529H72.9V79H78.3V71.4706H81L72 63Z" fill="var(--purpleColor)" />
  </svg>

);

EmptyHomeDashboardIcon.defaultProps = {
  width: 86,
  height: 85,
};

export default EmptyHomeDashboardIcon;
