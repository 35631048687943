import React from 'react';

type Props = {
  width?: number;
  height?: number;
  fill?: string;
};

const ProductTour = ({ width, height, fill }: Props) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_1763_68)">
      <path
        id="se-icon-hover"
        d="M7.99998 1.33333C4.31998 1.33333 1.33331 4.31999 1.33331 7.99999C1.33331 11.68 4.31998 14.6667 7.99998 14.6667C11.68 14.6667 14.6666 11.68 14.6666 7.99999C14.6666 4.31999 11.68 1.33333 7.99998 1.33333ZM7.99998 13.3333C5.05998 13.3333 2.66665 10.94 2.66665 7.99999C2.66665 5.05999 5.05998 2.66666 7.99998 2.66666C10.94 2.66666 13.3333 5.05999 13.3333 7.99999C13.3333 10.94 10.94 13.3333 7.99998 13.3333ZM4.33331 11.6667L9.33998 9.33999L11.6666 4.33333L6.65998 6.65999L4.33331 11.6667ZM7.99998 7.26666C8.40665 7.26666 8.73331 7.59333 8.73331 7.99999C8.73331 8.40666 8.40665 8.73333 7.99998 8.73333C7.59331 8.73333 7.26665 8.40666 7.26665 7.99999C7.26665 7.59333 7.59331 7.26666 7.99998 7.26666Z"
        fill={fill}
      />
    </g>
    <defs>
      <clipPath id="clip0_1763_68">
        <rect width="16" height="16" fill="white" />
      </clipPath>
    </defs>
  </svg>
);

ProductTour.defaultProps = {
  width: 16,
  height: 16,
  fill: 'var(--bannerFontColor)',
};

export default ProductTour;
