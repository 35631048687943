import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { ThemeProvider } from 'styled-components';
import GlobalStyle from 'globalStyles';
import 'index.css';
import App from 'App';
import store from 'redux-v2/store';
import light from 'themes/light.theme';
import 'rc-tooltip/assets/bootstrap_white.css';
import { BrowserRouter } from 'react-router-dom';

ReactDOM.render(
  <Provider store={store}>
    <ThemeProvider theme={light}>
      <GlobalStyle />
      <BrowserRouter>
        <App />
      </BrowserRouter>
    </ThemeProvider>
  </Provider>,
  document.getElementById('root'),
);
