import { PendoClassNames } from 'components/feature/Report/ReportSidebar/common/pendoClassNames';
import { FeatureContext } from 'core/constants/common';
import { LoadingStatus } from 'core/constants/dashboard';
import {
  Types,
  IReportSection,
  SourceTypes,
  IDashboardSection,
  ListingSection,
  IDashboardLinkedReport,
} from 'redux-v2/report-listing/report-listing.state';

// Supporting data to load the page
export interface IProfileDetailInfo {
  loadingStatus: LoadingStatus; // loader for pre-requist data
  accord: IAccordDetail; // Users, Groups, roles and team info
  selectedAccord: IAccordDetail; // selected users, Groups, roles and team info
  profileVisibleConfig: IProfileVisibilityConfig[]; // Config for accord
}

export interface IAccordDetail {
  [key: string]: IDropdownList[];
}
export interface IDropdownListAPIResponse {
  DisplayName: string;
  Value: string;
}
export interface IDropdownList {
  label: string;
  value: string;
}
export interface IProfileForm {
  loadingStatus: LoadingStatus; // loader for on save or load of profile info by id
  value: IProfileFormValue; // payload for update/create
  isDisabled?: boolean; // profile form validation
}

// IProfileFormValue properties are in PascalCase to match the API payload.
export interface IProfileFormValue {
  _id?: string;
  Name: string; // Profile name
  VisibilityMode: ProfileVisibleOption;
  IsAllPinned: boolean; // Global pin, not saved at API
  Accord: {
    [key: string]: Array<string>;
  };
  Reports: Array<ISelectedReport>; // payload list of reports
  Dashboards: Array<ISelectedDashboard>; // payload list of dashboards
  SelectedReportTypes:string[];
}

export interface ISelectedReport {
  Id: string;
  IsPinnedInProfile: boolean;
  SourceType: SourceTypes;
  Type: Types;
  AdjoinedByDashboard: boolean;
  FeatureContext: FeatureContext
}

export interface ISelectedDashboard {
  Id: string;
  IsPinnedInProfile: boolean;
  IsHomeInProfile: boolean;
  Type: Types
}

export interface IUpdateProfileFormValue {
  formValue: IProfileFormValue;
  isDisabled?: boolean;
  SelectionPayload?: IUpdateSelectReport;
}

export enum ProfileVisibleOption {
  SpecificSet = 'SpecificSet',
  EveryOne = 'ALL',
  None = 'NONE',
}

export const visibilityOptions = [
  {
    name: 'profile.onlySpecificRoleGroup',
    value: ProfileVisibleOption.SpecificSet,
    pendoClassName: PendoClassNames.ProfileSharingCriteriaSpecific,
  },
  {
    name: 'profile.everyone',
    value: ProfileVisibleOption.EveryOne,
    pendoClassName: PendoClassNames.ProfileSharingCriteriaNone,
  },
  {
    name: 'cmn.none',
    value: ProfileVisibleOption.None,
    pendoClassName: PendoClassNames.ProfileSharingCriteriaNone,
  },
];

export interface IGetVisibilityResponseValue {
  value: Array<IDropdownListAPIResponse>;
  id: string;
}

export interface IProfileListResponseValue {
  profileLists: Array<IProfileItem>;
}

export interface IProfileItem {
  Id: string;
  Name: string;
}
export interface IEditProfileItem {
  Id: string;
  Name?: string;
  checked: boolean;
  IsPinnedInProfile?:boolean;
  IsHomeInProfile?:boolean;
}

export interface IProfileVisibilityConfig {
  Id: string;
  DisplayName: string;
  RenderType: string;
  DataType: string;
}

export interface IProfileListing {
  reportSections: Array<IReportSection>;
  dashboardSections: Array<IDashboardSection>;
  selectedProfileTab: ListingSection;
  reportSearchKey: string;
  dashboardSearchKey: string;
  isProfileAllReportSelected: boolean;
  isProfileAllDashboardSelected: boolean;
  isAllLinkedReportsSelected: boolean;
  loadingStatus: LoadingStatus;
  dashboardListLoadingStatus: LoadingStatus;
  isProfileAllPinned: boolean;
}

export interface IUpdateSelectReport {
  id: string;
  selected: boolean;
  selectedItem: ProfileSelectionType;
  dashboardLinkedReports?: Array<IDashboardLinkedReport>;
  selectedReportTypes?:string[];
}

export enum ProfileSelectionType {
  All = 'all',
  Section = 'section',
  Card = 'card',
}

export enum ProfileCompletedActions {
  Add = 'ADD',
  UPDATE = 'UPDATE',
  NONE = 'NONE',
}
export enum Section{
  NONE = 'none',
  ALL = 'all',
}

export enum Animation{
NONE = 'none',
START = 'start',
}
