import React from 'react';
import { Switch, Route } from 'react-router-dom';
import { ProtectedRoute } from 'components/common';
import PageNotFound from 'components/common/PageNotFound/page-not-found.component';
import CircularLoadingScreen from 'components/common/loader/CircularFullScreenLoader/circular-loading.component';
import { routePaths } from 'core/constants/common';

const LazyLoadedReport = React.lazy(() => import('./report/report.route'));
const LazyLoadedReportViewer = React.lazy(
  () => import('./reportViewer/report-viewer.route'),
);
const LazyLoadedDashboard = React.lazy(
  () => import('./dashboard/dashboard.route'),
);
const LazyLoadedProfileDetail = React.lazy(
  () => import('./profile/profile.route'),
);
const LazyLoadedEditProfile = React.lazy(
  () => import('./profileEdit/profile-edit.route'),
);
const LazyLoadedUnauthorized = React.lazy(
  () => import('./unauthorized/unauthorized.route'),
);
const LazyLoadedMain = React.lazy(() => import('./main/main.route'));
const LazyLoadedReportBuilderDetail = React.lazy(
  () => import('./reportBuilder/report-builder.route'),
);
const LazyLoadedDashboardBuilder = React.lazy(
  () => import('./dashboard/dashboard-builder.route'),
);
const LazyLoadedReportBuilderEdit = React.lazy(
  () => import('./reportBuilder/report-edit.route'),
);
const LazyLoadedCustomAnalytics = React.lazy(
  () => import('./customAnalytics/custom-analytics.route'),
);
const LazyLoadNoAccess = React.lazy(
  () => import('./noAccess/noAccess.route'),
);

const Routes = () => (
  <>
    <React.Suspense fallback={<CircularLoadingScreen />}>
      <Switch>
        <ProtectedRoute
          path={routePaths.customAnalytics}
          exact
          component={LazyLoadedCustomAnalytics}
        />
        <ProtectedRoute
          path={routePaths.externalReports}
          component={LazyLoadedReportViewer}
        />
        <ProtectedRoute
          path={routePaths.sieraReports}
          component={LazyLoadedReport}
        />

        <ProtectedRoute
          path={routePaths.sieraDashboards}
          exact
          component={LazyLoadedDashboard}
        />

        <ProtectedRoute
          path={routePaths.createProfile}
          exact
          component={LazyLoadedProfileDetail}
        />

        <ProtectedRoute
          path={routePaths.editProfile}
          exact
          component={LazyLoadedEditProfile}
        />
        <Route
          path={routePaths.unauthorized}
          component={LazyLoadedUnauthorized}
        />
        <ProtectedRoute
          path={routePaths.reportBuilder}
          exact
          component={LazyLoadedReportBuilderDetail}
        />
        <ProtectedRoute
          path={routePaths.reportEdit}
          exact
          component={LazyLoadedReportBuilderEdit}
        />
        <ProtectedRoute
          path={routePaths.dashboardBuilder}
          exact
          component={LazyLoadedDashboardBuilder}
        />
        <ProtectedRoute
          path={routePaths.baseRoute}
          exact
          component={LazyLoadedMain}
        />
        <ProtectedRoute
          path={routePaths.noAccessRoute}
          exact
          component={LazyLoadNoAccess}
        />
        <ProtectedRoute
          path={routePaths.pageNotFound}
          component={PageNotFound}
        />
      </Switch>
    </React.Suspense>
  </>
);

export default Routes;
