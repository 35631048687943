import React from 'react';

type Props = {
    width: number;
    height: number;
    fill?: string;
}

const PlusIconNew = ({
  width, height, fill,
}: Props) => (
  <svg width={width} height={height} viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M14.3333 9.11905H8.61905V14.8333H6.71429V9.11905H1V7.21429H6.71429V1.5H8.61905V7.21429H14.3333V9.11905Z" fill={fill} />
  </svg>
);

PlusIconNew.defaultProps = {
  fill: '#006FD6',
};

export default PlusIconNew;
