import { PanelSourceTypes } from 'core/constants/dashboard';
import { AccessType } from 'core/constants/report';
import { SaveTempDrilldownCriteria } from 'core/models/dashboard';
import { isMailMergeURL } from 'core/utils/dashboard.util';
import API from 'services/api';
import { ObjModel, ReportResponseModel } from 'core/models';
import { batchHelperForDataRequest } from 'redux-v2/dashboard-store/dashboard-store-requests';
import DashboardBuilderService from './dashboard-builder.service';

class DashboardService extends API {
  static baseURL = process.env.REACT_APP_DATA_BASE_URL || '';

  // TODO: we will be getting this from settings api call,for now we will read it from env
  static apiRequestsLimitPerSecond =
    Number(process.env.REACT_APP_DASHBOARD_API_LIMIT) || 3;

  static onGoingApiRequestCount = 0;

  // all the fetch data requests
  static dataRequestsArray: Array<PanelDataFetchCriteria> = [];

  constructor() {
    super(DashboardService.baseURL);

    // to keep checking the datarequests array
    setInterval(() => {
      if (DashboardService.dataRequestsArray.length > 0) {
        this.processDataRequestsArray();
      }
    }, 500);
  }

  getDashboardList = async () => this.responsify(await this.instance.get('/dashboards/list'));

  deleteDashboard = async (dashboardId: string) => this.responsify(
    await this.instance.delete('dashboards/delete', {
      params: {
        dashboardId,
      },
    }),
  );

   getFiltersList = async (reportIDs: Array<string>) => this.responsify(
     await this.instance.post('reports/fetch-report-filters', reportIDs),
   );

   getDashboardConfig = async (id: string, personalizeHome: boolean) => this.genericResponseWrapper(
     await this.instance.get('/dashboards/config', {
       params: {
         id,
         personaliseLastVisit: personalizeHome,
       },
     }),
   );

  accesscontrol = async (reportId: string, type: AccessType) => this.responsifyGeneric(
    await this.instance.get('access-control/isAccessible', {
      params: {
        id: reportId,
        type,
      },
    }),
  );

  // as long as data requests available and limit hasn't been reached , trigger panel data requests
  processDataRequestsArray = () => {
    while (DashboardService.dataRequestsArray.length > 0 && DashboardService.onGoingApiRequestCount < DashboardService.apiRequestsLimitPerSecond) {
      DashboardService.onGoingApiRequestCount += 1;
      const topDataRequest = DashboardService.dataRequestsArray.shift();
      this.initiatePanelDataRequest(topDataRequest);
    }
  };

  resetDataRequestArray = () => {
    DashboardService.dataRequestsArray = [];
  };

  initiatePanelDataRequest = async ({
    Target,
    ...criteria
  }: PanelDataFetchCriteria) => {
    try {
      let result;
      const { SourceType, Url } = criteria;

      if (SourceType === PanelSourceTypes.EmbeddedUrl) {
        const dashboardService = new DashboardBuilderService();
        // we will validate the Embedded Url just to check if the domain is blacklisted
        const isValidUrl = await dashboardService.validateEmbedURL(Url);
        if (!isValidUrl) {
          batchHelperForDataRequest(null, Target, true); // marking isFailed to true
          DashboardService.onGoingApiRequestCount -= 1;
          return;
        }

        if (isMailMergeURL(Url)) {
          result = await this.instance.get('users/replace-mail-merge-fields', {
            params: {
              url: Url,
            },
          });
        }
      } else {
        result = await this.instance.post(
          'dashboards/fetch-report-data',
          criteria,
        );
      }
      batchHelperForDataRequest(this.responsifyGeneric(result), Target, false);
      DashboardService.onGoingApiRequestCount -= 1;
    } catch (ex: any) {
      batchHelperForDataRequest(null, Target, true, ex?.status);
      DashboardService.onGoingApiRequestCount -= 1;
    }
  };

  saveTempDrilldownState = async (
    criteria: SaveTempDrilldownCriteria,
  ): Promise<string> => this.responsify(
    await this.instance.post('reports/save-temp-drilldown-state', criteria),
  );

  getPanelData = async (criteria: PanelDataFetchCriteria) => {
    DashboardService.dataRequestsArray.push(criteria);
  };

  saveTempView = async (criteria: SaveTempViewCriteria) => {
    this.responsify(
      await this.instance.post('reports/save-temp-view', criteria),
    );
  };

  setPersonalizedDashboard = async (
    criteria: PersonalizedDashboardCriteria,
  ) => {
    this.responsify(
      await this.instance.put(
        'unified-listing/dashboards/personalise-home',
        null,
        {
          params: {
            id: criteria.id,
            home: criteria.home,
          },
        },
      ),
    );
  };
}

export const SingletonDashboardService = (function iife() {
  let dashboardService: any;

  function createDasbhboardService() {
    dashboardService = new DashboardService();
    return dashboardService;
  }

  return {
    getDasbhboardServiceInstance: () => {
      if (!dashboardService) {
        dashboardService = createDasbhboardService();
      }
      return dashboardService;
    },
  };
}());

export interface PanelDataFetchCriteria {
  SourceType: PanelSourceTypes;
  SourceId: string;
  Filters: any;
  Target?: Array<string>;
  Url: string;
  FiltersMetadata?: ObjModel.Obj;
}

export interface SaveTempViewCriteria {
  SourceId: string;
  Filters: any;
  DataSourceId: string;
  DataSourceType: PanelSourceTypes;
  FiltersMetadata?: ReportResponseModel.IFiltersMetaData;
}

export interface PersonalizedDashboardCriteria {
  id: string;
  home: boolean;
}
