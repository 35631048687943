/** @format */

import styled from 'styled-components';

const PopoverStyled = styled.div`
 width: 100%;
 flex: 1;
.se-popover-wrapper {
  position: relative;
  width: 100%;
  height: 32px;
}
.se-popover-content-container {
    position: fixed;
    right: 0;
    display: flex;
    flex-direction: column;
    box-shadow: 0px 2px 4px 0px var(--boxShadowColor);
    font-family: Regular;
    background-color: var(--white);
    z-index: 10000;
    width:262px;
    >div {
      flex: 1;
    }
  }

  .se-date-range-selector-cn.hide {
    display: none ;
  }

`;

export default PopoverStyled;
