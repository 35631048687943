import { FeatureList } from 'core/constants/common';
import Feature from './feature';

export default class SieraReports extends Feature {
  constructor(disabledFeatures: string[]) {
    super(FeatureList.sieraReports, disabledFeatures);
  }

  isAccessible(): boolean {
    return super.isRouteAccessible();
  }
}
