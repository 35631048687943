import { FeatureList } from 'core/constants/common';
import Feature from './feature';

export default class ReportListing extends Feature {
  constructor(disabledFeatures: string[]) {
    super(FeatureList.reportListing, disabledFeatures);
  }

  isAccessible(): boolean {
    return super.isRouteAccessible();
  }
}
