import React from 'react';

type Props = {
  width: number;
  height: number;
  fill?: string;
  backgroundColor?: string;
}
const ImgWidgetIcon = ({
  width, height, fill, backgroundColor,
}: Props) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={width} height={height} viewBox="0 0 26 26" fill="none">
    <rect width="26" height="26" fill={backgroundColor} />
    <path fill={fill} d="M3.24999 24.7013C2.72999 24.7013 2.27499 24.5063 1.88499 24.1163C1.49499 23.7263 1.29999 23.2713 1.29999 22.7513V3.25127C1.29999 2.73127 1.49499 2.27627 1.88499 1.88627C2.27499 1.49627 2.72999 1.30127 3.24999 1.30127H22.75C23.27 1.30127 23.725 1.49627 24.115 1.88627C24.505 2.27627 24.7 2.73127 24.7 3.25127V22.7513C24.7 23.2713 24.505 23.7263 24.115 24.1163C23.725 24.5063 23.27 24.7013 22.75 24.7013H3.24999ZM3.24999 22.7513H22.75V3.25127H3.24999V22.7513ZM5.06999 19.5988H20.9625L16.185 13.2288L11.895 18.7863L8.87249 14.6588L5.06999 19.5988Z" />
  </svg>
);

ImgWidgetIcon.defaultProps = {
  fill: 'var(--secondaryColor)',
  backgroundColor: 'transparent',
};

export default ImgWidgetIcon;
