import React from 'react';

type Props = {
  width: number;
  height: number;
  fill?: string;
}

const NoValues = ({
  width, height, fill,
}: Props) => (

  <svg style={{ width, height }} viewBox="0 0 60 60" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g id="Group 20570">
      <path id="Ellipse 710" d="M30 60C46.5685 60 60 46.5685 60 30C60 13.4315 46.5685 0 30 0C13.4315 0 0 13.4315 0 30C0 46.5685 13.4315 60 30 60Z" fill={fill} />
      <g id="Group 386797120">
        <g id="Group 19061">
          <path id="functions_FILL0_wght400_GRAD0_opsz48" d="M23 39V37.2284L31.0193 30.0006L23 22.7716V21H37V23.8121H27.5493L34.4015 29.9994L27.5493 36.1867H37V38.9987L23 39Z" fill="var(--secondaryColor)" />
          <g id="Path 8227" filter="url(#filter0_d_1079_9734)">
            <path d="M20.5807 19L19 20.5622L28.5663 30.3226C33.0196 34.6373 28.3817 30.1343 33.0033 34.5775L39.4378 41L41.0046 39.4332L20.5807 19Z" fill="var(--secondaryColor)" />
          </g>
        </g>
      </g>
    </g>
    <defs>
      <filter id="filter0_d_1079_9734" x="19" y="17.2" width="22.5044" height="23.8" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
        <feOffset dx="0.5" dy="-1.8" />
        <feComposite in2="hardAlpha" operator="out" />
        <feColorMatrix type="matrix" values="0 0 0 0 0.956863 0 0 0 0 0.964706 0 0 0 0 0.972549 0 0 0 1 0" />
        <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_1079_9734" />
        <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_1079_9734" result="shape" />
      </filter>
    </defs>
  </svg>

);

NoValues.defaultProps = {
  fill: 'var(--backgroundColor)',
};

export default NoValues;
