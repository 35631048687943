import { compareAsc, isValid, startOfDay } from 'date-fns';
import {
  DateDisplayFormat, DatePayloadFormat, IDateRangeTypes, RelativeDateRanges, calendarTypes,
} from 'core/constants/date';
import moment from 'moment-timezone';
import { FilterModel, ReportResponseModel } from 'core/models';
import { FieldEntitiesType } from 'core/constants/report';
import { MomentLocales } from 'core/constants/auth';
import { dateFormatter } from './formatters.utility';
/*
Function returns a label to display for date range filter.
*/
export const dateRangeShowcase = (startDate: any, endDate: any)
: string => {
  if (isValid(new Date(startDate)) && isValid(new Date(endDate))) {
    return (
      `${moment(startDate).format(DateDisplayFormat)} to
       ${moment(endDate).format(DateDisplayFormat)}`
    );
  }
  return null;
};

export const getDateFilterValue = (entity: string) => {
  const defaultUserFilter = {
    Type: IDateRangeTypes.Relative,
    Value: RelativeDateRanges.ThisYear,
  };
  const defaultDatetimeFilter = {
    Type: IDateRangeTypes.Relative,
    Value: RelativeDateRanges.LastSevenDays,
  };
  return entity === FieldEntitiesType.User ? defaultUserFilter : defaultDatetimeFilter;
};

/*
Function returns value based on passed date range payload. If valid returns same value
, if invalid returns a default value.
*/
export const dateRangeProcessor = (value: FilterModel.IDateRangePayload, entity?: string)
: FilterModel.IDateRangePayload => {
  if (isValidDateRangePayload(value)) {
    return value;
  }
  return getDateFilterValue(entity);
};

/*
Function returns the absolute value of predefined date ranges.
*/
export const evaluatePredefinedDateRange = (value: RelativeDateRanges, daysCount?:number)
: FilterModel.IDateRangeResponse => {
  let absoluteValue = null;

  switch (value) {
    case RelativeDateRanges.Yesterday: {
      absoluteValue = {
        From: moment().add(-1, 'days').startOf('day'),
        To: moment().add(-1, 'days').endOf('day'),
      };
      break;
    }
    case RelativeDateRanges.Today: {
      absoluteValue = {
        From: moment().startOf('day'),
        To: moment().endOf('day'),
      };
      break;
    }
    case RelativeDateRanges.Tomorrow: {
      absoluteValue = {
        From: moment().add(+1, 'days').startOf('day'),
        To: moment().add(+1, 'days').endOf('day'),
      };
      break;
    }

    case RelativeDateRanges.LastWeek: {
      absoluteValue = {
        From: moment().locale(MomentLocales.Default).add(-1, 'weeks').startOf('week'),
        To: moment().locale(MomentLocales.Default).add(-1, 'weeks').endOf('week'),
      };
      break;
    }

    case RelativeDateRanges.ThisWeek: {
      absoluteValue = {
        From: moment().locale(MomentLocales.Default).startOf('week'),
        To: moment().locale(MomentLocales.Default).endOf('week'),
      };
      break;
    }

    case RelativeDateRanges.NextWeek: {
      absoluteValue = {
        From: moment().locale(MomentLocales.Default).add(+1, 'weeks').startOf('week'),
        To: moment().locale(MomentLocales.Default).add(+1, 'weeks').endOf('week'),
      };
      break;
    }

    case RelativeDateRanges.LastMonth: {
      absoluteValue = {
        From: moment().add(-1, 'months').startOf('month'),
        To: moment().add(-1, 'months').endOf('month'),
      };
      break;
    }

    case RelativeDateRanges.ThisMonth: {
      absoluteValue = {
        From: moment().startOf('month'),
        To: moment().endOf('month'),
      };
      break;
    }

    case RelativeDateRanges.NextMonth: {
      absoluteValue = {
        From: moment().add(+1, 'months').startOf('month'),
        To: moment().add(+1, 'months').endOf('month'),
      };
      break;
    }

    case RelativeDateRanges.LastQuarter: {
      absoluteValue = {
        From: moment().add(-1, 'quarters').startOf('quarter'),
        To: moment().add(-1, 'quarters').endOf('quarter'),
      };
      break;
    }

    case RelativeDateRanges.ThisQuarter: {
      absoluteValue = {
        From: moment().startOf('quarter'),
        To: moment().endOf('quarter'),
      };
      break;
    }

    case RelativeDateRanges.NextQuarter: {
      absoluteValue = {
        From: moment().add(+1, 'quarters').startOf('quarter'),
        To: moment().add(+1, 'quarters').endOf('quarter'),
      };
      break;
    }

    case RelativeDateRanges.LastOneYear: {
      absoluteValue = {
        From: moment().subtract(1, 'years').startOf('year'),
        To: moment().subtract(1, 'years').endOf('year'),
      };
      break;
    }
    case RelativeDateRanges.ThisYear: {
      absoluteValue = {
        From: moment().startOf('year'),
        To: moment().endOf('year'),
      };
      break;
    }

    case RelativeDateRanges.NextYear: {
      absoluteValue = {
        From: moment().add(1, 'years').startOf('year'),
        To: moment().add(1, 'years').endOf('year'),
      };
      break;
    }

    case RelativeDateRanges.LastNDays: {
      absoluteValue = {
        From: moment().subtract(daysCount, 'day').startOf('day'),
        To: moment().endOf('day'),
      };
      break;
    }
    case RelativeDateRanges.NextNDays: {
      absoluteValue = {
        From: moment().startOf('day'),
        To: moment().add(daysCount, 'day').endOf('day'),
      };
      break;
    }
    // below can be excluded, check BE data (migration might be needed)
    case RelativeDateRanges.LastTwoYear: {
      absoluteValue = {
        From: moment().subtract(2, 'years').startOf('year'),
        To: moment().subtract(1, 'years').endOf('year'),
      };
      break;
    }

    case RelativeDateRanges.LastThreeSixtyFiveDays: {
      absoluteValue = {
        From: moment().subtract(364, 'day').startOf('day'),
        To: moment().endOf('day'),
      };
      break;
    }

    case RelativeDateRanges.LastSevenThirtyDays: {
      absoluteValue = {
        From: moment().subtract(729, 'day').startOf('day'),
        To: moment().endOf('day'),
      };
      break;
    }

    case RelativeDateRanges.LastSevenDays: {
      absoluteValue = {
        From: moment().subtract(6, 'day').startOf('day'),
        To: moment().endOf('day'),
      };
      break;
    }
    case RelativeDateRanges.NextSevenDays: {
      absoluteValue = {
        From: moment().startOf('day'),
        To: moment().add(6, 'day').endOf('day'),
      };
      break;
    }

    case RelativeDateRanges.LastThirtyDays: {
      absoluteValue = {
        From: moment().subtract(29, 'day').startOf('day'),
        To: moment().endOf('day'),
      };
      break;
    }

    case RelativeDateRanges.LastNintyDays: {
      absoluteValue = {
        From: moment().subtract(89, 'day').startOf('day'),
        To: moment().endOf('day'),
      };
      break;
    }

    default:
      break;
  }

  return absoluteValue;
};

/*
Function validates for valid date range payload.
*/
export const isValidDateRangePayload = (value: FilterModel.IDateRangePayload)
:boolean => {
  if (value && value.Type && Object.values(IDateRangeTypes).includes(value.Type) && value.Value) {
    if (value.Type === IDateRangeTypes.Absolute) {
      if (isValid(new Date(value.Value.From)) && isValid(new Date(value.Value.To))) {
        return true;
      }
    } else if (Object.values(RelativeDateRanges).includes(value.Value)) {
      return true;
    }
  }
  return false;
};

export const isEqualDateRangePayload = (
  payload1: FilterModel.IDateRangePayload, payload2: FilterModel.IDateRangePayload,
): boolean => {
  if (isValidDateRangePayload(payload1) && isValidDateRangePayload(payload2)) {
    if (payload1.Type === payload2.Type) {
      if (payload1.Type === IDateRangeTypes.Absolute) {
        if (!compareAsc(startOfDay(new Date(payload1.Value.From)), startOfDay(new Date(payload2.Value.From)))
          && !compareAsc(startOfDay(new Date(payload1.Value.To)), startOfDay(new Date(payload2.Value.To)))) {
          return true;
        }
      } else if (payload1.Value === payload2.Value) {
        return true;
      }
    }
  }
  return false;
};

const handleThisYear = (range: RelativeDateRanges, partitionColumnFilterDateLimit: number) => {
  const daysInThisYear = daysInYear(moment().year());

  if (daysInThisYear > partitionColumnFilterDateLimit) {
    const endDateofCurrYear = moment().endOf('year');
    return (
      {
        Type: IDateRangeTypes.Absolute,
        Value: {
          From: endDateofCurrYear.clone().subtract(partitionColumnFilterDateLimit - 1, 'days').startOf('day').format(DatePayloadFormat),
          To: endDateofCurrYear.format(DatePayloadFormat),
        },
      }
    );
  }
  return {
    Type: IDateRangeTypes.Relative,
    Value: range,
  };
};

const handleLastOneYear = (range: RelativeDateRanges, partitionColumnFilterDateLimit: number) => {
  const daysInLastYear = daysInYear(moment().subtract(1, 'year').year());

  if (daysInLastYear > partitionColumnFilterDateLimit) {
    const endDateOfPrevYear = moment([moment().year() - 1]).endOf('year');
    return (
      {
        Type: IDateRangeTypes.Absolute,
        Value: {
          From: endDateOfPrevYear.clone().subtract(partitionColumnFilterDateLimit - 1, 'days').startOf('day').format(DatePayloadFormat),
          To: endDateOfPrevYear.clone().format(DatePayloadFormat),
        },
      }
    );
  }
  return {
    Type: IDateRangeTypes.Relative,
    Value: range,
  };
};

const handleThisQuarter = (range: RelativeDateRanges, partitionColumnFilterDateLimit: number) => {
  const startDateOfThisQuarter = moment().startOf('quarter');
  const endDateOfThisQuarter = moment().endOf('quarter');
  const numberOfDaysInThisQuarter = numberOfDaysInARange(startDateOfThisQuarter, endDateOfThisQuarter);

  if (numberOfDaysInThisQuarter > partitionColumnFilterDateLimit) {
    return (
      {
        Type: IDateRangeTypes.Absolute,
        Value: {
          From: endDateOfThisQuarter.clone().subtract(partitionColumnFilterDateLimit - 1, 'days').startOf('day').format(DatePayloadFormat),
          To: endDateOfThisQuarter.clone().format(DatePayloadFormat),
        },
      }
    );
  }
  return {
    Type: IDateRangeTypes.Relative,
    Value: range,
  };
};

const handleLastQuarter = (range: RelativeDateRanges, partitionColumnFilterDateLimit: number) => {
  const startDateOfLastQuarter = moment().subtract(1, 'quarter').startOf('quarter');
  const endDateOfLastQuarter = moment().subtract(1, 'quarter').endOf('quarter');
  const numberOfDaysInLastQuarter = numberOfDaysInARange(startDateOfLastQuarter, endDateOfLastQuarter);

  if (numberOfDaysInLastQuarter > partitionColumnFilterDateLimit) {
    return (
      {
        Type: IDateRangeTypes.Absolute,
        Value: {
          From: endDateOfLastQuarter.clone().subtract(partitionColumnFilterDateLimit - 1, 'days').startOf('day').format(DatePayloadFormat),
          To: endDateOfLastQuarter.clone().format(DatePayloadFormat),
        },
      }
    );
  }
  return {
    Type: IDateRangeTypes.Relative,
    Value: range,
  };
};

const handleThisMonth = (range: RelativeDateRanges, partitionColumnFilterDateLimit: number) => {
  const numberOfDaysInThisMonth = moment().daysInMonth();

  if (numberOfDaysInThisMonth > partitionColumnFilterDateLimit) {
    const endDateofThisMonth = moment().endOf('month');
    return (
      {
        Type: IDateRangeTypes.Absolute,
        Value: {
          From: endDateofThisMonth.clone().subtract(partitionColumnFilterDateLimit - 1, 'days').startOf('day').format(DatePayloadFormat),
          To: endDateofThisMonth.format(DatePayloadFormat),
        },
      }
    );
  }
  return {
    Type: IDateRangeTypes.Relative,
    Value: range,
  };
};

const handleLastMonth = (range: RelativeDateRanges, partitionColumnFilterDateLimit: number) => {
  const numberOfDaysInLastMonth = moment().subtract(1, 'month').daysInMonth();

  if (numberOfDaysInLastMonth > partitionColumnFilterDateLimit) {
    const endDateofLastMonth = moment().subtract(1, 'month').endOf('month');
    return (
      {
        Type: IDateRangeTypes.Absolute,
        Value: {
          From: endDateofLastMonth.clone().subtract(partitionColumnFilterDateLimit - 1, 'days').startOf('day').format(DatePayloadFormat),
          To: endDateofLastMonth.format(DatePayloadFormat),
        },
      }
    );
  }
  return {
    Type: IDateRangeTypes.Relative,
    Value: range,
  };
};

const handleThisWeek = (range: RelativeDateRanges, partitionColumnFilterDateLimit: number) => {
  const numberOfDaysInWeek = 7;

  if (numberOfDaysInWeek > partitionColumnFilterDateLimit) {
    const endDateofThisWeek = moment().endOf('week');
    return (
      {
        Type: IDateRangeTypes.Absolute,
        Value: {
          From: endDateofThisWeek.clone().subtract(partitionColumnFilterDateLimit - 1, 'days').startOf('day').format(DatePayloadFormat),
          To: endDateofThisWeek.format(DatePayloadFormat),
        },
      }
    );
  }
  return {
    Type: IDateRangeTypes.Relative,
    Value: range,
  };
};

const handleLastWeek = (range: RelativeDateRanges, partitionColumnFilterDateLimit: number) => {
  const numberOfDaysInWeek = 7;

  if (numberOfDaysInWeek > partitionColumnFilterDateLimit) {
    const endDateofLastWeek = moment().subtract(1, 'week').endOf('week');
    return (
      {
        Type: IDateRangeTypes.Absolute,
        Value: {
          From: endDateofLastWeek.clone().subtract(partitionColumnFilterDateLimit - 1, 'days').startOf('day').format(DatePayloadFormat),
          To: endDateofLastWeek.format(DatePayloadFormat),
        },
      }
    );
  }
  return {
    Type: IDateRangeTypes.Relative,
    Value: range,
  };
};

const handleLastTwoYear = (range: RelativeDateRanges, partitionColumnFilterDateLimit: number) => {
  const startDateOfLastTwoYears = moment([moment().year() - 2]).startOf('year');
  const endDateOfLasttwoYears = moment([moment().year() - 1]).endOf('year');
  const daysInTwoLastYear = numberOfDaysInARange(startDateOfLastTwoYears, endDateOfLasttwoYears);

  if (daysInTwoLastYear > partitionColumnFilterDateLimit) {
    const endDateOfPrevYear = moment([moment().year() - 1]).endOf('year');
    return (
      {
        Type: IDateRangeTypes.Absolute,
        Value: {
          From: endDateOfPrevYear.clone().subtract(partitionColumnFilterDateLimit - 1, 'days').startOf('day').format(DatePayloadFormat),
          To: endDateOfPrevYear.clone().format(DatePayloadFormat),
        },
      }
    );
  }
  return {
    Type: IDateRangeTypes.Relative,
    Value: range,
  };
};

const handleLastThreeSixtyFiveDays = (range: RelativeDateRanges, partitionColumnFilterDateLimit: number) => {
  const startDateofLastTwelveMonths = moment().subtract(365, 'day').startOf('day');
  const endDateofLastTwelveMonths = moment().endOf('day');
  const daysInLastTwelveMonths = numberOfDaysInARange(startDateofLastTwelveMonths, endDateofLastTwelveMonths);
  if (daysInLastTwelveMonths > partitionColumnFilterDateLimit) {
    return (
      {
        Type: IDateRangeTypes.Absolute,
        Value: {
          From: endDateofLastTwelveMonths.clone().subtract(partitionColumnFilterDateLimit - 1, 'days').startOf('day').format(DatePayloadFormat),
          To: endDateofLastTwelveMonths.clone().format(DatePayloadFormat),
        },
      }
    );
  }
  return {
    Type: IDateRangeTypes.Relative,
    Value: range,
  };
};

const handleLastSevenThirtyDays = (range: RelativeDateRanges, partitionColumnFilterDateLimit: number) => {
  const startDateofLastTwentyFourMonths = moment().subtract(730, 'day').startOf('day');
  const endDateofLastTwentyFourMonths = moment().endOf('day');
  const daysInLastTwentyFourMonths = numberOfDaysInARange(startDateofLastTwentyFourMonths, endDateofLastTwentyFourMonths);
  if (daysInLastTwentyFourMonths > partitionColumnFilterDateLimit) {
    return (
      {
        Type: IDateRangeTypes.Absolute,
        Value: {
          From: endDateofLastTwentyFourMonths.clone().subtract(partitionColumnFilterDateLimit - 1, 'days').startOf('day').format(DatePayloadFormat),
          To: endDateofLastTwentyFourMonths.clone().format(DatePayloadFormat),
        },
      }
    );
  }
  return {
    Type: IDateRangeTypes.Relative,
    Value: range,
  };
};

const handleLastNintyDays = (range: RelativeDateRanges, partitionColumnFilterDateLimit: number) => {
  const startDateofLastThreeMonths = moment().subtract(90, 'day').startOf('day');
  const endDateofLastThreeMonths = moment().endOf('day');
  const daysInLastThreeMonths = numberOfDaysInARange(startDateofLastThreeMonths, endDateofLastThreeMonths);
  if (daysInLastThreeMonths > partitionColumnFilterDateLimit) {
    return (
      {
        Type: IDateRangeTypes.Absolute,
        Value: {
          From: endDateofLastThreeMonths.clone().subtract(partitionColumnFilterDateLimit - 1, 'days').startOf('day').format(DatePayloadFormat),
          To: endDateofLastThreeMonths.clone().format(DatePayloadFormat),
        },
      }
    );
  }
  return {
    Type: IDateRangeTypes.Relative,
    Value: range,
  };
};

const handleLastThirtyDays = (range: RelativeDateRanges, partitionColumnFilterDateLimit: number) => {
  const endDateofLastThirtyDays = moment().endOf('day');
  const daysInLastThreeMonths = 30;
  if (daysInLastThreeMonths > partitionColumnFilterDateLimit) {
    return (
      {
        Type: IDateRangeTypes.Absolute,
        Value: {
          From: endDateofLastThirtyDays.clone().subtract(partitionColumnFilterDateLimit - 1, 'days').startOf('day').format(DatePayloadFormat),
          To: endDateofLastThirtyDays.clone().format(DatePayloadFormat),
        },
      }
    );
  }
  return {
    Type: IDateRangeTypes.Relative,
    Value: range,
  };
};

const handleLastSevenDays = (range: RelativeDateRanges, partitionColumnFilterDateLimit: number) => {
  const endDateofLastSevenDays = moment().endOf('day');
  const daysInLastSevenDays = 7;
  if (daysInLastSevenDays > partitionColumnFilterDateLimit) {
    return (
      {
        Type: IDateRangeTypes.Absolute,
        Value: {
          From: endDateofLastSevenDays.clone().subtract(partitionColumnFilterDateLimit - 1, 'days').startOf('day').format(DatePayloadFormat),
          To: endDateofLastSevenDays.clone().format(DatePayloadFormat),
        },
      }
    );
  }
  return {
    Type: IDateRangeTypes.Relative,
    Value: range,
  };
};

export const getDatesIfRelativeRangeIsRestricted = (range: RelativeDateRanges, partitionColumnFilterDateLimit: number) => {
  if (partitionColumnFilterDateLimit === null) {
    return {
      Type: IDateRangeTypes.Relative,
      Value: range,
    };
  }
  switch (range) {
    case RelativeDateRanges.ThisYear: {
      return handleThisYear(range, partitionColumnFilterDateLimit);
    }
    case RelativeDateRanges.LastOneYear: {
      return handleLastOneYear(range, partitionColumnFilterDateLimit);
    }
    case RelativeDateRanges.ThisQuarter: {
      return handleThisQuarter(range, partitionColumnFilterDateLimit);
    }
    case RelativeDateRanges.LastQuarter: {
      return handleLastQuarter(range, partitionColumnFilterDateLimit);
    }
    case RelativeDateRanges.ThisMonth: {
      return handleThisMonth(range, partitionColumnFilterDateLimit);
    }
    case RelativeDateRanges.LastMonth: {
      return handleLastMonth(range, partitionColumnFilterDateLimit);
    }
    case RelativeDateRanges.ThisWeek: {
      return handleThisWeek(range, partitionColumnFilterDateLimit);
    }
    case RelativeDateRanges.LastWeek: {
      return handleLastWeek(range, partitionColumnFilterDateLimit);
    }
    case RelativeDateRanges.LastTwoYear: {
      return handleLastTwoYear(range, partitionColumnFilterDateLimit);
    }
    case RelativeDateRanges.LastThreeSixtyFiveDays: {
      return handleLastThreeSixtyFiveDays(range, partitionColumnFilterDateLimit);
    }
    case RelativeDateRanges.LastSevenThirtyDays: {
      return handleLastSevenThirtyDays(range, partitionColumnFilterDateLimit);
    }
    case RelativeDateRanges.LastNintyDays: {
      return handleLastNintyDays(range, partitionColumnFilterDateLimit);
    }
    case RelativeDateRanges.LastThirtyDays: {
      return handleLastThirtyDays(range, partitionColumnFilterDateLimit);
    }
    case RelativeDateRanges.LastSevenDays: {
      return handleLastSevenDays(range, partitionColumnFilterDateLimit);
    }
    default: {
      return {
        Type: IDateRangeTypes.Relative,
        Value: range,
      };
    }
  }
};

export const getDatesIfAbsoluteRangeIsRestricted = (fromDate: moment.Moment, toDate: moment.Moment, partitionColumnFilterDateLimit: number, isStartDatechange?:boolean) => {
  let newFromDate = fromDate;
  let newEndDate = toDate;

  if (partitionColumnFilterDateLimit !== null && toDate > fromDate.clone().add(partitionColumnFilterDateLimit - 1, 'days')) {
    if (isStartDatechange) {
      newEndDate = newFromDate.clone().add(partitionColumnFilterDateLimit - 1, 'days');
    } else {
      newFromDate = toDate.clone().subtract(partitionColumnFilterDateLimit - 1, 'days');
    }
  }

  return {
    Type: IDateRangeTypes.Absolute,
    Value: {
      From: newFromDate.startOf('day').format(DatePayloadFormat),
      To: newEndDate.endOf('day').format(DatePayloadFormat),
    },
  };
};

const daysInYear = (year: number) => (
  ((year % 4 === 0 && year % 100 > 0) || year % 400 === 0) ? 366 : 365
);

const numberOfDaysInARange = (startDate: moment.Moment, endDate: moment.Moment) => (
  endDate.diff(startDate, 'days') + 1
);

export const isValidDateString = (value: any) => typeof value === 'string' && moment(value).isValid();

export const dayRangeOptions = [
  {
    label: 'Yesterday',
    value: 'Yesterday',
    type: RelativeDateRanges.Yesterday,
  },
  {
    label: 'Today',
    value: 'Today',
    type: RelativeDateRanges.Today,
  },
];
export const sevenDaysOptions = [
  {
    label: 'Last 7 Days',
    value: 'Last 7 Days',
    type: RelativeDateRanges.LastSevenDays,
  },
];
export const weekRangeOptions = [
  {
    label: 'Last Week',
    value: 'Last Week',
    type: RelativeDateRanges.LastWeek,
  },
  {
    label: 'This Week',
    value: 'This Week',
    type: RelativeDateRanges.ThisWeek,
  },
];
export const monthRangeOptions = [
  {
    label: 'Last Month',
    value: 'Last Month',
    type: RelativeDateRanges.LastMonth,
  },
  {
    label: 'This Month',
    value: 'This Month',
    type: RelativeDateRanges.ThisMonth,
  },
];
export const quarterRangeOptions = [
  {
    label: 'Last Quarter',
    value: 'Last Quarter',
    type: RelativeDateRanges.LastQuarter,
  },
  {
    label: 'This Quarter',
    value: 'This Quarter',
    type: RelativeDateRanges.ThisQuarter,
  },
];
export const yearRangeOptions = [
  {
    label: 'Last Year',
    value: 'Last Year',
    type: RelativeDateRanges.LastOneYear,
  },
  {
    label: 'This Year',
    value: 'This Year',
    type: RelativeDateRanges.ThisYear,
  },
];
export const customRangeOptions = [
  {
    label: 'Custom Range',
    value: IDateRangeTypes.Absolute,
    type: RelativeDateRanges.Custom,
  },
];

export const LastNDaysOptions = [
  {
    label: 'Last 30 Days',
    value: 'Last 30 Days',
    type: RelativeDateRanges.LastThirtyDays,
  },
  {
    label: 'Last 90 Days',
    value: 'Last 90 Days',
    type: RelativeDateRanges.LastNintyDays,
  },
  {
    label: 'Last 365 Days',
    value: 'Last 365 Days',
    type: RelativeDateRanges.LastThreeSixtyFiveDays,
  },
  {
    label: 'Last 730 Days',
    value: 'Last 730 Days',
    type: RelativeDateRanges.LastSevenThirtyDays,
  },
  {
    label: 'Last 2 Years',
    value: 'Last 2 Years',
    type: RelativeDateRanges.LastTwoYear,
  },
];

export const PredefinedDateRanges = [
  ...dayRangeOptions,
  {
    label: '',
    options: sevenDaysOptions,
  },
  {
    label: '',
    options: weekRangeOptions,
  },
  {
    label: '',
    options: monthRangeOptions,
  },
  {
    label: '',
    options: quarterRangeOptions,
  },
  {
    label: '',
    options: yearRangeOptions,
  },
  {
    label: '',
    options: LastNDaysOptions,
  },
  {
    label: '',
    options: customRangeOptions,
  },
];

export const singleDatesList = [RelativeDateRanges.Today, RelativeDateRanges.Yesterday, RelativeDateRanges.Tomorrow];
export const customDateRangeList = [RelativeDateRanges.Custom, RelativeDateRanges.LastNDays, RelativeDateRanges.NextNDays];

// used to display date range in required format
// @dateRange => relative date range for which needs to be formatted
// returns => formatted date string
export const getPredefinedDateRangeDisplay = (dateRange:RelativeDateRanges) :string => {
  if (singleDatesList.includes(dateRange)) {
    return evaluatePredefinedDateRange(dateRange).From.format(DateDisplayFormat);
  }
  if (customDateRangeList.includes(dateRange)) {
    return '';
  }
  const date = evaluatePredefinedDateRange(dateRange);
  return `${date?.From?.format(DateDisplayFormat)} - ${date?.To?.format(DateDisplayFormat)}`;
};

// used to compute calendar type based on start of the week
// @weekstart => day of the week indicator in numeric format
// returns => calendarType string, indicating startOfWeek
export const getCalendarTypeProp = (weekstart:number) :calendarTypes => {
  switch (weekstart) {
    case 1:
      return calendarTypes.ISO8601;
    case 6:
      return calendarTypes.ISLAMIC;
    default: return calendarTypes.GREGORY;
  }
};
// used to convert JS date object into moment object excluding timezone
// @dateObj=> JS date object
// returns => moment object
export const convertDateObjToMomentObj = (dateObj:Date):moment.Moment => {
  const date = String(dateObj.getDate()).padStart(2, '0');
  const month = String(dateObj.getMonth() + 1).padStart(2, '0');
  const dateString = `${dateObj.getFullYear()}-${month}-${date}`;
  return moment(dateString);
};

// used to convert epoc milliseconds into formatted date
// @date=> epoc milliseconds
// @formatter => type of formatter
// returns => formatted date string
export const convertEpocToDate = (date: string, formatter: ReportResponseModel.IColumnFormatter) => dateFormatter(date, formatter.FormatterMeta.DateStyle, formatter.FormatterMeta.Seperator, null);
