import React from 'react';

type Props = {
  width: number;
  height: number;
  fill?: string;
}

const BulbNewIcon = ({
  width, height, fill,
}: Props) => (
  <svg
    style={{
      width,
      height,
    }}
    viewBox="0 0 22 22"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M8.49984 20.5003C8.49984 20.9587 8.87484 21.3337 9.33317 21.3337H12.6665C13.1248 21.3337 13.4998 20.9587 13.4998 20.5003V19.667H8.49984V20.5003ZM10.9998 4.66699C7.78317 4.66699 5.1665 7.28366 5.1665 10.5003C5.1665 12.4837 6.15817 14.2253 7.6665 15.2837V17.167C7.6665 17.6253 8.0415 18.0003 8.49984 18.0003H13.4998C13.9582 18.0003 14.3332 17.6253 14.3332 17.167V15.2837C15.8415 14.2253 16.8332 12.4837 16.8332 10.5003C16.8332 7.28366 14.2165 4.66699 10.9998 4.66699ZM13.3748 13.917L12.6665 14.417V16.3337H9.33317V14.417L8.62484 13.917C7.49984 13.1337 6.83317 11.8587 6.83317 10.5003C6.83317 8.20033 8.69984 6.33366 10.9998 6.33366C13.2998 6.33366 15.1665 8.20033 15.1665 10.5003C15.1665 11.8587 14.4998 13.1337 13.3748 13.917Z" fill={fill} />
    <path d="M7.77197 4.34912L9.07101 3.59912L7.57101 1.00105L6.27197 1.75105L7.77197 4.34912Z" fill={fill} />
    <path d="M12.7715 3.59814L14.0705 4.34814L15.5705 1.75007L14.2715 1.00007L12.7715 3.59814Z" fill={fill} />
    <path d="M4.89795 7.73584L5.28618 6.28695L2.3884 5.51049L2.00017 6.95938L4.89795 7.73584Z" fill={fill} />
    <path d="M16.5571 6.28467L16.9454 7.73356L19.8431 6.9571L19.4549 5.50821L16.5571 6.28467Z" fill={fill} />
  </svg>
);

BulbNewIcon.defaultProps = {
  fill: '#454F5B',
};

export default BulbNewIcon;
