import { IFilterResponse } from '../filter';
import { IVariables } from '../store';
import { ReportBuilderStatus } from './report-builder';

export interface ISaveSuccessPayload {
  reportId: string;
  saveType: ReportBuilderStatus;
}

export interface IFilterDataLoadRequestPayload {
  filterId: string;
  variables: IVariables;
  filterValue?: any;
  isLazyLoad?: boolean;
}

export interface ISearchFilterPayloadRequest {
  filterId: string;
  searchKey: string;
}
export interface ILoadMoreFilterPayloadRequest {
  filterId: string;
}

export interface IFilterReducerPayload {
  filterId: string;
  isLazyLoad: boolean;
}

export interface IFilterDataLoadPayload {
  filterId: string;
  filterResponse: IFilterResponse;
}

export interface IAddDynamicFilterPayload {
  index: number;
}

export interface IDeleteDynamicFilterPayload {
  filterId: string;
}

export interface IFilterValueChangePayload {
  filterId: string;
  filterValue: string;
  variables: IVariables;
}

export interface IFilterFormValues {
  name: string;
  filterId: string;
  userStatus?: string;
}

export interface IAddConfigPayolad {
  id: string
}

export interface ISwitchUserFieldFilterPayload {
  index: number;
  context: SwitchContext
  isSwitchFieldAdded?: boolean
}

export enum SwitchContext {
  Fields = 'Fields',
  Filters = 'Filters'
}
