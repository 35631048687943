import React from 'react';

type Props = {
  width: number;
  height: number;
  bgFill?: string;
  strokeFill?: string;
}

const AddIcon = ({
  width, height, bgFill, strokeFill,
}: Props) => (
  <svg
    id="add_black_24dp_3_"
    data-name="add_black_24dp (3)"
    xmlns="http://www.w3.org/2000/svg"
    style={{
      width,
      height,
    }}
    viewBox="0 0 16 16"
  >
    <path
      id="Path_6394"
      data-name="Path 6394"
      d="M0,0H16V16H0Z"
      style={{
        fill: bgFill,
      }}
    />
    <path
      id="Path_6395"
      data-name="Path 6395"
      d="M14.117,10.209H10.209v3.907h-1.3V10.209H5v-1.3H8.907V5h1.3V8.907h3.907Z"
      transform="translate(-1.558 -1.558)"
      fill={strokeFill}
    />
  </svg>
);

AddIcon.defaultProps = {
  bgFill: 'none',
  strokeFill: '#fff',
};

export default AddIcon;
