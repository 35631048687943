import { WeekStart } from 'core/constants/auth';
import { statusCodes } from 'core/constants/report';

export interface UserAttributes {
    Id: string,
    AutoId: number,
    Name: string,
    OrgShortCode: string,
    RegionId: string,
    TokenId: string,
    UserTimeZone: string,
    UserDateFormat: string,
    Role: string,
    passPhrase: string, // email-id
    IsReportingDisabled: boolean;
    isSieraEnabled: boolean;
    source?: SourceTypes;
}

export interface Auth {
    Token: string;
    ExpiresOn: number;
    UserAttributes: UserAttributes;
    OrgAttributes: OrgAttributes;
    RedirectAttributes: RedirectUrlAttributes;
    isTenantDisabled?: boolean;
    statusCodes?:statusCodes;
}

export interface OrgAttributes {
    DefinedWeekStart: WeekStart
}

export interface RedirectUrlAttributes {
    Url: string;
    Redirect: boolean;
}

export interface IsAuthResponse {
    IsAuthorized: boolean,
    ReportingDisabled: boolean
}

export enum SettingConfigTypes {
   TenantSettings= 'TenantSettings'
}

export enum SourceTypes {
    ANDROID = 'ANDROID',
    IOS = 'IOS',
    MARVIN = 'MARVIN',
    LSQ='LSQ'
}

export enum MobileAuthExceptions{
    tokenExpired = 'MXMobileTokenExpiredException',
    sessionExpired = 'MXMobileInvalidSessionException',
    mismatchToken='MXMobileTokenMismatchException',
    success = 'success'
}
