import API from 'services/api';
import { UserFilterPayloadType } from 'core/constants/filter';
import { ISelectFilterResponse } from 'core/models/filter';
import { FilterModel, ObjModel } from 'core/models';
import SieraResponse from 'core/models/siera-response';

export default class FilterService extends API {
    static baseURL: string = process.env.REACT_APP_DATA_BASE_URL || '';

    constructor() {
      super(FilterService.baseURL, true, true);
    }

    async GetGroups(params:ObjModel.Obj): Promise<Array<FilterModel.ISelectFilterResponse>> {
      return this.responsifyGeneric<Array<FilterModel.ISelectFilterResponse>>(
        await this.instance.get('groups/get-groups', { params }),
      );
    }

    async GetUserFilter(data: IUserOptionsFetchPayload, params:ObjModel.Obj): Promise<FilterModel.IUserFilterOptionResponse> {
      return this.responsifyGeneric<FilterModel.IUserFilterOptionResponse>(
        await this.instance.post('users/get-users', data, { params }),
      );
    }

    async GetLSQDropdownValues(params:ObjModel.Obj): Promise<SieraResponse<ISelectFilterResponse[]>> {
      return this.genericResponseWrapper<ISelectFilterResponse[]>(
        await this.instance.get('filters/get-dropdown-values', { params }),
      );
    }

    async GetCustomDefinedDropdownValues(filterKey: string): Promise<Array<ISelectFilterResponse>> {
      return this.responsifyGeneric<Array<ISelectFilterResponse>>(
        await this.instance.get(`filters/get-custom-defined-filter-values?key=${filterKey}`),
      );
    }
}

export interface IUserOptionsFetchPayload {
  Type: string,
  Users?: Array<number>,
  Groups?: Array<any>,
  UserIDs: Array<number>,
  InclusionType: UserFilterPayloadType,
  Page: FilterModel.IPage,
  Search: string,
  AdditionalFilters: ObjModel.ObjGeneric<any>
  Status?: string,
}
