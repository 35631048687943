import { ISettingsResponse } from 'redux-v2/settings-store/settings-store-state';
import API from './api';

export default class CommonDataService extends API {
  static baseURL: string = process.env.REACT_APP_DATA_BASE_URL || '';

  constructor() {
    super(CommonDataService.baseURL, true, true);
  }

  getSettingsCode = async (key?: string): Promise<ISettingsResponse> => {
    const url = '/settings/fetch-plan-settings';
    const params: any = {};

    if (key !== undefined) {
      params.key = key;
    }

    return this.responsify(await this.instance.get(url, { params }));
  };
}
