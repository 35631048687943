declare global {
    interface Window {
      envVariables: any;
      onTokenRefreshed?: () => void;
      webkit: any;
      LSQAndroidClient: any;
    }
}

const globalLogger = (obj:any) => {
  if (window?.envVariables.SIERA_ALLOW_DEBUG) {
    console.log(obj);
  }
};

export { globalLogger };
