import React from 'react';

type Props = {
  width: number;
  height: number;
  fill?: string;
}

const UserIcon = ({
  width, height, fill,
}: Props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={width}
    height={height}
    viewBox="0 0 20 20"
    preserveAspectRatio="xMidYMid slice"
  >
    <g
      id="Group_22581"
      data-name="Group 22581"
      transform="translate(-536 -503)"
    >
      <path
        id="supervisor_account_FILL0_wght400_GRAD0_opsz48"
        d="M16.08,16.95a1.878,1.878,0,1,1,1.33-.55A1.812,1.812,0,0,1,16.08,16.95Zm-3.92,4.42V20.35a1.711,1.711,0,0,1,.22-.89,1.467,1.467,0,0,1,.62-.57,6.618,6.618,0,0,1,1.5-.56,6.833,6.833,0,0,1,1.58-.18,7.218,7.218,0,0,1,1.58.17,5.822,5.822,0,0,1,1.5.57,1.331,1.331,0,0,1,.62.56,1.765,1.765,0,0,1,.22.9v1.02ZM10.4,14.95a2.877,2.877,0,0,1-3-3,2.877,2.877,0,0,1,3-3,2.877,2.877,0,0,1,3,3,2.877,2.877,0,0,1-3,3ZM4,21.37V19.49a2.4,2.4,0,0,1,.34-1.25,2.16,2.16,0,0,1,1.02-.87,15.033,15.033,0,0,1,2.57-.9,10.389,10.389,0,0,1,2.47-.3,9.66,9.66,0,0,1,1.14.06q.5.06,1.08.18L11.6,17.43a4.726,4.726,0,0,0-.56-.05q-.3-.01-.64-.01a10.629,10.629,0,0,0-2.22.23,10.158,10.158,0,0,0-2.34.85,1.119,1.119,0,0,0-.46.44,1.158,1.158,0,0,0-.18.6v.68h5.76v1.2ZM10.96,20.17Zm-.56-6.42a1.8,1.8,0,1,0,0-3.6,1.8,1.8,0,1,0,0,3.6ZM10.4,11.95Z"
        transform="translate(534 497.05)"
        fill={fill}
      />
      <rect
        id="Rectangle_8006"
        data-name="Rectangle 8006"
        width={width}
        height={height}
        transform="translate(536 503)"
        fill="none"
      />
    </g>
  </svg>
);

UserIcon.defaultProps = {
  fill: '#e32c8d',
};

export default UserIcon;
