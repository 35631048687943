import React from 'react';

type Props = {
    width: number;
    height: number;
    fill?: string;
}

const NewReplaceIcon = ({
  width, height, fill,
}: Props) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={width} height={height} viewBox="0 0 12 10.8">
    <path id="sync_alt_FILL0_wght400_GRAD0_opsz48" d="M13.135,11.745l-.63-.63L14.29,9.33H4.555v-.9H14.29l-1.8-1.8L13.12,6,16,8.88ZM6.865,16.8,4,13.935l2.88-2.88.63.63-1.8,1.8h9.735v.9H5.71L7.495,16.17Z" transform="translate(-4 -6)" fill={fill} />
  </svg>

);

NewReplaceIcon.defaultProps = {
  fill: 'var(--secondaryColor)',
};

export default NewReplaceIcon;
