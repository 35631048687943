import React from 'react';

type Props = {
  width: number;
  height: number;
  fill?: string;
}

const ArrowUpIcon = ({
  width, height, fill,
}: Props) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_234_23839)">
      <path
        d="M11.3333 9.33331L7.99992 5.99998L4.66658 9.33331H11.3333Z"
        fill={fill}
      />
    </g>
    <defs>
      <clipPath id="clip0_234_23839">
        <rect
          width="16"
          height="16"
          fill="white"
          transform="matrix(-1 0 0 -1 16 16)"
        />
      </clipPath>
    </defs>
  </svg>

);

ArrowUpIcon.defaultProps = {
  fill: 'var(--secondaryColor)',
};

export default ArrowUpIcon;
