import React from 'react';

type Props = {
  width?: number;
  height?: number;
  fill?: string;
}

const TagsIcon = ({
  width, height, fill,
}: Props) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M2.43803 11.4824C2.14587 11.1902 1.9998 10.8378 1.9998 10.4251C1.9998 10.0124 2.14587 9.65992 2.43803 9.36775L9.38608 2.4197C9.54451 2.26127 9.71261 2.15171 9.89038 2.09101C10.0681 2.03034 10.2556 2 10.4527 2H16.0852C16.5041 2 16.8552 2.1399 17.1385 2.4197C17.4218 2.69951 17.5635 3.04887 17.5635 3.46776V9.10032C17.5635 9.29742 17.5314 9.49103 17.4672 9.68114C17.403 9.87126 17.2917 10.0455 17.1333 10.204L10.2328 17.115C9.92826 17.4195 9.5679 17.5717 9.15168 17.5717C8.73546 17.5717 8.3751 17.4195 8.07058 17.115L2.43803 11.4824ZM3.44903 10.4819L9.08159 16.1144L16.0852 9.10032V3.46776H10.4527L3.44903 10.4819ZM13.1948 5.41322C13.1948 5.67261 13.2906 5.89804 13.482 6.08949C13.6735 6.28095 13.8989 6.37668 14.1583 6.37668C14.4177 6.37668 14.6431 6.28095 14.8346 6.08949C15.026 5.89804 15.1218 5.67261 15.1218 5.41322C15.1218 5.15382 15.026 4.9284 14.8346 4.73694C14.6431 4.54548 14.4177 4.44975 14.1583 4.44975C13.8989 4.44975 13.6735 4.54548 13.482 4.73694C13.2906 4.9284 13.1948 5.15382 13.1948 5.41322Z"
      fill={fill}
    />
    <circle
      cx="14.0129"
      cy="5.54321"
      r="1"
      transform="rotate(-45 14.0129 5.54321)"
      fill="white"
      stroke={fill}
    />
  </svg>

);

TagsIcon.defaultProps = {
  width: 20,
  height: 20,
  fill: 'var(--secondaryColor)',
};

export default TagsIcon;
