import React from 'react';

type Props = {
    width?: number;
    height?: number;
    fill?: string;
}

const SalesCloudIcon = ({
  width, height, fill,
}: Props) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={width} height={height} viewBox="0 0 14 14" fill="none">
    <path d="M3.66665 4.5H0.333313V13.6667H3.66665V4.5Z" fill={fill} />
    <path d="M13.6666 7.8335H10.3333V13.6668H13.6666V7.8335Z" fill={fill} />
    <path d="M8.66665 0.333496H5.33331V13.6668H8.66665V0.333496Z" fill={fill} />
  </svg>

);

SalesCloudIcon.defaultProps = {
  height: 20,
  width: 20,
  fill: 'var(--secondaryColor)',
};

export default SalesCloudIcon;
