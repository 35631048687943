import React from 'react';

type Props = {
  width: number;
  height: number;
  fill?: string;
}

const ClearIcon = ({
  width, height, fill,
}: Props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    style={{
      width, height,
    }}
    viewBox="0 0 27 27"
  >
    <defs>
      <filter
        id="Rectangle_2284"
        x="0"
        y="0"
        width="27"
        height="27"
        filterUnits="userSpaceOnUse"
      >
        <feOffset dy="1" />
        <feGaussianBlur stdDeviation="0.5" result="blur" />
        <feFlood floodOpacity="0.161" />
        <feComposite operator="in" in2="blur" />
        <feComposite in="SourceGraphic" />
      </filter>
    </defs>
    <g id="Slide_in_Button" data-name="Slide in Button" transform="translate(-238.641 -111.64)">
      <g transform="matrix(1, 0, 0, 1, 238.64, 111.64)" filter="url(#Rectangle_2284)">
        <rect
          id="Rectangle_2284-2"
          data-name="Rectangle 2284"
          width="24"
          height="24"
          rx="12"
          transform="translate(1.5 0.5)"
          fill="#fff"
        />
      </g>
      <g id="expand_more_black_24dp" transform="translate(262.419 114.14) rotate(90)">
        <path
          id="Path_5577"
          data-name="Path 5577"
          d="M0,0H20.278V20.278H0Z"
          fill="none"
        />
        <path
          id="Path_5578"
          data-name="Path 5578"
          d="M8.948,0,5.07,3.87,1.191,0,0,1.191l5.07,5.07,5.07-5.07Z"
          transform="translate(5.07 7.258)"
          fill={fill}
        />
      </g>
    </g>
  </svg>
);

ClearIcon.defaultProps = {
  fill: 'var(--border)',
};

export default ClearIcon;
