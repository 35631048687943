import styled from 'styled-components';

const StyledTextAreaFieldComponent = styled.div`
    .se-text-area-field-label{
      font-family:SemiBold;
      font-size: 13px;
      color: var(--secondaryColor);
      padding-bottom:6px;
      display: flex;
    }
    &.se-text-area-field-component-wrapper{
        display:flex;
        flex-direction:column;
    }
    .se-text-area-field-component{
        width: 100%;
        font-family: Regular;
        font-size: 13px;
        resize: none;
        color: var(--secondaryColor);
        border-radius: 2px;
        background-color:var(--white);
        border: 1px solid var(--borderColor);
        min-height: 32px;
        padding: 6px 11px;
        box-sizing: border-box;
        outline: none;

        :focus-visible {
            border-color: var(--border);
        }

        ::placeholder {
            color: var(--searchBoxTextColor);
        }

        :hover {
            border-color: var(--border);
        }

    }
    .danger {
        border-color: var(--dangerColor) !important;
    }

    .se-text-area-field-error {
        font: 12px Regular;
        color: var(--dangerColor);
        margin-top: 6px;
    }

  


`;
export default StyledTextAreaFieldComponent;
