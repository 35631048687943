import { CustomAnalyticsData, ICustomAnalyticsListing, IRequestProcessing } from 'core/models/custom-analytics';

export interface ICustomAnalyticsState {
    requestProcessing: IRequestProcessing;
    customAnalyticsListing: ICustomAnalyticsListing,
    deleteData: CustomAnalyticsData;
    editData: Array<CustomAnalyticsData>;
    isEditModalOpen: boolean;
}

export enum CustomAnalyticsAction {
    REQUEST_CUSTOM_ANALYTICS_LIMITS = 'REQUEST_CUSTOM_ANALYTICS_LIMITS',
    REQUEST_CUSTOM_ANALYTICS_REPORTS_LIST = 'REQUEST_CUSTOM_ANALYTICS_REPORTS_LIST',
    REQUEST_CUSTOM_ANALYTICS_DASHBOARDS_LIST = 'REQUEST_CUSTOM_ANALYTICS_DASHBOARDS_LIST',
    UPDATE_SELECTED_TAB = 'UPDATED_SELECTED_TAB',
    UPDATE_CUSTOM_ANALYTICS_LIST_REPORT_PAYLOAD = 'UPDATE_CUSTOM_ANALYTICS_LIST_REPORT_PAYLOAD',
    UPDATE_CUSTOM_ANALYTICS_LIST_DASHBOARD_PAYLOAD = 'UPDATE_CUSTOM_ANALYTICS_LIST_DASHBOARD_PAYLOAD',
    GET_CREATED_BY_LIST_REPORTS = 'GET_CREATED_BY_LIST_REPORTS',
    GET_PRODUCT_LINE_LIST_REPORTS = 'GET_PRODUCT_LINE_LIST_REPORTS',
    GET_DATASET_LIST_REPORTS = 'GET_DATASET_LIST_REPORTS',
    GET_CREATED_BY_LIST_DASHBOARD = 'GET_CREATED_BY_LIST_DASHBOARD',
    UPDATE_REPORT_SEARCH_KEY = 'UPDATE_REPORT_SEARCH_KEY',
    UPDATE_DASHBOARD_SEARCH_KEY = 'UPDATE_DASHBOARD_SEARCH_KEY',
    UPDATE_SELECTED_REPORTS = 'UPDATE_SELECTED_REPORTS',
    UPDATE_SELECTED_DASHBOARDS = 'UPDATE_SELECTED_DASHBOARDS',
    SET_LOADING_ROWS= 'SET_LOADING_REPORTS',
    UPDATE_REPORT_OWNER = 'UPDATE_REPORT_OWNER',
    UPDATE_DASHBOARD_OWNER = 'UPDATE_DASHBOARD_OWNER',
    SET_DELETE_ID = 'SET_DELETE_ID',
    SET_EDIT_IDS = 'SET_EDIT_IDS',
    OPEN_EDIT_MODAL = 'OPEN_EDIT_MODAL',
}
