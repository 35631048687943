/** @format */

import React from 'react';
import { useSelector } from 'react-redux';
import authSelector from 'redux-v2/auth-store/auth-store.selector';
import StyledErrorScreenContainer from './error-screen-component.styled';
import LsqSieraIcon from '../svg/LsqSiera/lsq-siera-icon.svg';

interface IErrorScreenComponent {
  err: string;
  errDescription: string;
  redirectToButtonContent: string;
  redirectTo?: string;
  icon?: React.ReactNode;
}
const ErrorScreenComponent = (props: IErrorScreenComponent) => {
  const {
    err, errDescription, redirectToButtonContent, redirectTo, icon,
  } = props;
  const { isMarvin } = useSelector(authSelector);

  const navigateToMarvin = () => {
    window.top.location.href = document.referrer;
  };

  const navigationOnSelection = () => (
    <div
      onClick={() => (isMarvin ? navigateToMarvin() : window.open(redirectTo, '_self'))}
      className="se-go-to-dashboard"
    >
      {redirectToButtonContent}
    </div>
  );
  return (
    <StyledErrorScreenContainer>
      <div className="error-screen">
        {icon}
        <div
          className="se-info-data-header "
        >
          {err}
        </div>
        <div
          className="se-contact-data-text "
        >
          {errDescription}
        </div>
        {redirectTo && navigationOnSelection()}
      </div>
    </StyledErrorScreenContainer>
  );
};

ErrorScreenComponent.defaultProps = {
  icon: <LsqSieraIcon width={262} height={131} />,
};

export default ErrorScreenComponent;
