import React from 'react';

type Props = {
  width: number;
  height: number;
  fill?: string;
}

const AccountsActivityEntityIcon = ({
  width, height, fill,
}: Props) => (

  <svg width={width} height={height} viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path id="AccountsActivity" fill={fill} d="M9.99984 5.83333V2.5H1.6665V17.5H18.3332V5.83333H9.99984ZM4.99984 15.8333H3.33317V14.1667H4.99984V15.8333ZM4.99984 12.5H3.33317V10.8333H4.99984V12.5ZM4.99984 9.16667H3.33317V7.5H4.99984V9.16667ZM4.99984 5.83333H3.33317V4.16667H4.99984V5.83333ZM8.33317 15.8333H6.6665V14.1667H8.33317V15.8333ZM8.33317 12.5H6.6665V10.8333H8.33317V12.5ZM8.33317 9.16667H6.6665V7.5H8.33317V9.16667ZM8.33317 5.83333H6.6665V4.16667H8.33317V5.83333ZM16.6665 15.8333H9.99984V14.1667H11.6665V12.5H9.99984V10.8333H11.6665V9.16667H9.99984V7.5H16.6665V15.8333ZM14.9998 9.16667H13.3332V10.8333H14.9998V9.16667ZM14.9998 12.5H13.3332V14.1667H14.9998V12.5Z" />
    <circle cx="15" cy="14.1666" r="5" fill="white" />
    <path id="AccountsActivity" fill={fill} d="M14.375 17.9166C14.3086 17.9165 14.2433 17.8968 14.186 17.8595C14.1287 17.8223 14.0813 17.7688 14.0488 17.7045L12.7088 15.0498H11.25V14.218H12.9263C12.9933 14.2174 13.0593 14.2368 13.1174 14.2741C13.1754 14.3114 13.2234 14.3653 13.2563 14.4301L14.25 16.4139L15.6788 10.7301C15.6979 10.6466 15.74 10.5717 15.7991 10.5158C15.8583 10.46 15.9317 10.4259 16.0091 10.4183C16.0865 10.4106 16.1641 10.4299 16.2313 10.4733C16.2984 10.5168 16.3517 10.5823 16.3837 10.6608L17.325 12.8705H18.75V13.7023H17.085C17.0133 13.7025 16.943 13.6799 16.8825 13.6371C16.822 13.5944 16.7739 13.5333 16.7437 13.4611L16.125 12.0305L14.7375 17.6116C14.7171 17.691 14.676 17.7618 14.6192 17.8151C14.5624 17.8685 14.4927 17.9019 14.4188 17.9111L14.375 17.9166Z" />
  </svg>
);

AccountsActivityEntityIcon.defaultProps = {
  fill: 'var(--accountsActivityEntity)',
};

export default AccountsActivityEntityIcon;
