/** @format */

import { spinMixin, flexCenterMixin } from 'core/utils/mixins';
import styled from 'styled-components';

const StyledLoadingComponent = styled.div<{
  rotatingColor: string;
}>`

    height: 100%;
    width: 100%;
    ${flexCenterMixin}
    
  .se-loading-spinner {
    z-index: 1;
    width: 16px;
    height: 16px;
    border: 2px solid var(--borderColor);
    border-radius: 50%;
    border-top-color: ${(props) => props.rotatingColor};
    ${spinMixin({ duration: '1500ms' })}
  }
`;

export default StyledLoadingComponent;
