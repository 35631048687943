import React, { useState } from 'react';
import { ErrorBoundary } from 'react-error-boundary';
import { useSelector } from 'react-redux';
import { ReduxModel } from 'core/models';
import ErrorFallback from '../ErrorFallback/error-fallback.component';

const ErrorBoundaryWrapper = <T, >({ children }: React.PropsWithChildren<T>) => {
  const showError = useSelector((state: ReduxModel.IGlobalState) => state.globalStore.showErrorBoundary);
  const [hasError, setHasError] = useState(false);

  const onError = (error:any, errorInfo: any) => {
    console.error('ErrorBoundary caught an error:', error, errorInfo);
    setHasError(true);
  };

  return (
    <>
      {hasError || showError ? (
        <ErrorFallback />
      ) : (
        <ErrorBoundary
          fallbackRender={() => null}
          onError={onError}
        >
          {children}
        </ErrorBoundary>
      )}
    </>
  );
};

export default ErrorBoundaryWrapper;
