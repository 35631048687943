import { IDashboardConfig, PanelDataFetchCriteria } from 'core/models/dashboard';
import { IEditTagPayload, ObjectActionType } from 'redux-v2/report-listing/report-listing.state';
import { IEditProfileDashboardPayload } from 'redux-v2/global-store/global-store.state';
import SieraResponse from 'core/models/siera-response';
import API from './api';

export default class DashboardBuilderService extends API {
  static baseURL: string = process.env.REACT_APP_DATA_BASE_URL || '';

  constructor() {
    super(DashboardBuilderService.baseURL, true, true);
  }

  getAllReports = async () => this.responsify(
    await this.instance.get('unified-listing/list', {
      params: {
        section: '',
        profileId: 'ALL',
        tagId: '',
        sourceType: 'SIERA',
      },
    }),
  );

  getReportConfig = async (reportId: string) => this.responsify(
    await this.instance.get('reports/get-raw-config', {
      params: {
        reportId,
      },
    }),
  );

  editTagsByObject = async (data: IEditTagPayload) => (
    this.responsify(await this.instance.post('unified-listing/tags/tag-untag', data))
  )

  editProfilesToDashboard = async (data: IEditProfileDashboardPayload) => (
    this.responsify(await this.instance.put('profiles/update-dashboard-profiles ', data))
  )

  saveDashboardConfig = async (data: IDashboardConfig): Promise<SieraResponse<string>> => this.genericResponseWrapper(
    await this.instance.post('dashboards/save-config', data),
  );

  getWidgetReportData = async (panelCriteria: PanelDataFetchCriteria) => this.responsify(
    await this.instance.post('dashboards/fetch-report-data', panelCriteria),
  );

  validateEmbedURL = async (url: string) => this.responsify(
    await this.instance.get('dashboards/validate-url', {
      params: {
        url,
      },
    }),
  );

  replaceMailMergeFields = async (url: string) => this.responsify(
    await this.instance.get('users/replace-mail-merge-fields', {
      params: {
        url,
      },
    }),
  );

  getTagsByObject = async (objectId: string, objectType: ObjectActionType) => (
    this.responsify(await this.instance.get('unified-listing/tags/object', {
      params: {
        objectId,
        objectType,
      },
    }))
  );
}
