import React from 'react';

type Props = {
  width?: number;
  height?: number;
  fill?: string;
};

const DoubleArrow = ({ width, height, fill }: Props) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_1763_26)">
      <path
        d="M11.7267 12L12.6667 11.06L9.61332 8L12.6667 4.94L11.7267 4L7.72665 8L11.7267 12Z"
        fill={fill}
      />
      <path
        d="M7.33333 12L8.27333 11.06L5.21999 8L8.27333 4.94L7.33333 4L3.33333 8L7.33333 12Z"
        fill={fill}
      />
    </g>
    <defs>
      <clipPath id="clip0_1763_26">
        <rect width="16" height="16" fill="white" />
      </clipPath>
    </defs>
  </svg>
);

DoubleArrow.defaultProps = {
  width: 16,
  height: 16,
  fill: 'var(--bannerFontColor)',
};

export default DoubleArrow;
