import React from 'react';

type Props = {
  width?: number;
  height?: number;
};

const LeadSquaredIcon = ({ width, height }: Props) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 222 44"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M3.31787 2.32886V18.4856H19.7002V34.7878H35.7874V2.32886H3.31787Z"
      fill="var(--leadsquaredIconBlue)"
    />
    <path
      d="M40.9533 12.2267C39.625 12.2267 39.625 10.1889 40.9533 10.1889H43.1671C43.6099 10.1889 44.0526 10.48 44.0526 10.9167V32.6045H44.9382C46.2665 32.6045 46.2665 34.6422 44.9382 34.6422H40.9533C39.625 34.6422 39.625 32.6045 40.9533 32.6045H41.8388V12.2267H40.9533Z"
      fill="var(--leadsquaredIconBlack)"
    />
    <path
      d="M55.4167 34.9333C50.3987 34.9333 46.709 31.1489 46.709 26.3455C46.709 21.6878 50.3987 17.7578 55.1216 17.7578C59.6968 17.7578 62.6486 21.5422 62.7962 26.2C62.7962 26.7822 62.501 27.3644 61.7631 27.3644H48.7752C49.3656 30.5666 52.1698 32.8955 55.4167 32.8955C56.5974 32.8955 58.3685 32.6044 59.4016 32.0222C60.1396 31.5855 60.5823 32.0222 60.8775 32.3133C61.1727 32.75 61.0251 33.3322 60.4348 33.7689C58.9589 34.4966 56.8926 34.9333 55.4167 34.9333ZM48.7752 25.3266H60.5823C59.992 22.1244 58.2209 19.65 54.974 19.65C51.8746 19.65 49.218 22.27 48.7752 25.3266Z"
      fill="var(--leadsquaredIconBlack)"

    />
    <path
      d="M78.7356 34.7878C78.2928 34.7878 77.9977 34.4967 77.9977 34.06V32.0222C76.6694 33.9144 74.3079 35.0789 71.7989 35.0789C66.9285 35.0789 63.2388 31.1489 63.2388 26.4911C63.2388 21.8333 66.9285 18.0489 71.5038 18.0489C76.079 18.0489 77.8501 21.3967 77.8501 21.3967V18.9222C77.8501 18.4856 78.1453 18.0489 78.588 18.0489H80.3591C81.6874 18.0489 81.6874 20.0867 80.3591 20.0867H79.9163V32.8956H80.3591C81.6874 32.8956 81.6874 34.9333 80.3591 34.9333H78.7356V34.7878ZM71.6513 19.7956C68.1092 19.7956 65.305 22.7067 65.305 26.2C65.305 29.6933 67.6664 32.75 71.6513 32.75C73.7176 32.75 77.9977 31.1489 77.9977 26.3456C77.9977 22.9978 75.3411 19.7956 71.6513 19.7956Z"
      fill="var(--leadsquaredIconBlack)"

    />
    <path
      d="M97.9221 34.7878C97.4794 34.7878 97.1842 34.4967 97.1842 34.06V32.0222C95.8559 33.9145 93.4945 35.0789 90.9854 35.0789C86.115 35.0789 82.4253 31.1489 82.4253 26.4911C82.4253 21.8333 86.115 18.0489 90.6903 18.0489C95.2655 18.0489 97.0366 21.3967 97.0366 21.3967V12.2267H96.5938C95.2655 12.2267 95.2655 10.1889 96.5938 10.1889H98.3649C98.8077 10.1889 99.1028 10.48 99.1028 11.0622V32.75H99.5456C100.874 32.75 100.874 34.7878 99.5456 34.7878H97.9221ZM90.8379 19.7956C87.2957 19.7956 84.4915 22.7067 84.4915 26.2C84.4915 29.6933 86.853 32.75 90.8379 32.75C92.9041 32.75 97.0366 31.1489 97.0366 26.3456C97.0366 22.9978 94.5276 19.7956 90.8379 19.7956Z"
      fill="var(--leadsquaredIconBlack)"

    />
    <path
      d="M113.124 17.7578C113.567 17.7578 114.157 18.0489 114.157 18.6311V22.4155C114.157 22.9978 113.714 23.2889 113.124 23.2889C112.534 23.2889 112.091 22.8522 112.091 22.4155C112.091 20.5233 110.32 19.5044 108.401 19.5044C106.778 19.5044 104.712 20.3778 104.712 22.27C104.712 23.8711 106.335 24.7444 109.287 25.3266C111.943 25.9089 114.6 26.6366 114.6 29.8389C114.6 32.8955 111.501 34.7878 108.696 34.7878C107.073 34.7878 105.302 34.2055 104.416 33.0411V33.9144C104.416 34.4966 103.826 34.7878 103.383 34.7878C102.94 34.7878 102.35 34.4966 102.35 33.9144V29.8389C102.35 29.2566 102.94 28.82 103.383 28.82C103.974 28.82 104.564 29.2566 104.564 29.8389C104.564 32.0222 106.778 32.8955 108.696 32.8955C110.32 32.8955 112.534 31.7311 112.534 29.8389C112.534 27.9466 110.32 27.51 108.696 27.2189C104.564 26.3455 102.645 24.89 102.645 22.27C102.645 19.3589 105.449 17.7578 108.106 17.7578C109.73 17.7578 111.205 18.4855 112.091 19.3589V18.6311C112.091 17.9033 112.534 17.7578 113.124 17.7578Z"
      fill="var(--leadsquaredIconBlue)"

    />
    <path
      d="M133.344 17.9034C134.672 17.9034 134.672 19.9411 133.344 19.9411H132.901V39.3H133.344C134.672 39.3 134.672 41.3378 133.344 41.3378H131.72C131.278 41.3378 130.982 41.0467 130.982 40.4645V31.2945C130.982 31.2945 129.211 34.6422 124.636 34.6422C119.913 34.6422 116.371 30.7122 116.371 26.2C116.371 21.5422 120.061 17.6122 124.931 17.6122C127.44 17.6122 129.654 18.9222 131.13 20.8145V18.6311C131.13 18.1945 131.425 17.9034 131.868 17.9034H133.344ZM124.636 19.65C120.651 19.65 118.142 22.7067 118.29 26.2C118.29 29.6934 121.094 32.6045 124.636 32.6045C128.326 32.6045 130.835 29.4022 130.835 26.0545C130.835 21.2511 126.85 19.65 124.636 19.65Z"
      fill="var(--leadsquaredIconBlue)"

    />
    <path
      d="M149.431 19.7955C148.103 19.7955 148.103 17.7578 149.431 17.7578H151.35C151.792 17.7578 152.235 18.0489 152.235 18.4855V32.6044H152.973C154.301 32.6044 154.301 34.6422 152.973 34.6422H150.907C150.464 34.6422 150.021 34.3511 150.021 33.7689V32.0222C148.693 33.9144 146.479 34.6422 144.265 34.6422C139.395 34.6422 137.033 31.44 137.033 27.2189V19.7955H136.296C134.967 19.7955 134.967 17.7578 136.296 17.7578H138.214C138.657 17.7578 139.1 18.0489 139.1 18.4855V27.2189C139.1 30.2755 140.723 32.6044 144.413 32.6044C147.66 32.6044 150.169 30.8577 150.169 26.9278V19.7955H149.431Z"
      fill="var(--leadsquaredIconBlue)"
    />
    <path
      d="M170.536 34.7878C170.094 34.7878 169.798 34.4967 169.798 34.06V32.0222C168.47 33.9144 166.109 35.0789 163.6 35.0789C158.729 35.0789 155.04 31.1489 155.04 26.4911C155.04 21.8333 158.729 18.0489 163.305 18.0489C167.88 18.0489 169.651 21.3967 169.651 21.3967V18.9222C169.651 18.4856 169.946 18.0489 170.389 18.0489H172.16C173.488 18.0489 173.488 20.0867 172.16 20.0867H171.717V32.8956H172.16C173.488 32.8956 173.488 34.9333 172.16 34.9333H170.536V34.7878ZM163.452 19.7956C159.91 19.7956 157.106 22.7067 157.106 26.2C157.106 29.6933 159.467 32.75 163.452 32.75C165.518 32.75 169.798 31.1489 169.798 26.3456C169.946 22.9978 167.289 19.7956 163.452 19.7956Z"
      fill="var(--leadsquaredIconBlue)"

    />
    <path
      d="M178.211 33.9144C178.211 34.3511 177.915 34.6422 177.473 34.6422H175.849C174.521 34.6422 174.521 32.6044 175.849 32.6044H176.292V19.7955H175.849C174.521 19.7955 174.521 17.7578 175.849 17.7578H177.473C177.915 17.7578 178.211 18.0489 178.211 18.6311V20.6689C179.687 18.4855 181.753 17.7578 184.262 17.7578C184.852 17.7578 185.295 18.0489 185.295 18.7766C185.295 19.7955 184.262 19.7955 184.262 19.7955H184.114C181.458 19.7955 178.211 21.2511 178.211 24.7444V33.9144Z"
      fill="var(--leadsquaredIconBlue)"

    />
    <path
      d="M193.56 34.9333C188.542 34.9333 184.853 31.1489 184.853 26.3455C184.853 21.6878 188.542 17.7578 193.265 17.7578C197.84 17.7578 200.792 21.5422 200.94 26.2C200.94 26.7822 200.645 27.3644 199.907 27.3644H186.919C187.509 30.5666 190.313 32.8955 193.56 32.8955C194.741 32.8955 196.512 32.6044 197.545 32.0222C198.283 31.5855 198.726 32.0222 199.021 32.3133C199.316 32.75 199.169 33.3322 198.578 33.7689C197.25 34.4966 195.036 34.9333 193.56 34.9333ZM186.919 25.3266H198.726C198.136 22.1244 196.364 19.65 193.118 19.65C190.018 19.65 187.362 22.27 186.919 25.3266Z"
      fill="var(--leadsquaredIconBlue)"

    />
    <path
      d="M216.879 34.7878C216.436 34.7878 216.141 34.4967 216.141 34.06V32.0222C214.813 33.9145 212.451 35.0789 209.942 35.0789C205.072 35.0789 201.382 31.1489 201.382 26.4911C201.382 21.8333 205.072 18.0489 209.647 18.0489C214.223 18.0489 215.994 21.3967 215.994 21.3967V12.2267H215.551C214.223 12.2267 214.223 10.1889 215.551 10.1889H217.322C217.765 10.1889 218.06 10.48 218.06 11.0622V32.75H218.503C219.831 32.75 219.831 34.7878 218.503 34.7878H216.879ZM209.795 19.7956C206.253 19.7956 203.449 22.7067 203.449 26.2C203.449 29.6933 205.81 32.75 209.795 32.75C211.861 32.75 215.994 31.1489 215.994 26.3456C215.994 22.9978 213.485 19.7956 209.795 19.7956Z"
      fill="var(--leadsquaredIconBlue)"

    />
    <path
      d="M19.7002 34.7878H3.31787V18.4855L19.7002 34.7878Z"
      fill="var(--leadsquaredIconBlack)"

    />
  </svg>
);

LeadSquaredIcon.defaultProps = {
  width: '222',
  height: '44',
};

export default LeadSquaredIcon;
