import React from 'react';

type Props = {
  width: number;
  height: number;
  fill?: string;
  backgroundColor?: string;
}
const WidgetIcon = ({
  width, height, fill, backgroundColor,
}: Props) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={width} height={height} viewBox="0 0 26 26" fill="none">
    <rect width="26" height="26" fill={backgroundColor} />
    <path fill={fill} d="M17.8478 14.2454L12.079 8.47664L17.8478 2.70789L23.6165 8.47664L17.8478 14.2454ZM2.70819 12.2954V4.1433H10.8603V12.2954H2.70819ZM14.029 23.6162V15.4641H22.1811V23.6162H14.029ZM2.70819 23.6162V15.4641H10.8603V23.6162H2.70819ZM4.33319 10.6704H9.23527V5.7683H4.33319V10.6704ZM17.929 12.0516L21.4228 8.55789L17.929 5.06414L14.4353 8.55789L17.929 12.0516ZM15.654 21.9912H20.5561V17.0891H15.654V21.9912ZM4.33319 21.9912H9.23527V17.0891H4.33319V21.9912Z" />
  </svg>
);

WidgetIcon.defaultProps = {
  fill: 'var(--secondaryColor)',
  backgroundColor: 'transparent',
};

export default WidgetIcon;
