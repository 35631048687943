import React from 'react';

type Props = {
  width: number;
  height: number;
}

const BackIcon = ({
  width, height,
}: Props) => (
  <svg width={width} height={height} viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <mask id="mask0_866_14813" maskUnits="userSpaceOnUse" x="0" y="0" width="16" height="16">
      <rect width="16" height="16" fill="#D9D9D9" />
    </mask>
    <g mask="url(#mask0_866_14813)">
      <path d="M8.0013 13.3332L2.66797 7.99984L8.0013 2.6665L8.9513 3.59984L5.21797 7.33317H13.3346V8.6665H5.21797L8.9513 12.3998L8.0013 13.3332Z" fill="var(--primaryColor)" />
    </g>
  </svg>

);

export default BackIcon;
