import React from 'react';

type Props = {
    width: number,
    height: number,
    fill?: string,
}

const ProfilePinnedIcon = ({ width, height, fill }: Props) => (
  <svg
    style={{
      height,
      width,
    }}
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_1545_5296)">
      <path fill={fill} d="M11.6664 3.33329V7.49996C11.6664 8.43329 11.9747 9.29996 12.4997 9.99996H7.49972C8.04138 9.28329 8.33305 8.41663 8.33305 7.49996V3.33329H11.6664ZM14.1664 1.66663H5.83305C5.37472 1.66663 4.99972 2.04163 4.99972 2.49996C4.99972 2.95829 5.37472 3.33329 5.83305 3.33329H6.66638V7.49996C6.66638 8.88329 5.54972 9.99996 4.16638 9.99996V11.6666H9.14138V17.5L9.97472 18.3333L10.808 17.5V11.6666H15.833V9.99996C14.4497 9.99996 13.333 8.88329 13.333 7.49996V3.33329H14.1664C14.6247 3.33329 14.9997 2.95829 14.9997 2.49996C14.9997 2.04163 14.6247 1.66663 14.1664 1.66663Z" />
    </g>
    <circle cx="14.0005" cy="10" r="5" fill="#F9FAFB" />
    <path fill={fill} d="M14.0002 6C11.7922 6 10.0002 7.792 10.0002 10C10.0002 12.208 11.7922 14 14.0002 14C16.2082 14 18.0002 12.208 18.0002 10C18.0002 7.792 16.2082 6 14.0002 6ZM12.1402 12.6C12.6642 12.224 13.3042 12 14.0002 12C14.6962 12 15.3362 12.224 15.8602 12.6C15.3362 12.976 14.6962 13.2 14.0002 13.2C13.3042 13.2 12.6642 12.976 12.1402 12.6ZM16.4562 12.048C15.7802 11.52 14.9282 11.2 14.0002 11.2C13.0722 11.2 12.2202 11.52 11.5442 12.048C11.0802 11.492 10.8002 10.78 10.8002 10C10.8002 8.232 12.2322 6.8 14.0002 6.8C15.7682 6.8 17.2002 8.232 17.2002 10C17.2002 10.78 16.9202 11.492 16.4562 12.048Z" />
    <path fill={fill} d="M14.0003 7.59998C13.2283 7.59998 12.6003 8.22798 12.6003 8.99998C12.6003 9.77198 13.2283 10.4 14.0003 10.4C14.7723 10.4 15.4003 9.77198 15.4003 8.99998C15.4003 8.22798 14.7723 7.59998 14.0003 7.59998ZM14.0003 9.59998C13.6683 9.59998 13.4003 9.33198 13.4003 8.99998C13.4003 8.66798 13.6683 8.39998 14.0003 8.39998C14.3323 8.39998 14.6003 8.66798 14.6003 8.99998C14.6003 9.33198 14.3323 9.59998 14.0003 9.59998Z" />
    <defs>
      <clipPath id="clip0_1545_5296">
        <rect width="20" height="20" fill="white" />
      </clipPath>
    </defs>
  </svg>
);

ProfilePinnedIcon.defaultProps = {
  fill: 'var(--navSectionIcon)',
};

export default ProfilePinnedIcon;
