import React from 'react';

type Props = {
  width?: number;
  height?: number;
  fill?: string;
}

const OptionDonutChartIcon = ({
  width, height, fill,
}: Props) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 17 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect
      width="16"
      height="16"
      fill="transparent"
    />
    <path
      d="M11.4798 5.97069C11.0312 5.52642 10.4704 5.19523 9.8437 5.02345C9.31106 4.87745 8.85545 4.44339 8.85545 3.89111V3.24121C8.85545 2.68893 9.30593 2.23297 9.85154 2.31852C11.1688 2.52506 12.3523 3.13468 13.2719 4.0172M11.4798 5.97069C12.1622 6.64658 12.5849 7.58422 12.5849 8.62059C12.5849 10.6803 10.9152 12.3501 8.85545 12.3501C7.13791 12.3501 5.69154 11.189 5.25836 9.609C5.11233 9.07637 4.67825 8.62059 4.12597 8.62059H3.47607C2.92379 8.62059 2.46784 9.07117 2.5534 9.61678C3.03169 12.6668 5.67111 15 8.85545 15C12.3787 15 15.2348 12.1438 15.2348 8.62059C15.2348 6.81144 14.4817 5.17818 13.2719 4.0172M11.4798 5.97069L13.2719 4.0172"
      stroke={fill}
      strokeWidth="1.6"
    />
    <path
      d="M6.48569 2.14795C6.48569 1.59567 6.03384 1.13818 5.49107 1.24029C3.33581 1.64578 1.63699 3.3446 1.2315 5.49986C1.12939 6.04262 1.58688 6.49448 2.13916 6.49448H2.36003C2.91231 6.49448 3.34422 6.03541 3.51785 5.51113C3.82757 4.57585 4.56706 3.83636 5.50234 3.52663C6.02662 3.35301 6.48569 2.9211 6.48569 2.36881V2.14795Z"
      stroke={fill}
      strokeWidth="1.6"
    />
  </svg>

);

OptionDonutChartIcon.defaultProps = {
  width: 16,
  height: 16,
  fill: 'var(--secondaryColor)',
};

export default OptionDonutChartIcon;
