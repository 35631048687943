import React from 'react';
import { APIRequestStatus } from 'core/constants/redux';
import { IEditProfileItem, IProfileItem } from 'core/models/profile';
import {
  ActionInfoObject,
  IEditTagsData,
  ITagsData,
} from 'redux-v2/report-listing/report-listing.state';
import { FeatureContext, ToastTypes } from 'core/constants/common';
import { ToastPosition } from 'react-toastify';
import { Timers } from 'core/constants/report';
import { DeviceType } from 'core/models/store';
import { ObjModel } from 'core/models';

export interface IGlobalStoreState {
  requestProcessing: IRequestProcessing;
  tags: {
    globaltags: ITagsData[];
    updatedTagsList: Array<IEditTagsData>;
    tagItemInfo: ActionInfoObject;
    activeTagsList: Array<IEditTagsData>;
  };
  profiles: {
    availableProfiles: IProfileItem[];
    updatedProfilesList: Array<IEditProfileItem>;
    reportDetails: IReportDetails;
  };
  updateNavBarCollapse:boolean;
  showErrorBoundary: boolean;
  selectedPage:string;
  deviceType: DeviceType;
  featureGating: IFeatureGating;
}
export enum GlobalStoreActions {
  GET_ALL_TAGS_REQUEST = '[GB]GET_ALL_TAGS_REQUEST',
  GET_PROFILE_LIST_REQUEST = '[GB] GET_PROFILE_LIST_REQUEST',
  UPDATE_TAG_SELECTION = '[GB] UPDATE_TAG_SELECTION',
  UPDATE_PROFILE_SELECTION = '[GB] UPDATE_PROFILE_SELECTION',
  RESET_PROFILE_SELECTION = '[GB]RESET_PROFILE_SELECTION',
  RESET_TAGS_SELECTION = '[GB] RESET_TAGS_SELECTION',
  EDIT_TAGS_BY_OBJECTID_REQUEST = '[GB] EDIT_TAGS_BY_OBJECTID_REQUEST',
  EDIT_PROFILES_TO_DASHBOARD_REQUEST = '[GB] EDIT_PROFILES_TO_DASHBOARD_REQUEST',
  EDIT_PROFILES_TO_REPORTS_REQUEST = '[GB] EDIT_PROFILES_TO_REPORTS_REQUEST',
  UPDATE_NAVBAR_COLLAPSE = '[GB] UPDATE_NAVBAR_COLLAPSE',
  SET_SHOW_ERROR_BOUNDARY = '[GB] SET_SHOW_ERROR_BOUNDARY',
  GET_TAGS_BY_OBJECT_REQUEST = '[GB] GET_TAGS_BY_OBJECT_REQUEST',
  RESET_GLOBAL_TAGS_AND_PROFILES = '[GB] RESET_GLOBAL_TAGS_AND_PROFILES',
  TEST_THUNK = '[GB] TEST_THUNK',
  SET_SELECTED_PAGE = '[Pendo] SET_SELECTED_PAGE',
  SET_DEVICE_CONTEXT = 'SET_DEVICE_CONTEXT',
  UPDATE_FEATURE_GATING_MODAL_VIEW_STATUS= '[GB] UPDATE_FEATURE_GATING_MODAL_VIEW_STATUS',
}
export interface IUpdateRequestProcessingPayload {
  type: GlobalStoreActions;
  status: APIRequestStatus;
}

export interface IRequestProcessing {
  [GlobalStoreActions.GET_ALL_TAGS_REQUEST]: APIRequestStatus;
  [GlobalStoreActions.GET_PROFILE_LIST_REQUEST]: APIRequestStatus;
  [GlobalStoreActions.EDIT_TAGS_BY_OBJECTID_REQUEST]: APIRequestStatus;
  [GlobalStoreActions.EDIT_PROFILES_TO_DASHBOARD_REQUEST]: APIRequestStatus;
  [GlobalStoreActions.EDIT_PROFILES_TO_REPORTS_REQUEST]: APIRequestStatus;
  [GlobalStoreActions.GET_TAGS_BY_OBJECT_REQUEST]: APIRequestStatus;
}

export interface IEditProfilePayload {
  Profiles: Array<string>;
  Reports: Array<IReportDetails>;
}

export interface IEditProfileDashboardPayload {
  Id:string;
  Type: string;
  AddedProfiles:Array<IEditDBProfileItem>;
  RemovedProfiles:Array<string>;
}
export interface IEditDBProfileItem{
  Id: string;
  IsPinnedInProfile:boolean;
  IsHomeInProfile:boolean;
}

export interface IEditReportProfileItem{
  Id: string;
  IsPinnedInProfile:boolean;
}
export interface IReportDetails{
  Id: string;
  SourceType:string;
  Type: string;
}

export interface IEditReportProfilePayload{
  Id: string;
  SourceType:string;
  Type: string;
  AddedProfiles: Array<IEditReportProfileItem>
  RemovedProfiles:Array<string>
  FeatureContext: FeatureContext
}

export interface ITostInfo {
  className: string;
  toastType: ToastTypes;
  icon: React.ReactNode;
  position: ToastPosition;
  autoClose: number;
  message: string;
  delay: number;
}

export interface IToast {
  showToast: boolean;
  ToastInfo: ITostInfo;
  TimeOutDelay: Timers;
}

export interface IFeatureGating{
  showLimitRestrictionModal?: boolean,
  showModuleRestrictionModal?: boolean,
  renderData?:ObjModel.Obj
}
