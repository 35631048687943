import { flexCenterMixin } from 'core/utils/mixins';
import styled from 'styled-components';

const StyledDeleteConfirmationModal = styled.div`
  .se-confirm-delete-title-container {
    ${flexCenterMixin}
    .se-confirm-title {
      color: var(--dangerColor);
      font-size: 16px;
      font-family: SemiBold;
      margin-left: 12px;
    }
  }
  .se-confirm-delete-button-container {
    ${flexCenterMixin}
    .se-cancel-delete-button {
      margin-right: 0px;
    }
    .se-confirm-delete-button {
      color: var(--dangerColor);
      font-size: 12px;
    }
  }
  .se-confirm-delete-body {
    width: 520px;
    padding: 20px 16px;
    color: var(--secondaryColor);
    font-size: 15px;
    box-sizing: border-box;
  }
  .se-confirm-delete-sub-content {
    color: var(--secondaryColor);
    font-size: 15px;
    padding: 0 16px 20px;
  }
`;
export default StyledDeleteConfirmationModal;
