import { createAsyncThunk } from '@reduxjs/toolkit';
import ProfileService from 'services/profile.service';

import { IDropdownListAPIResponse, IProfileFormValue } from 'core/models/profile';
import { ITagsData } from 'redux-v2/report-listing/report-listing.state';
import { ProfileActions } from './profile.state';

export const getAllReportsForProfile = createAsyncThunk(ProfileActions.GET_REPORT_LIST, async (globalTags: ITagsData[]) => {
  const reportData = await new ProfileService().getAllReports();
  return { reportData, globalTags };
});
export const getAllDashboardForProfile = createAsyncThunk(ProfileActions.GET_DASHBOARD_LIST, async (globalTags: ITagsData[]) => {
  const dashboardListData = await new ProfileService().getAllDashboards();
  return { dashboardListData, globalTags };
});
export const getVisibilityConfig = createAsyncThunk(ProfileActions.GET_VISIBILITY_CONFIG, async () => new ProfileService().getVisibilityConfiguration());
export const getProfileVisibilityValue = createAsyncThunk(ProfileActions.GET_VISIBILITY_VALUES, async (id: string) => {
  const value: Array<IDropdownListAPIResponse> = await new ProfileService().getDropdownValues(id);
  return { value, id };
});
export const saveProfileForm = createAsyncThunk(ProfileActions.SAVE_PROFILE_DETAIL_FORM, async (savePayload: IProfileFormValue) => new ProfileService()
  .saveProfileForm(savePayload));
export const updateProfileForm = createAsyncThunk(ProfileActions.EDIT_PROFILE_DETAIL_FORM, async (updatePayload: IProfileFormValue) => new ProfileService().saveEditProfileForm(updatePayload));
export const getProfilesList = createAsyncThunk(ProfileActions.GET_PROFILE_LIST_REQUEST, async () => new ProfileService().getProfileList());
export const getProfileInfoByProfileId = createAsyncThunk(ProfileActions.GET_PROFILE_INFO_BY_PROFILE_ID, async (profileId: string) => new ProfileService().getProfileInfo(profileId));
export const deleteProfile = createAsyncThunk(ProfileActions.DELETE_PROFILE_REQUEST, async (profileId : string) => new ProfileService().deleteProfile(profileId));
