import React from 'react';

type Props = {
  width: number;
  height: number;
  fill?: string;
}

const BulbIcon = ({
  width, height, fill,
}: Props) => (
  <svg
    id="lightbulb_black_24dp"
    xmlns="http://www.w3.org/2000/svg"
    style={{
      width,
      height,
    }}
    viewBox="0 0 20 20"
  >
    <path
      id="Path_882"
      data-name="Path 882"
      d="M0,0H20V20H0Z"
      fill="none"
    />
    <path
      id="Path_883"
      data-name="Path 883"
      d="M8.324,17.821a.834.834,0,0,0,.831.833H12.48a.834.834,0,0,0,.831-.833v-.833H8.324ZM10.817,2A5.829,5.829,0,0,0,7.493,12.609V14.49a.834.834,0,0,0,.831.833h4.986a.834.834,0,0,0,.831-.833V12.609A5.829,5.829,0,0,0,10.817,2Zm2.369,9.243-.706.5v1.915H9.155V11.743l-.706-.5a4.155,4.155,0,1,1,4.737,0Z"
      transform="translate(-0.817 -0.327)"
      fill={fill}
    />
  </svg>
);

BulbIcon.defaultProps = {
  fill: '#46bfc9',
};

export default BulbIcon;
