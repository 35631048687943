import React from 'react';

type Props = {
  width?: number;
  height?: number;
  fill?: string;
}

const NoWidgetIcon = ({
  width, height, fill,
}: Props) => (
  <svg width={width} height={height} viewBox="0 0 76 76" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect width={width} height={height} fill={fill} />
    <path d="M52.1707 41.6417L35.3082 24.7792L52.1707 7.91675L69.0332 24.7792L52.1707 41.6417ZM7.9165 35.9417V12.1126H31.7457V35.9417H7.9165ZM41.0082 69.0334V45.2042H64.8373V69.0334H41.0082ZM7.9165 69.0334V45.2042H31.7457V69.0334H7.9165ZM12.6665 31.1917H26.9957V16.8626H12.6665V31.1917ZM52.4082 35.2292L62.6207 25.0167L52.4082 14.8042L42.1957 25.0167L52.4082 35.2292ZM45.7582 64.2834H60.0873V49.9542H45.7582V64.2834ZM12.6665 64.2834H26.9957V49.9542H12.6665V64.2834Z" fill="#919EAB" />
    <path d="M64 76C70.6274 76 76 70.6274 76 64C76 57.3726 70.6274 52 64 52C57.3726 52 52 57.3726 52 64C52 70.6274 57.3726 76 64 76Z" fill="#F4F6F8" />
    <path d="M63.1424 72V64.8576H56V63.1424H63.1424V56H64.856V63.1424H72V64.856H64.8576V72H63.1424Z" fill="#919EAB" />
  </svg>

);

NoWidgetIcon.defaultProps = {
  width: 76,
  height: 76,
  fill: 'var(--backgroundColor)',
};

export default NoWidgetIcon;
