import { FeatureList } from 'core/constants/common';
import Feature from 'features/feature';
import CreateProfile from './create-profile.feature';
import DashboardBuilder from './dashboard-builder.feature';
import EditProfile from './edit-profile.feature';
import ExternalReports from './external-reports.feature';
import ReportBuilder from './report-builder.feature';
import ReportEdit from './report-edit.feature';
import ReportListing from './report-listing.feature';
import SieraDashboards from './siera-dashboards.feature';
import SieraReports from './siera-reports.feature';
import CustomAnalytics from './custom-analytics.feature';

export default class FeatureFactory {
  private featureId: string

  private disabledFeatures: string[]= []

  constructor(featureId:string, disabledFeatures: string[]) {
    this.featureId = featureId;
    this.disabledFeatures = disabledFeatures;
  }

  getFeature(): Feature {
    return this.getFeatureInstance(this.featureId);
  }

  private getFeatureInstance(feature: string) {
    switch (feature) {
      case FeatureList.sieraReports: {
        return new SieraReports(this.disabledFeatures);
      }
      case FeatureList.sieraDashboards: {
        return new SieraDashboards(this.disabledFeatures);
      }
      case FeatureList.reportBuilder: {
        return new ReportBuilder(this.disabledFeatures);
      }
      case FeatureList.dashboardBuilder: {
        return new DashboardBuilder(this.disabledFeatures);
      }
      case FeatureList.externalReports: {
        return new ExternalReports(this.disabledFeatures);
      }
      case FeatureList.reportListing: {
        return new ReportListing(this.disabledFeatures);
      }
      case FeatureList.reportEdit: {
        return new ReportEdit(this.disabledFeatures);
      }
      case FeatureList.createProfile: {
        return new CreateProfile(this.disabledFeatures);
      }
      case FeatureList.editProfile: {
        return new EditProfile(this.disabledFeatures);
      }
      case FeatureList.customAnalytics: {
        return new CustomAnalytics(this.disabledFeatures);
      }

      default: {
        return new SieraReports(this.disabledFeatures);
      }
    }
  }
}
