import React from 'react';

type Props = {
  width?: number;
  height?: number;
  fill?: string;
};

const EmptyBookmarkedReport = ({ width, height, fill }: Props) => (
  <svg
    style={{
      height,
      width,
    }}
    viewBox="0 0 80 85"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M43.5817 8.08088H15.212V71.9126H63.846V28.0274H43.5817V8.08088ZM55.7393 63.9318H23.3175V55.9534H55.7393V63.9318ZM55.7393 39.9955V47.9739H23.3175V39.9955H55.7393Z" fill="#DFE3E8" />
    <path d="M23.5294 56H55.5282V64H23.5294V56ZM23.5294 40H55.5282V48H23.5294V40ZM47.5288 0H15.5288C13.4064 0.00920669 11.3738 0.855704 9.87496 2.35462C8.37615 3.85354 7.53293 5.88308 7.52945 8V72C7.52367 74.1121 8.3578 76.1402 9.84897 77.6399C11.3401 79.1395 13.3667 79.9882 15.4842 80H63.5276C65.6507 79.9914 67.6841 79.145 69.1835 77.6458C70.6828 76.1465 71.5262 74.1163 71.5294 71.9988V24L47.5288 0ZM63.5276 71.9988H15.5288V8H43.5285V27.9994H63.5276V71.9988Z" fill="#919EAB" />
    <path d="M66 85C73.732 85 80 78.732 80 71C80 63.268 73.732 57 66 57C58.268 57 52 63.268 52 71C52 78.732 58.268 85 66 85Z" fill="white" />
    <path d="M71.6666 66H65L63.3333 64.3334H58.3333C57.4166 64.3334 56.6666 65.0834 56.6666 66V76C56.6666 76.9167 57.4166 77.6667 58.3333 77.6667H71.6666C72.5833 77.6667 73.3333 76.9167 73.3333 76V67.6667C73.3333 66.75 72.5833 66 71.6666 66ZM71.6666 76H58.3333V66H62.6416L64.3083 67.6667H71.6666V76Z" fill={fill} />
    <circle cx="71" cy="74" r="6" fill="white" />
    <path d="M73.2222 69.5H67.8889C67.3956 69.5 67 69.9 67 70.3889V76.6111C67 77.1 67.3956 77.5 67.8889 77.5H74.1111C74.6 77.5 75 77.1 75 76.6111V71.2778L73.2222 69.5ZM71 76.6111C70.2622 76.6111 69.6667 76.0156 69.6667 75.2778C69.6667 74.54 70.2622 73.9444 71 73.9444C71.7378 73.9444 72.3333 74.54 72.3333 75.2778C72.3333 76.0156 71.7378 76.6111 71 76.6111ZM72.3333 72.1667H67.8889V70.3889H72.3333V72.1667Z" fill={fill} />
  </svg>
);

EmptyBookmarkedReport.defaultProps = {
  fill: 'var(--siennaColor)',
  width: 80,
  height: 85,
};

export default EmptyBookmarkedReport;
