import { AuthResponseModel, ObjModel } from 'core/models';
import { MobileAuthExceptions, SourceTypes } from './auth-response';

export interface IVariables {
  SER_USER_ID: string;
  SER_USER_AUTO_ID: number;
}

export interface IState {
  token: string | null;
  expiresOn: number;
  userAttributes: AuthResponseModel.UserAttributes;
  orgAttributes: AuthResponseModel.OrgAttributes;
  variables: IVariables;
  isMarvin: boolean;
  isV1Report: boolean;
  statusCode: number;
  userSettings: IUserSettings;
  activeInterval: number;
  isEmbedded: boolean;
  isReportingDisabled: boolean;
  isSieraEnabled: boolean;
  disabledFeatures: string[];
  deviceType: DeviceType;
  mobileAuthInfo: MobileAuthInfo;
}

export interface Action {
  type: string;
  payload?: any;
}

export interface IUserSettings {
  showViewMode: ShowViewModeTypes;
}

export enum ShowViewModeTypes {
  V1PlatformUI = 'v1PlatformUI',
  MarvinUI = 'marvinUI',
}

export enum DeviceType {
  MOBILE = 'mobile',
  TABLET = 'tablet',
  DESKTOP = 'desktop'
}

export interface MobileAuthInfo {
  ExceptionType? : MobileAuthExceptions;
  source?: SourceTypes;
  statusCode?: number;
}
