import React from 'react';

type Props = {
  width: number;
  height: number;
  fill?: string;
}

const HamburgerIcon = ({
  width, height, fill,
}: Props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    style={{
      width,
      height,
    }}
    viewBox="0 0 16 16"
  >
    <g id="Top_Stuff" data-name="Top Stuff" transform="translate(-12 -47)">
      <g id="menu_black_24dp" transform="translate(12 47)">
        <path
          id="Path_453"
          data-name="Path 453"
          d="M0,0H16V16H0Z"
          fill="none"
        />
        <path id="Path_454" data-name="Path 454" d="M3,14H13V12.667H3Zm0-3.333H13V9.333H3ZM3,6V7.333H13V6Z" transform="translate(0 -2)" fill={fill} />
      </g>
    </g>
  </svg>
);

HamburgerIcon.defaultProps = {
  fill: 'var(--primaryColor)',
};

export default HamburgerIcon;
