/** @format */

import React from 'react';
import useLocalize from '../Localization/useLocalize.hook';
import StyledInputFieldComponent from './input-field.style';

interface IInputFieldProps {
  uniqKey: string;
  label?: string;
  placeholder?: string;
  maxLength?: number;
  required?: boolean;
  inputValue: string;
  onInputChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  errorMessage?: string;
  showErrorMessage?: boolean;
  disabled?: boolean;
  enableAutoFocus?: boolean;
  classNames?:Array<string>;
  isOptional?: boolean;
  pendoClassName?: string;
}

const InputField = (props: IInputFieldProps) => {
  const {
    uniqKey,
    label,
    placeholder,
    maxLength,
    required,
    inputValue,
    errorMessage,
    onInputChange,
    showErrorMessage,
    disabled,
    enableAutoFocus,
    classNames,
    isOptional,
    pendoClassName,
  } = props;

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    onInputChange(e);
  };
  return (
    <>
      <StyledInputFieldComponent className="se-input-field-component-wrapper">
        {label && (
        <div className="se-input-field-label">
          {label}
          {isOptional ? <span>{useLocalize('dashboardBuilder.isOptional')}</span> : ''}
        </div>
        )}
        <input
          id={uniqKey}
          type="text"
          name={uniqKey}
          onChange={handleChange}
          placeholder={placeholder}
          className={`se-input-field-component ${pendoClassName} ${
            showErrorMessage ? ' danger' : ''
          } ${classNames?.join(' ')}`}
          value={inputValue}
          maxLength={maxLength}
          disabled={disabled}
          required={required}
          autoFocus={enableAutoFocus}
          autoComplete="off"
        />
        {showErrorMessage && (
          <div className="se-input-field-error">{errorMessage || ''}</div>
        )}
      </StyledInputFieldComponent>
    </>
  );
};

InputField.defaultProps = {
  label: useLocalize('cmn.enterName'),
  placeholder: useLocalize('cmn.enterName'),
  required: true,
  errorMessage: useLocalize('cmn.errorInvalidInput'),
  showErrorMessage: false,
  disabled: false,
  enableAutoFocus: false,
  classNames: [''],
  isOptional: false,
};
export default InputField;
