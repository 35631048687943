import React from 'react';

type Props = {
  width: number;
  height: number;
  fill?: string;
}

const CopyIcon = ({
  width, height, fill,
}: Props) => (
  <svg width={width} height={height} viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M13.5455 3H5.36364C4.61364 3 4 3.61364 4 4.36364V13.9091H5.36364V4.36364H13.5455V3ZM15.5909 5.72727H8.09091C7.34091 5.72727 6.72727 6.34091 6.72727 7.09091V16.6364C6.72727 17.3864 7.34091 18 8.09091 18H15.5909C16.3409 18 16.9545 17.3864 16.9545 16.6364V7.09091C16.9545 6.34091 16.3409 5.72727 15.5909 5.72727ZM15.5909 16.6364H8.09091V7.09091H15.5909V16.6364Z" fill="var(--secondaryColor)" />
  </svg>

);

CopyIcon.defaultProps = {
  fill: 'var(--secondaryColor)',
};

export default CopyIcon;
