import React from 'react';

type Props = {
    width: number,
    height: number,
    fill?: string,
    rotate?:string
}

const NextIcon = ({
  width, height, fill, rotate,
}: Props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    style={{
      width, height, rotate,
    }}
    viewBox="0 0 24 24"
    fill={fill}
  >
    <path
      d="M0 0h24v24H0z"
      fill="none"
    />
    <path
      d="M10 6L8.59 7.41 13.17 12l-4.58 4.59L10 18l6-6z"
    />
  </svg>
);

NextIcon.defaultProps = {
  fill: '#var(--paginationPrev)',
  rotate: '0deg',
};

export default NextIcon;
