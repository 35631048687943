import React from 'react';

type Props = {
  width?: number;
  height?: number;
  fill?: string;
}

const EditProfileIcon = ({
  width, height, fill,
}: Props) => (

  <svg width={width} height={height} viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect width="14" height="14" fill="#F4F6F8" />
    <path d="M2.90216 11.0974H3.49043L9.41321 5.17464L8.82494 4.58638L2.90216 10.5092V11.0974ZM11.1112 4.59975L9.39984 2.88842L9.96137 2.32689C10.1129 2.17537 10.3001 2.09961 10.5229 2.09961C10.7457 2.09961 10.9329 2.17537 11.0844 2.32689L11.6727 2.91516C11.8242 3.06669 11.9 3.25386 11.9 3.47669C11.9 3.69952 11.8242 3.88669 11.6727 4.03822L11.1112 4.59975ZM10.5496 5.16127L3.8113 11.8996H2.09998V10.1883L8.83831 3.44995L10.5496 5.16127ZM9.11907 4.88051L8.82494 4.58638L9.41321 5.17464L9.11907 4.88051Z" fill="#0064C0" />
  </svg>

);

EditProfileIcon.defaultProps = {
  fill: 'var(--backgroundColor)',
  width: 14,
  height: 14,
};

export default EditProfileIcon;
