import React from 'react';

type Props = {
  width: number;
  height: number;
  fill: string;
};

const DangerIconFilled = ({ width, height, fill }: Props) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 15 15"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M8.25 5.25H6.75V3.75H8.25M8.25 11.25H6.75V6.75H8.25M7.5 0C6.51509 0 5.53982 0.193993 4.62987 0.570904C3.71993 0.947814 2.89314 1.50026 2.1967 2.1967C0.790176 3.60322 0 5.51088 0 7.5C0 9.48912 0.790176 11.3968 2.1967 12.8033C2.89314 13.4997 3.71993 14.0522 4.62987 14.4291C5.53982 14.806 6.51509 15 7.5 15C9.48912 15 11.3968 14.2098 12.8033 12.8033C14.2098 11.3968 15 9.48912 15 7.5C15 6.51509 14.806 5.53982 14.4291 4.62987C14.0522 3.71993 13.4997 2.89314 12.8033 2.1967C12.1069 1.50026 11.2801 0.947814 10.3701 0.570904C9.46018 0.193993 8.48491 0 7.5 0Z"
      fill={fill}
    />
  </svg>
);

export default DangerIconFilled;
