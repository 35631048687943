import React from 'react';

type Props = {
  width?: number;
  height?: number;
  fill?: string;
  opacity?:number;
}

const CloseNewIcon = ({
  width, height, fill, opacity,
}: Props) => (
  <svg
    style={{
      width,
      height,
    }}
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g
      data-name="close-24px (1)"
      opacity={opacity}
    >
      <path className="icon-stroke" fill={fill} d="M15.8312 5.34375L14.6562 4.16875L9.99995 8.825L5.3437 4.16875L4.1687 5.34375L8.82495 10L4.1687 14.6562L5.3437 15.8312L9.99995 11.1687L14.6562 15.8312L15.8312 14.6562L11.1687 10L15.8312 5.34375Z" />
    </g>
  </svg>
);

CloseNewIcon.defaultProps = {
  fill: '#262626',
  opacity: '0.967',
  width: 20,
  height: 20,
};

export default CloseNewIcon;
