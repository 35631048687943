import React from 'react';
import StyledLoadingComponent from 'components/common/LoadingSpinner/loading.style';

export interface ILoadingComponentProps {
  iconHeight?: number;
  iconWidth?: number;
  rotatingColor?:string;
}

const LoadingComponent = (props: ILoadingComponentProps) => {
  const {
    iconHeight, iconWidth, rotatingColor,
  } = props;
  return (
    <StyledLoadingComponent rotatingColor={rotatingColor}>
      <div className="se-loading-spinner" style={{ width: iconWidth, height: iconHeight }} />
    </StyledLoadingComponent>
  );
};

LoadingComponent.defaultProps = {
  iconHeight: 16,
  iconWidth: 16,
  rotatingColor: 'var(--border)',
};
export default LoadingComponent;
