import { flexCenterMixin } from 'core/utils/mixins';
import styled from 'styled-components';

const StyledCircularLoadingScreen = styled.div<{
  position: string;
  backgroundColor: string;
}>`
  ${flexCenterMixin}
  position: ${(props) => props.position};
  z-index: 9999;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: ${(props) => props.backgroundColor};
`;

export default StyledCircularLoadingScreen;
