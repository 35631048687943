import React from 'react';

type Props = {
  width: number;
  height: number;
  fill?: string;
}

const AccountsEntityIcon = ({
  width, height, fill,
}: Props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={width}
    height={height}
    viewBox="0 0 20 20"
  >
    <path id="Accounts" fill={fill} d="M9.99984 5.83333V2.5H1.6665V17.5H18.3332V5.83333H9.99984ZM4.99984 15.8333H3.33317V14.1667H4.99984V15.8333ZM4.99984 12.5H3.33317V10.8333H4.99984V12.5ZM4.99984 9.16667H3.33317V7.5H4.99984V9.16667ZM4.99984 5.83333H3.33317V4.16667H4.99984V5.83333ZM8.33317 15.8333H6.6665V14.1667H8.33317V15.8333ZM8.33317 12.5H6.6665V10.8333H8.33317V12.5ZM8.33317 9.16667H6.6665V7.5H8.33317V9.16667ZM8.33317 5.83333H6.6665V4.16667H8.33317V5.83333ZM16.6665 15.8333H9.99984V14.1667H11.6665V12.5H9.99984V10.8333H11.6665V9.16667H9.99984V7.5H16.6665V15.8333ZM14.9998 9.16667H13.3332V10.8333H14.9998V9.16667ZM14.9998 12.5H13.3332V14.1667H14.9998V12.5Z" />
  </svg>

);

AccountsEntityIcon.defaultProps = {
  fill: 'var(--accountsEntity)',
};

export default AccountsEntityIcon;
