import React from 'react';

type Props = {
  width: number;
  height: number;
  fill?: string;
}

const AscSortIcon = ({
  width, height, fill,
}: Props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    style={{
      width,
      height,
    }}
    viewBox="0 0 12.133 7.734"
  >
    <path id="Path_7711" data-name="Path 7711" d="M0,0H4.044V1.289H0ZM0,7.734V6.445H12.133V7.734ZM0,3.223H8.089V4.512H0Z" fill={fill} />
  </svg>

);
AscSortIcon.defaultProps = {
  fill: '#cad3db',
};

export default AscSortIcon;
