/* eslint-disable no-shadow */
const enum CacheItem {
    Auth = 'SIERA_AUTH',
    AuthExpiry = 'EXPIRES_ON',
    UserAttr = 'User_Attr',
    OrgAttr = 'Org_Attr',
    ShowViewMode = 'Show_View_Mode'
}

export default CacheItem;
