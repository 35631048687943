import React from 'react';

type Props = {
  width: number;
  height: number;
  fill?: string;
}

const AreaChart = ({
  width, height, fill,
}: Props) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M2 2V22C2 22.1105 2.08954 22.2 2.2 22.2H22.2"
      stroke={fill}
      strokeWidth="1.6"
      strokeLinecap="round"
      className="icon-stroke"
    />
    <path
      d="M5.85547 11.1048C5.85547 9.47232 8.07623 7.16012 10.7203 7.51249C12.5558 7.75711 12.262 9.76173 14.0975 10.0064C16.2046 10.2872 18.0429 7.83903 18.7026 6.8331C18.7622 6.7422 18.9623 6.77588 18.9623 6.87688V18.2527C18.9623 18.3037 18.9063 18.345 18.8372 18.345L5.98056 18.345C5.91147 18.345 5.85547 18.3052 5.85547 18.2542L5.85547 11.1048Z"
      stroke={fill}
      strokeWidth="1.6"
      strokeLinecap="round"
      className="icon-stroke"
    />
  </svg>

);

AreaChart.defaultProps = {
  fill: 'var(--labelColor)',
};

export default AreaChart;
