import { IDropdownListAPIResponse, IProfileFormValue, IProfileVisibilityConfig } from 'core/models/profile';
import API from './api';

export default class ProfileService extends API {
  static baseURL: string = process.env.REACT_APP_DATA_BASE_URL || '';

  constructor() {
    super(ProfileService.baseURL, true, true);
  }

  getVisibilityConfiguration = async (): Promise<Array<IProfileVisibilityConfig>> => this.responsify(
    await this.instance.get(
      '/profiles/accords-list', {
        params: {
          entity: 'User',
        },
      },
    ),
  );

  getDropdownValues = async (filterId: string): Promise<IDropdownListAPIResponse[]> => this.responsify(
    await this.instance.get(
      '/profiles/accord-values', {
        params: {
          key: filterId,
        },
      },
    ),
  );

  saveProfileForm = async (data: IProfileFormValue) => this.responsify(await this.instance.post('profiles/create', data));

  saveEditProfileForm = async (data: IProfileFormValue) => this.responsify(await this.instance.put('profiles/update', data))

  getProfileList = async () => this.responsify(await this.instance.get('profiles/list'))

  getProfileInfo = async (profileId:string) => (
    this.responsify(await this.instance.get('/profiles/profile', {
      params: {
        profileId,
      },
    }))
  )

  deleteProfile = async (profileId: string) => this.responsify(await this.instance.delete('profiles/delete', {
    params: {
      profileId,
    },
  }))

  getAllReports = async () => (
    this.responsify(await this.instance.get('unified-listing/list', {
      params: {
        section: '',
        profileId: 'ALL',
        tagId: '',
      },
    }))
  );

  getAllDashboards = async () => (
    this.responsify(await this.instance.get('unified-listing/dashboards/list-dashboards', {
      params: {
        section: 'all',
        profileId: 'ALL',
        mode: 'create',
        tagId: '',
      },
    }))
  );
}
