import React from 'react';

type Props = {
  width: number;
  height: number;
  fill?: string;
}
const ReportPinnedIcon = ({
  width, height, fill,
}: Props) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M12.5 10H7.5L8 3H12L12.5 10Z"
      fill="white"
      className="se-icon"
    />
    <path
      d="M11.6663 3.33317V7.49984C11.6663 8.43317 11.9746 9.29984 12.4996 9.99984H7.49959C8.04126 9.28317 8.33293 8.4165 8.33293 7.49984V3.33317H11.6663ZM14.1663 1.6665H5.83293C5.37459 1.6665 4.99959 2.0415 4.99959 2.49984C4.99959 2.95817 5.37459 3.33317 5.83293 3.33317H6.66626V7.49984C6.66626 8.88317 5.54959 9.99984 4.16626 9.99984V11.6665H9.14126V17.4998L9.97459 18.3332L10.8079 17.4998V11.6665H15.8329V9.99984C14.4496 9.99984 13.3329 8.88317 13.3329 7.49984V3.33317H14.1663C14.6246 3.33317 14.9996 2.95817 14.9996 2.49984C14.9996 2.0415 14.6246 1.6665 14.1663 1.6665Z"
      fill={fill}
      className="se-icon-border"
    />
  </svg>

);

ReportPinnedIcon.defaultProps = {
  fill: 'var(--searchBoxTextColor)',
};

export default ReportPinnedIcon;
