import React from 'react';

type Props = {
  width?: number;
  height?: number;
  fill?: string;
}
const ScheduledReportIcon = ({
  width, height, fill,
}: Props) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={width} height={height} viewBox="0 0 17 16" fill="none">
    <path d="M11.9629 8C11.2917 8 10.6356 8.19903 10.0776 8.57192C9.51948 8.94482 9.08451 9.47483 8.82766 10.0949C8.57081 10.715 8.5036 11.3974 8.63454 12.0557C8.76549 12.714 9.0887 13.3186 9.5633 13.7932C10.0379 14.2678 10.6426 14.591 11.3009 14.722C11.9592 14.8529 12.6415 14.7857 13.2616 14.5289C13.8817 14.272 14.4117 13.8371 14.7846 13.279C15.1575 12.7209 15.3565 12.0648 15.3565 11.3936C15.3563 10.4936 14.9987 9.63057 14.3623 8.9942C13.726 8.35782 12.8629 8.00021 11.9629 8ZM13.0829 12.988L11.6237 11.5288V9.36H12.3021V11.2536L13.5573 12.5088L13.0829 12.988Z" fill={fill} />
    <path d="M7.88956 7.99991L3.81996 6.98151V4.61351L9.83996 7.15191C10.4987 6.81917 11.226 6.64494 11.964 6.64311C12.032 6.64311 12.0928 6.64951 12.1544 6.64951L2.45996 2.57031V13.4295L7.21116 11.4295V11.3959C7.21187 10.869 7.3014 10.3459 7.47596 9.84871L3.81996 11.3863V9.01751L7.88956 7.99991Z" fill={fill} />
  </svg>

);

ScheduledReportIcon.defaultProps = {
  fill: 'var(--primaryColor)',
  width: 54,
  height: 64,
};

export default ScheduledReportIcon;
