/* eslint-disable import/prefer-default-export */
import cloneDeep from 'lodash/cloneDeep';
import isEmpty from 'lodash/isEmpty';
import { SectionItemId } from 'core/constants/report-listing';
import {
  IDashboardItem,
  IDashboardListData, IDashboardSection, IEditTagsData, IReportData, IReportListingState, IReportSection, ITagsData, ReportFilterValues, TagByObject, Types,
} from './report-listing.state';

export const groupReportBasedOnTags = (reportData: IReportData[], globalTags: ITagsData[], resetIsPinned = false) => {
  const groupedReports : IReportSection[] = [];

  let updatedData = [...reportData];
  if (resetIsPinned) {
    updatedData = [...reportData.map((item) => ({ ...item, IsPinnedInProfile: false }))];
  }

  const untaggedReports = updatedData.filter((reportItem) => isEmpty(reportItem.Tags));
  if (untaggedReports?.length !== 0) {
    groupedReports.push({
      DisplayName: 'Untagged Reports',
      Reports: { Data: untaggedReports },
      SectionId: 'UntaggedReports',
      TotalCount: untaggedReports?.length,
      Dashboards: { Data: [] },
      isProfileSectionSelected: false,
      isSectionLinkedReportsSelected: false,
    });
  }
  globalTags?.forEach((item) => {
    const tagSectionReport = updatedData.filter((report) => !isEmpty(report.Tags) && report.Tags.includes(item.Id));
    if (tagSectionReport?.length !== 0) {
      groupedReports.push({
        DisplayName: item.Name,
        Reports: { Data: tagSectionReport },
        SectionId: item.Id,
        TotalCount: tagSectionReport?.length,
        Dashboards: { Data: [] },
        isProfileSectionSelected: false,
        isSectionLinkedReportsSelected: false,
      });
    }
  });

  return groupedReports;
};

export const getDashboardSection = (dashboardData: Array<IDashboardListData>, globalTags: ITagsData[], resetIsPinned = false) => {
  const dashboardSections : IDashboardSection[] = [];

  let updatedData = [...dashboardData];
  if (resetIsPinned) {
    updatedData = [...dashboardData.map((item) => ({ ...item, IsPinnedInProfile: false, IsHomeInProfile: false }))];
  }

  const untaggedDashboards = updatedData.filter((dashboardItem) => isEmpty(dashboardItem.Tags));

  if (untaggedDashboards?.length !== 0) {
    dashboardSections.push({
      DisplayName: 'Untagged Dashboards',
      Reports: { Data: [] },
      Dashboards: {
        Data: untaggedDashboards.map((item) => ({
          ...item,
          Name: item.TitleSection.Title,
          Description: item.TitleSection.Description,
        })),
      },
      SectionId: 'UntaggedDashboards',
      TotalCount: untaggedDashboards?.length,
    });
  }
  globalTags?.forEach((item) => {
    const tagSectionDashboard = updatedData.filter((data) => !isEmpty(data.Tags) && data?.Tags?.includes(item.Id));
    if (tagSectionDashboard?.length !== 0) {
      dashboardSections.push({
        DisplayName: item.Name,
        Reports: { Data: [] },
        Dashboards: {
          Data: tagSectionDashboard.map((dashboard) => ({
            ...dashboard,
            Name: dashboard.TitleSection.Title,
            Description: dashboard.TitleSection.Description,
          })),
        },
        SectionId: item.Id,
        TotalCount: tagSectionDashboard?.length,
      });
    }
  });
  return dashboardSections;
};

export const getFilteredReportByFilterValue = (state: IReportListingState, payload: ReportFilterValues, selectedNavBarItem: string): IReportSection[] => {
  // if all reports selected
  let section: IReportSection[] = [];

  if (selectedNavBarItem === SectionItemId.AllReports) {
    section = state.allReportSections;
  } else if (selectedNavBarItem === SectionItemId.PinnedReports) {
    section = state.pinnedCategories;
  } else {
    section = state.tagCategoriesReports;
  }
  // if (section.Reports) return null;
  if (payload === ReportFilterValues.AllReports) return section;

  // for siera or v1 reports
  const clonedAllReports = cloneDeep(section);
  const isInternal = payload === ReportFilterValues.SieraReports ? Types.Internal : Types.External;
  let newFilteredReports: IReportSection[] = [];
  clonedAllReports?.forEach((rep: IReportSection) => {
    const newSection = rep;
    newSection.Reports.Data = rep.Reports?.Data?.filter((data: IReportData) => data.Type === isInternal);
    if (newSection.Reports?.Data?.length !== 0) { newFilteredReports.push(newSection); }
  });
  // if empty array we want it has null, to show an empty screen
  if (newFilteredReports.length === 0) newFilteredReports = null;

  return newFilteredReports;
};

export const getAllDashboardSection = (state: IReportListingState, payload: Array<IDashboardItem>, globalTags: ITagsData[], selectedNavBarItem: string) => {
  const dashboardSections: IReportSection[] = [];

  if (payload) {
    // for pinned section
    if (selectedNavBarItem === SectionItemId.PinnedDashboard) {
      dashboardSections.push({
        DisplayName: 'Pinned Dashboards',
        Reports: { Data: [] },
        Dashboards: { Data: payload },
        SectionId: 'pinnedDashboards',
        TotalCount: payload?.length,
      });
    } else {
      const untaggedReports = payload.filter((data) => isEmpty(data.Tags));
      if (untaggedReports?.length !== 0) {
        dashboardSections.push({
          DisplayName: 'Untagged Dashboards',
          Reports: { Data: [] },
          Dashboards: { Data: untaggedReports },
          SectionId: 'UntaggedDashboards',
          TotalCount: untaggedReports?.length,
        });
      }
      globalTags?.forEach((item) => {
        const tagSectionReport = payload.filter((data) => !isEmpty(data.Tags) && data?.Tags?.includes(item.Id));
        if (tagSectionReport?.length !== 0) {
          dashboardSections.push({
            DisplayName: item.Name,
            Reports: { Data: [] },
            Dashboards: { Data: tagSectionReport },
            SectionId: item.Id,
            TotalCount: tagSectionReport?.length,
          });
        }
      });
    }
  }
  if (isEmpty(dashboardSections)) {
    return null;
  }
  return dashboardSections;
};

export const getEditTagsList = (payload: TagByObject) => {
  const editTagsList: IEditTagsData[] = [];
  const tagMetaData = payload.TagMetaData;
  const linkedTags = payload.Tags;

  tagMetaData.forEach((item) => {
    const isChecked = linkedTags?.includes(item.Id);
    editTagsList.push({ ...item, checked: isChecked });
  });

  return editTagsList;
};
