import { IScheduleBuilderPayload, IScheduleListPayload } from 'redux-v2/schedule-builder/schedule-builder.state';
import API from './api';

export default class ScheduleBuilderService extends API {
    static baseURL: string = process.env.REACT_APP_DATA_BASE_URL || '';

    constructor() {
      super(ScheduleBuilderService.baseURL, true, true);
    }

    createScheduleReport = async (payload: IScheduleBuilderPayload) => (
      this.responsify(await this.instance.post('schedule/create', payload))
    )

     updateScheduleReport = async (payload: IScheduleBuilderPayload) => (
       this.responsify(await this.instance.put('schedule/edit', payload))
     )

    getScheduleLimits = async () => (
      this.responsify(await this.instance.get('schedule/limits'))
    )

    getScheduleList = async (payload: IScheduleListPayload) => (
      this.responsify(await this.instance.post('schedule/list', payload))
    )

    getScheduleReportNames = async () => (
      this.responsify(await this.instance.get('schedule/get-report-names'))
    );

    getScheduleOwnerNames = async () => (
      this.responsify(await this.instance.get('schedule/get-owner-names'))
    );

    deactivateSchedule = async (scheduleId: string) => (
      this.responsify(await this.instance.delete('schedule/delete', {
        params: {
          scheduleId,
        },
      }))
    );

    getScheduleHistory = async (scheduleId: string) => (
      this.responsify(await this.instance.get('schedule/get-schedule-audits', {
        params: {
          scheduleId,
        },
      }))
    );
}
