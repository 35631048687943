import React from 'react';
import { AutomationClassNames } from 'core/constants/report';

export interface IDraggableItemProps<T> {
  draggableId: string;
  text: string; // display text
  index: number; // index for Draggable component
  data: T; // custom data to return on clicking close button
  state?: DraggableItemState; // defines which icons and border colours to show
  isDragDisabled?: boolean; // disable dragging of item
  className?: string; // class name passed when wrapped with a custom styled component;
  automationClassName?: AutomationClassNames;
  textTooltipContent?: string;
  borderColor?: DraggableItemBorderColor;
  accentColor?: string; // adds entity indicator color on the left
  highlight?: boolean; // show green highlight on first render
  close?: IDraggableItemCloseProps<T>,
  icons?: IDraggableItemIconProps<T>[]; // array of icons rendered in order on the right just before close button
  showNotificationOnAdd?: boolean; // show Notification for 5 sec on Addition of any field
  showRadioSelection?: boolean, // flag to show radio selection
  showCheckboxSelection? :boolean, // flag to show checkboxes
  itemSelectionHandler?: Function, // selection handler
  selectedItems?: Array<string>, // to indicate if item is selected
  checked?: boolean, // boolean to set checked item or not
  showShimmer?: boolean,
}

export interface IDraggableItemCloseProps<T> {
  hidden?: boolean; // whether to hide the close button
  disabled?: boolean; // whether to disable the close button, regardless of tooltip
  disabledOverlay?: TooltipOverlay; // overlay for disabled close button tooltip
  className?: string;
  onClick?(data: T): void;
  showClose?: boolean;
}

export interface IDraggableItemIconProps<T> {
  key: string
  icon: React.ReactElement | ((data: T, index: number) => React.ReactElement);
  hidden?: boolean | ((data: T, index: number) => boolean);
  className?: string;
  onClick?: (data: T, index: number, event: React.MouseEvent) => void;
  tooltip?: IDraggableItemIconTooltip;
}

export interface IDraggableItemIconTooltip {
  overlay: TooltipOverlay;
  overlayClassName?: string;
  defaultVisible?: boolean;
}

export enum DraggableItemState {
  Default, // item is at rest
  Dragging, // item is being dragged
  AboutToBeAdded, // item is hovering over a droppable section
  AboutToBeCombined, // item is hovering over another item
  DropDisallowed, // item is not allowed to be dropped
}

export enum DraggableItemBorderColor {
  None = '',
  Red = 'se-remove-red-border',
  Green = 'se-add-green-border',
  Blue = 'se-replace-blue-border',
}

type TooltipOverlay = (() => React.ReactNode) | React.ReactNode;
