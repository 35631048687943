import React from 'react';

type Props = {
  width?: number;
  height?: number;
  fill?: string;
}

const OptionAreaChartIcon = ({
  width, height, fill,
}: Props) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect
      width={width}
      height={height}
      fill="transparent"
    />
    <path
      d="M1.6001 1.60059V14.0406C1.6001 14.151 1.68964 14.2406 1.8001 14.2406H14.2401"
      stroke="#454F5B"
      strokeWidth="1.6"
      strokeLinecap="round"
    />
    <path
      d="M4.01221 7.2979C4.01221 6.2764 5.40183 4.82956 7.05631 5.05005C8.20491 5.20312 8.02103 6.4575 9.16962 6.61057C10.4881 6.78629 11.6384 5.25438 12.0512 4.62493C12.0885 4.56805 12.2137 4.58912 12.2137 4.65233V11.7706C12.2137 11.8026 12.1787 11.8284 12.1355 11.8284L4.09048 11.8284C4.04725 11.8284 4.01221 11.8035 4.01221 11.7716L4.01221 7.2979Z"
      stroke={fill}
      strokeWidth="1.6"
      strokeLinecap="round"
    />
  </svg>

);

OptionAreaChartIcon.defaultProps = {
  width: 16,
  height: 16,
  fill: 'var(--secondaryColor)',
};

export default OptionAreaChartIcon;
