import React from 'react';

type Props = {
  width?: number;
  height?: number;
};

const EmptyDataAllIcon = ({ width, height }: Props) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 80 85"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M43.5817 8.08081H15.212V71.9125H63.8459V28.0273H43.5817V8.08081ZM55.7393 63.9317H23.3174V55.9534H55.7393V63.9317ZM55.7393 39.9955V47.9738H23.3174V39.9955H55.7393Z"
      fill="#DFE3E8"
    />
    <path
      d="M23.5294 56H55.5282V64H23.5294V56ZM23.5294 40H55.5282V48H23.5294V40ZM47.5288 0H15.5288C13.4064 0.00920669 11.3738 0.855704 9.87496 2.35462C8.37615 3.85354 7.53293 5.88308 7.52945 8V72C7.52367 74.1121 8.3578 76.1402 9.84897 77.6399C11.3401 79.1395 13.3667 79.9882 15.4842 80H63.5276C65.6507 79.9914 67.6841 79.145 69.1835 77.6458C70.6828 76.1465 71.5262 74.1163 71.5294 71.9988V24L47.5288 0ZM63.5276 71.9988H15.5288V8H43.5285V27.9994H63.5276V71.9988Z"
      fill="#919EAB"
    />
    <path
      d="M66 85C73.732 85 80 78.732 80 71C80 63.268 73.732 57 66 57C58.268 57 52 63.268 52 71C52 78.732 58.268 85 66 85Z"
      fill="white"
    />
    <path
      d="M61.7708 75.1875H67.5V73.9375H61.7708V75.1875ZM61.7708 71.625H70.2292V70.375H61.7708V71.625ZM61.7708 68.0625H70.2292V66.8125H61.7708V68.0625ZM59.75 78.5C59.4167 78.5 59.125 78.375 58.875 78.125C58.625 77.875 58.5 77.5833 58.5 77.25V64.75C58.5 64.4167 58.625 64.125 58.875 63.875C59.125 63.625 59.4167 63.5 59.75 63.5H72.25C72.5833 63.5 72.875 63.625 73.125 63.875C73.375 64.125 73.5 64.4167 73.5 64.75V77.25C73.5 77.5833 73.375 77.875 73.125 78.125C72.875 78.375 72.5833 78.5 72.25 78.5H59.75ZM59.75 77.25H72.25V64.75H59.75V77.25ZM59.75 64.75V77.25V64.75Z"
      fill="#3BB345"
    />
  </svg>
);

EmptyDataAllIcon.defaultProps = {
  width: 80,
  height: 85,
};

export default EmptyDataAllIcon;
