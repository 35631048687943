import React from 'react';

type Props = {
  width?: number;
  height?: number;
  fill?: string;
};

const AddWidgetIcon = ({ width, height, fill }: Props) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 21 21"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect width="20" height="20" fill="white" />
    <path
      d="M13.7292 10.9585L9.29171 6.521L13.7292 2.0835L18.1667 6.521L13.7292 10.9585ZM2.08337 9.4585V3.18766H8.35421V9.4585H2.08337ZM10.7917 18.1668V11.896H17.0625V18.1668H10.7917ZM2.08337 18.1668V11.896H8.35421V18.1668H2.08337ZM3.33337 8.2085H7.10421V4.43766H3.33337V8.2085ZM13.7917 9.271L16.4792 6.5835L13.7917 3.896L11.1042 6.5835L13.7917 9.271ZM12.0417 16.9168H15.8125V13.146H12.0417V16.9168ZM3.33337 16.9168H7.10421V13.146H3.33337V16.9168Z"
      fill={fill}
    />
    <path
      d="M14.5021 20.0035C17.5408 20.0035 20.0042 17.7642 20.0042 15.0018C20.0042 12.2394 17.5408 10 14.5021 10C11.4634 10 9 12.2394 9 15.0018C9 17.7642 11.4634 20.0035 14.5021 20.0035Z"
      fill="white"
    />
    <path
      d="M14.1088 18.3365V15.3595H10.834V14.6445H14.1088V11.6675H14.8945V14.6445H18.1701V15.3588H14.8953V18.3365H14.1088Z"
      fill={fill}
    />
  </svg>
);

AddWidgetIcon.defaultProps = {
  width: 76,
  height: 76,
  fill: 'var(--secondaryColor)',
};

export default AddWidgetIcon;
