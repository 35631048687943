import React from 'react';

type Props = {
  width: number;
  height: number;
  fill?: string;
  backgroundColor?: string;
}
const DividerWidgetIcon = ({
  width, height, fill, backgroundColor,
}: Props) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={width} height={height} viewBox="0 0 26 26" fill="none">
    <rect width="26" height="26" fill={backgroundColor} />
    <path fill={fill} d="M1.29999 24.7V22.75H24.7V24.7H1.29999ZM1.29999 3.25005V1.30005H24.7V3.25005H1.29999Z" />
  </svg>
);

DividerWidgetIcon.defaultProps = {
  fill: 'var(--secondaryColor)',
  backgroundColor: 'transparent',
};

export default DividerWidgetIcon;
