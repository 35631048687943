import React from 'react';
import { UserFilterPayloadType } from 'core/constants/filter';
import { FilterModel } from 'core/models';

export const formatGroupLabelContainer = (data: any) => (
  <div className="se-react-select-group-container">
    <div className="se-react-select-group-label">
      <b>{data.label}</b>
    </div>
    <div className="se-react-select-group-separator" />
  </div>
);

export const getGroupedDropdownItems = (
  options: FilterModel.ISelectFilterOptions[],
): FilterModel.ISelectFilterOptions[] => options.reduce((acc, item) => {
  if (item?.options) {
    return acc.concat(item.options.map((option) => ({ ...option })));
  }
  return acc.concat(item);
}, []);

// Function will be called whenever value changed, create userValue for selected values
// param selectedOptions: value returned by React Select's onChange handler.
export const getNewUserValue = (
  selectedOptions: Array<FilterModel.ISelectFilterOptions>,
  userValue: FilterModel.IUserFilterPayload,
  Options: Array<FilterModel.ISelectFilterOptions>,
  Count: number,
) => {
  const newUserValue = {} as FilterModel.IUserFilterPayload;
  const selectedValuesInSubset = selectedOptions.map((option) => option.value);
  if (Count === Options.length) {
    // Block handle the case when all options are loaded.
    // If all options selected, set the type to "ALL".If unselected values count is lesser
    // than selected values, set type to "NOTIN" and UserIds to unselected values otherwise
    // set type to "IN" and UserIds to selected values.
    if (selectedValuesInSubset.length === Options.length) {
      newUserValue.Type = UserFilterPayloadType.All;
      newUserValue.UserIds = [];
    } else if (
      Options.length - selectedValuesInSubset.length
      < selectedValuesInSubset.length
    ) {
      newUserValue.Type = UserFilterPayloadType.NotIn;
      newUserValue.UserIds = Options.filter(
        (option) => !selectedValuesInSubset.includes(option.value),
      ).map((option) => option.value);
    } else {
      newUserValue.Type = UserFilterPayloadType.In;
      newUserValue.UserIds = selectedValuesInSubset;
    }
  } else if (userValue) {
    const unselectedValuesInSubset = Options.filter(
      (option) => !selectedValuesInSubset.includes(option.value),
    ).map((option) => option.value);
    switch (userValue.Type) {
      case UserFilterPayloadType.All:
        // For this case all unselected values are present in loaded options list but all selected
        // values may not be neccessarily present therefore not possible to extract all selected
        // values hence value type is set to "NOTIN".
        newUserValue.Type = UserFilterPayloadType.NotIn;
        newUserValue.UserIds = Options.filter(
          (option) => !selectedValuesInSubset.includes(option.value),
        ).map((option) => option.value);
        break;
      case UserFilterPayloadType.In: {
        // Not all unselected values present in options list hence value type is not changed only UserIds
        // list is updated, new selected values are added if not present in UserIds list and unselected
        // items are removed from UserIds list.
        let newSelectedValues = userValue.UserIds.filter(
          (item) => !unselectedValuesInSubset.includes(item),
        );
        newSelectedValues = newSelectedValues.concat(
          selectedValuesInSubset.filter(
            (item: number) => !newSelectedValues.includes(item),
          ),
        );
        newUserValue.Type = UserFilterPayloadType.In;
        newUserValue.UserIds = newSelectedValues;
        break;
      }
      case UserFilterPayloadType.NotIn: {
        let newUnselectedValues = userValue.UserIds.filter(
          (item: number) => !selectedValuesInSubset.includes(item),
        );
        newUnselectedValues = newUnselectedValues.concat(
          unselectedValuesInSubset.filter(
            (item: number) => !newUnselectedValues.includes(item),
          ),
        );
        if (newUnselectedValues.length) {
          newUserValue.Type = UserFilterPayloadType.NotIn;
          newUserValue.UserIds = newUnselectedValues;
        } else {
          newUserValue.Type = UserFilterPayloadType.All;
          newUserValue.UserIds = [];
        }
        break;
      }
      default:
        break;
    }
  } else {
    newUserValue.Type = UserFilterPayloadType.In;
    newUserValue.UserIds = selectedValuesInSubset;
  }
  return newUserValue;
};

// Function called whenever user select, unselect values in loaded options(subset)
// for filter type "UserMultiSelect".
// param config: config of the filter.
// param userValue: user filter value.
// returns selected value count in entire option list.
export const getSelectedValueCountForUserFilter = (
  config: FilterModel.IConfig,
  userValue: FilterModel.IUserFilterPayload,
) => {
  let count = 0;
  if (config?.Meta?.OptionsMeta?.Count) {
    switch (userValue.Type) {
      case UserFilterPayloadType.All:
        count = config.Meta.OptionsMeta.Count;
        break;
      case UserFilterPayloadType.In: {
        count = userValue.UserIds.length;
        break;
      }
      case UserFilterPayloadType.NotIn: {
        count = config.Meta.OptionsMeta.Count - userValue.UserIds.length;
        break;
      }
      default:
        break;
    }
  }
  return count;
};
