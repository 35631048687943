import React from 'react';

type Props = {
  width?: number;
  height?: number;
  fill?: string;
}

const OptionKPIIcon = ({
  width, height, fill,
}: Props) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect
      width="16"
      height="16"
      fill="transparent"
    />
    <path
      d="M1.54839 12V7.2H0V6H2.7871V12H1.54839ZM4.95484 12V9.25C4.95484 9 5.03656 8.79583 5.2 8.6375C5.36344 8.47917 5.57419 8.4 5.83226 8.4H8.36129V7.2H4.95484V6H8.72258C8.98065 6 9.1914 6.07917 9.35484 6.2375C9.51828 6.39583 9.6 6.6 9.6 6.85V8.75C9.6 9 9.51828 9.20417 9.35484 9.3625C9.1914 9.52083 8.98065 9.6 8.72258 9.6H6.19355V10.8H9.6V12H4.95484ZM11.3548 12V10.8H14.7613V9.6H12.3871V8.4H14.7613V7.2H11.3548V6H15.1226C15.3806 6 15.5914 6.07917 15.7548 6.2375C15.9183 6.39583 16 6.6 16 6.85V11.15C16 11.4 15.9183 11.6042 15.7548 11.7625C15.5914 11.9208 15.3806 12 15.1226 12H11.3548Z"
      fill={fill}
    />
  </svg>

);

OptionKPIIcon.defaultProps = {
  width: 16,
  height: 16,
  fill: 'var(--secondaryColor)',
};

export default OptionKPIIcon;
