import React from 'react';

type Props = {
  width?: number;
  height?: number;
    fill?: string;
    bgColor?: string;
}

const CustomEntityIcon = ({
  width, height, fill, bgColor,
}: Props) => (
  <svg width={width} height={height} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect width="24" height="24" fill={bgColor} />
    <path fill={fill} d="M3.59971 22.8001C2.93971 22.8001 2.37471 22.5651 1.90471 22.0951C1.43471 21.6251 1.19971 21.0601 1.19971 20.4001V3.60007C1.19971 2.94007 1.43471 2.37507 1.90471 1.90507C2.37471 1.43507 2.93971 1.20007 3.59971 1.20007H20.3997C21.0597 1.20007 21.6247 1.43507 22.0947 1.90507C22.5647 2.37507 22.7997 2.94007 22.7997 3.60007V20.4001C22.7997 21.0601 22.5647 21.6251 22.0947 22.0951C21.6247 22.5651 21.0597 22.8001 20.3997 22.8001H3.59971ZM3.59971 7.59007H20.3997V3.60007H3.59971V7.59007ZM3.59971 14.0101H20.3997V9.99007H3.59971V14.0101ZM3.59971 20.4001H20.3997V16.4101H3.59971V20.4001ZM4.79971 6.78007V4.38007H7.19971V6.78007H4.79971ZM4.79971 13.2001V10.8001H7.19971V13.2001H4.79971ZM4.79971 19.6201V17.2201H7.19971V19.6201H4.79971Z" />
  </svg>

);

CustomEntityIcon.defaultProps = {
  fill: 'var(--customIconColor)',
  width: 24,
  height: 24,
  bgColor: 'transparent',
};

export default CustomEntityIcon;
