import React from 'react';

type Props = {
  width?: number;
  height?: number;
  fill?: string;
};

const ReportIssue = ({ width, height, fill }: Props) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_1763_2)">
      <path
        d="M13.3333 5.33333H11.46C11.16 4.81333 10.7467 4.36667 10.2467 4.02667L11.3333 2.94L10.3933 2L8.94666 3.44667C8.63999 3.37333 8.32666 3.33333 7.99999 3.33333C7.67332 3.33333 7.35999 3.37333 7.05999 3.44667L5.60666 2L4.66666 2.94L5.74666 4.02667C5.25332 4.36667 4.83999 4.81333 4.53999 5.33333H2.66666V6.66667H4.05999C4.02666 6.88667 3.99999 7.10667 3.99999 7.33333V8H2.66666V9.33333H3.99999V10C3.99999 10.2267 4.02666 10.4467 4.05999 10.6667H2.66666V12H4.53999C5.23332 13.1933 6.51999 14 7.99999 14C9.47999 14 10.7667 13.1933 11.46 12H13.3333V10.6667H11.94C11.9733 10.4467 12 10.2267 12 10V9.33333H13.3333V8H12V7.33333C12 7.10667 11.9733 6.88667 11.94 6.66667H13.3333V5.33333ZM10.6667 8V10C10.6667 10.1467 10.6467 10.3133 10.62 10.4667L10.5533 10.9L10.3067 11.3333C9.82666 12.16 8.94666 12.6667 7.99999 12.6667C7.05332 12.6667 6.17332 12.1533 5.69332 11.3333L5.44666 10.9067L5.37999 10.4733C5.35332 10.32 5.33332 10.1533 5.33332 10V7.33333C5.33332 7.18 5.35332 7.01333 5.37999 6.86667L5.44666 6.43333L5.69332 6C5.89332 5.65333 6.17332 5.35333 6.49999 5.12667L6.87999 4.86667L7.37332 4.74667C7.57999 4.69333 7.79332 4.66667 7.99999 4.66667C8.21332 4.66667 8.41999 4.69333 8.63332 4.74667L9.08666 4.85333L9.49332 5.13333C9.82666 5.36 10.1 5.65333 10.3 6.00667L10.5533 6.44L10.62 6.87333C10.6467 7.02 10.6667 7.18667 10.6667 7.33333V8ZM6.66666 9.33333H9.33332V10.6667H6.66666V9.33333ZM6.66666 6.66667H9.33332V8H6.66666V6.66667Z"
        fill={fill}
      />
    </g>
    <defs>
      <clipPath id="clip0_1763_2">
        <rect width="16" height="16" fill="white" />
      </clipPath>
    </defs>
  </svg>
);

ReportIssue.defaultProps = {
  width: 16,
  height: 16,
  fill: 'var(--bannerFontColor)',
};

export default ReportIssue;
