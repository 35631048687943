import React from 'react';

type Props = {
  width?: number;
  height?: number;
  fill?: string;
}

const EmptyWidgetIcon = ({
  width, height, fill,
}: Props) => (

  <svg width={width} height={height} viewBox="0 0 100 100" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M50 100C77.6142 100 100 77.6142 100 50C100 22.3858 77.6142 0 50 0C22.3858 0 0 22.3858 0 50C0 77.6142 22.3858 100 50 100Z" fill="#F4F6F8" />
    <path d="M57.4582 51.916L48.5832 43.041L57.4582 34.166L66.3332 43.041L57.4582 51.916ZM34.1665 48.916V36.3743H46.7082V48.916H34.1665ZM51.5832 66.3327V53.791H64.1248V66.3327H51.5832ZM34.1665 66.3327V53.791H46.7082V66.3327H34.1665ZM36.6665 46.416H44.2082V38.8743H36.6665V46.416ZM57.5832 48.541L62.9582 43.166L57.5832 37.791L52.2082 43.166L57.5832 48.541ZM54.0832 63.8327H61.6248V56.291H54.0832V63.8327ZM36.6665 63.8327H44.2082V56.291H36.6665V63.8327Z" fill="#454F5B" />
    <g filter="url(#filter0_d_2756_11681)">
      <path d="M30.3043 27L27 30.2664L46.9981 50.6745C56.3076 59.6961 46.6122 50.2808 56.2734 59.5711L69.7246 73L73 69.7239L30.3043 27Z" fill="#454F5B" />
    </g>
    <defs>
      <filter id="filter0_d_2756_11681" x="27" y="25.2" width="46.5" height="47.8" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
        <feOffset dx="0.5" dy="-1.8" />
        <feComposite in2="hardAlpha" operator="out" />
        <feColorMatrix type="matrix" values="0 0 0 0 0.956863 0 0 0 0 0.964706 0 0 0 0 0.972549 0 0 0 1 0" />
        <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_2756_11681" />
        <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_2756_11681" result="shape" />
      </filter>
    </defs>
  </svg>

);

EmptyWidgetIcon.defaultProps = {
  width: 100,
  height: 100,
  fill: 'var(--backgroundColor)',
};

export default EmptyWidgetIcon;
