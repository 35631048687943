import React from 'react';

type Props = {
    width: number;
    height: number;
    fill?: string;
}

const FilterTickIcon = ({
  width, height, fill,
}: Props) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 16 16"
    fill={fill}
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_111_22809)">
      <path
        d="M5.99993 10.7999L3.19993 7.99994L2.2666 8.93328L5.99993 12.6666L13.9999 4.66661L13.0666 3.73328L5.99993 10.7999Z"
        fill={fill}
      />
    </g>
    <defs>
      <clipPath id="clip0_111_22809">
        <rect width="16" height="16" fill="white" />
      </clipPath>
    </defs>
  </svg>

);

FilterTickIcon.defaultProps = {
  fill: 'var(--border)',
};

export default FilterTickIcon;
