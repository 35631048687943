import React from 'react';

type Props = {
    width: number;
    height: number;
    fill: string;
}

const FilterTickIcon = ({
  width, height, fill,
}: Props) => (
  <svg width={width} height={height} viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <mask id="mask0_1676_1885" maskUnits="userSpaceOnUse" x="0" y="0" width="16" height="16">
      <rect width="16" height="16" fill="#D9D9D9" />
    </mask>
    <g mask="url(#mask0_1676_1885)">
      <path
        d="M7.06683 11.0666L11.7668 6.36665L10.8335 5.43331L7.06683 9.19998L5.16683 7.29998L4.2335 8.23331L7.06683 11.0666ZM8.00016 14.6666C7.07794 14.6666 6.21127 14.4915 5.40016 14.1413C4.58905 13.7915 3.8835 13.3166 3.2835 12.7166C2.6835 12.1166 2.20861 11.4111 1.85883 10.6C1.50861 9.78887 1.3335 8.9222 1.3335 7.99998C1.3335 7.07776 1.50861 6.21109 1.85883 5.39998C2.20861 4.58887 2.6835 3.88331 3.2835 3.28331C3.8835 2.68331 4.58905 2.2082 5.40016 1.85798C6.21127 1.5082 7.07794 1.33331 8.00016 1.33331C8.92239 1.33331 9.78905 1.5082 10.6002 1.85798C11.4113 2.2082 12.1168 2.68331 12.7168 3.28331C13.3168 3.88331 13.7917 4.58887 14.1415 5.39998C14.4917 6.21109 14.6668 7.07776 14.6668 7.99998C14.6668 8.9222 14.4917 9.78887 14.1415 10.6C13.7917 11.4111 13.3168 12.1166 12.7168 12.7166C12.1168 13.3166 11.4113 13.7915 10.6002 14.1413C9.78905 14.4915 8.92239 14.6666 8.00016 14.6666Z"
        fill={fill}
      />
    </g>
  </svg>

);

export default FilterTickIcon;
