import React from 'react';

type Props = {
  width?: number;
  height?: number;
};

const EmptyDataTagsDashboard = ({ width, height }: Props) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 86 85"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M3.55371 3.47266V38.0536H33.0468V3.47266H3.55371Z"
      fill="#DFE3E8"
    />
    <path
      d="M4.1416 53.4727V75.6907H32.7416V53.4727H4.1416Z"
      fill="#DFE3E8"
    />
    <path
      d="M47.1416 3.47266V25.6907H75.7416V3.47266H47.1416Z"
      fill="#DFE3E8"
    />
    <path
      d="M46.5537 41.4727V76.0536H76.0468V41.4727H46.5537Z"
      fill="#DFE3E8"
    />
    <path
      d="M43.333 30V0H80V30H43.333ZM0 43.333V0H36.667V43.333H0ZM43.333 80V36.667H80V80H43.333ZM0 80V50H36.667V80H0ZM6.667 36.667H30V6.667H6.667V36.667ZM50 73.333H73.333V43.333H50V73.333ZM50 23.333H73.333V6.667H50V23.333ZM6.667 73.333H30V56.667H6.667V73.333Z"
      fill="#919EAB"
    />
    <path
      d="M72 85C79.732 85 86 78.732 86 71C86 63.268 79.732 57 72 57C64.268 57 58 63.268 58 71C58 78.732 64.268 85 72 85Z"
      fill="white"
    />
    <path
      d="M64.438 74.4824C64.1459 74.1902 63.9998 73.8378 63.9998 73.4251C63.9998 73.0124 64.1459 72.6599 64.438 72.3678L71.3861 65.4197C71.5445 65.2613 71.7126 65.1517 71.8904 65.091C72.0681 65.0303 72.2556 65 72.4527 65H78.0852C78.5041 65 78.8552 65.1399 79.1385 65.4197C79.4218 65.6995 79.5635 66.0489 79.5635 66.4678V72.1003C79.5635 72.2974 79.5314 72.491 79.4672 72.6811C79.403 72.8713 79.2917 73.0455 79.1333 73.204L72.2328 80.115C71.9283 80.4195 71.5679 80.5717 71.1517 80.5717C70.7355 80.5717 70.3751 80.4195 70.0706 80.115L64.438 74.4824ZM65.449 73.4819L71.0816 79.1144L78.0852 72.1003V66.4678H72.4527L65.449 73.4819ZM75.1948 68.4132C75.1948 68.6726 75.2906 68.898 75.482 69.0895C75.6735 69.281 75.8989 69.3767 76.1583 69.3767C76.4177 69.3767 76.6431 69.281 76.8346 69.0895C77.026 68.898 77.1218 68.6726 77.1218 68.4132C77.1218 68.1538 77.026 67.9284 76.8346 67.7369C76.6431 67.5455 76.4177 67.4498 76.1583 67.4498C75.8989 67.4498 75.6735 67.5455 75.482 67.7369C75.2906 67.9284 75.1948 68.1538 75.1948 68.4132Z"
      fill="#454F5B"
    />
    <circle
      cx="76.0129"
      cy="68.543"
      r="1"
      transform="rotate(-45 76.0129 68.543)"
      fill="white"
      stroke="#454F5B"
    />
  </svg>
);

EmptyDataTagsDashboard.defaultProps = {
  width: 86,
  height: 85,
};

export default EmptyDataTagsDashboard;
