import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { APIRequestStatus } from 'core/constants/redux';
import initialReportListingState from './sidebar-store.initial-state';
import {
  IDashboardItem,
  ISidebarStoreState, ITagsByPofile, ITagsData, ListingSection, SidebarActionTypes,
} from './sidebar-store.state';
import { getAllTags, getTagsByUser } from './sidebar-store.requests';

const sidebarStoreSlice = createSlice({
  name: 'sidebarStore',
  initialState: initialReportListingState,
  reducers: {
    [SidebarActionTypes.SET_SELECTED_NAVBAR_ITEM]: (state: ISidebarStoreState, action: PayloadAction<string>) => {
      state.selectedNavBarItem = action.payload;
    },
    [SidebarActionTypes.SET_SELECTED_SECTION]: (state: ISidebarStoreState, action: PayloadAction<ListingSection>) => {
      state.selectedSection = action.payload;
    },
    [SidebarActionTypes.RESET_LISTING_STATE]: (state: ISidebarStoreState) => {
      state.globaltags = [];
    },
    [SidebarActionTypes.SET_SELECTED_HOME_DASHBOARD]: (state: ISidebarStoreState, action: PayloadAction<IDashboardItem>) => {
      state.selectedHomeDashboard = action.payload;
    },
    [SidebarActionTypes.SET_REDIRECT_ALL_DASHBOARDS]: (state: ISidebarStoreState, action: PayloadAction<boolean>) => {
      state.redirectAllDashboard = action.payload;
    },
    [SidebarActionTypes.RESET_TAG_ANIMATION]: (state: ISidebarStoreState, action: PayloadAction<boolean>) => {
      state.resetTagAnimation = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getAllTags.pending, (state: ISidebarStoreState) => {
      state.requestProcessing[SidebarActionTypes.GET_ALL_TAGS_REQUEST] = APIRequestStatus.Processing;
    });
    builder.addCase(getAllTags.fulfilled, (state: ISidebarStoreState, action: PayloadAction<Array<ITagsData>>) => {
      state.globaltags = action.payload;
      state.requestProcessing[SidebarActionTypes.GET_ALL_TAGS_REQUEST] = APIRequestStatus.Success;
    });
    builder.addCase(getAllTags.rejected, (state: ISidebarStoreState) => {
      state.requestProcessing[SidebarActionTypes.GET_ALL_TAGS_REQUEST] = APIRequestStatus.Failure;
    });
    builder.addCase(getTagsByUser.pending, (state: ISidebarStoreState) => {
      state.requestProcessing[SidebarActionTypes.GET_TAGS_BY_USER_REQUEST] = APIRequestStatus.Processing;
    });
    builder.addCase(getTagsByUser.fulfilled, (state: ISidebarStoreState, action: PayloadAction<ITagsByPofile>) => {
      state.globaltags = action?.payload?.TagMetaData;
      const reportSectionTags: ITagsData[] = [];
      const globalTags = action?.payload?.TagMetaData;
      action.payload?.ReportTags?.forEach((tagId: string) => {
        const tagdata = globalTags?.find((item: { Id: string; }) => item.Id === tagId);
        if (tagdata) {
          reportSectionTags?.push(tagdata);
        }
      });
      state.reportTagsbasedOnProfile = reportSectionTags;
      const dashboardSectionTags: ITagsData[] = [];
      action.payload?.DashboardTags?.forEach((tagId: string) => {
        const tagdata = globalTags?.find((item: { Id: string; }) => item.Id === tagId);
        if (tagdata) {
          dashboardSectionTags?.push(tagdata);
        }
      });
      state.dashboardTagsbasedOnProfile = dashboardSectionTags;
      state.requestProcessing[SidebarActionTypes.GET_TAGS_BY_USER_REQUEST] = APIRequestStatus.Success;
      state.requestProcessing[SidebarActionTypes.GET_ALL_TAGS_REQUEST] = APIRequestStatus.Success;
    });
    builder.addCase(getTagsByUser.rejected, (state: ISidebarStoreState) => {
      state.requestProcessing[SidebarActionTypes.GET_TAGS_BY_USER_REQUEST] = APIRequestStatus.Failure;
    });
  },
});

export const {
  [SidebarActionTypes.SET_SELECTED_NAVBAR_ITEM]: setSelectedNavbarItem,
  [SidebarActionTypes.SET_SELECTED_SECTION]: setSelectedSection,
  [SidebarActionTypes.RESET_LISTING_STATE]: resetListingState,
  [SidebarActionTypes.SET_SELECTED_HOME_DASHBOARD]: setSelectedHomeDashboard,
  [SidebarActionTypes.SET_REDIRECT_ALL_DASHBOARDS]: redirectAllDashboards,
  [SidebarActionTypes.RESET_TAG_ANIMATION]: resetTagAnimation,
} = sidebarStoreSlice.actions;

export default sidebarStoreSlice;
