import React, { useRef, ReactNode } from 'react';
import ModalStyled from './modal.styled';
import { CloseNewIcon } from '../svg';

interface IModalProps {
  show: boolean;
  onClose: Function;
  footer?: ReactNode;
  closeOnBlurClick?: boolean;
  title?: ReactNode;
  classNames?: Array<string>;
  contentClassName?: string;
}

const Modal = (props: React.PropsWithChildren<IModalProps>) => {
  const {
    show,
    closeOnBlurClick,
    onClose,
    footer,
    title,
    children,
    classNames,
    contentClassName = '',
  } = props;
  const modalRef = useRef<HTMLDivElement>(null);

  const handleClickOutside: React.MouseEventHandler<HTMLDivElement> = (
    event,
  ) => {
    if (
      closeOnBlurClick
      && event.target instanceof HTMLElement
      && modalRef.current
      && !modalRef.current.contains(event.target)
    ) {
      onClose();
    }
  };

  const handleOverlayClick = () => {
    if (closeOnBlurClick) {
      onClose();
    }
  };

  if (!show) {
    return null;
  }

  return (
    <ModalStyled>
      <div className="modal">
        <div className="modal-overlay" onClick={handleOverlayClick} />
        <div
          className={`modal-content ${contentClassName}`}
          ref={modalRef}
          onClick={handleClickOutside}
        >
          <div className="modal-header">
            {title && <div className="modal-title">{title}</div>}
            <button
              className={`se-modal-close-icon ${classNames?.join(' ')}`}
              type="button"
              onClick={() => onClose()}
            >
              <CloseNewIcon
                width={20}
                height={20}
                fill="var(--searchBoxTextColor)"
              />
            </button>
          </div>
          <div className="modal-body">{children}</div>
          {footer && <div className="modal-footer">{footer}</div>}
        </div>
      </div>
    </ModalStyled>
  );
};

Modal.defaultProps = {
  closeOnBlurClick: true,
  title: '',
};

export default Modal;
