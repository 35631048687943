import React from 'react';

type Props = {
  width?: number;
  height?: number;
  fill?: string;
}

const OptionColumnChartIcon = ({
  width, height, fill,
}: Props) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect
      width="16"
      height="16"
      fill="transparent"
    />
    <path
      d="M2 2V13.85C2 13.9328 2.06716 14 2.15 14H14"
      stroke={fill}
      strokeWidth="1.5"
      strokeLinecap="round"
    />
    <path
      d="M11 3.875L11 11.7529"
      stroke={fill}
      strokeWidth="1.5"
      strokeLinecap="round"
    />
    <path
      d="M8 5.75L8 11.7958"
      stroke={fill}
      strokeWidth="1.5"
      strokeLinecap="round"
    />
    <path
      d="M5 8.75L5 11.75"
      stroke={fill}
      strokeWidth="1.5"
      strokeLinecap="round"
    />
  </svg>

);

OptionColumnChartIcon.defaultProps = {
  width: 16,
  height: 16,
  fill: 'var(--secondaryColor)',
};

export default OptionColumnChartIcon;
