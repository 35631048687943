import React from 'react';

type Props = {
  width: number;
  height: number;
  fill?: string;
}

const WebContentEntityIcon = ({
  width, height, fill,
}: Props) => (
  <svg width={width} height={height} viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path id="WebContent" fill={fill} fillRule="evenodd" clipRule="evenodd" d="M6.75 9.99756H13.25V8.37256H6.75V9.99756ZM6.75 6.74756H13.25V5.12256H6.75V6.74756ZM14.9 14.8525L13.1687 12.5976C12.9385 12.2861 12.6542 12.0458 12.3156 11.8765C11.9771 11.7072 11.6115 11.6226 11.2187 11.6226H5.1V3.47744H14.9V14.8525ZM14.13 16.5274L11.8891 13.5929C11.8078 13.4846 11.7096 13.3999 11.5945 13.339C11.4794 13.2781 11.3542 13.2476 11.2187 13.2476H5.1V16.5274H14.13ZM3.5 3.47744C3.5 2.82228 3.89378 2.25908 4.45758 2.01163C4.66131 1.91892 4.88379 1.87256 5.125 1.87256H14.875C15.1162 1.87256 15.3387 1.91892 15.5424 2.01165C16.1062 2.2591 16.5 2.82229 16.5 3.47744V16.5274C16.5 17.4111 15.7837 18.1274 14.9 18.1274H5.1C4.21635 18.1274 3.5 17.4111 3.5 16.5274V3.47744Z" />
  </svg>
);

WebContentEntityIcon.defaultProps = {
  fill: 'var(--webContentEntity)',
};

export default WebContentEntityIcon;
