import React from 'react';

type Props = {
  width: number;
  height: number;
  fill?: string;
};
const AllReportsIcon = ({ width, height, fill }: Props) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M6.925 17.025H13.8V15.525H6.925V17.025ZM6.925 12.75H17.075V11.25H6.925V12.75ZM6.925 8.475H17.075V6.975H6.925V8.475ZM4.5 21C4.1 21 3.75 20.85 3.45 20.55C3.15 20.25 3 19.9 3 19.5V4.5C3 4.1 3.15 3.75 3.45 3.45C3.75 3.15 4.1 3 4.5 3H19.5C19.9 3 20.25 3.15 20.55 3.45C20.85 3.75 21 4.1 21 4.5V19.5C21 19.9 20.85 20.25 20.55 20.55C20.25 20.85 19.9 21 19.5 21H4.5ZM4.5 19.5H19.5V4.5H4.5V19.5ZM4.5 4.5V19.5V4.5Z"
      fill={fill}
    />
  </svg>
);

AllReportsIcon.defaultProps = {
  fill: 'var(--opportunityColor)',
};

export default AllReportsIcon;
