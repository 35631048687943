// Custom  error middleware for application to catch all errors occurs in reducer.

import { setShowErrorScreen } from './global-store/global-store.reducer';

// next[method] called to send action to reducer.
export const customErrorMiddleware = (store: any) => (next:any) => (action: any) => {
  const { dispatch } = store;
  try {
    return next(action);
  } catch (error) {
    dispatch(setShowErrorScreen(true));
    console.error('reducer error', error);
    throw error;
  }
};

export default customErrorMiddleware;
