import React, { forwardRef } from 'react';
import Tooltip from 'rc-tooltip';
import { mouseEnterDelay } from 'components/feature/Report/ReportSidebar/common/constants';
import { TooltipPlacement } from 'core/constants/report';
import StyledPrimaryButton from './primary-button.style';
import useLocalize from '../Localization/useLocalize.hook';

interface IPrimaryButtonProps {
  onClickHandler?: () => void;
  uniqueKey: string;
  displayName?: string | React.ReactNode;
  classNames?: Array<string>;
  wrapperClassName?: Array<string>;
  isDisable?: boolean;
  tooltipPlacement?: string;
  tooltipOverlay?: React.ReactNode;
  overlayClassName?: string;
}

const PrimaryButton = forwardRef((props: IPrimaryButtonProps, ref: any) => {
  const {
    onClickHandler,
    classNames,
    displayName,
    isDisable,
    uniqueKey,
    wrapperClassName,
    tooltipPlacement,
    tooltipOverlay,
    overlayClassName,
  } = props;
  return (
    <Tooltip
      placement={tooltipPlacement}
      overlay={<span>{tooltipOverlay || displayName}</span>}
      mouseEnterDelay={mouseEnterDelay}
      overlayClassName={overlayClassName}
    >
      <StyledPrimaryButton
        key={uniqueKey}
        className={`${wrapperClassName.join(' ')}`}
      >
        <button
          ref={ref}
          type="button"
          className={`se-primary-changes ${isDisable ? 'se-button-disabled' : ''
          } ${classNames.join(' ')}`}
          onClick={() => !isDisable && onClickHandler()}
        >
          {displayName}
        </button>
      </StyledPrimaryButton>
    </Tooltip>
  );
});

PrimaryButton.defaultProps = {
  classNames: [],
  wrapperClassName: [],
  displayName: `${useLocalize('cmn.saveChanges')}`,
  isDisable: false,
  tooltipPlacement: TooltipPlacement.Bottom,
  tooltipOverlay: '',
  overlayClassName: 'se-dark-theme-tooltip',
};

export default PrimaryButton;
