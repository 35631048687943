import { flexCenterMixin } from 'core/utils/mixins';
import styled from 'styled-components';

const StyledDateContainer = styled.div`

  .se-date-range-wrapper{
    position:relative;
    width:100%;
    margin-top:4px;
    ${flexCenterMixin};
    gap: 4px;
  }
  .se-date-range-toggler {
    width: 100%;
    height: 100%;
    border: 1px solid var(--borderColor);
    border-radius: 2px;
    display: flex;
    align-items: center;
    padding: 5px 7px 5px 11px;
    font-size: 13px;
    font-family: Regular;
    color: var(--secondaryColor);
    background-color: transparent;
    overflow: hidden;
    box-shadow: none;
    text-align: left;

    :hover {
      border-color: var(--blueBorderColor);
      svg {
        path{
          fill: var(--secondaryColor);
        }
      }
    }

    .se-date-range-label {
      flex: 1;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      margin-right: 8px;
    }

    :disabled {
      background: var(--clearFilterColor);
    }
  }
.se-date-picker-open{
  .se-date-range-toggler{
     border-color: var(--blueBorderColor);
      svg {
        path{
          fill: var(--secondaryColor);
        }
      }
    }
}
.se-date-range-restricted-warning {
  background: var(--datePickerWarningColor);
  color: var(--white);
  font-family: Regular;
  font-size: 13px;
  font-weight: 400;
  line-height: 18px;
  letter-spacing: 0em;
  padding: 12px;
  text-align: center;
  max-width: 702px;
}

.se-warning-disclaimer {
  ${flexCenterMixin}
  margin-left:-4px;
  cursor:pointer;
}

.se-warning-disclaimer-label{
  color: var(--disclaimerColor);
}
.se-remove-red-border {
  border-color: var(--warningColor);
}
.se-add-green-border {
  border-color: var(--opportunityColor);
}
.se-date-range-selection-dropdown{
  .se-react-contol-value-container{
    padding-left:4px;
    color: var(--secondaryColor);
    font-size: 11px !important;
  }
  .se-react-select__control{
    padding:0px 12px 0px 11px;
    .se-react-select__dropdown-indicator{
      padding:0;
    }
    .se-react-select__indicators{
      padding-right:5px;
    }
  }
}
.se-warning-additional-info{
  padding-top:12px;
}
`;
export default StyledDateContainer;
