import { createAsyncThunk } from '@reduxjs/toolkit';
import { IEditTagPayload, ObjectActionType } from 'redux-v2/report-listing/report-listing.state';
import DashboardBuilderService from 'services/dashboard-builder.service';
import ReportBuilderService from 'services/report-builder.service';
import { GlobalStoreActions, IEditProfileDashboardPayload, IEditReportProfilePayload } from './global-store.state';

export const getAllTagsRequest = createAsyncThunk(GlobalStoreActions.GET_ALL_TAGS_REQUEST,
  async () => new ReportBuilderService().getAllTags());

export const getProfilesListRequest = createAsyncThunk(GlobalStoreActions.GET_PROFILE_LIST_REQUEST,
  async () => new ReportBuilderService().getProfileList());

export const editTagsByObjectRequest = createAsyncThunk(GlobalStoreActions.EDIT_TAGS_BY_OBJECTID_REQUEST,
  async (data: IEditTagPayload) => new DashboardBuilderService().editTagsByObject(data));

export const editProfilesToDashboardRequest = createAsyncThunk(GlobalStoreActions.EDIT_PROFILES_TO_DASHBOARD_REQUEST,
  async (data: IEditProfileDashboardPayload) => new DashboardBuilderService().editProfilesToDashboard(data));

export const editProfileToReportsRequest = createAsyncThunk(GlobalStoreActions.EDIT_PROFILES_TO_REPORTS_REQUEST,
  async (data: IEditReportProfilePayload) => new ReportBuilderService().editProfilesToReport(data));

export const getTagsByObjectIdRequest = createAsyncThunk(GlobalStoreActions.GET_TAGS_BY_OBJECT_REQUEST,
  async (params:{ objectId: string, objectType: ObjectActionType }) => new DashboardBuilderService().getTagsByObject(params.objectId, params.objectType));
