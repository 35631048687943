import React from 'react';

type Props = {
  width?: number;
  height?: number;
  fill?: string;
};

const Feedback = ({ width, height, fill }: Props) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_1763_58)">
      <path
        d="M13.3333 1.33333H2.66668C1.93334 1.33333 1.34001 1.93333 1.34001 2.66666L1.33334 14.6667L4.00001 12H13.3333C14.0667 12 14.6667 11.4 14.6667 10.6667V2.66666C14.6667 1.93333 14.0667 1.33333 13.3333 1.33333ZM13.3333 10.6667H3.44668L3.05334 11.06L2.66668 11.4467V2.66666H13.3333V10.6667ZM7.00001 9.33333H12V7.99999H8.33334L7.00001 9.33333ZM9.57334 5.41999C9.70668 5.28666 9.70668 5.07999 9.57334 4.94666L8.39334 3.76666C8.26001 3.63333 8.05334 3.63333 7.92001 3.76666L4.00001 7.68666V9.33333H5.64668L9.57334 5.41999Z"
        fill={fill}
      />
    </g>
    <defs>
      <clipPath id="clip0_1763_58">
        <rect width="16" height="16" fill="white" />
      </clipPath>
    </defs>
  </svg>
);

Feedback.defaultProps = {
  width: 16,
  height: 16,
  fill: 'var(--bannerFontColor)',
};

export default Feedback;
