import React from 'react';

type Props = {
  width?: number;
  height?: number;
  fill?: string;
};

const SieraTour = ({ width, height, fill }: Props) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M4.66667 11.3333H6V6.66667H4.66667V11.3333ZM7.33333 11.3333H8.66667V4.66667H7.33333V11.3333ZM10 11.3333H11.3333V8.66667H10V11.3333ZM3.33333 14C2.96667 14 2.65278 13.8694 2.39167 13.6083C2.13056 13.3472 2 13.0333 2 12.6667V3.33333C2 2.96667 2.13056 2.65278 2.39167 2.39167C2.65278 2.13056 2.96667 2 3.33333 2H12.6667C13.0333 2 13.3472 2.13056 13.6083 2.39167C13.8694 2.65278 14 2.96667 14 3.33333V12.6667C14 13.0333 13.8694 13.3472 13.6083 13.6083C13.3472 13.8694 13.0333 14 12.6667 14H3.33333ZM3.33333 12.6667H12.6667V3.33333H3.33333V12.6667Z" fill={fill} />
  </svg>
);

SieraTour.defaultProps = {
  width: 16,
  height: 16,
  fill: 'var(--bannerFontColor)',
};

export default SieraTour;
