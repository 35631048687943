import React from 'react';

type Props = {
  width: number;
  height: number;
  fill?: string;
}

const TickIcon = ({
  width, height, fill,
}: Props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    style={{
      width,
      height,
    }}
    viewBox="0 0 9.921 7.553"
  >
    <path
      id="Path_7465"
      data-name="Path 7465"
      d="M6.557,11.575,4.189,9.207,3.4,10l3.157,3.157L13.32,6.389,12.531,5.6Z"
      transform="translate(-3.4 -5.6)"
      fill={fill}
    />
  </svg>

);

TickIcon.defaultProps = {
  fill: 'var(--border)',
};

export default TickIcon;
