/* eslint-disable no-shadow */
// eslint-disable-next-line import/prefer-default-export
export enum FilterType {
  PRE_APPLIED = 'PRE_APPLIED',
  UserMultiSelect = 'UserMultiSelect',
  LSQMetadataMultiSelect = 'LSQMetadataMultiSelect',
  DateRange = 'DateRange',
  DRILLDOWN = 'DRILLDOWN',
  FILTER_ONLY = 'Filter_Only',
  CustomDefinedMultiSelect = 'CustomDefinedMultiSelect',
  CustomDefinedSingleSelect = 'CustomDefinedSingleSelect',
  LSQMetadataSingleSelect = 'LSQMetadataSingleSelect',
  MultiTextSearch = 'MultiTextSearch',
  FILTER = 'Filter',
  SET_FILTER = 'SET_FILTER',
  GroupMultiSelect = 'GroupMultiSelect',
  EmptyFilterType= '',
}

export enum ClickType {
  DRILLDOWN = 'DRILLDOWN',
  SET_FILTER = 'SET_FILTER',
}

export enum UserFilterPayloadType {
  All = 'ALL',
  In = 'IN',
  NotIn = 'NOTIN',
}

export enum SelectBulkAction {
  None = -1,
  SelectAll,
  RemoveAll,
}

export enum UserMultiSelectFilterKeyType {
  GroupUsers = 'GroupUsers',
}

export enum MaxLimitDesc {
  AppliedFilter = 'Maximum Limit of filters ({{Filter limit}}) have been reached',
  AppliedDimension = 'Maximum Limit of fields {{limit}} have been reached',
  DrilldownField = 'Maximum Limit of fields {{limit}} have been reached',
  Values = 'Maximum Limit of values {{limit}} have been reached',
}

export enum NumberOfFiltersApplied {
  AppliedFilters = 'All except ({{difference}})',
}

export const TaggedInputFilterPlaceholder = 'Type here and enter...';
export const TaggedInputRestrictedChars = "* These characters cannot be used: `   ;   \\   '   \"   <   .   ";
export const TaggedInputMaxLength = '* You cannot search for a string greater than 256 characters in length.';
export const TaggedInputMaxItem = '* You can search for max 15 strings at a time.';

export interface AccordGroup<T> {
  groupId: string;
  headerName: string;
  headerIcon: string;
  accordValues: T[];
  emptyStateText?: string;
  allItemsAddedMessage?: string;
}

export const GroupFilterId = 'Group-Filter-Id';
