import { createAsyncThunk } from '@reduxjs/toolkit';
import { ICustomAnalyticsDashboardIds, ICustomAnalyticsPayload, ICustomAnalyticsReportIds } from 'core/models/custom-analytics';
import CustomAnalyticsService from 'services/custom-analytics.service';
import { CustomAnalyticsAction } from './custom-analytics.state';

export const getCustomAnalyticsForReports = createAsyncThunk(CustomAnalyticsAction.REQUEST_CUSTOM_ANALYTICS_REPORTS_LIST,
  async (payload: ICustomAnalyticsPayload) => new CustomAnalyticsService().getCustomAnalyticsForReports(payload));

export const getCustomAnalyticsForDashboards = createAsyncThunk(CustomAnalyticsAction.REQUEST_CUSTOM_ANALYTICS_DASHBOARDS_LIST,
  async (payload: ICustomAnalyticsPayload) => new CustomAnalyticsService().getCustomAnalyticsForDashboards(payload));

export const getCreatedByListForReports = createAsyncThunk(CustomAnalyticsAction.GET_CREATED_BY_LIST_REPORTS,
  async (reportId?: string) => new CustomAnalyticsService().getCreatedByListForReports(reportId));

export const getCreatedByListForDashboards = createAsyncThunk(CustomAnalyticsAction.GET_CREATED_BY_LIST_DASHBOARD,
  async (dashboardId: string) => new CustomAnalyticsService().getCreatedByListForDashboards(dashboardId));

export const updateReportOwner = createAsyncThunk(CustomAnalyticsAction.UPDATE_REPORT_OWNER,
  async (payload: ICustomAnalyticsReportIds) => new CustomAnalyticsService().updateReportOwner(payload));

export const updateDashboardOwner = createAsyncThunk(CustomAnalyticsAction.UPDATE_DASHBOARD_OWNER,
  async (payload: ICustomAnalyticsDashboardIds) => new CustomAnalyticsService().updateDashboardOwner(payload));
