// eslint-disable-next-line import/prefer-default-export
export const pageSizeOptions = [
  {
    label: '10',
    value: 10,
  },
  {
    label: '25',
    value: 25,
  },
  {
    label: '50',
    value: 50,
  },
  {
    label: '100',
    value: 100,
  },
];

export const PaginationKey = {
  PageIndex: 'PageIndex',
  PageSize: 'PageSize',
};

export const defaultPageValue = {
  PageIndex: 1,
  PageSize: 25,
};
