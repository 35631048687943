import React from 'react';

type Props = {
  width: number;
  height: number;
  fill?: string;
}

const BarChart = ({
  width, height, fill,
}: Props) => (
  <svg width={width} height={height} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M2 2V22C2 22.1105 2.08954 22.2 2.2 22.2H22.2"
      stroke={fill}
      strokeWidth="1.6"
      strokeLinecap="round"
      className="icon-stroke"
    />
    <path
      d="M10.0182 16.4947L12.4254 16.4947C12.4585 16.4947 12.4854 16.5215 12.4854 16.5547L12.4854 17.7283L12.4854 18.9019C12.4854 18.935 12.4585 18.9619 12.4254 18.9619L10.0182 18.9619L7.551 18.9619L5.14383 18.9619C5.11069 18.9619 5.08383 18.935 5.08383 18.9019L5.08383 17.7283L5.08383 16.5547C5.08383 16.5215 5.11069 16.4947 5.14383 16.4947L7.551 16.4947L10.0182 16.4947Z"
      stroke={fill}
      strokeWidth="1.6"
      strokeLinecap="round"
      className="icon-stroke"
    />
    <path
      d="M12.3829 10.9436L15.9722 10.9436C16.0054 10.9436 16.0322 10.9705 16.0322 11.0036L16.0322 12.1772L16.0322 13.3508C16.0322 13.3839 16.0054 13.4108 15.9722 13.4108L12.3829 13.4108L8.7335 13.4108L5.14414 13.4108C5.111 13.4108 5.08414 13.3839 5.08414 13.3508L5.08414 12.1772L5.08414 11.0036C5.08414 10.9705 5.111 10.9436 5.14414 10.9436L8.7335 10.9436L12.3829 10.9436Z"
      stroke={fill}
      strokeWidth="1.6"
      strokeLinecap="round"
      className="icon-stroke"
    />
    <path
      d="M14.336 5.39238L18.9019 5.39238C18.9351 5.39238 18.9619 5.41925 18.9619 5.45238L18.9619 6.62597L18.9619 7.79956C18.9619 7.8327 18.9351 7.85956 18.9019 7.85956L14.336 7.85956L9.71001 7.85956L5.14405 7.85956C5.11092 7.85956 5.08405 7.8327 5.08405 7.79956L5.08405 6.62597L5.08405 5.45238C5.08405 5.41925 5.11092 5.39238 5.14405 5.39238L9.71001 5.39238L14.336 5.39238Z"
      stroke={fill}
      strokeWidth="1.6"
      strokeLinecap="round"
      className="icon-stroke"
    />
  </svg>

);

BarChart.defaultProps = {
  fill: 'var(--labelColor)',
};

export default BarChart;
