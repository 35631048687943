import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
  CustomAnalyticsData, ICustomAnalyticsPayload, ICustomAnalyticsResponse,
  IOwnerResponse,
} from 'core/models/custom-analytics';
import { APIRequestStatus } from 'core/constants/redux';
import { isValidArrayAndNotEmpty } from 'components/feature/Report/ReportSidebar/common/helpers';
import { CustomAnalyticsAction, ICustomAnalyticsState } from './custom-analytics.state';
import {
  getCreatedByListForDashboards,
  getCreatedByListForReports, getCustomAnalyticsForDashboards, getCustomAnalyticsForReports,
  updateDashboardOwner,
  updateReportOwner,
} from './custom-analytics.requests';
import InitialCustomAnalyticsStoreState from './custom-analytics-initial-state';

const customAnalyticsSlice = createSlice({
  name: 'customAnalyticsStoreSlice',
  initialState: InitialCustomAnalyticsStoreState,
  reducers: {
    [CustomAnalyticsAction.UPDATE_SELECTED_TAB]: (state: ICustomAnalyticsState, action: PayloadAction<string>) => {
      state.customAnalyticsListing.selectedReports = [];
      state.customAnalyticsListing.selectedDashboards = [];
      state.customAnalyticsListing.loadingRows = [];
      state.customAnalyticsListing.reportCustomAnalyticsPayload = InitialCustomAnalyticsStoreState.customAnalyticsListing.reportCustomAnalyticsPayload;
      state.customAnalyticsListing.dashboardCustomAnalyticsPayload = InitialCustomAnalyticsStoreState.customAnalyticsListing.dashboardCustomAnalyticsPayload;
      state.requestProcessing[CustomAnalyticsAction.UPDATE_REPORT_OWNER] = APIRequestStatus.Processing;
      state.requestProcessing[CustomAnalyticsAction.UPDATE_DASHBOARD_OWNER] = APIRequestStatus.Processing;
      state.customAnalyticsListing.reportCustomAnalyticsPayload = InitialCustomAnalyticsStoreState.customAnalyticsListing.reportCustomAnalyticsPayload;
      state.customAnalyticsListing.dashboardCustomAnalyticsPayload = InitialCustomAnalyticsStoreState.customAnalyticsListing.dashboardCustomAnalyticsPayload;
      state.customAnalyticsListing.selectedTab = action.payload;
    },
    [CustomAnalyticsAction.UPDATE_CUSTOM_ANALYTICS_LIST_REPORT_PAYLOAD]: (state: ICustomAnalyticsState, action: PayloadAction<ICustomAnalyticsPayload>) => {
      state.customAnalyticsListing.selectedReports = [];
      state.customAnalyticsListing.selectedDashboards = [];
      state.customAnalyticsListing.reportCustomAnalyticsPayload = action.payload;
    },
    [CustomAnalyticsAction.UPDATE_CUSTOM_ANALYTICS_LIST_DASHBOARD_PAYLOAD]: (state: ICustomAnalyticsState, action: PayloadAction<ICustomAnalyticsPayload>) => {
      state.customAnalyticsListing.selectedReports = [];
      state.customAnalyticsListing.selectedDashboards = [];
      state.customAnalyticsListing.dashboardCustomAnalyticsPayload = action.payload;
    },
    [CustomAnalyticsAction.UPDATE_SELECTED_REPORTS]: (state: ICustomAnalyticsState, action: PayloadAction<Array<CustomAnalyticsData>>) => {
      state.customAnalyticsListing.selectedReports = action.payload;
    },
    [CustomAnalyticsAction.UPDATE_SELECTED_DASHBOARDS]: (state: ICustomAnalyticsState, action: PayloadAction<Array<CustomAnalyticsData>>) => {
      state.customAnalyticsListing.selectedDashboards = action.payload;
    },
    [CustomAnalyticsAction.SET_LOADING_ROWS]: (state: ICustomAnalyticsState, action: PayloadAction<Array<CustomAnalyticsData>>) => {
      state.customAnalyticsListing.loadingRows = action.payload;
    },
    [CustomAnalyticsAction.SET_DELETE_ID]: (state: ICustomAnalyticsState, action: PayloadAction<CustomAnalyticsData>) => {
      state.deleteData = action.payload;
    },
    [CustomAnalyticsAction.SET_EDIT_IDS]: (state: ICustomAnalyticsState, action: PayloadAction<Array<CustomAnalyticsData>>) => {
      state.editData = action.payload;
    },
    [CustomAnalyticsAction.OPEN_EDIT_MODAL]: (state: ICustomAnalyticsState, action: PayloadAction<boolean>) => {
      state.isEditModalOpen = action.payload;
    },
    [CustomAnalyticsAction.UPDATE_REPORT_OWNER]: (state: ICustomAnalyticsState) => {
      state.requestProcessing[CustomAnalyticsAction.UPDATE_REPORT_OWNER] = APIRequestStatus.Processing;
    },
    [CustomAnalyticsAction.UPDATE_DASHBOARD_OWNER]: (state: ICustomAnalyticsState) => {
      state.requestProcessing[CustomAnalyticsAction.UPDATE_DASHBOARD_OWNER] = APIRequestStatus.Processing;
    },
    [CustomAnalyticsAction.GET_CREATED_BY_LIST_REPORTS]: (state: ICustomAnalyticsState) => {
      state.requestProcessing[CustomAnalyticsAction.GET_CREATED_BY_LIST_REPORTS] = APIRequestStatus.Processing;
    },
    [CustomAnalyticsAction.GET_CREATED_BY_LIST_DASHBOARD]: (state: ICustomAnalyticsState) => {
      state.requestProcessing[CustomAnalyticsAction.GET_CREATED_BY_LIST_DASHBOARD] = APIRequestStatus.Processing;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getCustomAnalyticsForReports.pending, (state:ICustomAnalyticsState) => {
      state.requestProcessing[CustomAnalyticsAction.REQUEST_CUSTOM_ANALYTICS_REPORTS_LIST] = APIRequestStatus.Processing;
    });
    builder.addCase(getCustomAnalyticsForReports.fulfilled, (state:ICustomAnalyticsState, action: PayloadAction<ICustomAnalyticsResponse>) => {
      state.customAnalyticsListing.reportData = action.payload;
      state.requestProcessing[CustomAnalyticsAction.REQUEST_CUSTOM_ANALYTICS_REPORTS_LIST] = APIRequestStatus.Success;
    });
    builder.addCase(getCustomAnalyticsForReports.rejected, (state:ICustomAnalyticsState) => {
      state.requestProcessing[CustomAnalyticsAction.REQUEST_CUSTOM_ANALYTICS_REPORTS_LIST] = APIRequestStatus.Failure;
    });

    builder.addCase(getCustomAnalyticsForDashboards.pending, (state:ICustomAnalyticsState) => {
      state.requestProcessing[CustomAnalyticsAction.REQUEST_CUSTOM_ANALYTICS_DASHBOARDS_LIST] = APIRequestStatus.Processing;
    });
    builder.addCase(getCustomAnalyticsForDashboards.fulfilled, (state:ICustomAnalyticsState, action: PayloadAction<ICustomAnalyticsResponse>) => {
      state.customAnalyticsListing.dashboardData = action.payload;
      state.requestProcessing[CustomAnalyticsAction.REQUEST_CUSTOM_ANALYTICS_DASHBOARDS_LIST] = APIRequestStatus.Success;
    });
    builder.addCase(getCustomAnalyticsForDashboards.rejected, (state:ICustomAnalyticsState) => {
      state.requestProcessing[CustomAnalyticsAction.REQUEST_CUSTOM_ANALYTICS_DASHBOARDS_LIST] = APIRequestStatus.Failure;
    });

    builder.addCase(getCreatedByListForReports.pending, (state:ICustomAnalyticsState) => {
      state.requestProcessing[CustomAnalyticsAction.GET_CREATED_BY_LIST_REPORTS] = APIRequestStatus.Processing;
    });
    builder.addCase(getCreatedByListForReports.fulfilled, (state:ICustomAnalyticsState, action: PayloadAction<Array<IOwnerResponse>>) => {
      if (isValidArrayAndNotEmpty(action?.payload)) {
        state.customAnalyticsListing.createdByListOfReports = action.payload.map((value: IOwnerResponse) => ({
          label: value.DisplayName,
          value: value.Value,
          ownerUpdatedOn: value.OwnerUpdatedOn,
        }));
      } else {
        state.customAnalyticsListing.createdByListOfReports = [];
      }
      state.requestProcessing[CustomAnalyticsAction.GET_CREATED_BY_LIST_REPORTS] = APIRequestStatus.Success;
    });
    builder.addCase(getCreatedByListForReports.rejected, (state:ICustomAnalyticsState) => {
      state.requestProcessing[CustomAnalyticsAction.GET_CREATED_BY_LIST_REPORTS] = APIRequestStatus.Failure;
    });

    builder.addCase(getCreatedByListForDashboards.pending, (state:ICustomAnalyticsState) => {
      state.requestProcessing[CustomAnalyticsAction.GET_CREATED_BY_LIST_DASHBOARD] = APIRequestStatus.Processing;
    });
    builder.addCase(getCreatedByListForDashboards.fulfilled, (state:ICustomAnalyticsState, action: PayloadAction<Array<IOwnerResponse>>) => {
      if (isValidArrayAndNotEmpty(action?.payload)) {
        state.customAnalyticsListing.createdByListOfDashboards = action.payload.map((value: IOwnerResponse) => ({
          label: value.DisplayName,
          value: value.Value,
          ownerUpdatedOn: value.OwnerUpdatedOn,
        }));
      } else {
        state.customAnalyticsListing.createdByListOfDashboards = [];
      }
      state.requestProcessing[CustomAnalyticsAction.GET_CREATED_BY_LIST_DASHBOARD] = APIRequestStatus.Success;
    });
    builder.addCase(getCreatedByListForDashboards.rejected, (state:ICustomAnalyticsState) => {
      state.requestProcessing[CustomAnalyticsAction.GET_CREATED_BY_LIST_DASHBOARD] = APIRequestStatus.Failure;
    });

    builder.addCase(updateReportOwner.pending, (state: ICustomAnalyticsState) => {
      state.requestProcessing[CustomAnalyticsAction.UPDATE_REPORT_OWNER] = APIRequestStatus.Processing;
    });
    builder.addCase(updateReportOwner.fulfilled, (state: ICustomAnalyticsState) => {
      state.customAnalyticsListing.selectedReports = [];
      state.requestProcessing[CustomAnalyticsAction.UPDATE_REPORT_OWNER] = APIRequestStatus.Success;
    });
    builder.addCase(updateReportOwner.rejected, (state: ICustomAnalyticsState) => {
      state.customAnalyticsListing.selectedReports = [];
      state.customAnalyticsListing.loadingRows = [];
      state.requestProcessing[CustomAnalyticsAction.UPDATE_REPORT_OWNER] = APIRequestStatus.Failure;
    });

    builder.addCase(updateDashboardOwner.pending, (state: ICustomAnalyticsState) => {
      state.requestProcessing[CustomAnalyticsAction.UPDATE_DASHBOARD_OWNER] = APIRequestStatus.Processing;
    });
    builder.addCase(updateDashboardOwner.fulfilled, (state: ICustomAnalyticsState) => {
      state.customAnalyticsListing.selectedDashboards = [];
      state.requestProcessing[CustomAnalyticsAction.UPDATE_DASHBOARD_OWNER] = APIRequestStatus.Success;
    });
    builder.addCase(updateDashboardOwner.rejected, (state: ICustomAnalyticsState) => {
      state.customAnalyticsListing.selectedDashboards = [];
      state.customAnalyticsListing.loadingRows = [];
      state.requestProcessing[CustomAnalyticsAction.UPDATE_DASHBOARD_OWNER] = APIRequestStatus.Failure;
    });
  },
});

export const {
  [CustomAnalyticsAction.UPDATE_SELECTED_TAB]: updatedSelectedTab,
  [CustomAnalyticsAction.UPDATE_CUSTOM_ANALYTICS_LIST_REPORT_PAYLOAD]: updateCustomAnalyticsListReportPayload,
  [CustomAnalyticsAction.UPDATE_CUSTOM_ANALYTICS_LIST_DASHBOARD_PAYLOAD]: updateCustomAnalyticsListDashboardPayload,
  [CustomAnalyticsAction.UPDATE_SELECTED_REPORTS]: updateSelectedReports,
  [CustomAnalyticsAction.UPDATE_SELECTED_DASHBOARDS]: updateSelectedDashboards,
  [CustomAnalyticsAction.SET_LOADING_ROWS]: setLoadingRows,
  [CustomAnalyticsAction.SET_DELETE_ID]: setDeleteId,
  [CustomAnalyticsAction.SET_EDIT_IDS]: setEditIds,
  [CustomAnalyticsAction.OPEN_EDIT_MODAL]: openEditModal,
  [CustomAnalyticsAction.UPDATE_REPORT_OWNER]: resetUpdateReportOwnerRequestState,
  [CustomAnalyticsAction.UPDATE_DASHBOARD_OWNER]: resetUpdateDashboardOwnerRequestState,
  [CustomAnalyticsAction.GET_CREATED_BY_LIST_REPORTS]: resetGetCreatedByReportsRequestState,
  [CustomAnalyticsAction.GET_CREATED_BY_LIST_DASHBOARD]: resetGetCreatedByDashboardsRequestState,
} = customAnalyticsSlice.actions;

export default customAnalyticsSlice;
