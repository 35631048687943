import { APIRequestStatus } from 'core/constants/redux';
import {
  IReportListingState,
  ListingSection, ObjectActionType, ReportFilterValues, ReportListingActionTypes, ReportPinningFliter, SourceTypes, Types,
} from './report-listing.state';

const initialReportListingState: IReportListingState = {
  requestProcessing: {
    [ReportListingActionTypes.GET_ALL_REPORTS_REQUEST]: APIRequestStatus.Success,
    [ReportListingActionTypes.GET_EXTERNAL_REPORT_DETAILS_REQUEST]: APIRequestStatus.Success,
    [ReportListingActionTypes.GET_TAGS_BY_OBJECT_REQUEST]: APIRequestStatus.Success,
    [ReportListingActionTypes.GET_ITEMS_BY_TAG_REQUEST]: APIRequestStatus.Success,
    [ReportListingActionTypes.GET_DASHBOARDS_LIST_REQUEST]: APIRequestStatus.Success,
    [ReportListingActionTypes.EDIT_TAGS_BY_OBJECTID_REQUEST]: APIRequestStatus.Processing,
    [ReportListingActionTypes.PIN_UNPIN_REPORTS_REQUEST]: APIRequestStatus.Processing,
    [ReportListingActionTypes.GET_HOME_DASHBOARDS_REQUEST]: APIRequestStatus.AboutToLoad,
    [ReportListingActionTypes.PIN_UNPIN_DASHBOARDS_REQUEST]: APIRequestStatus.Processing,
    [ReportListingActionTypes.GET_PINNED_DASHBOARDS_REQUEST]: APIRequestStatus.Success,
    [ReportListingActionTypes.GET_ALL_REPORTS_SEARCH_REQUEST]: APIRequestStatus.Success,
    [ReportListingActionTypes.V1REPORTS_ACCESS_CONTROLL_REQUEST]: APIRequestStatus.Processing,
    [ReportListingActionTypes.GET_ALL_DASHBOARDS_SEARCH_REQUEST]: APIRequestStatus.Success,
    [ReportListingActionTypes.GET_SAVED_REPORT_DETAILS_REQUEST]: APIRequestStatus.Processing,
    [ReportListingActionTypes.GET_SAVED_REPORT_DETAILS_SUCCESS]: APIRequestStatus.Success,
    [ReportListingActionTypes.GET_PINNED_REPORTS_REQUEST]: APIRequestStatus.Processing,
    [ReportListingActionTypes.GET_PINNED_REPORTS_FAILURE]: APIRequestStatus.Failure,
  },
  allReportSections: null,
  filteredReportSections: null,
  filterValue: ReportFilterValues.AllReports,
  viewerReport: null,
  externalReportDetails: {
    Id: '',
    Name: '',
    Description: '',
    Type: Types.Internal,
    SourceType: SourceTypes.SIERA,
    SourceInfo: {
      Id: '',
      IsCustom: false,
      Route: '',
      URL: '',
      CreatedOn: '',
      ModifiedOn: '',
    },
    IsPinned: true,
    IsPinnedInProfile: false,
    Tags: [],
  },
  selectedNavBarItem: '',
  globaltags: [],
  actionInfo: {
    ObjectId: '',
    ObjectType: ObjectActionType.Report,
    Id: '',
    SourceType: SourceTypes.SIERA,
    Type: Types.Internal,
  },
  reportTagsbasedOnProfile: null,
  dashboardTagsbasedOnProfile: [],
  activeEditTagsList: [],
  appliedEditTagsList: [],
  allReports: [],
  tagCategoriesReports: [],
  selectedSection: ListingSection.Report,
  allDashboards: [],
  allDashboardsSection: [],
  pinnedCategories: [],
  pinningFilterValue: ReportPinningFliter.AllPinned,
  homeDashboardFirstLoad: true,
  tagDashboards: [],
  homeDashboards: [],
  selectedHomeDashboard: null,
  redirectAllDashboard: true,
  globalSearchresults: [],
  globalSearchDashboardResults: [],
};

export default initialReportListingState;
