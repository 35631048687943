import { MySpace } from 'components/feature/Report/ReportSidebar/common/constants';
import { LoadingStatus } from 'core/constants/dashboard';
import {
  ProfileVisibleOption,
  ProfileCompletedActions,
} from 'core/models/profile';

import { APIRequestStatus } from 'core/constants/redux';
import { IProfileState, ProfileActions } from 'redux-v2/profile/profile.state';
import { ListingSection } from 'redux-v2/report-listing/report-listing.state';
import { ReportTypes } from 'core/constants/common';

const initialDashboardState: IProfileState = {
  requestProcessing: {
    [ProfileActions.GET_PROFILE_INFO_BY_PROFILE_ID]: APIRequestStatus.AboutToLoad,
    [ProfileActions.GET_VISIBILITY_VALUES]: APIRequestStatus.AboutToLoad,
  },
  profileListing: {
    reportSections: [],
    dashboardSections: [],
    reportSearchKey: '',
    dashboardSearchKey: '',
    selectedProfileTab: ListingSection.Report,
    isProfileAllReportSelected: false,
    isProfileAllDashboardSelected: false,
    loadingStatus: LoadingStatus.Loading,
    dashboardListLoadingStatus: LoadingStatus.Loading,
    isProfileAllPinned: false,
    isAllLinkedReportsSelected: false,
  },
  profileDetailInfo: {
    accord: {},
    selectedAccord: {},
    loadingStatus: LoadingStatus.Loading,
    profileVisibleConfig: [],
  },
  profileForm: {
    loadingStatus: LoadingStatus.Success,
    isDisabled: true,
    value: {
      Name: '',
      VisibilityMode: ProfileVisibleOption.SpecificSet,
      Accord: {},
      Reports: [],
      Dashboards: [],
      IsAllPinned: false,
      SelectedReportTypes: ReportTypes.map((type) => type.value),
    },
  },
  apiProfileFormValue: {
    Name: '',
    VisibilityMode: ProfileVisibleOption.SpecificSet,
    Accord: {},
    Reports: [],
    Dashboards: [],
    IsAllPinned: false,
    SelectedReportTypes: ReportTypes.map((type) => type.value),
  },
  availableProfiles: [],
  completedProfileAction: ProfileCompletedActions.NONE,
  selectedProfileId: MySpace,
  profileListLoading: LoadingStatus.Success,
  isEditMode: false,
  profileDeleteLoading: LoadingStatus.Loading,
  deleteId: '',
  selectedReportTypes: ReportTypes.map((type) => type.value),
};

export default initialDashboardState;
