import { ReportV2Icons } from 'components/feature/SideNavbarV2/sidebar-section-constants';
import { FeatureContext } from 'core/constants/common';
import { APIRequestStatus } from 'core/constants/redux';
import {
  IDashboardVisualization,
  IPanel,
  ITitleSection,
} from 'core/models/dashboard';
import { IFilterConfig } from 'core/models/report-response';

export interface IReportListingState {
  requestProcessing: IRequestProcessing;
  allReportSections: Array<IReportSection>;
  filteredReportSections: Array<IReportSection>;
  filterValue: ReportFilterValues;
  viewerReport: IReportData;
  externalReportDetails: IReportData;
  selectedNavBarItem: string;
  actionInfo: ActionInfoObject;
  globaltags: Array<ITagsData>;
  reportTagsbasedOnProfile: Array<ITagsData>;
  dashboardTagsbasedOnProfile: Array<ITagsData>;
  activeEditTagsList: Array<IEditTagsData>;
  appliedEditTagsList: Array<IEditTagsData>;
  allReports: Array<IReportData>;
  tagCategoriesReports: Array<IReportSection>;
  selectedSection: ListingSection;
  allDashboards: Array<IDashboardItem>;
  allDashboardsSection: Array<IReportSection>;
  pinnedCategories: Array<IReportSection>;
  pinningFilterValue: ReportPinningFliter;
  homeDashboardFirstLoad: boolean;
  tagDashboards: IDashboardItem[];
  homeDashboards: IDashboardItem[];
  selectedHomeDashboard: IDashboardItem;
  redirectAllDashboard: boolean;
  globalSearchresults: Array<IReportData>;
  globalSearchDashboardResults: Array<IReportData>;
}

export interface ISourceInfo {
  Id: string;
  IsCustom: boolean;
  Route?: string;
  URL?: string;
  CreatedOn: string;
  ModifiedOn: string;
}

export interface IRequestProcessing {
  [ReportListingActionTypes.GET_ALL_REPORTS_REQUEST]: APIRequestStatus;
  [ReportListingActionTypes.GET_EXTERNAL_REPORT_DETAILS_REQUEST]: APIRequestStatus;
  [ReportListingActionTypes.GET_TAGS_BY_OBJECT_REQUEST]: APIRequestStatus;
  [ReportListingActionTypes.GET_ITEMS_BY_TAG_REQUEST]: APIRequestStatus;
  [ReportListingActionTypes.GET_DASHBOARDS_LIST_REQUEST]: APIRequestStatus;
  [ReportListingActionTypes.EDIT_TAGS_BY_OBJECTID_REQUEST]: APIRequestStatus;
  [ReportListingActionTypes.PIN_UNPIN_REPORTS_REQUEST]: APIRequestStatus;
  [ReportListingActionTypes.GET_HOME_DASHBOARDS_REQUEST]: APIRequestStatus;
  [ReportListingActionTypes.PIN_UNPIN_DASHBOARDS_REQUEST]: APIRequestStatus;
  [ReportListingActionTypes.GET_PINNED_DASHBOARDS_REQUEST]: APIRequestStatus;
  [ReportListingActionTypes.GET_ALL_REPORTS_SEARCH_REQUEST]: APIRequestStatus;
  [ReportListingActionTypes.V1REPORTS_ACCESS_CONTROLL_REQUEST]: APIRequestStatus;
  [ReportListingActionTypes.GET_ALL_DASHBOARDS_SEARCH_REQUEST]: APIRequestStatus;
  [ReportListingActionTypes.GET_SAVED_REPORT_DETAILS_REQUEST]: APIRequestStatus;
  [ReportListingActionTypes.GET_SAVED_REPORT_DETAILS_SUCCESS]: APIRequestStatus;
  [ReportListingActionTypes.GET_PINNED_REPORTS_REQUEST]: APIRequestStatus;
  [ReportListingActionTypes.GET_PINNED_REPORTS_FAILURE]: APIRequestStatus;
}

export interface IReportData {
  Id: string;
  Name: string;
  Description: string;
  Type: Types;
  SourceType: SourceTypes;
  IsPinned: boolean;
  IsPinnedInProfile: boolean;
  SourceInfo?: ISourceInfo;
  Tags: string[];
  AdjoinedByDashboard?: boolean;
  isProfileReportSelected?: boolean;
  IsSieraLinked?: boolean;
  SieraLinkedReportId?: string;// if IsSieraLinked comes as true, we will have Report Id set here
  FeatureContext?:FeatureContext
}

export enum Types {
  Internal = 'Internal',
  External = 'External',
  System ='System',
  Custom ='Custom',
}

export enum SourceTypes {
  SIERA = 'SIERA',
  LOGI = 'Logi',
  LSQInternal = 'LSQInternal',
  LogiMobile = 'LogiMobile'
}

export enum ReportFilterValues {
  AllReports = 'all_reports',
  SieraReports = 'siera_reports',
  V1Reports = 'v1_reports',
}

export enum ListingSection {
  Report = 'Reports',
  Dashboard = 'Dashboards',
}

export interface IDeleteProfilePayload {
  profileId: string;
  isV1Report: boolean;
}

export enum ObjectActionType {
  Report = 'Report',
  Dashboard = 'Dashboard',
}

export interface IReports {
  Data: Array<IReportData>;
}

export interface IReportSection {
  SectionId: string;
  DisplayName: string;
  TotalCount: number;
  Reports: IReports;
  Dashboards: IDashboards;
  isProfileSectionSelected?: boolean;
  isSectionLinkedReportsSelected?: boolean;
}

export interface ITagsData {
  Id: string;
  Name: string;
  Colour: string;
}

export interface IReportsByTag {
  Tag: string;
  Data: Array<string>;
}

export interface ITagsByPofile {
  TagMetaData: Array<ITagsData>;
  ReportTags: Array<string>;
  DashboardTags: Array<string>;
}

export interface IEditTagsData {
  Id: string;
  Name?: string;
  Colour?: string;
  checked: boolean;
}

export interface IDashboardItem {
  IsHome: boolean;
  IsHomeInProfile: boolean;
  IsPersonalized: boolean;
  IsPinned: boolean;
  IsPinnedInProfile: boolean;
  Reports: Array<String>;
  TitleSection: ITitleSection;
  Id: string;
  Tags: Array<string>;
  Type:Types;
}

export interface IEditTagPayload {
  ObjectId: string;
  ObjectType: ObjectActionType;
  Id: string;
  SourceType: SourceTypes;
  Type: Types;
  Tags: Array<string>;
  Untags: Array<string>;
}

export interface IDashboards {
  Data: IDashboardItem[];
}

export interface ActionInfoObject {
  ObjectId: string;
  ObjectType: ObjectActionType;
  Id: string;
  SourceType: SourceTypes;
  Type: Types;
  globalTags?: ITagsData[];
  sectionItem?: string;
}

export interface TagByObject {
  Tags: Array<string>;
  TagMetaData: Array<ITagsData>;
}

export enum SelectedSection {
  Pinned = 'pinned',
  All = 'all',
  Tags = 'tag',
  Home = 'home'
}

export interface SectionItemInfo {
  Icon: ReportV2Icons;
  Name: string;
  className: string[];
}
export interface PinUnpinPayload {
  Id: string;
  IsPinned: boolean;
}

export enum ReportPinningFliter {
  AllPinned = 'all_pinned',
  PinnedByMe = 'pinned_by_me',
  PinnedForMe = 'pinned_for_me',
}

export interface IDashboardSection {
  SectionId: string;
  DisplayName: string;
  TotalCount: number;
  Dashboards: IDashboardData;
  isProfileSectionSelected?: boolean;
  Reports: IReports
}

export interface IDashboardData {
  Data: IDashboardListData[];
}

export interface IDashboardListData {
  Id: string;
  Name?: string;
  Description?: string;
  TitleSection: ITitleSection;
  Panels: Array<IPanel>;
  Visualization: IDashboardVisualization;
  Filters: Array<IFilterConfig>;
  EnabledOn: string;
  IsOpportunityRelated: boolean;
  Tags: Array<string>;
  Reports: Array<IDashboardLinkedReport>;
  IsPinnedInProfile: boolean;
  isDashboardSelected: boolean;
  IsHomeInProfile: boolean;
  Type: Types;
}

export interface IDashboardLinkedReport {
  Id: string;
}

export enum ListingMode {
  listing = 'listing',
  create = 'create',
  edit = 'edit',
}

export interface IOption {
  Id: string,
  Type?: string,
  SourceType?: SourceTypes,
  IsHome?: boolean;
  IsHomeInProfile?: boolean;
}

export interface IReportOrDashboardItem {
  Id: string;
  Name?: string;
  Tags: string[];
  TitleSection?: ITitleSection;
  ype?: string,
  SourceType?: SourceTypes,
  IsHome?: boolean;
  IsHomeInProfile?: boolean;
}

export enum ReportListingActionTypes {
    GET_ALL_REPORTS_REQUEST = 'GET_ALL_REPORTS_REQUEST',
    GET_ALL_REPORTS_SUCCESS = 'GET_ALL_REPORTS_SUCCESS',
    GET_ALL_REPORTS_FAILURE = 'GET_ALL_REPORTS_FAILURE',
    GET_EXTERNAL_REPORT_DETAILS_REQUEST = 'GET_EXTERNAL_REPORT_DETAILS_REQUEST',
    GET_EXTERNAL_REPORT_DETAILS_SUCCESS = 'GET_EXTERNAL_REPORT_DETAILS_SUCCESS',
    GET_EXTERNAL_REPORT_DETAILS_FAILURE = 'GET_EXTERNAL_REPORT_DETAILS_FAILURE',
    SET_REPORT_FILTER_VALUE = 'SET_REPORT_FILTER_VALUE',
    GET_ALL_TAGS_SUCCESS = 'GET_ALL_TAGS_SUCCESS',
    GET_ALL_TAGS_FAILURE = 'GET_ALL_TAGS_FAILURE',
    SET_REPORT_ACTION_REPORTID = 'SET_REPORT_ACTION_REPORTID',
    GET_TAGS_BY_USER_SUCCESS = 'GET_TAGS_BY_USER_SUCCESS',
    GET_TAGS_BY_USER_FAILURE = 'GET_TAGS_BY_USER_FAILURE',
    GET_ITEMS_BY_TAG_REQUEST = 'GET_ITEMS_BY_TAG_REQUEST',
    GET_ITEMS_BY_TAG_SUCCESS = 'GET_ITEMS_BY_TAG_SUCCESS',
    GET_ITEMS_BY_TAG_FAILURE = 'GET_ITEMS_BY_TAG_FAILURE',
    SELECT_OR_DESELECT_TAGS = 'SELECT_OR_DESELECT_TAGS',
    GET_DASHBOARDS_LIST_REQUEST = 'GET_DASHBOARDS_LIST_REQUEST',
    GET_DASHBOARDS_LIST_SUCCESS = 'GET_DASHBOARDS_LIST_SUCCESS',
    GET_DASHBOARDS_LIST_FAILURE = 'GET_DASHBOARDS_LIST_FAILURE',
    EDIT_TAGS_BY_OBJECTID_REQUEST = 'EDIT_TAGS_BY_OBJECTID_REQUEST',
    EDIT_TAGS_BY_OBJECTID_SUCCESS = 'EDIT_TAGS_BY_OBJECTID_SUCCESS',
    EDIT_TAGS_BY_OBJECTID_FAILURE = 'EDIT_TAGS_BY_OBJECTID_FAILURE',
    GET_TAGS_BY_OBJECT_REQUEST = 'GET_TAGS_BY_OBJECT_REQUEST',
    GET_TAGS_BY_OBJECT_SUCCESS = 'GET_TAGS_BY_OBJECT_SUCCESS',
    GET_TAGS_BY_OBJECT_FAILURE = 'GET_TAGS_BY_OBJECT_FAILURE',
    LOAD_TAG_ANIMATION = 'LOAD_TAG_ANIMATION',
    TAG_ANIMATION_COMPLETE = 'TAG_ANIMATION_COMPLETE',
    UPDATE_REPORT_BASED_ON_TAGS = 'UPDATE_REPORT_BASED_ON_TAGS',
    GET_PINNED_REPORTS_REQUEST = 'GET_PINNED_REPORTS_REQUEST',
    GET_PINNED_REPORTS_SUCCESS = 'GET_PINNED_REPORTS_SUCCESS',
    GET_PINNED_REPORTS_FAILURE = 'GET_PINNED_REPORTS_FAILURE',
    PIN_UNPIN_REPORTS_REQUEST = 'PIN_UNPIN_REPORTS_REQUEST',
    PIN_UNPIN_REPORTS_SUCCESS = 'PIN_UNPIN_REPORTS_SUCCESS',
    PIN_UNPIN_REPORTS_FAILURE = 'PIN_UNPIN_REPORTS_FAILURE',
    SET_REPORT_PINNED_FILTER_VALUE = 'SET_REPORT_PINNED_FILTER_VALUE',
    HOME_DASHBOARD_LOADED = 'HOME_DASHBOARD_LOADED',
    GET_DASHBOARD_BY_TAGS_REQUEST = 'GET_DASHBOARD_BY_TAGS_REQUEST',
    GET_DASHBOARD_BY_TAGS_SUCCESS = 'GET_DASHBOARD_BY_TAGS_SUCCESS',
    GET_DASHBOARD_BY_TAGS_FAILURE = 'GET_DASHBOARD_BY_TAGS_FAILURE',
    GET_HOME_DASHBOARDS_REQUEST = 'GET_HOME_DASHBOARDS_REQUEST',
    GET_HOME_DASHBOARDS_SUCCESS = 'GET_HOME_DASHBOARDS_SUCCESS',
    GET_HOME_DASHBOARDS_FAILURE = 'GET_HOME_DASHBOARDS_FAILURE',
    SET_SELECTED_HOME_DASHBOARD = 'SET_SELECTED_HOME_DASHBOARD',
    SET_REDIRECT_ALL_DASHBOARDS = 'SET_REDIRECT_ALL_DASHBOARDS',
    PIN_UNPIN_DASHBOARDS_REQUEST = 'PIN_UNPIN_DASHBOARDS_REQUEST',
    PIN_UNPIN_DASHBOARDS_SUCCESS = 'PIN_UNPIN_DASHBOARDS_SUCCESS',
    PIN_UNPIN_DASHBOARDS_FAILURE = 'PIN_UNPIN_DASHBOARDS_FAILURE',
    GET_PINNED_DASHBOARDS_REQUEST = 'GET_PINNED_DASHBOARDS_REQUEST',
    GET_PINNED_DASHBOARDS_SUCCESS = 'GET_PINNED_DASHBOARDS_SUCCESS',
    GET_PINNED_DASHBOARDS_FAILURE = 'GET_PINNED_DASHBOARDS_FAILURE',
    GET_ALL_REPORTS_SEARCH_REQUEST = 'GET_ALL_REPORTS_SEARCH_REQUEST',
    GET_ALL_REPORTS_SEARCH_SUCCESS = 'GET_ALL_REPORTS_SEARCH_SUCCESS',
    GET_ALL_REPORTS_SEARCH_FAILURE = 'GET_ALL_REPORTS_SEARCH_FAILURE',
    V1REPORTS_ACCESS_CONTROLL_REQUEST = 'V1REPORTS_ACCESS_CONTROLL_REQUEST',
    V1REPORTS_ACCESS_CONTROLL_SUCCESS = 'V1REPORTS_ACCESS_CONTROLL_SUCCESS',
    V1REPORTS_ACCESS_CONTROLL_FAILURE = 'V1REPORTS_ACCESS_CONTROLL_FAILURE',
    GET_ALL_DASHBOARDS_SEARCH_REQUEST = 'GET_ALL_DASHBOARDS_SEARCH_REQUEST',
    GET_ALL_DASHBOARDS_SEARCH_SUCCESS = 'GET_ALL_DASHBOARDS_SEARCH_SUCCESS',
    GET_ALL_DASHBOARDS_SEARCH_FAILURE = 'GET_ALL_DASHBOARDS_SEARCH_FAILURE',
    GET_SAVED_REPORT_DETAILS_SUCCESS = 'GET_SAVED_REPORT_DETAILS_SUCCESS',
    GET_SAVED_REPORT_DETAILS_FAILURE = 'GET_SAVED_REPORT_DETAILS_FAILURE',
    GET_SAVED_REPORT_DETAILS_REQUEST = 'GET_SAVED_REPORT_DETAILS_REQUEST',
    RESET_TAG_REQUEST_STATE = 'RESET_TAG_REQUEST_STATE',
  }

export interface IReportPayload {
    section: SelectedSection,
    profileId: string,
    tagId: string,
    mode?: string,
    sectionItem?:string,
    globalTags?: ITagsData[]
}

export interface IReportExternalRequest {
    reportId: string,
    sourceType: string
}
