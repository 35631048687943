import { APIRequestStatus } from 'core/constants/redux';
import { ListingSection } from 'redux-v2/report-listing/report-listing.state';
import { Direction } from 'core/models/custom-analytics';
import { defaultPageValue } from 'core/constants/pagination';
import { defaultSortOnField } from 'components/feature/CustomAnalytics/custom-analytics.utils';
import { CustomAnalyticsAction, ICustomAnalyticsState } from './custom-analytics.state';

const InitialCustomAnalyticsStoreState: ICustomAnalyticsState = {
  requestProcessing: {
    [CustomAnalyticsAction.REQUEST_CUSTOM_ANALYTICS_LIMITS]: APIRequestStatus.Processing,
    [CustomAnalyticsAction.REQUEST_CUSTOM_ANALYTICS_REPORTS_LIST]: APIRequestStatus.Processing,
    [CustomAnalyticsAction.REQUEST_CUSTOM_ANALYTICS_DASHBOARDS_LIST]: APIRequestStatus.Processing,
    [CustomAnalyticsAction.GET_CREATED_BY_LIST_REPORTS]: APIRequestStatus.Processing,
    [CustomAnalyticsAction.GET_PRODUCT_LINE_LIST_REPORTS]: APIRequestStatus.Processing,
    [CustomAnalyticsAction.GET_DATASET_LIST_REPORTS]: APIRequestStatus.Processing,
    [CustomAnalyticsAction.GET_CREATED_BY_LIST_DASHBOARD]: APIRequestStatus.Processing,
    [CustomAnalyticsAction.UPDATE_REPORT_OWNER]: APIRequestStatus.Processing,
    [CustomAnalyticsAction.UPDATE_DASHBOARD_OWNER]: APIRequestStatus.Processing,

  },
  customAnalyticsListing: {
    reportData: {
      Data: [],
      HasNextPage: false,
      TotalRows: 0,
    },
    dashboardData: {
      Data: [],
      HasNextPage: false,
      TotalRows: 0,
    },
    selectedTab: ListingSection.Report,
    reportCustomAnalyticsPayload: {
      Page: {
        PageIndex: defaultPageValue.PageIndex,
        PageSize: defaultPageValue.PageSize,
      },
      Filters: {
        Search: '',
      },
      Sort: [{
        Field: defaultSortOnField,
        Direction: Direction.DESC,
      }],
    },
    dashboardCustomAnalyticsPayload: {
      Page: {
        PageIndex: defaultPageValue.PageIndex,
        PageSize: defaultPageValue.PageSize,
      },
      Filters: {
        Search: '',
      },
      Sort: [{
        Field: defaultSortOnField,
        Direction: Direction.DESC,
      }],
    },
    createdByListOfReports: [],
    createdByListOfDashboards: [],
    selectedReports: [],
    selectedDashboards: [],
    loadingRows: [],
  },
  deleteData: null,
  editData: [],
  isEditModalOpen: false,
};

export default InitialCustomAnalyticsStoreState;
