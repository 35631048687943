import React from 'react';

type Props = {
  width: number;
  height: number;
  fill?: string;
}

const BookMarkReportIcon = ({
  width, height, fill,
}: Props) => (
  <svg
    style={{
      width,
      height,
    }}
    viewBox="0 0 22 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M16.6666 5.00004H9.99996L8.33329 3.33337H3.33329C2.41663 3.33337 1.66663 4.08337 1.66663 5.00004V15C1.66663 15.9167 2.41663 16.6667 3.33329 16.6667H16.6666C17.5833 16.6667 18.3333 15.9167 18.3333 15V6.66671C18.3333 5.75004 17.5833 5.00004 16.6666 5.00004ZM16.6666 15H3.33329V5.00004H7.64162L9.30829 6.66671H16.6666V15Z" fill={fill} />
    <circle cx="16" cy="13" r="6" fill="white" />
    <path d="M18.2222 8.5H12.8889C12.3956 8.5 12 8.9 12 9.38889V15.6111C12 16.1 12.3956 16.5 12.8889 16.5H19.1111C19.6 16.5 20 16.1 20 15.6111V10.2778L18.2222 8.5ZM16 15.6111C15.2622 15.6111 14.6667 15.0156 14.6667 14.2778C14.6667 13.54 15.2622 12.9444 16 12.9444C16.7378 12.9444 17.3333 13.54 17.3333 14.2778C17.3333 15.0156 16.7378 15.6111 16 15.6111ZM17.3333 11.1667H12.8889V9.38889H17.3333V11.1667Z" fill={fill} />
  </svg>

);

BookMarkReportIcon.defaultProps = {
  fill: 'var(--siennaColor)',
};

export default BookMarkReportIcon;
