import localizationObjectEnUs from 'components/common/Localization/localization.en-us';
import { langCode } from 'core/constants/auth';

export interface LocalizationState {
    localizationObject: Object;
    language: string;
  }

export const initialLocalizationState: LocalizationState = {
  localizationObject: localizationObjectEnUs,
  language: langCode.English,
};

export enum LocalizationAction {
    ADD_LOCALIZATION_OBJECT = '[Localization] ADD_LOCALIZATION_OBJECT',
    SET_LANGUAGE_CODE = '[Localization GET_LANGUAGE_CODE]',
  }
