import { LocalizationState } from 'redux-v2/localization/localization.initial-state';
import store from 'redux-v2/store';

const useLocalize = (str: string, values?: Array<string>) => {
  const localizationStore: LocalizationState = store.getState().Localization;
  const { localizationObject } = localizationStore;

  const [categoryName, keyName] = str.split('.');
  if (categoryName && localizationObject && Object.prototype.hasOwnProperty.call(localizationObject, categoryName)) {
    if (keyName && Object.prototype.hasOwnProperty.call(localizationObject[`${categoryName}` as keyof Object], keyName)) {
      if (values) {
        let localStr = `${localizationObject[categoryName as keyof Object][keyName as keyof Object]}`;
        values.forEach((item: string, index: number) => {
          localStr = localStr.replace(`{${index}}`, item);
        });
        return localStr;
      }
      return localizationObject[categoryName as keyof Object][keyName as keyof Object];
    }
    return `Couldn't find ${keyName} in ${categoryName} of localization Database`;
  }
  return `Couldn't find ${str} in localization Database`;
};

export default useLocalize;
