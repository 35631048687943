/* eslint-disable import/prefer-default-export */
/* eslint-disable no-shadow */
export enum URLHash {
    Report = 'report',
    Dashboard = 'dashboard',
    ReportV2 = 'reportv2',
    DashboardV2 ='dashboardv2',
    REPORTBUILDER='report-builder',
}

export enum SIERAPage {
    LISTING = 'report-list',
    REPORT='report',
    REPORTVIEWER='reportviewer',
    DASHBOARD='dashboard',
    UNAUTHORIZED='unauthorized',
    PROFILEDETAIL='profile-detail',
    REPORTV2='reportV2',
    REPORTBUILDER='report-builder',
    REPORTEDIT = 'report-edit',
    DASHBOARDBUILDER = 'dashboard-builder',
    ANALYTICS='analytics'
}

export const tenantManagementRedirectionUrl = `${process.env.REACT_APP_LSQ_URL}a/ManageSubscriptions`;
