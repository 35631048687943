import {
  ICustomAnalyticsDashboardIds, ICustomAnalyticsPayload, ICustomAnalyticsReportIds, ICustomAnalyticsResponse,
  IOwnerResponse,
} from 'core/models/custom-analytics';
import API from './api';

export default class CustomAnalyticsService extends API {
    static baseURL: string = process.env.REACT_APP_DATA_BASE_URL || '';

    constructor() {
      super(CustomAnalyticsService.baseURL, true, true);
    }

    getCustomAnalyticsForReports = async (payload : ICustomAnalyticsPayload): Promise<ICustomAnalyticsResponse> => this.responsify(
      await this.instance.post(
        '/reports/get-report-list', payload,
      ),
    );

    getCustomAnalyticsForDashboards = async (payload : ICustomAnalyticsPayload): Promise<ICustomAnalyticsResponse> => this.responsify(
      await this.instance.post(
        '/dashboards/get-dashboard-list', payload,
      ),
    );

    getCreatedByListForReports = async (reportId?: string): Promise<Array<IOwnerResponse>> => this.responsify(
      await this.instance.get(
        'reports/get-created-by-list',
        {
          params: {
            reportId,
          },
        },
      ),
    );

    getCreatedByListForDashboards = async (dashboardId?: string): Promise<Array<IOwnerResponse>> => this.responsify(
      await this.instance.get(
        'dashboards/get-created-by-list',
        {
          params: {
            dashboardId,
          },
        },
      ),
    );

    updateReportOwner = async (payload: ICustomAnalyticsReportIds): Promise<ICustomAnalyticsReportIds> => this.responsify(
      await this.instance.post(
        'reports/update-report-owner', payload,
      ),
    );

    updateDashboardOwner = async (payload: ICustomAnalyticsDashboardIds): Promise<ICustomAnalyticsDashboardIds> => this.responsify(
      await this.instance.post(
        'dashboards/update-dashboard-owner', payload,
      ),
    );
}
