import { IChartLabels } from 'components/feature/Report/ReportSidebar/ChartsVisualization/chart-visualization.type';

/* eslint-disable no-shadow */
export enum VisualizationTypes{
  Table='Table',
  ColumnChart='ColumnChart',
  LineChart='LineChart',
  AreaChart='AreaChart',
  BarChart='BarChart',
  SummaryTable= 'summary',
  MultiLineTable= 'multiline',
  NoData='Nodata',
  PieChart='PieChart',
  DonutChart='DonutChart',
  KPI='KPI',
  EmbeddedUrl='EmbeddedUrl'
}

export enum StackedTypes{
  Default='',
  Stacked='normal',
  Percent='percent'
}

export const Messages = {
  NoData: 'There are no data to display for the selected filter. Try adjusting the filter for what you are looking for.',
};

export const AdditionalDimensionEmptyState = {
  Fields: 'No fields are currently available in this section.',
  Filters: 'No filters are currently available in this section.',
};

export const AdditionalDimensionNoResultState = {
  Fields: 'No fields found.',
  Filters: 'No filters found.',
};

export const SearchBarPlaceHolder = {
  Fields: 'Search Fields',
  Filters: 'Search Filters',
  Values: 'Search Fields and Values',
};

export const NoDimensionDataMessages = {
  NoSearches: 'No Items Found.',
  AllColumnAdded: 'All columns have been added',
  AllFiltersAdded: 'All filters have been added',
  AllMeasuresAdded: 'All measures have been added',
};

export const ChartDefaultcolors = ['#379FE7', '#1F70B5', '#013E75', '#90ECD3', '#62D1D2', '#E5A893', '#DC7A6E', '#CC57AF', '#A355C8', '#7343B9'];

export const NoExportState = {
  Export: 'No Export Yet.',
};

export enum VisualizationLabels {
  NoChart = 'noChart',
  Bar = 'bar',
  Column = 'column',
  Line = 'line',
  Area = 'area',
  Pie = 'pie',
  Donut = 'donut',
}

export const chartLabels: IChartLabels = {
  LineChart: 'stackedLine',
  BarChart: 'stackedBar',
  AreaChart: 'stackedArea',
  ColumnChart: 'stackedColumn',
};

export enum ChartAttributesKey {
  Column = 'Column',
  Pie = 'Pie',
  Line='Line',
  Table = 'Table'
}
