import React from 'react';

type Props = {
  width?: number;
  height?: number;
  fill?: string;
  rotate?: string;
};

const DoubleRightArrow = ({
  width, height, fill, rotate,
}: Props) => (
  <svg
    style={{
      width,
      height,
      rotate,
    }}
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path fill={fill} d="M5.33984 15L4.16484 13.825L7.98151 10L4.16484 6.175L5.33984 5L10.3398 10L5.33984 15Z" />
    <path fill={fill} d="M10.832 15L9.65703 13.825L13.4737 10L9.65703 6.175L10.832 5L15.832 10L10.832 15Z" />
  </svg>

);

DoubleRightArrow.defaultProps = {
  width: 20,
  height: 20,
  fill: 'var(--secondaryColor)',
  rotate: '0deg',
};

export default DoubleRightArrow;
