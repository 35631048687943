import React from 'react';

type Props = {
  width: number;
  height: number;
  fill?: string;
}

const PinnedIcon = ({
  width, height, fill,
}: Props) => (
  <svg width={width} height={height} viewBox="0 0 20 20" fill={fill} xmlns="http://www.w3.org/2000/svg">
    <path d="M11.6663 3.33341V7.50008C11.6663 8.43341 11.9747 9.30008 12.4997 10.0001H7.49968C8.04134 9.28341 8.33301 8.41675 8.33301 7.50008V3.33341H11.6663ZM14.1663 1.66675H5.83301C5.37468 1.66675 4.99968 2.04175 4.99968 2.50008C4.99968 2.95841 5.37468 3.33341 5.83301 3.33341H6.66634V7.50008C6.66634 8.88341 5.54968 10.0001 4.16634 10.0001V11.6667H9.14134V17.5001L9.97468 18.3334L10.808 17.5001V11.6667H15.833V10.0001C14.4497 10.0001 13.333 8.88341 13.333 7.50008V3.33341H14.1663C14.6247 3.33341 14.9997 2.95841 14.9997 2.50008C14.9997 2.04175 14.6247 1.66675 14.1663 1.66675Z" fill={fill} />
  </svg>

);

PinnedIcon.defaultProps = {
  fill: '#ed7b24',
};

export default PinnedIcon;
