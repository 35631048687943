import React from 'react';

type Props = {
    width: number;
    height: number;
    fill?: string;
}

const TickMarkIcon = ({
  width, height, fill,
}: Props) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M10 20C15.5228 20 20 15.5228 20 10C20 4.47715 15.5228 0 10 0C4.47715 0 0 4.47715 0 10C0 15.5228 4.47715 20 10 20Z"
      fill={fill}
    />
    <path
      d="M8.02325 12.3219L5.40225 9.94194L4.51025 10.7419L8.02125 13.9329L15.5573 7.08394L14.6723 6.28394L8.02325 12.3219Z"
      fill="#FAFAFA"
    />
  </svg>

);

TickMarkIcon.defaultProps = {
  fill: '#189F3F',
};

export default TickMarkIcon;
