import { createAsyncThunk } from '@reduxjs/toolkit';
import ReportListingService from 'services/report-listing.service';
import { AccessType } from 'core/constants/report';
import {
  IEditTagPayload, IReportData, IReportExternalRequest, IReportPayload, ObjectActionType, PinUnpinPayload, ReportListingActionTypes, SelectedSection, SourceTypes,
} from './report-listing.state';
import {
  requestExternalReportDetailsSuccess,
} from './report-listing.reducer';

export const getAllReports = createAsyncThunk(ReportListingActionTypes.GET_ALL_REPORTS_REQUEST,
  async (payload:IReportPayload) => new ReportListingService().getAllReports(payload.section, payload.profileId, payload.tagId));
export const getAllReportsByGlobalSearch = createAsyncThunk(ReportListingActionTypes.GET_ALL_REPORTS_SEARCH_REQUEST,
  async (globalSearch: string) => new ReportListingService().getAllReportsByGlobalSearch(globalSearch));
export const getAllDashboardsByGlobalSearch = createAsyncThunk(ReportListingActionTypes.GET_ALL_DASHBOARDS_SEARCH_REQUEST,
  async (globalSearch: string) => new ReportListingService().getAllDashboardsByGlobalSearch(globalSearch));
export const getExternalReportDetails = createAsyncThunk(ReportListingActionTypes.GET_EXTERNAL_REPORT_DETAILS_REQUEST,
  async (payload:IReportExternalRequest, { dispatch }) => {
    const reportDetails: IReportData = await new ReportListingService().getExternalReportDetails(payload.reportId, payload.sourceType);
    let lsqInternalUrl = '';
    if (reportDetails?.IsSieraLinked) { // when this property is set to true , we will have corresponding SIERA report in SieraLinkedReportId
      lsqInternalUrl = `${window.location.origin}/report/${reportDetails.SieraLinkedReportId}`;
      window.open(lsqInternalUrl, '_self');
      return;
    }
    if (payload.sourceType === SourceTypes.LSQInternal) {
    // link to saved reports for v1
      lsqInternalUrl = `${process.env.REACT_APP_LSQ_URL}${reportDetails.SourceInfo.Route}`;
      window.open(lsqInternalUrl, '_blank');
    }
    dispatch(requestExternalReportDetailsSuccess(reportDetails));
  });
export const getReportsByTag = createAsyncThunk(ReportListingActionTypes.GET_ITEMS_BY_TAG_REQUEST,
  async (payload: IReportPayload) => new ReportListingService().getAllReports(payload.section, payload.profileId, payload.tagId));
export const getDashboardList = createAsyncThunk(ReportListingActionTypes.GET_DASHBOARDS_LIST_REQUEST,
  async (payload: IReportPayload) => new ReportListingService().getDashboardList(payload.section, payload.profileId, payload.tagId, payload.mode));

export const editTagsByObject = createAsyncThunk(ReportListingActionTypes.EDIT_TAGS_BY_OBJECTID_REQUEST,
  async (data: IEditTagPayload) => new ReportListingService().editTagsByObject(data));
export const getTagsByObject = createAsyncThunk(ReportListingActionTypes.GET_TAGS_BY_OBJECT_REQUEST,
  async (payload:{objectId: string, objectType: ObjectActionType}) => new ReportListingService().getTagsByObject(payload.objectId, payload.objectType));
export const getPinnedReports = createAsyncThunk(ReportListingActionTypes.GET_PINNED_REPORTS_REQUEST,
  async (payload:{section: SelectedSection, profileId: string, tagId: string, sectionItem?: string}) => new ReportListingService()
    .getAllReports(payload.section, payload.profileId, payload.tagId));
export const pinUnpinReport = createAsyncThunk(ReportListingActionTypes.PIN_UNPIN_REPORTS_REQUEST,
  async (data: PinUnpinPayload) => new ReportListingService().pinUnpinReport(data));
export const getHomeDashoardsList = createAsyncThunk(ReportListingActionTypes.GET_HOME_DASHBOARDS_REQUEST,
  async (payload: IReportPayload) => new ReportListingService().getDashboardList(payload.section, payload.profileId, payload.tagId, payload.mode));
export const pinUnpinDashboard = createAsyncThunk(ReportListingActionTypes.PIN_UNPIN_DASHBOARDS_REQUEST,
  async (data: PinUnpinPayload) => new ReportListingService().pinUnpinDashboard(data));
export const getPinnedDashboardList = createAsyncThunk(ReportListingActionTypes.GET_PINNED_DASHBOARDS_REQUEST,
  async (payload: IReportPayload) => new ReportListingService().getDashboardList(payload.section, payload.profileId, payload.tagId, payload.mode));
export const V1AccessControl = createAsyncThunk(ReportListingActionTypes.V1REPORTS_ACCESS_CONTROLL_REQUEST,
  async (payload:{reportId: string, type: AccessType}) => new ReportListingService().accesscontrol(payload.reportId, payload.type));
export const getSavedReportDetails = createAsyncThunk(ReportListingActionTypes.GET_SAVED_REPORT_DETAILS_REQUEST,
  async (savedReports: string) => new ReportListingService().getSavedReportDetails(savedReports));
