import React from 'react';
import ErrorFallback from 'components/common/ErrorFallback/error-fallback.component';
import { errorHandler } from 'core/utils/report.util';
import Routes from 'routes';
import { useLocation } from 'react-router-dom';
import ErrorBoundaryWrapper from 'components/common/ErrorBoundary/error-boundary-wrapper.component';
import Toast from 'components/common/Toast/toast.component';

const App = () => {
  const location = useLocation();
  return (
    <ErrorBoundaryWrapper
      FallbackComponent={ErrorFallback}
      onError={errorHandler}
      key={location.pathname}
    >
      <Routes />
      <Toast />
    </ErrorBoundaryWrapper>
  );
};

export default App;
