/* eslint-disable no-shadow */
export const DateDisplayFormat = 'MMM DD, yyyy';

export const DatePayloadFormat = 'YYYY-MM-DDTHH:mm:ss';

export const DateConversionFormat = 'YYYY-MM-DD';

export const TimeConversionFormat = 'HH:mm:ss';

export const InvalidDate = 'Invalid Date';

export enum IDateRangeTypes {
    Absolute = 'Absolute',
    Relative = 'Relative',
}

export const IRelativeDateRanges = [
  {
    key: 'today',
    value: 'Today',
  },
  {
    key: 'yesterday',
    value: 'Yesterday',
  },
  {
    key: 'thisWeek',
    value: 'This Week',
  },
  {
    key: 'thisMonth',
    value: 'This Month',
  },
  {
    key: 'thisQuarter',
    value: 'This Quarter',
  },
  {
    key: 'thisYear',
    value: 'This Year',
  },
  {
    key: 'lastSevenDays',
    value: 'Last 7 Days',
  },
  {
    key: 'lastWeek',
    value: 'Last Week',
  },
  {
    key: 'lastThirtyDays',
    value: 'Last 30 Days',
  },
  {
    key: 'lastMonth',
    value: 'Last Month',
  },
  {
    key: 'lastNintyDays',
    value: 'Last 90 Days',
  },
  {
    key: 'lastQuarter',
    value: 'Last Quarter',
  },
  {
    key: 'lastThreeSixtyFiveDays',
    value: 'Last 365 Days',
  },
  {
    key: 'lastOneYear',
    value: 'Last Year',
  },
  {
    key: 'lastSevenThirtyDays',
    value: 'Last 730 Days',
  },
  {
    key: 'lastTwoYear',
    value: 'Last 2 Years',
  },
];

export enum RelativeDateRanges {
    Today = 'Today',
    Yesterday = 'Yesterday',
    Tomorrow='Tomorrow', // newly added

    LastSevenDays = 'Last 7 Days',
    NextSevenDays= 'Next 7 Days', // newly added

    LastWeek = 'Last Week',
    ThisWeek = 'This Week',
    NextWeek= 'Next Week', // newly added

    LastMonth = 'Last Month',
    ThisMonth = 'This Month',
    NextMonth='Next Month', // newly added

    LastQuarter = 'Last Quarter',
    ThisQuarter = 'This Quarter',
    NextQuarter='Next Quarter', // newly added

    ThisYear = 'This Year',
    LastOneYear = 'Last Year',
    NextYear='Next Year', // newly added

    NextNDays='NextNDays', // newly added
    LastNDays='LastNDays', // newly added

    Custom='Absolute',

    LastThirtyDays = 'Last 30 Days',
    LastNintyDays = 'Last 90 Days',
    LastThreeSixtyFiveDays = 'Last 365 Days',
    LastSevenThirtyDays = 'Last 730 Days',
    LastTwoYear = 'Last 2 Years',
}

export enum calendarTypes {
    GREGORY='gregory', // weekStartDay = sunday
    ISLAMIC= 'islamic', // weekStartDay = saturday
    ISO8601= 'iso8601', // weekStartDay = monday
  }
