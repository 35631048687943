/* eslint-disable no-shadow */
export const enum UserRole {
  Administrator = 'Administrator',
  SalesManager = 'Sales_Manager',
  SalesUser = 'Sales_User',
  MarketingUser = 'Marketing_User',
}

export const enum langCode {
  English = 'en-US',
  EnglishMarvin = 'en',
  Hindi = 'hi-IN',
  HindiMarvin = 'hi',
  Espanol = 'es-ES',
  EspanolMarvin = 'la',
}

export enum WeekStart {
  Monday = 'Monday',
  Tuesday = 'Tuesday',
  Wednesday = 'Wednesday',
  Thursday = 'Thursday',
  Friday = 'Friday',
  Saturday = 'Saturday',
  Sunday = 'Sunday',
}

export const enum MomentLocales {
  Default = 'siera-default',
}
