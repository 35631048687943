import React from 'react';

export interface IZeroValuesIconProps {
  width?: string;
  height?: string;
  strokeFill?: string;
  bgFill?: string;
}

const ZeroValuesIcon = (props: IZeroValuesIconProps) => {
  const {
    width, height,
    strokeFill, bgFill,
  } = props;

  return (
    <svg
      id="motion_photos_on_black_24dp"
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox={`0 0 ${width} ${height}`}
    >
      <rect id="Rectangle_7703" data-name="Rectangle 7703" width={width} height={height} fill={bgFill} />
      <path
        id="Path_10842"
        data-name="Path 10842"
        d="M2.7,6.709,3.938,7.942A6.533,6.533,0,0,0,3.6,10.008,6.407,6.407,0,1,0,10.008,3.6a6.533,6.533,0,0,0-2.066.336L6.717,2.713A8.005,8.005,0,1,1,2,10.008,8.1,8.1,0,0,1,2.7,6.709Zm2.5,3.3a4.8,4.8,0,1,1,4.8,4.8A4.809,4.809,0,0,1,5.2,10.008ZM6,4.8A1.2,1.2,0,1,1,4.8,3.6,1.2,1.2,0,0,1,6,4.8Z"
        transform="translate(-0.09 -0.026)"
        fill="none"
        className="icon-active"
      />
      <path
        id="Path_9720"
        data-name="Path 9720"
        d="M2.7,6.7,3.936,7.936A6.526,6.526,0,0,0,3.6,10,6.4,6.4,0,1,0,10,3.6a6.526,6.526,0,0,0-2.064.336L6.712,2.712A8,8,0,1,1,2,10,8.088,8.088,0,0,1,2.7,6.7ZM6,4.8A1.2,1.2,0,1,1,4.8,3.6,1.2,1.2,0,0,1,6,4.8Z"
        fill={strokeFill}
        className="icon-stroke"
      />
    </svg>
  );
};

ZeroValuesIcon.defaultProps = {
  width: '20',
  height: '20',
  bgFill: 'none',
  strokeFill: 'var(--secondaryColor)',
};

export default ZeroValuesIcon;
