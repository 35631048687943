import React from 'react';

type Props = {
  width: number;
  height: number;
  fill?: string;
}

const PersonIcon = ({
  width, height, fill,
}: Props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    style={{
      width,
      height,
    }}
    viewBox="0 0 20 20"
  >
    <g id="person_remove_black_24dp" transform="translate(0.241 0.241)">
      <g id="Group_20186" data-name="Group 20186">
        <rect id="Rectangle_7693" data-name="Rectangle 7693" width="20" height="20" transform="translate(-0.241 -0.241)" fill="none" />
      </g>
      <g id="Group_20188" data-name="Group 20188" transform="translate(1.626 3.253)">
        <g id="Group_20187" data-name="Group 20187">
          <path id="Path_9134" data-name="Path 9134" d="M12.506,7.253a3.253,3.253,0,1,0-3.253,3.253A3.252,3.252,0,0,0,12.506,7.253Zm-1.626,0A1.626,1.626,0,1,1,9.253,5.626,1.631,1.631,0,0,1,10.879,7.253Z" transform="translate(-2.747 -4)" fill={fill} />
          <path id="Path_9135" data-name="Path 9135" d="M2,17.253v1.626H15.012V17.253C15.012,15.09,10.677,14,8.506,14S2,15.09,2,17.253Zm1.626,0c.163-.577,2.684-1.626,4.879-1.626s4.692,1.041,4.879,1.626Z" transform="translate(-2 -5.868)" fill={fill} />
          <rect id="Rectangle_7694" data-name="Rectangle 7694" width="5" height="2" transform="translate(12.133 4.506)" fill={fill} />
          <rect id="Rectangle_7696" data-name="Rectangle 7696" width="3" height="2" transform="translate(13.133 2.006)" fill={fill} />
          <rect id="Rectangle_7697" data-name="Rectangle 7697" width="3" height="2" transform="translate(13.133 -0.494)" fill={fill} />
        </g>
      </g>
    </g>
  </svg>
);

PersonIcon.defaultProps = {
  fill: '#3e4589',
};

export default PersonIcon;
