import React from 'react';

type Props = {
    width?: number;
    height?: number;
    fill?: string;
}

const ConverseIcon = ({
  width, height, fill,
}: Props) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={width} height={height} viewBox="0 0 20 20" fill="none">
    <mask id="mask0_912_7674" maskUnits="userSpaceOnUse" x="0" y="0" width="20" height="20">
      <rect width="20" height="20" fill="#D9D9D9" />
    </mask>
    <g mask="url(#mask0_912_7674)">
      <path d="M1.66669 18.3332V3.33317C1.66669 2.87484 1.82988 2.48248 2.15627 2.15609C2.48266 1.8297 2.87502 1.6665 3.33335 1.6665H16.6667C17.125 1.6665 17.5174 1.8297 17.8438 2.15609C18.1702 2.48248 18.3334 2.87484 18.3334 3.33317V13.3332C18.3334 13.7915 18.1702 14.1839 17.8438 14.5103C17.5174 14.8366 17.125 14.9998 16.6667 14.9998H5.00002L1.66669 18.3332ZM5.00002 11.6665H11.6667V9.99984H5.00002V11.6665ZM5.00002 9.1665H15V7.49984H5.00002V9.1665ZM5.00002 6.6665H15V4.99984H5.00002V6.6665Z" fill={fill} />
    </g>
  </svg>

);

ConverseIcon.defaultProps = {
  height: 20,
  width: 20,
  fill: 'var(--secondaryColor)',
};

export default ConverseIcon;
