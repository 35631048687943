import React from 'react';

type Props = {
    width?: number;
    height?: number;
    fill?: string;
}

const LandingPageProIcon = ({
  width, height, fill,
}: Props) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={width} height={height} viewBox="0 0 20 20" fill="none">
    <path d="M6.74982 10H13.2498V8.375H6.74982V10ZM6.74982 6.75H13.2498V5.125H6.74982V6.75ZM16.4592 16.8859L13.1686 12.6C12.9384 12.2885 12.654 12.0482 12.3154 11.8789C11.9769 11.7096 11.6113 11.625 11.2186 11.625H3.49982V3.5C3.49982 3.05312 3.65893 2.67057 3.97716 2.35234C4.29539 2.03411 4.67794 1.875 5.12482 1.875H14.8748C15.3217 1.875 15.7042 2.03411 16.0225 2.35234C16.3407 2.67057 16.4998 3.05312 16.4998 3.5V16.5C16.4998 16.5677 16.4964 16.632 16.4897 16.693C16.4829 16.7539 16.4727 16.8182 16.4592 16.8859ZM5.12482 18.125C4.67794 18.125 4.29539 17.9659 3.97716 17.6477C3.65893 17.3294 3.49982 16.9469 3.49982 16.5V13.25H11.2186C11.354 13.25 11.4792 13.2805 11.5943 13.3414C11.7095 13.4023 11.8076 13.487 11.8889 13.5953L15.3014 18.0641C15.2337 18.0911 15.1626 18.1081 15.0881 18.1148C15.0136 18.1216 14.9425 18.125 14.8748 18.125H5.12482Z" fill={fill} />
  </svg>
);

LandingPageProIcon.defaultProps = {
  height: 20,
  width: 20,
  fill: 'var(--secondaryColor)',
};

export default LandingPageProIcon;
