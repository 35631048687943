import React from 'react';

type Props = {
    width: number;
    height: number;
    fill?: string;
}

const LockIcon = ({
  width, height, fill,
}: Props) => (
  <svg width={width} height={height} viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M3.06287 12.9731V6.54001H10.7867V7.18304C11.2225 7.18164 11.6561 7.24435 12.0737 7.36916V6.54001C12.0727 6.19898 11.9368 5.8722 11.6957 5.63105C11.4545 5.38991 11.1277 5.254 10.7867 5.25302H10.1437V3.96695C10.1509 3.53994 10.0731 3.11577 9.91471 2.71915C9.75632 2.32254 9.52055 1.96143 9.22115 1.65689C8.92174 1.35235 8.5647 1.11047 8.17084 0.945363C7.77697 0.780255 7.35418 0.695221 6.92711 0.695221C6.50004 0.695221 6.07725 0.780255 5.68338 0.945363C5.28952 1.11047 4.93248 1.35235 4.63307 1.65689C4.33367 1.96143 4.0979 2.32254 3.93951 2.71915C3.78112 3.11577 3.70329 3.53994 3.71056 3.96695V5.25302H3.06287C2.72184 5.254 2.39506 5.38991 2.15392 5.63105C1.91277 5.8722 1.77686 6.19898 1.77588 6.54001V12.9731C1.77686 13.3141 1.91277 13.6409 2.15392 13.8821C2.39506 14.1232 2.72184 14.2591 3.06287 14.2601H7.0895C6.81536 13.8664 6.60466 13.4321 6.46508 12.9731H3.06287ZM4.9929 3.96695C4.9929 3.45508 5.19624 2.96417 5.55819 2.60222C5.92014 2.24027 6.41105 2.03693 6.92292 2.03693C7.4348 2.03693 7.92571 2.24027 8.28766 2.60222C8.64961 2.96417 8.85295 3.45508 8.85295 3.96695V5.25302H4.9929V3.96695Z"
      fill={fill}
    />
    <path
      d="M10.7829 8.47003C10.1466 8.47003 9.52463 8.65871 8.9956 9.0122C8.46656 9.36569 8.05423 9.86812 7.81074 10.456C7.56725 11.0438 7.50354 11.6906 7.62767 12.3147C7.7518 12.9387 8.05819 13.5119 8.5081 13.9618C8.95801 14.4117 9.53123 14.7181 10.1553 14.8423C10.7793 14.9664 11.4261 14.9027 12.014 14.6592C12.6018 14.4157 13.1042 14.0034 13.4577 13.4743C13.8112 12.9453 13.9999 12.3233 13.9999 11.6871C13.9996 10.8339 13.6606 10.0158 13.0574 9.41255C12.4541 8.80929 11.636 8.47028 10.7829 8.47003ZM10.7829 9.75703C10.9737 9.75703 11.1603 9.81362 11.319 9.91966C11.4777 10.0257 11.6014 10.1764 11.6744 10.3527C11.7475 10.5291 11.7666 10.7231 11.7293 10.9103C11.6921 11.0975 11.6002 11.2694 11.4652 11.4044C11.3303 11.5394 11.1583 11.6313 10.9711 11.6685C10.7839 11.7057 10.5899 11.6866 10.4136 11.6136C10.2373 11.5406 10.0865 11.4169 9.9805 11.2582C9.87446 11.0995 9.81786 10.9129 9.81786 10.722C9.81774 10.5953 9.84262 10.4697 9.89107 10.3526C9.93953 10.2355 10.0106 10.129 10.1002 10.0394C10.1899 9.94976 10.2963 9.87869 10.4134 9.83023C10.5306 9.78178 10.6561 9.7569 10.7829 9.75703ZM10.7829 13.6171C10.4677 13.6173 10.1573 13.5399 9.87914 13.3917C9.60097 13.2435 9.36355 13.0291 9.18786 12.7675C9.67078 12.481 10.2219 12.3299 10.7833 12.3299C11.3448 12.3299 11.8959 12.481 12.3788 12.7675C12.203 13.0293 11.9655 13.2437 11.6871 13.3919C11.4088 13.5401 11.0982 13.6174 10.7829 13.6171Z"
      fill={fill}
    />
  </svg>

);

LockIcon.defaultProps = {
  fill: '#f5802a',
};

export default LockIcon;
