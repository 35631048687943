import React from 'react';
import fromPairs from 'lodash/fromPairs';
import UnSortIcon from 'components/common/svg/Unsort/unsort.component';
import { ISorting, SortingOrderTypes } from 'core/models/report-builder/report-builder';
import { IDimension } from 'core/models/report-redux';
import { isDimensionApplied, isDimensionColumn, isDimensionRow } from 'core/utils/report.util';
import AscSortIcon from 'components/common/svg/AscShort/asc-short.svg';

export type ISortingRanked = ISorting & { rank: number }
export type columnSortMap = { [name: string]: ISortingRanked };
const sortAscIcon = <AscSortIcon width={12} height={12} fill="var(--labelColor)" />;
const sortDescIcon = <UnSortIcon width={12} height={12} fill="var(--labelColor)" />;
const sortInactiveIcon = <UnSortIcon width={12} height={12} />;

// makeColumnSortMap creates map of sort Field -> sort object with sorting rank/sequence (shown as superscript).
export const makeColumnSortMap = (sortState: ISorting[]): columnSortMap => (
  fromPairs(sortState?.map((state, index) => [state.Field, { ...state, rank: index } as ISortingRanked]))
);
export const nextSortOrder = (prev: SortingOrderTypes, skipInactive?: boolean): SortingOrderTypes => {
  if (prev === SortingOrderTypes.ASC) return SortingOrderTypes.DESC;
  if (prev === SortingOrderTypes.DESC) return !skipInactive ? null : SortingOrderTypes.ASC;
  return SortingOrderTypes.ASC;
};
export const sortIconFor = (order: SortingOrderTypes, superscript?: string) => {
  if (order !== SortingOrderTypes.ASC && order !== SortingOrderTypes.DESC) return sortInactiveIcon;
  return (
    <>
      {!!superscript && <div className="se-column-header-sort-sequence">{superscript}</div>}
      {order === SortingOrderTypes.DESC ? sortDescIcon : sortAscIcon}
    </>
  );
};
export const makeSuperscript = (name: string, columnSort: columnSortMap, availableDims: IDimension[], isDrilldownColumn: boolean, withAsterisk?: boolean): string => {
  const rowGroupedDims = isDrilldownColumn ? [] : availableDims.filter((dim) => isDimensionApplied(dim) && isDimensionRow(dim));
  const colGroupedDims = isDrilldownColumn ? [] : availableDims.filter((dim) => isDimensionApplied(dim) && isDimensionColumn(dim));
  const sortLength = Object.keys(columnSort).length;
  const hasMultiSort = sortLength > (colGroupedDims.length + rowGroupedDims.length); // mandatory sort fields are not considered in multi sort
  const hasMultiRowGroups = rowGroupedDims.length > 1;
  const sort = columnSort[name];
  let superscript = '';
  if (!!sort && sortLength > 1 && (hasMultiSort || hasMultiRowGroups)) {
    const rank = sort.rank - colGroupedDims.length + 1; // rank/sequence starts from row grouping, does not include column grouping
    superscript = rank.toString();
    if (withAsterisk && rank < rowGroupedDims.length) { // asterisk is shown only for mandatory row groups
      superscript += '*';
    }
  }
  return superscript;
};
