import { ClickSource, LoadingStatus } from 'core/constants/dashboard';
import {
  IDashboardBuilder,
  IDashboardConfig, IDashboardData, IDashboardList, IDashboardRequestProcessing, IFilterStore, IPanelPayloads,
} from 'core/models/dashboard';
import { ObjModel } from 'core/models';
import { statusCodes } from 'core/constants/report';

export interface IDashboardState {
    dashboardList: IDashboardList;
    filterStore: IFilterStore;
    requestProcessing: IDashboardRequestProcessing;
    dashboardConfig: IDashboardConfig;
    panelPayloads: Array<IPanelPayloads>
    dashboardData: ObjModel.ObjGeneric<IDashboardData>;
    configLoadingStatus: LoadingStatus;
    loadFilterdAnimation: boolean;
    accesscontrolLoading:LoadingStatus;
    dashboardBuilder: IDashboardBuilder;
    isEditMode: boolean;
    dashboardStatusCode: statusCodes;
    triggerCalls: boolean;
    filterLoaded:boolean;
}

export enum DashboardAction {
  REQUEST_DASHBOARDS_LIST = 'REQUEST_DASHBOARDS_LIST',
  REQUEST_DASHBOARDS_LIST_SUCCESS = 'REQUEST_DASHBOARDS_LIST_SUCCESS',
  REQUEST_DASHBOARDS_LIST_FAILURE = 'REQUEST_DASHBOARDS_LIST_FAILURE',
  REQUEST_ALL_DASHBOARDS = 'GET_ALL_DASHBOARDS',
  REQUEST_ALL_DASHBOARDS_SUCCESS = 'REQUEST_ALL_DASHBOARDS_SUCCESS',
  REQUEST_ALL_DASHBOARDS_FAILURE = 'REQUEST_ALL_DASHBOARDS_FAILURE',
  REQUEST_DASHBOARD_CONFIG = 'REQUEST_DASHBOARD_CONFIG',
  REQUEST_DASHBOARD_CONFIG_SUCCESS = 'REQUEST_DASHBOARD_CONFIG_SUCCESS',
  REQUEST_DASHBOARD_CONFIG_FAILURE = 'REQUEST_DASHBOARD_CONFIG_FAILURE',
  SET_INITAL_PANEL_DATA = 'SET_INITAL_PANEL_DATA',
  TRANSFORM_DASHBOARD_CONFIG_TO_PAYLOAD = 'TRANSFORM_DASHBOARD_CONFIG_TO_PAYLOAD',
  REQUEST_PANEL_DATA = 'REQUEST_PANEL_DATA',
  REQUEST_PANEL_DATA_SUCCESS = 'REQUEST_PANEL_DATA_SUCCESS',
  REQUEST_PANEL_DATA_FAILURE = 'REQUEST_PANEL_DATA_FAILURE',
  UPDATE_DASHBOARD_CONFIG = 'UPDATE_DASHBOARD_CONFIG',
  DASHBOARD_FILTERS_ONCHANGE = 'DASHBOARD_FILTERS_ONCHANGE',
  DASHBOARD_APPLY_FILTERS = 'DASHBOARD_APPLY_FILTERS',
  SAVE_TEMP_VIEW_REQUEST = 'SAVE_TEMP_VIEW_REQUEST',
  SAVE_TEMP_VIEW_SUCCESS = 'SAVE_TEMP_VIEW_SUCCESS',
  SAVE_TEMP_VIEW_FAILURE = 'SAVE_TEMP_VIEW_FAILURE',
  RESET_DASHBOARD_STATE = 'RESET_DASHBOARD_STATE',
  FILTERED_ANIMATION_COMPLETED = 'FILTERED_ANIMATION_COMPLETED',
  SAVE_TEMP_DRILLDOWN_REQUEST = 'SAVE_TEMP_DRILLDOWN_REQUEST',
  SAVE_TEMP_DRILLDOWN_SUCCESS = 'SAVE_TEMP_DRILLDOWN_SUCCESS',
  SAVE_TEMP_DRILLDOWN_FAILURE = 'SAVE_TEMP_VIEW_FAILURE',
  RESET_REDIRECTION_STATUS = 'RESET_REDIRECTION_STATUS',
  SET_PERSONALIZED_DASHBOARD_REQUEST = 'SET_PERSONALIZED_DASHBOARD_REQUEST',
  SET_PERSONALIZED_DASHBOARD_SUCCESS = 'SET_PERSONALIZED_DASHBOARD_SUCCESS',
  SET_PERSONALIZED_DASHBOARD_FAILURE = 'SET_PERSONALIZED_DASHBOARD_FAILURE',
  DASHBOARD_ACCESS_CONTROL_REQUEST = 'DASHBOARD_ACCESS_CONTROL_REQUEST',
  DASHBOARD_ACCESS_CONTROL_SUCCESS = 'DASHBOARD_ACCESS_CONTROL_SUCCESS',
  DASHBOARD_ACCESS_CONTROL_FAILURE = 'DASHBOARD_ACCESS_CONTROL_FAILURE',
  DELETE_DASHBOARD = 'DELETE_DASHBOARD',
  DELETE_DASHBOARD_SUCCESS = 'DELETE_DASHBOARD_SUCCESS',
  DELETE_DASHBOARD_FAILURE = 'DELETE_DASHBOARD_FAILURE',
  GET_FILTERS_LIST_REQUEST = 'GET_FILTERS_LIST_REQUEST',
  GET_FILTERS_LIST_SUCCESS = 'GET_FILTERS_LIST_SUCCESS',
  GET_FILTERS_LIST_FAILURE = 'GET_FILTERS_LIST_FAILURE',
  UPDATE_DASHBOARD_CONFIG_FOR_CLONING = 'UPDATE_DASHBOARD_CONFIG_FOR_CLONING',
  EDIT_DASHBOARD_WIDGET_INFO = 'EDIT_DASHBOARD_WIDGET_INFO',
  RESET_WIDGET_INFO='RESET_WIDGET_INFO',

  // Dashboard builder Actions
  GET_REPORT_LIST_REQUEST = '[DB] GET_REPORT_LIST_REQUEST',
  GET_REPORT_LIST_SUCCESS = '[DB] GET_REPORT_LIST_SUCCESS',
  GET_REPORT_LIST_FAILURE = '[DB] GET_REPORT_LIST_FAILURE',
  SET_REPORT_SEARCH_VALUE = '[DB] SET_REPORT_SEARCH_VALUE',
  GET_REPORT_INFO = '[DB] GET_REPORT_INFO',
  GET_REPORT_INFO_SUCCESS = '[DB] GET_REPORT_INFO_SUCCESS',
  GET_REPORT_INFO_FAILURE = '[DB] GET_REPORT_INFO_FAILURE',
  UPDATE_DASHBOARD_BUILDER_TITLE_SECTION = '[RB] UPDATE_DASHBOARD_BUILDER_TITLE_SECTION',
  UPDATE_DASHBOARD_BUILDER_PANEL_INFO = '[RB] UPDATE_DASHBOARD_BUILDER_PANEL_INFO',
  SAVE_DASHBOARD_CONFIG = '[DB] SAVE_DASHBOARD_CONFIG',
  SAVE_DASHBOARD_CONFIG_SUCCESS = '[DB] SAVE_DASHBOARD_CONFIG_SUCCESS',
  SAVE_DASHBOARD_CONFIG_FAILURE = '[DB] SAVE_DASHBOARD_CONFIG_FAILURE',
  SET_PAGE_CONTEXT = '[DB] SET_PAGE_CONTEXT',
  REMOVE_DASHBOARD_WIDGET = '[DB] REMOVE_DASHBOARD_WIDGET',
  ITEM_ADDED_SUCCESSFULLY = '[DB] ITEM_ADDED_SUCCESSFULLY',
  SET_TOAST = '[DB] SET_TOAST',
  GET_SELECTED_REPORT_WIDGET_INFO = '[DB] GET_SELECTED_REPORT_WIDGET_INFO',
  GET_SELECTED_REPORT_WIDGET_INFO_SUCCESS = '[DB] GET_SELECTED_REPORT_WIDGET_INFO_SUCCESS',
  GET_SELECTED_REPORT_WIDGET_INFO_FAILURE = '[DB] GET_SELECTED_REPORT_WIDGET_INFO_FAILURE',
  UPDATE_PREVIEW_WIDGET_SETTING = '[DB] UPDATE_PREVIEW_WIDGET_SETTING',
  SET_EDIT_MODE_FOR_DASHBOARD = 'SET_EDIT_MODE_FOR_DASHBOARD',
  UPDATE_WIDGET_SETTING = '[DB] UPDATE_WIDGET_SETTING',
  VALIDATE_EMBED_URL = '[DB] VALIDATE_EMBED_URL',
  VALIDATE_EMBED_URL_SUCCESS = '[DB] VALIDATE_EMBED_URL_SUCCESS',
  VALIDATE_EMBED_URL_FAILURE = '[DB] VALIDATE_EMBED_URL_FAILURE',
  RESET_EMBED_URL_STATE = '[DB] RESET_EMBED_URL_STATE',
  ADD_EDIT_EMBED_URL_CONFIG = '[DB] ADD_EDIT_EMBED_URL_CONFIG',
  UPDATE_FILTER='[DB] UPDATE_FILTER',
  ADD_FILTER='[DB]  ADD_FILTER',
  SAVE_AND_APPLY_FILTERS = '[DB] SAVE_AND_APPLY_FILTERS',
  UPDATE_SELECTED_FILTER_ITEM='[DB] UPDATE_SELECTED_FILTER_ITEM',
  DELETE_FILTER='[DB] DELETE_FILTER',
  RESET_UPDATED_PANELS = '[DB] RESET_UPDATED_PANELS',
  RESET_SELECTED_FILTER='[DB] RESET_SELECTED_FILTER',
  CLOSE_FILTER_PANEL= '[DB] CLOSE_FILTER_PANEL',
  FILTER_DATA_LOAD_REQUEST = '[DB] FILTER_DATA_LOAD_REQUEST',
  FILTER_DATA_LOAD_REQUEST_SUCCESS = '[DB] FILTER_DATA_LOAD_REQUEST_SUCCESS',
  FILTER_DATA_LOAD_REQUEST_FAILURE = '[DB] FILTER_DATA_LOAD_REQUEST_FAILURE',
  SEARCH_FILTER_DATA_LOAD_REQUEST = '[DB] SEARCH_FILTER_DATA_LOAD_REQUEST',
  LOAD_MORE_FILTER_DATA_LOAD_REQUEST = '[DB] LOAD_MORE_FILTER_DATA_LOAD_REQUEST',
  SET_FILTER_LOAD_DONE = 'SET_FILTER_LOAD_DONE',
  UPDATE_FILTER_DATA='[DB] UPDATE_FILTER_DATA',
  CLEAR_MODIFIED_FILTER_ID='[DB] CLEAR_MODIFIED_FILTER_ID',
  GET_WIDGET_REPORT_DATA_REQUEST='[DB] GET_WIDGET_REPORT_DATA_REQUEST'
}

export interface IActionInterface<T> {
  type: DashboardAction,
  payload: T,
  meta: ObjModel.Obj
}

export interface IValidateURLPayload{
  name: string;
  sourceUrl: string;
  embedUrl: string;
  source: ClickSource;
}
