import React from 'react';

type Props = {
  width: number;
  height: number;
  fill?: string;
}
const DocumentIcon = ({
  width, height, fill,
}: Props) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={width} height={height} viewBox="0 0 20 21" fill="none">
    <path fill={fill} d="M6.60001 14.1H13.7997V15.9H6.60001V14.1ZM6.60001 10.5H13.7997V12.3H6.60001V10.5ZM11.9999 1.5H4.79987C4.32231 1.50207 3.86498 1.69253 3.52775 2.02979C3.19051 2.36705 3.00079 2.82369 3.00001 3.3V17.7C2.99871 18.1752 3.18639 18.6315 3.5219 18.969C3.85741 19.3064 4.31338 19.4974 4.78984 19.5H15.5996C16.0773 19.4981 16.5348 19.3076 16.8722 18.9703C17.2095 18.633 17.3993 18.1762 17.4 17.6997V6.9L11.9999 1.5ZM15.5996 17.6997H4.79987V3.3H11.0998V7.79986H15.5996V17.6997Z" />
  </svg>
);

DocumentIcon.defaultProps = {
  fill: 'var(--primaryColor)',
};

export default DocumentIcon;
