import { APIRequestStatus } from 'core/constants/redux';
import { SortDirection } from 'core/constants/schedule-builder';
import { IScheduleBuilderState, ScheduleBuilderActionTypes } from './schedule-builder.state';

const initializeScheduleBuilderState: IScheduleBuilderState = {
  scheduleId: '',
  requestProcessing: {
    [ScheduleBuilderActionTypes.SAVE_SCHEDULE_CONFIG]: APIRequestStatus.Processing,
    [ScheduleBuilderActionTypes.UPDATE_SCHEDULE_INFO]: APIRequestStatus.Processing,
    [ScheduleBuilderActionTypes.GET_SCHEDULE_LIST]: APIRequestStatus.Processing,
    [ScheduleBuilderActionTypes.GET_SCHEDULE_REPORT_NAMES]: APIRequestStatus.Processing,
    [ScheduleBuilderActionTypes.GET_SCHEDULE_OWNER_NAMES]: APIRequestStatus.Processing,
    [ScheduleBuilderActionTypes.DEACTIVATE_SCHEDULE]: APIRequestStatus.Processing,
    [ScheduleBuilderActionTypes.GET_SCHEDULE_HISTORY]: APIRequestStatus.Processing,
  },
  availableSlots: [],
  scheduleLimits: null,
  scheduleList: null,
  isScheduleFilterOpen: false,
  scheduleReportNames: [],
  scheduleOwnerNames: [],
  scheduleHistory: [],
  scheduleListPayload: {
    Page: {
      PageIndex: 1,
      PageSize: 25,
    },
    Sort: {
      Field: 'LastModifiedOn',
      Direction: SortDirection.DESC,
    },
    Filters: {},
  },
};

export default initializeScheduleBuilderState;
