import { VisualizationTypes } from 'core/constants/visualizations';

export interface IAttributesOptions {
    Type: VisualizationTypes;
    Attributes: IAttributes[];
  }

export interface IAttributes {
    key: string;
    label: string;
    PendoClassNames: string;
    hide: boolean;
  }

export interface IChartLabels {
    LineChart: string;
    BarChart: string;
    AreaChart: string;
    ColumnChart: string;
  }
export enum IChartAttributesKeys {
  ShowDataLabels ='showDataLabels',
  showSharedTooltip = 'showSharedTooltip',
  hideLegend ='hideLegend',
  stacked ='stacked',
  fullStacked ='fullStacked',
  showSeriesLabel ='showSeriesLabel',
  showCategoryName ='showCategoryName',
  showPercentage ='showPercentage',
  showSubTotals = 'showSubTotals',
  showSummary = 'showSummary',
}
