import React from 'react';

type Props = {
  width?: number;
  height?: number;
  fill?: string;
}

const HomeDashboard = ({
  width, height, fill,
}: Props) => (

  <svg
    width={width}
    height={height}
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M10.0569 4.37037L14.8382 9.11111V16.4403H12.6373V10.8889H7.213V16.4403H5.27567V9.11111L10.0569 4.37037ZM10.0569 2L1.5 10.1917H3.68571V18H8.77039V12.1438H11.0132V18H16.3143V10.1917H18.5L10.0569 2Z"
      fill={fill}
      className="se-icon-stroke"
    />
  </svg>

);

HomeDashboard.defaultProps = {
  fill: 'var(--purpleColor)',
  width: 20,
  height: 20,
};

export default HomeDashboard;
