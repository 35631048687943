import React from 'react';

type Props = {
  width: number;
  height: number;
  fill?: string;
}

const PieChart = ({
  width, height, fill,
}: Props) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M1.8 12.7857C1.8 7.89739 5.5263 3.87844 10.2928 3.41588C10.3186 3.41337 10.3403 3.41959 10.364 3.44259C10.391 3.46875 10.4143 3.51343 10.4143 3.57141V11.7857C10.4143 12.7798 11.2202 13.5857 12.2143 13.5857H20.4286C20.4865 13.5857 20.5312 13.609 20.5573 13.636C20.5804 13.6598 20.5866 13.6815 20.5841 13.7073C20.2301 17.3551 17.7918 20.3964 14.4731 21.6209C13.4587 21.9952 12.3613 22.2 11.2143 22.2C9.18155 22.2 7.30146 21.5567 5.76339 20.4624C3.36306 18.7547 1.8 15.9527 1.8 12.7857Z"
      stroke={fill}
      strokeWidth="1.6"
      className="icon-stroke"
    />
    <path
      d="M22.155 10.2928C22.1575 10.3186 22.1513 10.3403 22.1283 10.364C22.1021 10.391 22.0574 10.4143 21.9994 10.4143H13.7852C13.6747 10.4143 13.5852 10.3247 13.5852 10.2143V2C13.5852 1.94201 13.6085 1.89734 13.6355 1.87117C13.6592 1.84818 13.6808 1.84196 13.7066 1.84447C18.1698 2.27759 21.7219 5.82967 22.155 10.2928Z"
      stroke={fill}
      strokeWidth="1.6"
      className="icon-stroke"
    />
  </svg>

);

PieChart.defaultProps = {
  fill: 'var(--labelColor)',
};

export default PieChart;
