import React, {
  useState, useEffect, ReactElement,
} from 'react';
import PopoverStyled from './popover.styled';

interface IPopoverProps {
  popoverContent:ReactElement;
  anchorElement:ReactElement;
  open:boolean;
  setOpen:(isOpen:boolean)=>void;
  isLeftAligned?:boolean;
  rightOffset?: number;
  topOffset?: number;
}

const Popover = (props: React.PropsWithChildren<IPopoverProps>) => {
  const {
    popoverContent, anchorElement, open, setOpen, isLeftAligned, rightOffset, topOffset,
  } = props;

  const [top, setTop] = useState(null);
  const [right, setRight] = useState(null);
  const togglerRef = React.useRef(null);
  const popoverRef = React.useRef(null);

  useEffect(() => {
    const checkIfClickedOutside = (e: any) => {
      if (open && togglerRef.current && !togglerRef.current.contains(e.target)
        && popoverRef.current && !popoverRef.current.contains(e.target)) {
        setOpen(false);
      }
    };
    document.addEventListener('mousedown', checkIfClickedOutside);
    return () => {
      document.removeEventListener('mousedown', checkIfClickedOutside);
    };
  }, [open]);

  const toggle = () => {
    getPopoverPosition();
    setOpen(!open);
  };

  // this function is for placing the popover based on its position
  const getPopoverPosition = () => {
    const viewportOffsetToggle = togglerRef.current.getBoundingClientRect();
    const viewPortHeight = Math.max(document.documentElement.clientHeight || 0, window.innerHeight || 0);
    let popoverTop = null;
    let popoverRight = rightOffset ?? 60;
    if (viewportOffsetToggle.top > 350) {
      popoverTop = viewportOffsetToggle.top - 316;
    } else if (viewportOffsetToggle.bottom + 316 > viewPortHeight) {
      popoverTop = viewportOffsetToggle.top - (viewportOffsetToggle.bottom + 316 - viewPortHeight);
      popoverRight = viewportOffsetToggle.width + 75;
    } else {
      popoverTop = viewportOffsetToggle.bottom;
    }
    setTop(popoverTop + topOffset);
    setRight(popoverRight);
  };

  return (
    <PopoverStyled>
      <div
        className="se-popover-wrapper"
      >
        <div
          ref={togglerRef}
          onClick={toggle}
        >
          {anchorElement}
        </div>

        <div
          ref={popoverRef}
          className="se-popover-content-container"
          style={{
            top: `${top}px`,
            right: isLeftAligned ? 'unset' : `${right}px`,
            left: isLeftAligned ? '100px' : 'unset',
          }}
        >
          { open
          && (
          <>
              {popoverContent}
          </>
          )}
        </div>
      </div>

    </PopoverStyled>
  );
};
Popover.defaultProps = {
  topOffset: 4,
};

export default Popover;
