import React from 'react';
import { useSelector } from 'react-redux';
import { ReduxModel } from 'core/models';
import ErrorScreenComponent from '../ErrorScreenComponent/error-screen-component.component';
import useLocalize from '../Localization/useLocalize.hook';
import LeadSquaredIcon from '../svg/LeadSquared/leadSquared-icon-svg';

const Unauthorized = () => {
  const isReportingDisabled = useSelector((state: ReduxModel.IGlobalState) => state.auth.isReportingDisabled as boolean);

  const err = String(isReportingDisabled ? useLocalize('cmn.reportingDisabledmsg') : useLocalize('cmn.sieraReports'));
  const errDescription = String(isReportingDisabled ? useLocalize('cmn.enableReporting') : useLocalize('cmn.toEnableSiera'));
  const redirectToButtonContent = String(useLocalize('cmn.goBackDashboard'));
  const icon = isReportingDisabled ? <LeadSquaredIcon /> : undefined;

  return (
    <ErrorScreenComponent err={err} errDescription={errDescription} redirectToButtonContent={redirectToButtonContent} icon={icon} />
  );
};

export default Unauthorized;
