import React from 'react';

type Props = {
  width: number;
  height: number;
  fill?: string;
}

const ClearIcon = ({
  width, height, fill,
}: Props) => (
  <svg
    id="cancel-24px_2_"
    data-name="cancel-24px (2)"
    xmlns="http://www.w3.org/2000/svg"
    style={{
      width,
      height,
    }}
    viewBox="0 0 16 16"
  >
    <path
      id="Path_25"
      data-name="Path 25"
      d="M0,0H16V16H0Z"
      fill="none"
    />
    <path
      id="Path_26"
      data-name="Path 26"
      d="M8.63,2a6.63,6.63,0,1,0,6.63,6.63A6.624,6.624,0,0,0,8.63,2Zm3.315,9.01-.935.935L8.63,9.565l-2.38,2.38-.935-.935L7.7,8.63,5.315,6.25l.935-.935L8.63,7.7l2.38-2.38.935.935L9.565,8.63Z"
      transform="translate(-0.63 -0.63)"
      fill={fill}
    />
  </svg>
);

ClearIcon.defaultProps = {
  fill: '#ADBAC7',
};

export default ClearIcon;
