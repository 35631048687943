import React from 'react';

type Props = {
  width: number;
  height: number;
  fill?: string;
  backgroundColor?: string;
}
const TextWidgetIcon = ({
  width, height, fill, backgroundColor,
}: Props) => (
  <svg width={width} height={height} viewBox="0 0 26 26" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect width="26" height="26" fill={backgroundColor} />
    <rect x="3" y="3" width="20" height="20" fill={backgroundColor} />
    <path fill={fill} d="M8.25 21.0003V7.50031H3V5.00031H16V7.50031H10.75V21.0003H8.25ZM17.25 21.0003V12.5003H14V10.0003H23V12.5003H19.75V21.0003H17.25Z" />
  </svg>
);

TextWidgetIcon.defaultProps = {
  fill: '#454F5B',
  backgroundColor: 'transparent',
};

export default TextWidgetIcon;
