import React from 'react';

type Props = {
  width: number;
  height: number;
  fill?: string;
  backgroundColor?: string;
}
const BtnWidgetIcon = ({
  width, height, fill, backgroundColor,
}: Props) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={width} height={height} viewBox="0 0 26 26" fill="none">
    <rect width="26" height="26" fill={backgroundColor} />
    <path fill={fill} d="M12.3561 19.7317C10.4829 19.6341 8.90244 18.9122 7.61463 17.5659C6.32683 16.2195 5.68293 14.6 5.68293 12.7073C5.68293 10.7561 6.36585 9.09756 7.73171 7.73171C9.09756 6.36585 10.7561 5.68293 12.7073 5.68293C14.6 5.68293 16.2195 6.32683 17.5659 7.61463C18.9122 8.90244 19.6341 10.4927 19.7317 12.3854L17.8878 11.8C17.6732 10.5512 17.0878 9.5122 16.1317 8.68293C15.1756 7.85366 14.0341 7.43902 12.7073 7.43902C11.2439 7.43902 10 7.95122 8.97561 8.97561C7.95122 10 7.43902 11.2439 7.43902 12.7073C7.43902 14.0146 7.85366 15.1512 8.68293 16.1171C9.5122 17.0829 10.5512 17.6732 11.8 17.8878L12.3561 19.7317ZM13.761 24.3561C13.5854 24.3756 13.4098 24.3902 13.2341 24.4C13.0585 24.4098 12.8829 24.4146 12.7073 24.4146C11.0878 24.4146 9.56585 24.1073 8.14146 23.4927C6.71707 22.878 5.47805 22.0439 4.42439 20.9902C3.37073 19.9366 2.53659 18.6976 1.92195 17.2732C1.30732 15.8488 1 14.3268 1 12.7073C1 11.0878 1.30732 9.56585 1.92195 8.14146C2.53659 6.71707 3.37073 5.47805 4.42439 4.42439C5.47805 3.37073 6.71707 2.53659 8.14146 1.92195C9.56585 1.30732 11.0878 1 12.7073 1C14.3268 1 15.8488 1.30732 17.2732 1.92195C18.6976 2.53659 19.9366 3.37073 20.9902 4.42439C22.0439 5.47805 22.878 6.71707 23.4927 8.14146C24.1073 9.56585 24.4146 11.0878 24.4146 12.7073C24.4146 12.8829 24.4098 13.0585 24.4 13.2341C24.3902 13.4098 24.3756 13.5854 24.3561 13.761L22.6585 13.2341V12.7073C22.6585 9.93659 21.6927 7.58537 19.761 5.65366C17.8293 3.72195 15.478 2.7561 12.7073 2.7561C9.93659 2.7561 7.58537 3.72195 5.65366 5.65366C3.72195 7.58537 2.7561 9.93659 2.7561 12.7073C2.7561 15.478 3.72195 17.8293 5.65366 19.761C7.58537 21.6927 9.93659 22.6585 12.7073 22.6585H13.2341L13.761 24.3561ZM22.6878 25L17.6829 19.9951L16.2195 24.4146L12.7073 12.7073L24.4146 16.2195L19.9951 17.6829L25 22.6878L22.6878 25Z" />
  </svg>
);

BtnWidgetIcon.defaultProps = {
  fill: 'var(--secondaryColor)',
  backgroundColor: 'transparent',
};

export default BtnWidgetIcon;
