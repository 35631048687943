import { APIRequestStatus } from 'core/constants/redux';
import { ISettingsState, ModuleName, SettingsStoreActions } from './settings-store-state';
// initial state for settings slice is set for when siera is disabled,
// also fetch - plan - settings api should not be called for siera disabled tenants
const InitialSettingsState = (): ISettingsState => ({
  requestProcessing: {
    [SettingsStoreActions.SET_SETTINGS_REQUEST]: APIRequestStatus.AboutToLoad,
  },
  productLines: [],
  dataSets: [],
  data: {
    PlanInfo: {
      PlanName: 'Legacy',
    },
    Configuration: {
      ReportBuilder: {
        Module: ModuleName.ReportBuilder,
        IsEnabled: true,
        Settings: {
          CustomReportsLimit: 10,
          CustomReportsCreated: 10,
          Filter: {
            FiltersLimit: 15,
            StringFieldPartitionFilterLimit: 30,
          },
          Grouping: {
            RowGroupingLimit: 3,
            ColumnGroupingLimit: 1,
          },
          Measures: {
            MeasuresLimit: 25,
          },
        },
      },
      DashboardBuilder: {
        Module: ModuleName.DashboardBuilder,
        IsEnabled: false,
        Settings: {
          CustomDashboardsLimit: 10,
          CustomDashboardsCreated: 10,
          Filter: {
            FiltersLimit: 15,
            StringFieldPartitionFilterLimit: 30,
          },
          Widgets: {
            WidgetsLimit: 25,
          },
        },
      },
      Scheduler: {
        Module: ModuleName.Scheduler,
        IsEnabled: false,
        Settings: {
          SchedulesTenantLimit: 2,
          SchedulesTenantCreated: 1,
          SchedulesAdminUserLimit: 3,
          SchedulesAdminUserCreated: 2,
          SchedulesNonAdminUserLimit: 2,
          SchedulesNonAdminUserCreated: 2,
          RowLimitPerDay: 1000,
          AvailableSlots: [
            {
              Hour: 0,
              Minute: 30,
            },
            {
              Hour: 1,
              Minute: 30,
            },
          ],
        },
      },
      NamespaceBuilder: {
        Module: ModuleName.NamespaceBuilder,
        IsEnabled: true,
        Settings: {
          CustomNamespaceLimit: 20,
        },
      },
      Export: {
        Module: ModuleName.Export,
        IsEnabled: false,
        Settings: {
          RowLimitPerDay: 1000,
          ExportAdminUserLimit: 25,
          ExportUserCreated: 0,
          ExportSalesUserLimit: 10,
          ExportMarketingUserLimit: 10,
          ExportSalesManagerLimit: 10,
        },
      },
      ExpressionBuilder: {
        Module: ModuleName.ExpressionBuilder,
        IsEnabled: true,
        Settings: {
          CustomExpressionLimit: 10,
          CustomExpressionCreated: 2,
        },
      },
      DimensionBuilder: {
        Module: ModuleName.DimensionBuilder,
        IsEnabled: true,
        Settings: {
          CustomDimensionLimit: 10,
          CustomDimensionCreated: 2,
        },
      },
      ScheduleForOthers: {
        Module: ModuleName.ScheduleForOthers,
        IsEnabled: true,
      },
    },
  },
  showFeatureBlockedError: false,
});
export default InitialSettingsState;
