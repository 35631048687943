import { configureStore } from '@reduxjs/toolkit';
import { enableMapSet } from 'immer';
import globalStoreSlice from './global-store/global-store.reducer';
import reportStoreSlice from './report/report-store.reducer';
import profileStoreSlice from './profile/profile.reducer';
import authStoreSlice from './auth-store/auth-store.reducer';
import localizationReducer from './localization/localization.reducer';
import reportListingSlice from './report-listing/report-listing.reducer';
import sidebarStoreSlice from './sidebar-store/sidebar-store.reducer';
import dashboardStoreSlice from './dashboard-store/dashboard-store.reducer';
import reportBuilderSlice from './report-builder/report-builder-store.reducer';
import customErrorMiddleware from './customMiddleware';
import scheduleBuilderSlice from './schedule-builder/schedule-builder.reducer';
import settingsStoreSlice from './settings-store/settings-store.reducer';
import customAnalyticsSlice from './customAnalytics/custom-analytics.reducer';

const isDevelop = process.env.NODE_ENV === 'development';

enableMapSet();

const store = configureStore({
  reducer: {
    reportStoreV2: reportStoreSlice.reducer,
    profile: profileStoreSlice.reducer,
    auth: authStoreSlice.reducer,
    Localization: localizationReducer.reducer,
    reportListing: reportListingSlice.reducer,
    globalStore: globalStoreSlice.reducer,
    sidebarStore: sidebarStoreSlice.reducer,
    dashboard: dashboardStoreSlice.reducer,
    reportBuilder: reportBuilderSlice.reducer,
    scheduleBuilder: scheduleBuilderSlice.reducer,
    settings: settingsStoreSlice.reducer,
    customAnalytics: customAnalyticsSlice.reducer,
  },
  devTools: isDevelop,
  middleware: (getDefaultMiddleware) => getDefaultMiddleware({ serializableCheck: false }).concat(customErrorMiddleware),
});

export default store;
