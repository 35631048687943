import { IColumnExprPart } from 'core/models/report-expressions';
import { DateBinningFormatters } from './formatters';

/**
 * The set of first-level types of expressions. Each type may have second-level
 * child types as `ExpressionSubTypes`.
 */
export enum ExpressionTypes {
  Aggregation = 'AggExpr',
  Arithmetic = 'ArithmeticExpr',
  Column = 'ColumnExpr',
  Constant = 'ConstantExpr',
  Keyword = 'KeywordExpr',
  Comparison = 'ComparisonExpr',
  Logical = 'LogicalExpr',
  Date ='DateExpr',
  ArrayFn = 'ArrayFnExpr',
  Config = 'ConfigExpr',
  Condition = 'ConditionExpr',
}

/**
 * The super-set of second-level types of expressions. This set is type-guarded
 * by T which is a first-level type, so that the final type contains only the
 * child types of that first-level type.
 *
 * For instance, `ExpressionSubTypes<AggExpr>` will contain all of `AggExprTypes`
 * (like `Sum`) but none of the others (like `LessThan`).
 */
export type ExpressionSubTypes<T extends ExpressionTypes> =
  T extends ExpressionTypes.Aggregation ? AggExprTypes
  : T extends ExpressionTypes.Arithmetic ? ArithmeticExprTypes
  : T extends ExpressionTypes.Comparison ? CompExprTypes
  : T extends ExpressionTypes.Logical ? LogicalExprTypes
  : T extends ExpressionTypes.Column ? IColumnExprPart
  : T extends ExpressionTypes.Constant ? null
  : T extends ExpressionTypes.Keyword ? KeywordExprTypes
  : T extends ExpressionTypes.Config ? null
  : T extends ExpressionTypes.Date ? DateExprTypes
  : T extends ExpressionTypes.ArrayFn ? ArrayFnExprTypes
  : T extends ExpressionTypes.Condition? ConditionExprTypes
  : never;

export enum KeywordExprTypes {
  Null = 'NullExpr',
}

export enum AggExprTypes {
  Sum = 'SumExpr',
  SumIf = 'SumIfExpr',
  Max = 'MaxExpr',
  MaxIf = 'MaxIfExpr',
  Min = 'MinExpr',
  MinIf = 'MinIfExpr',
  Avg = 'AvgExpr',
  AvgIf = 'AvgIfExpr',
  Count = 'CountExpr',
  CountIf = 'CountIfExpr',
  CountDistinct = 'CountDistinctExpr',
  CountDistinctIf = 'CountDistinctIfExpr',
}

export enum ConditionExprTypes{
  If = 'IfExpr'
}

export enum ArithmeticExprTypes {
  Plus = 'PlusExpr',
  Minus = 'MinusExpr',
  Multiply = 'MultiplyExpr',
  Divide = 'DivideExpr',
}

export type CompExprTypes = FilterOperatorTypes;

export enum LogicalExprTypes {
  And = 'AndExpr',
  Or = 'OrExpr',
  Not = 'NotExpr',
  IsNull = 'IsNullCheckExpr',
}

export enum DateFnExprTypes {
  CurrentDateTime = 'CurrentDateTimeExpr',
  Add = 'DurationAddExpr',
  Subtract = 'DurationSubtractExpr',
}

export enum DateDiffFnExprTypes {
  DifferenceExpr = 'DifferenceExpr',
}

export type DateExprTypes = DateBinningFormatters | DateFnExprTypes | DateDiffFnExprTypes;

export enum ArrayFnExprTypes {
  ArrayJoinExpr ='ArrayJoinExpr',
}

export enum ConfigExprNamespace {
  UserNamespace = 'User',
  TenantSettingsNamespace = 'TenantSettings',
}

export enum TenantSettingKey {
  InitialLeadStage = 'LeadStages.Initial',
  FinalLeadStage = 'LeadStages.Final',
  ActiveLeadStages = 'LeadStages.Active',
  InactiveLeadStages = 'LeadStages.Inactive',
  NonFinalLeadStages = 'LeadStages.NonFinal',
}

export enum TimeDuration {
  SECOND = 'SECOND',
  MINUTE = 'MINUTE',
  HOUR = 'HOUR',
  DAY = 'DAY',
  WEEK = 'WEEK',
  MONTH = 'MONTH',
  QUARTER = 'QUARTER',
  YEAR = 'YEAR',
}

export enum RawAggrExpressionString {
  Sum = 'Sum(%s)',
  Count ='Count(%s)',
  CountIf = 'CountIf(%s)',
  CountDistinct = 'CountDistinct(%s)',
  CountDistinctIf = 'CountDistinctIf(%s)',
  SumIf = 'SumIf(%s)',
  Max = 'Max(%s)',
  Min = 'Min(%s)',
  Avg = 'Avg(%s)',
  MaxIf = 'MaxIf(%s)',
  MinIf = 'MinIf(%s)',
  AvgIf = 'AvgIf(%s)',
}

export enum RawConditionExpressionString {
 If = 'If(%s)',
}

export enum RawDateExpressionString {
  CurrentDateTime = 'CurrentDateTime',
  DurationAdd ='DurationAdd(%s)',
  DurationSubtract = 'DurationSubtract(%s)',
  ToHour = 'ToHour(%s)',
  ToDayOfWeek = 'ToDayOfWeek(%s)',
  ToDate = 'ToDate(%s)',
  ToWeek = 'ToWeek(%s)',
  ToYearWeek = 'ToYearWeek(%s)',
  ToMonth = 'ToMonth(%s)',
  ToMonthOfYear = 'ToMonthOfYear(%s)',
  ToQuarter = 'ToQuarter(%s)',
  ToQuarterOfYear = 'ToQuarterOfYear(%s)',
  ToYear='ToYear(%s)',
  DateDiff = 'DateDiff(%s)',
  DateUnitDiff = 'DateUnitDiff(%s)',
}

export enum FilterOperatorTypes {
  EQ = 'EQ',
  NOT_EQ = 'NOT_EQ',
  IN = 'IN',
  NOT_IN = 'NOT_IN',
  LIKE = 'LIKE',
  NOT_LIKE = 'NOT_LIKE',
  BETWEEN = 'BETWEEN',
  LT = 'LT',
  GT = 'GT',
  LTE = 'LTE',
  GTE = 'GTE',
  EXISTS = 'EXISTS',
  NOT_EXISTS = 'NOT_EXISTS',
  HAS = 'HAS',
  HAS_ANY = 'HAS_ANY',
  HAS_NOT_ANY = 'HAS_NOT_ANY',
}

export const FilterOperators = new Map<FilterOperatorTypes, string>([
  [FilterOperatorTypes.EQ, '='],
  [FilterOperatorTypes.NOT_EQ, '!='],
  [FilterOperatorTypes.IN, 'in'],
  [FilterOperatorTypes.NOT_IN, 'notin'],
  [FilterOperatorTypes.LIKE, 'LIKE'],
  [FilterOperatorTypes.NOT_LIKE, 'NOT_LIKE'],
  [FilterOperatorTypes.BETWEEN, 'BETWEEN'],
  [FilterOperatorTypes.LT, '<'],
  [FilterOperatorTypes.GT, '>'],
  [FilterOperatorTypes.LTE, '<='],
  [FilterOperatorTypes.GTE, '>='],
  [FilterOperatorTypes.EXISTS, 'EXISTS'],
  [FilterOperatorTypes.NOT_EXISTS, 'NOT_EXISTS'],
  [FilterOperatorTypes.HAS, 'HAS'],
  [FilterOperatorTypes.HAS_ANY, 'HAS_ANY'],
  [FilterOperatorTypes.HAS_NOT_ANY, 'HAS_NOT_ANY'],
]);

export const logicalOperatorMap = new Map<LogicalExprTypes, string>([
  [LogicalExprTypes.And, 'and'],
  [LogicalExprTypes.Or, 'or'],
  [LogicalExprTypes.Not, 'not'],
  [LogicalExprTypes.IsNull, 'isNull'],
]);

export const expSyntaxMetaData = {
  Count: {
    exp: 'Count',
    title: 'Count (Field)',
    desc: 'Counts the number of rows or not-NULL values',
    returnText: 'Number',
    example: 'Count(`Lead.ProspectId`)',
    link: 'https://help.leadsquared.com/siera-expression-builder/#Count',
  },
  CountDistinct: {
    exp: 'CountDistinct',
    title: 'CountDistinct (Field)',
    desc: 'Counts the number of unique rows or not-NULL values',
    returnText: 'Number',
    example: 'CountDistinct(`Lead.ProspectId`)',
    link: 'https://help.leadsquared.com/siera-expression-builder/#CountDistinct',
  },
  Sum: {
    exp: 'Sum',
    title: 'Sum (Field)',
    desc: 'Calculates the sum. Only works for numbers',
    returnText: 'Number',
    example: 'Sum(`Lead.mx_Custom_2`)',
    link: 'https://help.leadsquared.com/siera-expression-builder/#Sum',
  },
  SumIf: {
    exp: 'SumIf',
    title: 'SumIf (Field, Condition)',
    desc: 'Calculates the sum of field, if the condition is true',
    returnText: 'Number',
    example: 'Sumif(`Lead.mx_Custom_2`, `Lead.Stage` = \'Prospect\')',
    link: 'https://help.leadsquared.com/siera-expression-builder/#SumIf',
  },
  CountIf: {
    exp: 'CountIf',
    title: 'CountIf (Condition)',
    desc: 'Counts the number of rows where a specific condition is true for a given field',
    returnText: 'Number',
    example: 'CountIf(Lead.Stage` = \'Prospect\')',
    link: 'https://help.leadsquared.com/siera-expression-builder/#CountIf',
  },
  CountDistinctIf: {
    exp: 'CountDistinctIf',
    title: 'CountDistinctIf (Field, Condition)',
    desc: 'Counts the number of distinct values in a field that satisfy a given condition',
    returnText: 'Number',
    example: 'CountDistinctIf(`Lead.ProspectId`, `Lead.Stage` = \'Prospect\')',
    link: 'https://help.leadsquared.com/siera-expression-builder/#CountDistinctIf',
  },
  Max: {
    exp: 'Max',
    title: 'Max (Field)',
    desc: 'Returns the maximum value of a numeric field across all rows',
    returnText: 'Number, DateTime',
    example: 'Max(`Lead.mx_Custom_2`)',
    link: 'https://help.leadsquared.com/siera-expression-builder/#Max',
  },
  MaxIf: {
    exp: 'MaxIf',
    title: 'MaxIf (Field, Condition)',
    desc: 'Returns the maximum value of a numeric field for rows that satisfy a given condition',
    returnText: 'Number, DateTime',
    example: 'MaxIf(`Lead.mx_Custom_2`, `Lead.Stage` = \'Prospect\')',
    link: 'https://help.leadsquared.com/siera-expression-builder/#MaxIf',
  },
  Min: {
    exp: 'Min',
    title: 'Min (Field)',
    desc: 'Returns the minimum value of a numeric field across all rows',
    returnText: 'Number, DateTime',
    example: 'Min(`Lead.mx_Custom_2`)',
    link: 'https://help.leadsquared.com/siera-expression-builder/#Min',
  },
  MinIf: {
    exp: 'MinIf',
    title: 'MinIf (Field, Condition)',
    desc: 'Returns the minimum value of a numeric field for rows that satisfy a given condition',
    returnText: 'Number, DateTime',
    example: 'MinIf(`Lead.mx_Custom_2`, `Lead.Stage` = \'Prospect\')',
    link: 'https://help.leadsquared.com/siera-expression-builder/#MinIf',
  },
  Avg: {
    exp: 'Avg',
    title: 'Avg (Field)',
    desc: 'Calculates the average (mean) value of a numeric field across all rows',
    returnText: 'Number',
    example: 'Avg(`Lead.mx_Custom_2`)',
    link: 'https://help.leadsquared.com/siera-expression-builder/#Avg',
  },
  AvgIf: {
    exp: 'AvgIf',
    title: 'AvgIf (Field, Condition)',
    desc: 'Calculates the average (mean) value of a numeric field for rows that satisfy a given condition',
    returnText: 'Number',
    example: 'AvgIf(`Lead.mx_Custom_2`, `Lead.Stage` = \'Prospect\')',
    link: 'https://help.leadsquared.com/siera-expression-builder/#AvgIf',
  },
  ToHour: {
    exp: 'ToHour',
    title: 'ToHour (DateTime Field)',
    desc: 'Extracts the hour component from a given DateTime',
    returnText: 'String ("HH:00\')',
    example: 'CountIf(ToHour(`Lead.CreatedOn`)=\'17:00\')',
    link: 'https://help.leadsquared.com/siera-expression-builder/#ToHour',
  },
  ToDateOfYear: {
    exp: 'ToDateOfYear',
    title: 'ToDateOfYear (DateTime Field)',
    desc: 'Extracts the date of the year from a given DateTime',
    returnText: 'String (YYYY-MM-DD)',
    example: 'CountIf(ToDateOfYear(`Lead.mx_Custom_2`)=\'2023-02-02\')',
    link: 'https://help.leadsquared.com/siera-expression-builder/#ToDateOfYear',
  },
  ToMonthOfYear: {
    exp: 'ToMonthOfYear',
    title: 'ToMonthOfYear (DateTime Field)',
    desc: 'Extracts the month of the year from a given DateTime',
    returnText: 'String (\'000M-0M-01\')',
    example: 'CountIf(ToMonthOfYear(`Lead.mx_Custom_2`)=\'0002-02-01\')',
    link: 'https://help.leadsquared.com/siera-expression-builder/#ToMonthOfYear',
  },
  ToQuarterOfYear: {
    exp: 'ToQuarterOfYear',
    title: 'ToQuarterOfYear (DateTime Field)',
    desc: 'Extracts the quarter of the year from a given DateTime',
    returnText: 'String (\'QX\')',
    example: 'CountIf(ToQuarterOfYear(`Lead.mx_Custom_2`)= \'Q2\')',
    link: 'https://help.leadsquared.com/siera-expression-builder/#ToQuarterOfYear',
  },
  ToYear: {
    exp: 'ToYear',
    title: 'ToYear (DateTime Field)',
    desc: 'Extracts the year from a given DateTime',
    returnText: 'Number (YYYY)',
    example: 'CountIf(ToYear(`Lead.mx_Custom_2`)= 2023)',
    link: 'https://help.leadsquared.com/siera-expression-builder/#ToYear',
  },
  ToYearWeek: {
    exp: 'ToYearWeek',
    title: 'ToYearWeek (DateTime Field)',
    desc: 'Extracts the year and week from a given DateTime',
    returnText: 'Number (WW, 1-53)',
    example: 'CountIf(ToYearWeek(`Lead.mx_Custom_2`)=47)',
    link: 'https://help.leadsquared.com/siera-expression-builder/#ToYearWeek',
  },
  ToMonth: {
    exp: 'ToMonth',
    title: 'ToMonth (DateTime Field)',
    desc: 'Extracts the month from a given DateTime',
    returnText: 'String (\'YYYY-MM-01\')',
    example: 'CountIf(ToMonth(`Lead.CreatedOn`) = \'2023-02-01\' )',
    link: 'https://help.leadsquared.com/siera-expression-builder/#ToMonth',
  },
  ToQuarter: {
    exp: 'ToQuarter',
    title: 'ToQuarter (DateTime Field)',
    desc: 'Extracts the quarter from a given DateTime',
    returnText: 'String (\'YYYY QX\')',
    example: 'CountIf(ToQuarter(\'Lead.CreatedOn\') = \'2023 Q2\' )',
    link: 'https://help.leadsquared.com/siera-expression-builder/#ToQuater',
  },
  ToDayOfWeek: {
    exp: 'ToDayOfWeek',
    title: 'ToDayOfWeek (DateTime Field)',
    desc: 'Extracts the day of the week from a given DateTime',
    returnText: 'Number (0-6, where 0 is Sunday)',
    example: 'CountIf(ToDayOfWeek(`Lead.CreatedOn`) = 6 )',
    link: 'https://help.leadsquared.com/siera-expression-builder/#ToDayOfWeek',
  },
  ToDate: {
    exp: 'ToDate',
    title: 'ToDate (DateTime Field)',
    desc: 'Extracts the date component from a given DateTime',
    returnText: 'String',
    example: 'CountIf(ToDate(`Lead.mx_Custom_2`)=\'2023-10-03\')',
    link: 'https://help.leadsquared.com/siera-expression-builder/#ToDate',
  },
  ToWeek: {
    exp: 'ToWeek',
    title: 'ToWeek (DateTime Field)',
    desc: 'Extracts the week from a given DateTime',
    returnText: 'Number (YYYYWW)',
    example: 'CountIf(ToWeek(`Lead.CreatedOn`) = 202347 )',
    link: 'https://help.leadsquared.com/siera-expression-builder/#ToWeek',
  },
  DurationAdd: {
    exp: 'DurationAdd',
    title: 'DurationAdd (DateTime Field, Interval, Time Unit)',
    desc: 'Adds a duration to a given DateTime',
    returnText: 'DateTime',
    example: 'DurationAdd(`Lead.mx_Custom_2`,4, \'MONTH\')',
    link: 'https://help.leadsquared.com/siera-expression-builder/#DurationAdd',
  },
  DateUnitDiff: {
    exp: 'DateUnitDiff',
    title: 'DateUnitDiff(DateTime Field, DateTime Field, TimeUnit)',
    desc: 'Returns the count of the specified Time Unit boundaries crossed between the start and end dates. DateUnitDiff(<start date>, <end date>,<interval>)',
    returnText: 'Number',
    example: 'CountIf(DateUnitDiff(`Lead.CreatedOn`, `Lead.MOdifiedOn`,\'Month\')=4)',
    link: 'https://help.leadsquared.com/siera-expression-builder/#DateUnitDiff',
  },
  DateDiff: {
    exp: 'Age',
    title: 'DateDiff(DateTime Field, DateTime Field, TimeUnit)',
    desc: 'Returns the time elapsed between the start date and end date in terms of the specified time unit.   DateDiff(<start date>, <end date>, <interval>)',
    returnText: 'Number',
    example: 'CountIf(DateDiff(`Lead.CreatedOn`, `Lead.MOdifiedOn`, \'Month\')=4)',
    link: 'https://help.leadsquared.com/siera-expression-builder/#DateDiff',
  },
  DurationSubtract: {
    exp: 'DurationSubtract',
    title: 'DurationSubtract (DateTime Field, Interval, Time Unit)',
    desc: 'Subtracts a duration from a given DateTime',
    returnText: 'DateTime',
    example: 'DurationSubtract(`Lead.mx_Custom_2`,4, \'MONTH\')',
    link: 'https://help.leadsquared.com/siera-expression-builder/#DurationSubtract',
  },
  NotInOp: {
    exp: 'NotInOp',
    title: 'notin [ list of values ]',
    desc: 'Checks if a value is not present in a list of values',
    returnText: 'Boolean',
    example: 'CountIf(`Lead.ProspectStage` notin [\'INVALID\', \'DISQUALIFIED\'])',
    link: 'https://help.leadsquared.com/siera-expression-builder/#notin',
  },
  Null: {
    exp: 'Null',
    title: 'Null',
    desc: 'Represents a null value',
    returnText: 'Null',
    example: 'CountIf(`Lead.ProspectStage` = Null )',
    link: '',
  },
  InOp: {
    exp: 'InOp',
    title: 'in [ list of values ]',
    desc: 'Checks if a value is present in a list of values',
    returnText: 'Boolean',
    example: 'CountIf(`Lead.ProspectStage` in  [\'INVALID\', \'DISQUALIFIED\'])',
    link: 'https://help.leadsquared.com/siera-expression-builder/#in',
  },
  Date: {
    exp: 'Date',
    title: 'Date (YYYY-MM-DD)',
    desc: 'Creates a date using the provided year, month, and day components',
    returnText: 'Date',
    example: 'CountIf(ToDate(`Lead.mx_Custom_2`)=Date(\'2023-10-03\'))',
    link: '',
  },
  CurrentDateTime: {
    exp: 'CurrentDateTime',
    title: 'CurrentDateTime',
    desc: 'Returns the current date and time',
    returnText: 'DateTime',
    example: 'DurationSubtract(CURRENTDATETIME, 4, MONTH))',
    link: 'https://help.leadsquared.com/siera-expression-builder/#CurrentDateTime',
  },
  OrOp: {
    exp: 'OrOp',
    title: '(Condition1) or (Condition2)',
    desc: 'Performs a logical OR operation on multiple conditions, returning true if at least one condition is true',
    returnText: 'Boolean',
    example: '(`Lead.ProspectStage` = \'Customer\') OR (`Lead.mx_Sub_Stage` IN [\'Converted\',\'Paid\'])',
    link: 'https://help.leadsquared.com/siera-expression-builder/#or',
  },
  AndOp: {
    exp: 'AndOp',
    title: '(Condition1) and (Condition2)',
    desc: 'Performs a logical AND operation on multiple conditions, returning true if all conditions are true',
    returnText: 'Boolean',
    example: '(`Lead.ProspectStage` = \'Customer\') AND (`Lead.Source` IN [\'Referral\',\'Affiliate\'])',
    link: 'https://help.leadsquared.com/siera-expression-builder/#and',
  },
  If: {
    exp: 'If',
    title: 'If(Condition, ValueIfTrue, ValueIfFalse)',
    desc: 'Returns a string based on a condition. The condition can be nested.',
    returnText: 'String',
    example: 'If (`Lead.ProspectStage`= \'Customer\', \'Hot\', If (`Lead.ProspectStage`= \'Prospect\', \'Medium\', \'Cold\' ) )',
    link: 'https://help.leadsquared.com/siera-expression-builder/#if',
  },
  [TenantSettingKey.FinalLeadStage]: {
    exp: 'LeadStages.Final',
    title: 'LeadStages.Final',
    desc: 'Returns the final lead stage',
    returnText: 'String',
    example: 'CountIf(`Lead.Stage` = Config(\'TenantSettings\', \'LeadStages.Final\'))',
    link: '',
  },
  [TenantSettingKey.ActiveLeadStages]: {
    exp: 'LeadStages.Active',
    title: 'LeadStages.Active',
    desc: 'Returns the active lead stages in a list',
    returnText: 'String Array',
    example: 'CountIf(`Lead.Stage` in Config(\'TenantSettings\',\'LeadStages.Active\'))',
    link: '',
  },
  [TenantSettingKey.InitialLeadStage]: {
    exp: 'LeadStages.Initial',
    title: 'LeadStages.Initial',
    desc: 'Returns the default lead stage',
    returnText: 'String',
    example: 'CountIf(`Lead.Stage` = Config(\'TenantSettings\', \'LeadStages.Initial\'))',
    link: '',
  },
  [TenantSettingKey.InactiveLeadStages]: {
    exp: 'LeadStages.Inactive',
    title: 'LeadStages.Inactive',
    desc: 'Returns the inactive lead stages in a list',
    returnText: 'String Array',
    example: 'CountIf(`Lead.Stage` in Config(\'TenantSettings\',\'LeadStages.Inactive\'))',
    link: '',
  },
  [TenantSettingKey.NonFinalLeadStages]: {
    exp: 'LeadStages.NonFinal',
    title: 'LeadStages.NonFinal',
    desc: 'Returns the non-final lead stages in a list',
    returnText: 'String Array',
    example: 'CountIf(`Lead.Stage` in Config(\'TenantSettings\',\'LeadStages.NonFinal\'))',
    link: '',
  },
};

export interface expMetaData{
  exp: string,
  title: string,
  desc:string,
  returnText: string,
  example: string,
  link: string,
}

export const usersPrefix = 'users';
