import React from 'react';

type Props = {
  width?: number;
  height?: number;
  fill?: string;
}

const CustomExpressionIcon = ({
  width, height, fill,
}: Props) => (
  <svg width={width} height={height} viewBox={`0 0 ${width} ${height}`} fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M12.1667 15.017H2.16667V4.83366H8V3.16699H2.16667C1.25 3.16699 0.5 3.91699 0.5 4.83366V14.8337C0.5 15.7503 1.25 16.5003 2.16667 16.5003H12.1667C13.0833 16.5003 13.8333 15.7503 13.8333 14.8337V9.00033H12.1667V15.017Z"
      fill={fill}
    />
    <path
      d="M13.8337 0.666992H12.167V3.16699H9.66699C9.67533 3.17533 9.66699 4.83366 9.66699 4.83366H12.167V7.32533C12.1753 7.33366 13.8337 7.32533 13.8337 7.32533V4.83366H16.3337V3.16699H13.8337V0.666992Z"
      fill={fill}
    />
    <path d="M10.4997 6.5H3.83301V8.16667H10.4997V6.5Z" fill={fill} />
    <path d="M3.83301 9V10.6667H10.4997V9H7.99967H3.83301Z" fill={fill} />
    <path d="M10.4997 11.5H3.83301V13.1667H10.4997V11.5Z" fill={fill} />
  </svg>
);

CustomExpressionIcon.defaultProps = {
  fill: 'var(--searchBoxTextColor)',
  width: 20,
  height: 20,
};

export default CustomExpressionIcon;
