import { breakpoints } from 'components/feature/Report/ReportSidebar/common/constants';

/**
 * @todo: Add tablet and desktop once breakpoints are decided
 */
export enum size {
  sm = breakpoints.MOBILE,
  mm = breakpoints.TABLET,
  smmm=breakpoints.TABLET // caters to both small and medium devices
}

const device = {
  mobile: `(max-width: ${size.sm}px)`, // it will be applied to the mobile screen which has width of 430px or less
  desktop: `(min-width: ${size.mm + 1}px)`, // it will be applied to the table/desktop which has width of more than 1024px
  mobileTablet: `(max-width: ${size.smmm}px)`, // it will be applied to the mobile/tablet/ipad which has width within 1024px
};

export default device;
