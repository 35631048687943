import { PendoClassNames } from 'components/feature/Report/ReportSidebar/common/pendoClassNames';
import { IFilterItem } from 'core/models/dashboard';
import FilterFetchUtility from 'core/utils/filter-fetch-utility';
import Cache from 'core/cache/cache';
import { FilterType } from './filter';
import CacheItem from './cache';

export const SelectVisualization = [
  {
    label: 'Column Chart',
    value: 'ColumnChart',
    pendoClass: PendoClassNames.DashboardBuilderVisulizationColumnChart,
  },
  {
    label: 'Table (No Chart)',
    value: 'Table',
    pendoClass: PendoClassNames.DashboardBuilderVisulizationTable,
  },
  {
    label: 'Bar Chart',
    value: 'BarChart',
    pendoClass: PendoClassNames.DashboardBuilderVisulizationBarChart,
  },
  {
    label: 'Area Chart',
    value: 'AreaChart',
    pendoClass: PendoClassNames.DashboardBuilderVisulizationAreaChart,
  },
  {
    label: 'Line Chart',
    value: 'LineChart',
    pendoClass: PendoClassNames.DashboardBuilderVisulizationLineChart,
  },
  {
    label: 'Pie Chart',
    value: 'PieChart',
    pendoClass: PendoClassNames.DashboardBuilderVisulizationPieChart,
  },
  {
    label: 'Donut Chart',
    value: 'DonutChart',
    pendoClass: PendoClassNames.DashboardBuilderVisulizationDonutChart,
  },
  {
    label: 'KPI',
    value: 'KPI',
    pendoClass: PendoClassNames.DashboardBuilderVisulizationKpiChart,
  },
];

export const filterTypeOptions = [
  {
    label: 'Date-Time Type Filter',
    value: FilterType.DateRange,
    pendoClass: PendoClassNames.DashboardBuilderDateFilterType,
  },
  {
    label: 'String Type Filter',
    value: FilterType.MultiTextSearch,
    pendoClass: PendoClassNames.DashboardBuilderStringFilterType,
  },
  {
    label: 'Dropdown Type Filter',
    value: FilterType.LSQMetadataMultiSelect,
    pendoClass: PendoClassNames.DashboardBuilderDropdownFilterType,
  },
  {
    label: 'User Filter',
    value: FilterType.UserMultiSelect,
    pendoClass: PendoClassNames.DashboardBuilderUserFilterType,
  },
  {
    label: 'Sales Group Filter',
    value: FilterType.GroupMultiSelect,
    pendoClass: PendoClassNames.DashboardBuilderSalesGroupFilterType,
  },
];
export const filterTypeLabel = {
  [FilterType.DateRange]: 'Date-Time',
  [FilterType.MultiTextSearch]: 'String',
  [FilterType.LSQMetadataMultiSelect]: 'Dropdown',
  [FilterType.UserMultiSelect]: 'User',
  [FilterType.GroupMultiSelect]: 'Sales Group',
  [FilterType.PRE_APPLIED]: '',
  [FilterType.DRILLDOWN]: '',
  [FilterType.FILTER_ONLY]: '',
  [FilterType.LSQMetadataSingleSelect]: '',
  [FilterType.CustomDefinedMultiSelect]: '',
  [FilterType.CustomDefinedSingleSelect]: '',
  [FilterType.FILTER]: '',
  [FilterType.SET_FILTER]: '',
  [FilterType.EmptyFilterType]: '',
};

export const defaultGroupFilterKey = 'Group-filter-key';
export const defaultPreAppliedKey = 'pre-applied-filter-key';
export const defaultGroupMetaDataKey = 'GroupUsers';

export const defaultGroupFilter : IFilterItem = {
  ID: defaultGroupFilterKey,
  alwaysHidden: true,
  Type: FilterType.GroupMultiSelect,
  DefaultValue: null,
  filterData: new FilterFetchUtility(null).GetDefaultSingleFilter({
    ID: defaultGroupFilterKey,
    Type: FilterType.GroupMultiSelect,
    DefaultValue: null,
  }, null),
};

export const getDefaultPreAppliedFilter = () : IFilterItem => {
  const userAttributes = Cache.getItem(CacheItem.UserAttr);

  return ({
    ID: defaultPreAppliedKey,
    alwaysHidden: true,
    Type: FilterType.PRE_APPLIED,
    DefaultValue: null,
    filterData: new FilterFetchUtility(null).GetDefaultSingleFilter({
      ID: defaultPreAppliedKey,
      Type: FilterType.PRE_APPLIED,
      DefaultValue: userAttributes?.AutoId,
    }, null),
  });
};
