import React from 'react';

type Props = {
  width: number;
  height: number;
  fill?: string;
}

const InfoIcon = ({
  width, height, fill,
}: Props) => (
  <svg
    id="info-24px_2_"
    data-name="info-24px (2)"
    xmlns="http://www.w3.org/2000/svg"
    style={{
      width,
      height,
    }}
    viewBox="0 0 16 16"
  >
    <path
      id="Path_1"
      data-name="Path 1"
      d="M0,0H16V16H0Z"
      fill="none"
    />
    <path
      id="Path_2"
      className="icon-stroke"
      data-name="Path 2"
      d="M7.989,5.327H9.32V6.658H7.989Zm0,2.662H9.32v3.993H7.989ZM8.655,2a6.655,6.655,0,1,0,6.655,6.655A6.657,6.657,0,0,0,8.655,2Zm0,11.978a5.324,5.324,0,1,1,5.324-5.324A5.331,5.331,0,0,1,8.655,13.978Z"
      transform="translate(-0.655 -0.655)"
      fill={fill}
    />
  </svg>
);

InfoIcon.defaultProps = {
  fill: 'var(--secondaryColor)',
};

export default InfoIcon;
