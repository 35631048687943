export const textEllipsisMixin = ({ whiteSpace = 'nowrap' }:any) => `
    text-overflow: ellipsis;
    white-space: ${whiteSpace};
    overflow: hidden;
`;

export const flexCenterMixin = ({ justifyContent = 'center', alignItems = 'center' }: any) => `
    display:flex;
    align-items:${alignItems};
    justify-content:${justifyContent};
    -webkit-justify-content: ${justifyContent};
    -moz-justify-content: ${justifyContent};
    -ms-justify-content: ${justifyContent};
`;

export const spinMixin = ({ duration = '1500ms' }:{duration:string}) => `
    -webkit-animation-name: spin;
    -webkit-animation-duration: ${duration};
    -webkit-animation-iteration-count: infinite;
    -webkit-animation-timing-function: linear;
    -moz-animation-name: spin;
    -moz-animation-duration: ${duration};
    -moz-animation-iteration-count: infinite;
    -moz-animation-timing-function: linear;
    -ms-animation-name: spin;
    -ms-animation-duration: ${duration};
    -ms-animation-iteration-count: infinite;
    -ms-animation-timing-function: linear;
    animation-name: spin;
    animation-duration: ${duration};
    animation-iteration-count: infinite;
    animation-timing-function: linear;

    @-ms-keyframes spin {
        from {
          -ms-transform: rotate(0deg);
        }
        to {
          -ms-transform: rotate(360deg);
        }
      }
      
      @-moz-keyframes spin {
        from {
          -moz-transform: rotate(0deg);
        }
        to {
          -moz-transform: rotate(360deg);
        }
      }
      
      @-webkit-keyframes spin {
        from {
          -webkit-transform: rotate(0deg);
        }
        to {
          -webkit-transform: rotate(360deg);
        }
      }
      
      @keyframes spin {
        from {
          transform: rotate(0deg);
        }
        to {
          transform: rotate(360deg);
        }
      }
`;

// add position relative to the parent
export const hoverBorderMixin = () => `
  &:before {
    content: '';
    position: absolute;
    top: 0px;
    width: 100%;
    left: 0;
    border-top: 1px solid var(--lsqAltColor);
  }

  &:hover::before{
    border-color: var(--lsqSectionHover);
  }

  &:after {
    content: '';
    position: absolute;
    bottom: 0;
    width: 100%;
    left: 0;
    border-bottom: 1px solid var(--lsqAltColor);
  }

  &:hover::after{
    border-color: var(--lsqSectionHover);
  }
`;

export const verticalElipsisMixin = ({ line = 1 }:{line:number}) => `
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: ${line};
`;

export const customScrollbarMixin = () => `
  overflow: auto !important;

  &::-webkit-scrollbar {
    background-color: white;
    width: 6px;
  }
  
  &:hover::-webkit-scrollbar {
    visibility: visible !important;
  }

  &::-webkit-scrollbar {
    visibility: hidden;
  }

  &::-webkit-scrollbar-track {
    background-color: transparent;
    border-radius:8px;
    cursor:pointer;
  }
  
  &::-webkit-scrollbar-thumb {
    background-color:transparent;
    cursor:pointer;
    border-radius:3px;
  } 
 
  &:hover::-webkit-scrollbar-thumb {
    background-color: #ADBAC7;
    cursor:pointer;
    border-radius:3px;
  }
`;
