import { ReportV2Icons } from 'components/feature/SideNavbarV2/sidebar-section-constants';
import { APIRequestStatus } from 'core/constants/redux';
import {
  ITitleSection,
} from 'core/models/dashboard';

export interface ISidebarStoreState {
  requestProcessing: IRequestProcessing;
  selectedNavBarItem: string;
  globaltags: Array<ITagsData>;
  reportTagsbasedOnProfile: Array<ITagsData>;
  dashboardTagsbasedOnProfile: Array<ITagsData>;
  selectedSection: ListingSection;
  selectedHomeDashboard: IDashboardItem;
  redirectAllDashboard: boolean;
  resetTagAnimation: boolean;
}

export enum Types {
  Internal = 'Internal',
  External = 'External',
  System ='System',
  Custom ='Custom',
}

export interface IDashboardItem {
  IsHome: boolean;
  IsHomeInProfile: boolean;
  IsPersonalized: boolean;
  IsPinned: boolean;
  IsPinnedInProfile: boolean;
  Reports: Array<String>;
  TitleSection: ITitleSection;
  Id: string;
  Tags: Array<string>;
  Type:Types;
}

export interface IRequestProcessing {
  [SidebarActionTypes.GET_ALL_TAGS_REQUEST]: APIRequestStatus;
  [SidebarActionTypes.GET_TAGS_BY_USER_REQUEST]: APIRequestStatus;
}

export enum ListingSection {
  Report = 'Reports',
  Dashboard = 'Dashboards',
}

export interface IDeleteProfilePayload {
  profileId: string;
  isV1Report: boolean;
}

export enum ObjectActionType {
  Report = 'Report',
  Dashboard = 'Dashboard',
}

export interface ITagsData {
  Id: string;
  Name: string;
  Colour: string;
}

export interface ITagsByPofile {
  TagMetaData: Array<ITagsData>;
  ReportTags: Array<string>;
  DashboardTags: Array<string>;
}

export interface IEditTagsData {
  Id: string;
  Name?: string;
  Colour?: string;
  checked: boolean;
}

export enum SelectedSection {
  Pinned = 'pinned',
  All = 'all',
  Tags = 'tag',
  Home = 'home'
}

export interface SectionItemInfo {
  Icon: ReportV2Icons;
  Name: string;
  className: string[];
}
export interface PinUnpinPayload {
  Id: string;
  IsPinned: boolean;
}

export enum ReportPinningFliter {
  AllPinned = 'all_pinned',
  PinnedByMe = 'pinned_by_me',
  PinnedForMe = 'pinned_for_me',
}

export interface IDashboardLinkedReport {
  Id: string;
}

export enum ListingMode {
  listing = 'listing',
  create = 'create',
  edit = 'edit',
}

export enum SidebarActionTypes {
    SET_SELECTED_NAVBAR_ITEM = ' SET_SELECTED_NAVBAR_ITEM',
    GET_ALL_TAGS_REQUEST = 'GET_ALL_TAGS_REQUEST',
    GET_TAGS_BY_USER_REQUEST = 'GET_TAGS_BY_USER_REQUEST',
    SET_SELECTED_SECTION = 'SET_SELECTED_SECTION',
    TAG_ANIMATION_COMPLETE = 'TAG_ANIMATION_COMPLETE',
    RESET_LISTING_STATE = 'RESET_LISTING_STATE',
    SET_SELECTED_HOME_DASHBOARD = 'SET_SELECTED_HOME_DASHBOARD',
    SET_REDIRECT_ALL_DASHBOARDS = 'SET_REDIRECT_ALL_DASHBOARDS',
    RESET_TAG_ANIMATION = 'RESET_TAG_ANIMATION',
  }

export interface IReportPayload {
    section: SelectedSection,
    profileId: string,
    tagId: string,
    mode?: string
}

export interface IReportExternalRequest {
    reportId: string,
    sourceType: string
}
