import { APIRequestStatus } from 'core/constants/redux';
import {
  ISidebarStoreState,
  ListingSection, SidebarActionTypes,
} from './sidebar-store.state';

const initialReportListingState: ISidebarStoreState = {
  requestProcessing: {
    [SidebarActionTypes.GET_ALL_TAGS_REQUEST]: APIRequestStatus.Processing,
    [SidebarActionTypes.GET_TAGS_BY_USER_REQUEST]: APIRequestStatus.Processing,
  },
  selectedNavBarItem: '',
  globaltags: [],
  reportTagsbasedOnProfile: null,
  dashboardTagsbasedOnProfile: [],
  selectedSection: ListingSection.Report,
  selectedHomeDashboard: null,
  redirectAllDashboard: true,
  resetTagAnimation: false,
};

export default initialReportListingState;
