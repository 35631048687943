import React from 'react';

type Props = {
  width?: number;
  height?: number;
  fill?: string;
};

const EditWidgetIcon = ({ width, height, fill }: Props) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect width="20" height="20" fill="white" />
    <path fill={fill} d="M13.7288 10.958L9.29134 6.52051L13.7288 2.08301L18.1663 6.52051L13.7288 10.958ZM2.08301 9.45801V3.18717H8.35384V9.45801H2.08301ZM10.7913 18.1663V11.8955H17.0622V18.1663H10.7913ZM2.08301 18.1663V11.8955H8.35384V18.1663H2.08301ZM3.33301 8.20801H7.10384V4.43717H3.33301V8.20801ZM13.7913 9.27051L16.4788 6.58301L13.7913 3.89551L11.1038 6.58301L13.7913 9.27051ZM12.0413 16.9163H15.8122V13.1455H12.0413V16.9163ZM3.33301 16.9163H7.10384V13.1455H3.33301V16.9163Z" />
    <rect width="8" height="8" transform="translate(10 11)" fill="white" />
    <path fill={fill} d="M11.6586 17.3416H11.9947L15.3792 13.9571L15.043 13.621L11.6586 17.0054V17.3416ZM16.3494 13.6286L15.3715 12.6507L15.6924 12.3298C15.779 12.2432 15.886 12.2 16.0133 12.2C16.1406 12.2 16.2476 12.2432 16.3342 12.3298L16.6703 12.666C16.7569 12.7526 16.8002 12.8595 16.8002 12.9869C16.8002 13.1142 16.7569 13.2211 16.6703 13.3077L16.3494 13.6286ZM16.0286 13.9495L12.1781 17.8H11.2002V16.8221L15.0507 12.9716L16.0286 13.9495ZM15.2111 13.789L15.043 13.621L15.3792 13.9571L15.2111 13.789Z" />
  </svg>

);

EditWidgetIcon.defaultProps = {
  width: 20,
  height: 20,
  fill: 'var(--secondaryColor)',
};

export default EditWidgetIcon;
