import { IEditProfileItem, IProfileItem } from 'core/models/profile';
import { IEditTagsData, ITagsData } from 'redux-v2/report-listing/report-listing.state';
import {
  IGlobalStoreState,
} from './global-store.state';

export const getTagsList = (
  tagsList: Array<ITagsData>,
) => tagsList.map((item:ITagsData) => ({ ...item, checked: false }));

export const getUpdatedTagList = (state: IGlobalStoreState, tagId: string, isChecked: boolean) => {
  const newState = { ...state };
  const updatedTagsSelection: IEditTagsData[] = newState.tags.updatedTagsList.map((item) => {
    if (item.Id === tagId) {
      return ({ ...item, checked: isChecked });
    }
    return item;
  });

  return updatedTagsSelection;
};

export const getProfileList = (
  profilesList: Array<IProfileItem>,
) => profilesList.map((item:IProfileItem) => ({
  ...item, checked: false, IsPinnedInProfile: false, IsHomeInProfile: false,
}));

export const getUpdatedProfileList = (state: IGlobalStoreState, profileId: string, isChecked: boolean, IsPinnedInProfile?:boolean, IsHomeInProfile?:boolean) => {
  const newState = { ...state };
  const appliedUpdatedProfiles: IEditProfileItem[] = newState.profiles.updatedProfilesList.map((item) => {
    if (item.Id === profileId) {
      return ({
        ...item,
        checked: (IsPinnedInProfile || IsHomeInProfile) ? true : isChecked,
        IsPinnedInProfile: IsPinnedInProfile || false,
        IsHomeInProfile: IsHomeInProfile || false,
      });
    }
    return item;
  });
  return appliedUpdatedProfiles;
};
