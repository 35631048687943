import React from 'react';

type Props = {
  width?: number;
  height?: number;
  fill?: string;
}

const OptionBarChartIcon = ({
  width, height, fill,
}: Props) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect
      width="16"
      height="16"
      fill="transparent"
    />
    <path
      d="M1.6001 1.60059V14.2506C1.6001 14.3334 1.66725 14.4006 1.7501 14.4006H14.4001"
      stroke={fill}
      strokeWidth="1.5"
      strokeLinecap="round"
    />
    <path
      d="M12.4487 11.2007L4.04568 11.2007"
      stroke={fill}
      strokeWidth="1.5"
      strokeLinecap="round"
    />
    <path
      d="M10.4487 8.00049L3.99987 8.00049"
      stroke={fill}
      strokeWidth="1.5"
      strokeLinecap="round"
    />
    <path
      d="M7.2002 4.80078L4.0002 4.80078"
      stroke={fill}
      strokeWidth="1.5"
      strokeLinecap="round"
    />
  </svg>

);

OptionBarChartIcon.defaultProps = {
  width: 16,
  height: 16,
  fill: 'var(--secondaryColor)',
};

export default OptionBarChartIcon;
