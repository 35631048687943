import React from 'react';
import StyledTextAreaFieldComponent from './text-area-field.styled';
import useLocalize from '../Localization/useLocalize.hook';

interface ITextAreaFieldProps {
  uniqKey: string;
  label?: string;
  placeholder?:string;
  maxLength?:number;
  required?: boolean;
  inputValue:string;
  onInputChange: (event: React.ChangeEvent<HTMLTextAreaElement>) => void
  errorMessage?:string;
  showErrorMessage?:boolean;
  disabled?: boolean;
  colCount?:number;
  rowCount?:number;
  infoIcon?:React.ReactElement;
  classNames?:Array<string>;
}

const TextAreaField = (props: ITextAreaFieldProps) => {
  const {
    uniqKey,
    label,
    placeholder,
    maxLength,
    required,
    inputValue,
    errorMessage,
    onInputChange,
    showErrorMessage,
    disabled,
    colCount,
    rowCount,
    infoIcon,
    classNames,
  } = props;

  const handleChange = (e:React.ChangeEvent<HTMLTextAreaElement>) => {
    onInputChange(e);
  };
  return (
    <>
      <StyledTextAreaFieldComponent className="se-text-area-field-component-wrapper">
        {label && (
        <div className="se-text-area-field-label">
          {label}
          {
          infoIcon && infoIcon
          }
        </div>
        )}

        <textarea
          id={uniqKey}
          name={uniqKey}
          cols={colCount}
          rows={rowCount}
          onChange={handleChange}
          placeholder={placeholder}
          className={`se-text-area-field-component ${
            showErrorMessage ? ' danger' : ''
          } ${classNames?.join(' ')}`}
          value={inputValue}
          maxLength={maxLength}
          disabled={disabled}
          required={required}
        />
        {showErrorMessage && (
          <div className="se-text-area-field-error">
            {errorMessage || '' }
          </div>
        )}
      </StyledTextAreaFieldComponent>
    </>
  );
};

TextAreaField.defaultProps = {
  label: useLocalize('cmn.enterDesc'),
  placeholder: useLocalize('cmn.enterDesc'),
  required: true,
  errorMessage: useLocalize('cmn.errorInvalidInput'),
  showErrorMessage: false,
  disabled: false,
  colCount: 30,
  rowCount: 3,
  classNames: [''],
};
export default TextAreaField;
