import React from 'react';
import { ReduxModel } from 'core/models';
import { useSelector } from 'react-redux';
import ErrorComponent from '../Error/error.component';
import StylesErrorFallbackConatiner from './error-fallback.style';
import useLocalize from '../Localization/useLocalize.hook';

const ErrorFallback = () => {
  const [userAttributes, reportId] = useSelector((state: ReduxModel.IGlobalState) => [state.auth.userAttributes, state.reportStoreV2.reportId] as const);
  const onBackClickHandler = () => {
    const url = new URL(window.location.href);
    url.pathname = '/';
    url.search = '';
    window.location.href = url.toString();
  };
  return (
    <StylesErrorFallbackConatiner>
      <div className="se-error-fallback-component">
        <ErrorComponent
          message={`${useLocalize('err.reportVisualizationNotCorrect')}`}
          tenant={userAttributes && userAttributes.OrgShortCode}
          issue={`Report: ${reportId}`}
          retry={() => {
            window.location.reload();
          }}
          back={onBackClickHandler}
        />
      </div>
    </StylesErrorFallbackConatiner>
  );
};
export default ErrorFallback;
