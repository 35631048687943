/** @format */

import React from 'react';

type Props = {
  width?: number;
  height?: number;
};

const EmptyDataDashboardSearchIcon = ({ width, height }: Props) => (

  <svg width={width} height={height} viewBox="0 0 86 85" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M3.55371 3.47266V38.0536H33.0468V3.47266H3.55371Z" fill="#DFE3E8" />
    <path d="M4.1416 53.4727V75.6907H32.7416V53.4727H4.1416Z" fill="#DFE3E8" />
    <path d="M47.1416 3.47266V25.6907H75.7416V3.47266H47.1416Z" fill="#DFE3E8" />
    <path d="M46.5537 41.4727V76.0536H76.0468V41.4727H46.5537Z" fill="#DFE3E8" />
    <path d="M43.333 30V0H80V30H43.333ZM0 43.333V0H36.667V43.333H0ZM43.333 80V36.667H80V80H43.333ZM0 80V50H36.667V80H0ZM6.667 36.667H30V6.667H6.667V36.667ZM50 73.333H73.333V43.333H50V73.333ZM50 23.333H73.333V6.667H50V23.333ZM6.667 73.333H30V56.667H6.667V73.333Z" fill="#919EAB" />
    <path d="M72 85C79.732 85 86 78.732 86 71C86 63.268 79.732 57 72 57C64.268 57 58 63.268 58 71C58 78.732 64.268 85 72 85Z" fill="white" />
    <path d="M78 66.2L76.8 65L72 69.8L67.2 65L66 66.2L70.8 71L66 75.8L67.2 77L72 72.2L76.8 77L78 75.8L73.2 71L78 66.2Z" fill="#919EAB" />
  </svg>

);

EmptyDataDashboardSearchIcon.defaultProps = {
  width: 80,
  height: 85,
};

export default EmptyDataDashboardSearchIcon;
