import React from 'react';

type Props = {
  width?: number;
  height?: number;
}

const SearchIcon = ({
  width, height,
}: Props) => (

  <svg width={width} height={height} viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M12.881 11.6441H12.229L11.998 11.4211C12.9028 10.3668 13.364 9.00295 13.2847 7.61588C13.2054 6.22881 12.5918 4.92641 11.5727 3.98211C10.5536 3.03781 9.20825 2.52506 7.81917 2.55151C6.43009 2.57796 5.10527 3.14155 4.12286 4.12396C3.14045 5.10637 2.57686 6.43119 2.55041 7.82027C2.52396 9.20935 3.03671 10.5547 3.98101 11.5738C4.92531 12.5929 6.22771 13.2065 7.61478 13.2858C9.00185 13.3651 10.3657 12.9039 11.42 11.9991L11.643 12.2301V12.8821L15.766 16.9991L16.996 15.7691L12.881 11.6441ZM7.93001 11.6441C7.19545 11.6441 6.47738 11.4263 5.86662 11.0182C5.25586 10.6101 4.77982 10.03 4.49872 9.35139C4.21762 8.67275 4.14407 7.92599 4.28737 7.20554C4.43068 6.4851 4.7844 5.82332 5.30381 5.30391C5.82323 4.7845 6.485 4.43078 7.20544 4.28747C7.92589 4.14416 8.67265 4.21771 9.35129 4.49882C10.0299 4.77992 10.61 5.25595 11.0181 5.86672C11.4262 6.47748 11.644 7.19555 11.644 7.93011C11.6447 8.41802 11.549 8.90127 11.3626 9.35217C11.1762 9.80307 10.9027 10.2128 10.5577 10.5578C10.2127 10.9028 9.80297 11.1763 9.35207 11.3627C8.90117 11.5491 8.41792 11.6448 7.93001 11.6441Z" fill="#919EAB" />
  </svg>

);

SearchIcon.defaultProps = {
  width: 20,
  height: 20,
};

export default SearchIcon;
