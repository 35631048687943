import styled from 'styled-components';

const StyledErrorScreenContainer = styled.div`

.error-screen{
  top: 50%;
  left: 50%;
  position: absolute;
  transform: translate(-50%, -50%);
  display: flex; 
  align-items: center;
  text-align: center;
  justify-content: center;
  flex-direction: column;
  color: var(--noDataColor);

  
  >.se-info-data-header{
    width: 833px;
    font-family: Semibold;
    font-size: 32px;
    text-align: center;
    letter-spacing: 0px;
    color: var(--secondaryColor);
    opacity: 1;
    line-height: 40px;
    margin-top: 16px
  }

  .se-info-data-header-lsq{
    width: 322px;
    color: var(--lsqDataHeader);
  }

  > .se-contact-data-text{
      color:var(--labelColor);
      width: 907px;
      height: 32px;
      font-family: Regular;
      font-style: normal;
      font-size: 24px;
      line-height: 32px;
      text-align: center;
      letter-spacing: 0px;
      opacity: 1;
      margin-top: 12px;
  }
  .se-contact-data-text-lsq{
    width: 782px;
    height: 64px;
    color:var(--lsqText);
  }


  > .se-go-to-dashboard{
    border: 1px solid var(--border);
    font-family: Semibold;
    font-size: 12px;
    line-height: 15.96px;
    text-align: center;
    padding:8px 16px;
    color: var(--border);
    text-decoration: none;
    border-radius:2px;
    margin-top: 24px;
    transition: 300ms ease-out;
    cursor: pointer;
    &:hover{
      background-color: rgba(0,111,124,0.1);
    }
  }
  .se-go-to-dashboard-lsq{
    border: 1px solid var(--lsqButton);
    color: var(--lsqText);
    background-color: var(--whiteBackground);
    &:hover{
      background-color: var(--white);
    }
  }

}
`;
export default StyledErrorScreenContainer;
