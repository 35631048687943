import React from 'react';

type Props = {
  width: number;
  height: number;
  fill?: string;
}

const PreDefinedIcon = ({
  width, height, fill,
}: Props) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 12 13"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M4.3469 6.96048L4.88181 5.24999L3.46871 4.15225H5.19217L5.75701 2.38289L6.30688 4.15225H8.0473L6.6342 5.24999L7.15513 6.96048L5.758 5.90465L4.3469 6.96048ZM2.25121 12.4931V7.9724C1.82437 7.53726 1.49715 7.01459 1.29218 6.44055C1.10102 5.90079 1.0025 5.3326 1.00078 4.76C0.989278 4.13184 1.10452 3.50782 1.33962 2.9252C1.57472 2.34259 1.92485 1.81334 2.3691 1.3691C2.81334 0.92485 3.34259 0.574716 3.9252 0.339617C4.50782 0.104517 5.13184 -0.0107223 5.76 0.000784079C6.38816 -0.0107223 7.01218 0.104517 7.5948 0.339617C8.17741 0.574716 8.70666 0.92485 9.1509 1.3691C9.59515 1.81334 9.94528 2.34259 10.1804 2.9252C10.4155 3.50782 10.5307 4.13184 10.5192 4.76C10.5178 5.33254 10.4196 5.90073 10.2288 6.44055C10.0238 7.01459 9.69662 7.53726 9.26978 7.9724V12.4931L5.76 11.3185L2.25121 12.4931ZM5.761 8.62605C6.27005 8.63044 6.77488 8.53342 7.24603 8.34064C7.71719 8.14785 8.14524 7.86317 8.5052 7.50321C8.86517 7.14324 9.14985 6.71519 9.34263 6.24404C9.53541 5.77288 9.63244 5.26805 9.62805 4.759C9.63244 4.24995 9.53541 3.74512 9.34263 3.27397C9.14985 2.80281 8.86517 2.37476 8.5052 2.0148C8.14524 1.65483 7.71719 1.37015 7.24603 1.17737C6.77488 0.984586 6.27005 0.887561 5.761 0.891952C5.25178 0.887425 4.74676 0.984384 4.27542 1.17717C3.80408 1.36995 3.37587 1.6547 3.01579 2.01479C2.6557 2.37487 2.37095 2.80309 2.17817 3.27442C1.98538 3.74576 1.88842 4.25078 1.89295 4.76C1.88856 5.26905 1.98558 5.77388 2.17837 6.24504C2.37115 6.71619 2.65583 7.14424 3.01579 7.50421C3.37576 7.86417 3.80381 8.14885 4.27496 8.34163C4.74612 8.53442 5.25095 8.63144 5.76 8.62705L5.761 8.62605ZM3.14338 11.2437L5.761 10.4254L8.37862 11.2437V8.7009C7.99255 8.98331 7.55936 9.19484 7.09925 9.32561C6.22569 9.58328 5.29631 9.58328 4.42275 9.32561C3.96264 9.19484 3.52944 8.98331 3.14338 8.7009V11.2437Z" fill={fill} />
  </svg>
);

PreDefinedIcon.defaultProps = {
  fill: '#A456BA',
};

export default PreDefinedIcon;
