import React from 'react';

type Props = {
  width: number;
  height: number;
  fill?: string;
}
const PinnedIcon = ({
  width, height, fill,
}: Props) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className="se-pinned-report-icon"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M13.334 7.49999V3.33399H14.167C14.3877 3.3332 14.5991 3.24519 14.7551 3.08914C14.9112 2.93309 14.9992 2.72167 15 2.50099C14.9995 2.28014 14.9116 2.06846 14.7555 1.9122C14.5994 1.75594 14.3878 1.66778 14.167 1.66699H5.83399C5.61331 1.66778 5.40189 1.7558 5.24585 1.91185C5.0898 2.06789 5.00178 2.27931 5.00099 2.49999C5.00178 2.72067 5.0898 2.93209 5.24585 3.08814C5.40189 3.24419 5.61331 3.3322 5.83399 3.33299H6.66699V7.49999C6.66699 8.16303 6.4036 8.79892 5.93476 9.26776C5.46592 9.7366 4.83003 9.99999 4.16699 9.99999V11.667H9.14199V17.5L9.97499 18.333L10.808 17.5V11.667H15.833V9.99999C15.1701 9.99973 14.5345 9.73622 14.0659 9.26741C13.5972 8.79859 13.334 8.16286 13.334 7.49999Z"
      fill={fill}
    />
  </svg>
);

PinnedIcon.defaultProps = {
  fill: 'var(--navSectionIcon)',
};

export default PinnedIcon;
