import React from 'react';

type Props = {
    width: number,
    height: number,
}

const LsqSieraIcon = ({ width, height }: Props) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 262 131"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M4.3182 2.32886V18.4856H20.7006V34.7878H36.7878V2.32886H4.3182Z"
      fill="var(--leadsquaredIconBlue)"

    />
    <path
      d="M41.9534 12.2267C40.6251 12.2267 40.6251 10.1889 41.9534 10.1889H44.1672C44.61 10.1889 45.0527 10.48 45.0527 10.9167V32.6044H45.9383C47.2666 32.6044 47.2666 34.6422 45.9383 34.6422H41.9534C40.6251 34.6422 40.6251 32.6044 41.9534 32.6044H42.8389V12.2267H41.9534Z"
      fill="var(--leadsquaredIconBlack)"

    />
    <path
      d="M56.4169 34.9333C51.3989 34.9333 47.7092 31.1489 47.7092 26.3456C47.7092 21.6878 51.3989 17.7578 56.1218 17.7578C60.697 17.7578 63.6488 21.5422 63.7964 26.2C63.7964 26.7822 63.5012 27.3644 62.7633 27.3644H49.7754C50.3658 30.5667 53.17 32.8956 56.4169 32.8956C57.5977 32.8956 59.3687 32.6044 60.4018 32.0222C61.1398 31.5856 61.5826 32.0222 61.8777 32.3133C62.1729 32.75 62.0253 33.3322 61.435 33.7689C59.9591 34.4967 57.8928 34.9333 56.4169 34.9333ZM49.7754 25.3267H61.5826C60.9922 22.1244 59.2211 19.65 55.9742 19.65C52.8748 19.65 50.2182 22.27 49.7754 25.3267Z"
      fill="var(--leadsquaredIconBlack)"

    />
    <path
      d="M79.736 34.7878C79.2932 34.7878 78.998 34.4967 78.998 34.06V32.0222C77.6697 33.9144 75.3083 35.0789 72.7993 35.0789C67.9289 35.0789 64.2392 31.1489 64.2392 26.4911C64.2392 21.8333 67.9289 18.0489 72.5041 18.0489C77.0794 18.0489 78.8505 21.3967 78.8505 21.3967V18.9222C78.8505 18.4856 79.1456 18.0489 79.5884 18.0489H81.3595C82.6878 18.0489 82.6878 20.0867 81.3595 20.0867H80.9167V32.8956H81.3595C82.6878 32.8956 82.6878 34.9333 81.3595 34.9333H79.736V34.7878ZM72.6517 19.7956C69.1096 19.7956 66.3054 22.7067 66.3054 26.2C66.3054 29.6933 68.6668 32.75 72.6517 32.75C74.718 32.75 78.998 31.1489 78.998 26.3456C78.998 22.9978 76.3414 19.7956 72.6517 19.7956Z"
      fill="var(--leadsquaredIconBlack)"

    />
    <path
      d="M98.9225 34.7878C98.4798 34.7878 98.1846 34.4967 98.1846 34.06V32.0222C96.8563 33.9144 94.4949 35.0789 91.9859 35.0789C87.1154 35.0789 83.4257 31.1489 83.4257 26.4911C83.4257 21.8333 87.1154 18.0489 91.6907 18.0489C96.266 18.0489 98.037 21.3967 98.037 21.3967V12.2267H97.5942C96.2659 12.2267 96.2659 10.1889 97.5942 10.1889H99.3653C99.8081 10.1889 100.103 10.48 100.103 11.0622V32.75H100.546C101.874 32.75 101.874 34.7878 100.546 34.7878H98.9225ZM91.8383 19.7956C88.2961 19.7956 85.492 22.7067 85.492 26.2C85.492 29.6933 87.8534 32.75 91.8383 32.75C93.9045 32.75 98.037 31.1489 98.037 26.3456C98.037 22.9978 95.528 19.7956 91.8383 19.7956Z"
      fill="var(--leadsquaredIconBlack)"

    />
    <path
      d="M114.124 17.7578C114.567 17.7578 115.157 18.0489 115.157 18.6311V22.4156C115.157 22.9978 114.715 23.2889 114.124 23.2889C113.534 23.2889 113.091 22.8522 113.091 22.4156C113.091 20.5233 111.32 19.5044 109.401 19.5044C107.778 19.5044 105.712 20.3778 105.712 22.27C105.712 23.8711 107.335 24.7444 110.287 25.3267C112.943 25.9089 115.6 26.6367 115.6 29.8389C115.6 32.8956 112.501 34.7878 109.697 34.7878C108.073 34.7878 106.302 34.2056 105.416 33.0411V33.9144C105.416 34.4967 104.826 34.7878 104.383 34.7878C103.941 34.7878 103.35 34.4967 103.35 33.9144V29.8389C103.35 29.2567 103.941 28.82 104.383 28.82C104.974 28.82 105.564 29.2567 105.564 29.8389C105.564 32.0222 107.778 32.8956 109.697 32.8956C111.32 32.8956 113.534 31.7311 113.534 29.8389C113.534 27.9467 111.32 27.51 109.697 27.2189C105.564 26.3456 103.645 24.89 103.645 22.27C103.645 19.3589 106.45 17.7578 109.106 17.7578C110.73 17.7578 112.206 18.4856 113.091 19.3589V18.6311C113.091 17.9033 113.534 17.7578 114.124 17.7578Z"
      fill="var(--leadsquaredIconBlue)"

    />
    <path
      d="M134.344 17.9033C135.672 17.9033 135.672 19.9411 134.344 19.9411H133.901V39.3H134.344C135.672 39.3 135.672 41.3378 134.344 41.3378H132.72C132.278 41.3378 131.982 41.0467 131.982 40.4644V31.2944C131.982 31.2944 130.211 34.6422 125.636 34.6422C120.913 34.6422 117.371 30.7122 117.371 26.2C117.371 21.5422 121.061 17.6122 125.931 17.6122C128.44 17.6122 130.654 18.9222 132.13 20.8145V18.6311C132.13 18.1945 132.425 17.9033 132.868 17.9033H134.344ZM125.636 19.65C121.651 19.65 119.142 22.7067 119.29 26.2C119.29 29.6933 122.094 32.6044 125.636 32.6044C129.326 32.6044 131.835 29.4022 131.835 26.0544C131.835 21.2511 127.85 19.65 125.636 19.65Z"
      fill="var(--leadsquaredIconBlue)"

    />
    <path
      d="M150.431 19.7956C149.103 19.7956 149.103 17.7578 150.431 17.7578H152.35C152.793 17.7578 153.235 18.0489 153.235 18.4856V32.6044H153.973C155.302 32.6044 155.302 34.6422 153.973 34.6422H151.907C151.464 34.6422 151.022 34.3511 151.022 33.7689V32.0222C149.693 33.9144 147.48 34.6422 145.266 34.6422C140.395 34.6422 138.034 31.44 138.034 27.2189V19.7956H137.296C135.968 19.7956 135.968 17.7578 137.296 17.7578H139.215C139.657 17.7578 140.1 18.0489 140.1 18.4856V27.2189C140.1 30.2756 141.723 32.6044 145.413 32.6044C148.66 32.6044 151.169 30.8578 151.169 26.9278V19.7956H150.431Z"
      fill="var(--leadsquaredIconBlue)"

    />
    <path
      d="M171.536 34.7878C171.094 34.7878 170.799 34.4967 170.799 34.06V32.0222C169.47 33.9144 167.109 35.0789 164.6 35.0789C159.729 35.0789 156.04 31.1489 156.04 26.4911C156.04 21.8333 159.729 18.0489 164.305 18.0489C168.88 18.0489 170.651 21.3967 170.651 21.3967V18.9222C170.651 18.4856 170.946 18.0489 171.389 18.0489H173.16C174.488 18.0489 174.488 20.0867 173.16 20.0867H172.717V32.8956H173.16C174.488 32.8956 174.488 34.9333 173.16 34.9333H171.536V34.7878ZM164.452 19.7956C160.91 19.7956 158.106 22.7067 158.106 26.2C158.106 29.6933 160.467 32.75 164.452 32.75C166.518 32.75 170.799 31.1489 170.799 26.3456C170.946 22.9978 168.29 19.7956 164.452 19.7956Z"
      fill="var(--leadsquaredIconBlue)"

    />
    <path
      d="M179.211 33.9144C179.211 34.3511 178.916 34.6422 178.473 34.6422H176.85C175.521 34.6422 175.521 32.6044 176.85 32.6044H177.292V19.7956H176.85C175.521 19.7956 175.521 17.7578 176.85 17.7578H178.473C178.916 17.7578 179.211 18.0489 179.211 18.6311V20.6689C180.687 18.4856 182.753 17.7578 185.262 17.7578C185.853 17.7578 186.295 18.0489 186.295 18.7767C186.295 19.7956 185.262 19.7956 185.262 19.7956H185.115C182.458 19.7956 179.211 21.2511 179.211 24.7444V33.9144Z"
      fill="var(--leadsquaredIconBlue)"

    />
    <path
      d="M194.56 34.9333C189.542 34.9333 185.853 31.1489 185.853 26.3456C185.853 21.6878 189.542 17.7578 194.265 17.7578C198.84 17.7578 201.792 21.5422 201.94 26.2C201.94 26.7822 201.645 27.3644 200.907 27.3644H187.919C188.509 30.5667 191.313 32.8956 194.56 32.8956C195.741 32.8956 197.512 32.6044 198.545 32.0222C199.283 31.5856 199.726 32.0222 200.021 32.3133C200.316 32.75 200.169 33.3322 199.578 33.7689C198.25 34.4967 196.036 34.9333 194.56 34.9333ZM187.919 25.3267H199.726C199.136 22.1244 197.365 19.65 194.118 19.65C191.018 19.65 188.362 22.27 187.919 25.3267Z"
      fill="var(--leadsquaredIconBlue)"

    />
    <path
      d="M217.879 34.7878C217.437 34.7878 217.141 34.4967 217.141 34.06V32.0222C215.813 33.9144 213.452 35.0789 210.943 35.0789C206.072 35.0789 202.383 31.1489 202.383 26.4911C202.383 21.8333 206.072 18.0489 210.648 18.0489C215.223 18.0489 216.994 21.3967 216.994 21.3967V12.2267H216.551C215.223 12.2267 215.223 10.1889 216.551 10.1889H218.322C218.765 10.1889 219.06 10.48 219.06 11.0622V32.75H219.503C220.831 32.75 220.831 34.7878 219.503 34.7878H217.879ZM210.795 19.7956C207.253 19.7956 204.449 22.7067 204.449 26.2C204.449 29.6933 206.81 32.75 210.795 32.75C212.861 32.75 216.994 31.1489 216.994 26.3456C216.994 22.9978 214.485 19.7956 210.795 19.7956Z"
      fill="var(--leadsquaredIconBlue)"

    />
    <path
      d="M20.7006 34.7878H4.3182V18.4855L20.7006 34.7878Z"
      fill="var(--leadsquaredIconBlack)"

    />
    <path
      d="M0.949003 123.703C3.13171 125.883 9.96453 131 22.3016 131C36.2519 131 44.698 121.145 44.698 111.384C44.698 98.4964 32.9304 92.7159 25.5282 89.7782C18.0311 86.7458 15.184 84.0924 15.184 80.5862C15.184 78.0276 17.7464 75.7533 21.1628 75.7533C26.9517 75.7533 33.8794 80.9653 34.7335 81.4391C35.5876 82.1024 37.2958 81.2496 37.9601 80.2072L42.8 72.9104C43.1796 72.2471 43.3694 70.5414 42.2306 69.878C39.3836 67.7933 31.7916 62.7708 21.922 62.7708C7.30732 62.7708 0 72.2471 0 81.6286C0 93.0001 10.2492 99.3492 18.4107 102.571C24.8639 105.13 28.1854 108.162 28.1854 112.142C28.1854 115.459 25.4333 117.828 21.6373 117.828C15.3738 117.828 8.54103 112.806 8.16142 112.616C7.49712 112.142 5.69402 111.953 4.93481 113.185L0.474502 121.05C-0.284701 122.377 0 122.756 0.949003 123.703Z"
      fill="var(--headerBackgroundColor)"
    />
    <path
      d="M56.2618 128.252C56.2618 129.2 57.1159 130.052 58.0649 130.052H69.4529C70.4019 130.052 71.256 129.2 71.256 128.252V65.5189C71.256 64.5713 70.4019 63.7185 69.4529 63.7185H58.0649C57.1159 63.7185 56.2618 64.5713 56.2618 65.5189V128.252Z"
      fill="var(--headerBackgroundColor)"
    />
    <path
      d="M143.192 128.252C143.192 129.2 143.951 130.052 144.995 130.052H156.288C157.237 130.052 158.091 129.2 158.091 128.252V104.087H164.639L177.261 129.2C177.451 129.579 178.02 130.052 178.78 130.052H191.401C193.015 130.052 193.679 128.536 193.015 127.304L180.013 103.235C188.27 99.9178 194.058 92.9054 194.058 84.1872C194.058 72.9104 184.758 63.7185 173.37 63.7185H144.995C143.951 63.7185 143.192 64.5713 143.192 65.5189V128.252ZM158.091 92.0525V77.2695H172.137C176.027 77.2695 179.349 80.4915 179.349 84.4715C179.349 88.4515 176.027 92.0525 172.137 92.0525H158.091Z"
      fill="var(--headerBackgroundColor)"
    />
    <path
      d="M199.84 130.052H210.279C212.177 130.052 213.221 128.915 213.695 127.778L217.112 120.197H242.925L246.341 127.683C247.195 129.484 247.954 130.052 249.663 130.052H260.197C261.62 130.052 262.379 128.82 261.81 127.589L232.106 63.8132C231.821 63.2446 231.157 62.7708 230.493 62.7708H229.544C228.879 62.7708 228.215 63.2446 227.93 63.8132L198.227 127.589C197.657 128.82 198.416 130.052 199.84 130.052ZM222.806 107.309L229.923 91.6735H230.018L237.231 107.309H222.806Z"
      fill="var(--headerBackgroundColor)"
    />
    <path
      d="M87.846 77.617H130.867V63.7185L87.846 63.7185V77.617Z"
      fill="url(#paint0_linear_401_7034)"
    />
    <path
      d="M87.8462 103.519H125.806V89.6203H87.8462V103.519Z"
      fill="url(#paint1_linear_401_7034)"
    />
    <path
      d="M87.846 130.052H130.235V116.154H87.846V130.052Z"
      fill="url(#paint2_linear_401_7034)"
    />
    <defs>
      <linearGradient
        id="paint0_linear_401_7034"
        x1="130.867"
        y1="70.6677"
        x2="87.846"
        y2="70.6677"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="var(--leadsquaredLightBlue)" />
        <stop offset="1" stopColor="var(--blueBorderColor)" />
      </linearGradient>
      <linearGradient
        id="paint1_linear_401_7034"
        x1="125.806"
        y1="96.5695"
        x2="87.8462"
        y2="96.5695"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="var(--leadsquaredLightBlue)" />
        <stop offset="1" stopColor="var(--blueBorderColor)" />
      </linearGradient>
      <linearGradient
        id="paint2_linear_401_7034"
        x1="130.235"
        y1="123.103"
        x2="87.846"
        y2="123.103"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="var(--leadsquaredLightBlue)" />
        <stop offset="1" stopColor="var(--blueBorderColor)" />
      </linearGradient>
    </defs>
  </svg>

);

export default LsqSieraIcon;
