import React from 'react';

type Props = {
  width?: number;
  height?: number;
  strokeFill?: string;
}

const ArrowLeftIcon = ({
  width, height, strokeFill,
}: Props) => (
  <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg" style={{ width, height }}>
    <g id="expand_more_black_24dp">
      <g id="arrow_back">
        <mask id="mask0_1_929" maskUnits="userSpaceOnUse" x="0" y="0" width="20" height="20">
          <rect id="Bounding box" width="20" height="20" fill="#D9D9D9" />
        </mask>
        <g mask="url(#mask0_1_929)">
          <path id="arrow_back_2" d="M9.99998 16.6667L3.33331 10L9.99998 3.33334L11.1875 4.50001L6.52081 9.16668H16.6666V10.8333H6.52081L11.1875 15.5L9.99998 16.6667Z" fill={strokeFill} />
        </g>
      </g>
    </g>
  </svg>
);

ArrowLeftIcon.defaultProps = {
  width: 20,
  height: 20,
  strokeFill: 'var(--primaryColor)',
};

export default ArrowLeftIcon;
