import React from 'react';

type Props = {
  width: number;
  height: number;
  fill?: string;
}

const DraggableIcon = ({
  width, height, fill,
}: Props) => (
  <svg
    id="drag_indicator_black_24dp"
    xmlns="http://www.w3.org/2000/svg"
    style={{
      width,
      height,
    }}
    viewBox="0 0 15.996 15.996"
  >
    <path
      id="Path_7617"
      data-name="Path 7617"
      d="M0,0H16V16H0Z"
      fill="none"
    />
    <path
      id="Path_7618"
      data-name="Path 7618"
      d="M9.666,13.331A1.333,1.333,0,1,1,8.333,12,1.337,1.337,0,0,1,9.666,13.331ZM8.333,8A1.333,1.333,0,1,0,9.666,9.332,1.337,1.337,0,0,0,8.333,8Zm0-4A1.333,1.333,0,1,0,9.666,5.333,1.337,1.337,0,0,0,8.333,4Zm4,2.666A1.333,1.333,0,1,0,11,5.333,1.337,1.337,0,0,0,12.332,6.666Zm0,1.333a1.333,1.333,0,1,0,1.333,1.333A1.337,1.337,0,0,0,12.332,8Zm0,4a1.333,1.333,0,1,0,1.333,1.333A1.337,1.337,0,0,0,12.332,12Z"
      transform="translate(-2.334 -1.334)"
      className="icon-stroke"
      fill={fill}
    />
  </svg>
);

DraggableIcon.defaultProps = {
  fill: '#adbac7',
};

export default DraggableIcon;
