import React from 'react';

type Props = {
  width?: number;
  height?: number;
  fill?: string;
}

const OptionTableIcon = ({
  width, height, fill,
}: Props) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect
      width="16"
      height="16"
      fill="transparent"
    />
    <path
      d="M12.9334 2.3999H2.93343C2.2001 2.3999 1.6001 2.9999 1.6001 3.73324V13.0666C1.6001 13.7999 2.2001 14.3999 2.93343 14.3999H12.9334C13.6668 14.3999 14.2668 13.7999 14.2668 13.0666V3.73324C14.2668 2.9999 13.6668 2.3999 12.9334 2.3999ZM12.9334 3.73324V5.73324H2.93343V3.73324H12.9334ZM9.6001 13.0666H6.26676V7.06657H9.6001V13.0666ZM2.93343 7.06657H4.93343V13.0666H2.93343V7.06657ZM10.9334 13.0666V7.06657H12.9334V13.0666H10.9334Z"
      fill={fill}
    />
  </svg>

);

OptionTableIcon.defaultProps = {
  width: 16,
  height: 16,
  fill: 'var(--secondaryColor)',
};

export default OptionTableIcon;
