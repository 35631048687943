import React from 'react';

type Props = {
  width: number;
  height: number;
  fill?: string;
}

const TaskIcon = ({
  width, height, fill,
}: Props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={width}
    height={height}
    viewBox="0 0 12 13.333"
  >
    <path
      id="assignment_turned_in_FILL0_wght400_GRAD0_opsz48"
      d="M11.05,11.85l4.617-4.617-.717-.717-3.9,3.9L9.033,8.4l-.7.7ZM7,15.333a1,1,0,0,1-1-1v-10a1,1,0,0,1,1-1h3.417a1.479,1.479,0,0,1,.533-.958,1.658,1.658,0,0,1,2.1,0,1.479,1.479,0,0,1,.533.958H17a1,1,0,0,1,1,1v10a1,1,0,0,1-1,1Zm0-1H17v-10H7ZM12,4.05a.578.578,0,0,0,.408-.992.578.578,0,1,0-.817.817A.56.56,0,0,0,12,4.05ZM7,14.333v0Z"
      transform="translate(-6 -2)"
      fill={fill}
    />
  </svg>
);

TaskIcon.defaultProps = {
  fill: 'var(--searchBoxTextColor)',
};

export default TaskIcon;
