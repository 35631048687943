import { Dispatch } from '@reduxjs/toolkit';
import { Direction, ICustomAnalyticsPayload } from 'core/models/custom-analytics';
import { updateCustomAnalyticsListDashboardPayload, updateCustomAnalyticsListReportPayload } from 'redux-v2/customAnalytics/custom-analytics.reducer';

export const getInitials = (owner: string) => {
  if (owner) {
    const splitName = owner?.trim().split(' ');
    if (splitName?.length > 1) {
      return `${splitName[0][0].toUpperCase()}${splitName[1][0].toUpperCase()}`;
    }
    return owner.charAt(0).toUpperCase();
  }
  return '';
};

const getNewDirection = (index: number, existingPayload: ICustomAnalyticsPayload) => ((index !== -1 && existingPayload.Sort[index].Direction === Direction.DESC) ? Direction.ASC : Direction.DESC);

export const multiSort = (existingPayload: ICustomAnalyticsPayload, params: any, isCtrlPressed: boolean, sortDirectionCycle: Array<Direction>, isReport: boolean, dispatch: Dispatch<any>) => {
  const index = existingPayload?.Sort?.findIndex((item) => item.Field === params.column.colId);
  let newDirection;

  if (!isCtrlPressed) {
    // Single column sort
    newDirection = getNewDirection(index, existingPayload);
    existingPayload.Sort = [];
    existingPayload.Sort.push({ Field: params.column.colId, Direction: newDirection });
  } else if (index !== -1) {
    if (existingPayload.Sort?.length === 1 && existingPayload.Sort[index].Field === params.column.colId) {
      newDirection = getNewDirection(index, existingPayload);
      existingPayload.Sort = [];
      existingPayload.Sort.push({ Field: params.column.colId, Direction: newDirection });
      if (isReport) {
        dispatch(updateCustomAnalyticsListReportPayload(existingPayload));
      } else {
        dispatch(updateCustomAnalyticsListDashboardPayload(existingPayload));
      }
      return;
    }
    // multi column sort
    const currentDirection = existingPayload.Sort[index].Direction;
    newDirection = sortDirectionCycle[(sortDirectionCycle.indexOf(currentDirection) + 1) % 3]; // Cycle through ASC, DESC, and null
    if (newDirection === null) {
      existingPayload.Sort.splice(index, 1); // Remove column from sort if new direction is null
    } else {
      existingPayload.Sort[index] = { ...existingPayload.Sort[index], Direction: newDirection };
    }
  } else {
    existingPayload.Sort.push({ Field: params.column.colId, Direction: Direction.ASC });
  }
  if (isReport) {
    dispatch(updateCustomAnalyticsListReportPayload(existingPayload));
  } else {
    dispatch(updateCustomAnalyticsListDashboardPayload(existingPayload));
  }
};

export const defaultSortOnField = 'ModifiedOn';
