import { Obj } from 'core/models/obj';

export default class VariableParser {
  _variables : Obj

  constructor(variables: Obj) {
    this._variables = variables;
  }

  ContainsVariable = (stringWithVariable: any) : [any, boolean] => {
    if (typeof stringWithVariable !== 'string') {
      return [stringWithVariable, false];
    }
    let found = false;
    let variableKey = '';
    Object.keys(this._variables).forEach((key: string) => {
      if (stringWithVariable.indexOf(key) !== -1) {
        found = true;
        variableKey = key;
      }
    });
    return [variableKey, found];
  }

  ReplaceVariable = (stringWithVariable: any) : any => {
    const variableDetails: [string, boolean] = this.ContainsVariable(stringWithVariable);
    const key = variableDetails[0];
    const variableFound = variableDetails[1];
    if (variableFound && key && key.length) {
      if (stringWithVariable === `{{${key}}}`) {
        return this._variables[key];
      }
      return stringWithVariable.replace(`{{${key}}}`, this._variables[key]);
    }
    return stringWithVariable;
  }
}
