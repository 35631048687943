import { FeatureList, routePaths } from 'core/constants/common';
import { ReduxModel } from 'core/models';
import { getFeatureFromPath } from 'core/utils/common.utility';
import FeatureFactory from 'features/feature-factory';
import FeatureDefinitions from 'features/featureDefinitions';
import React from 'react';
import { shallowEqual, useSelector } from 'react-redux';
import { Redirect, Route, RouteProps } from 'react-router-dom';

// We are taking in the component that should be rendered only if the user has not having reporting feature disabled
// We are also passing the rest of the props to the <Route /> component
const ProtectedRoute: React.FC<RouteProps> = ({ ...routerProps }) => {
  const { path } = routerProps;
  const disabledFeatures = useSelector((state: ReduxModel.IGlobalState) => state.auth.disabledFeatures as string[], shallowEqual);
  const featureId: FeatureList = getFeatureFromPath(path as routePaths);
  const feature = new FeatureFactory(featureId, disabledFeatures).getFeature();
  const featureInfo = FeatureDefinitions[featureId];

  if (!feature.isAccessible() && featureInfo.isAdminOnly) {
    return <Redirect to={routePaths.noAccessRoute} />;
  }

  if (!feature.isAccessible()) {
    return <Redirect to={routePaths.unauthorized} />;
  }
  return <Route {...routerProps} />;
};

export default ProtectedRoute;
