import {
  ISelectedReport,
  ISelectedDashboard,
  IGetVisibilityResponseValue,
  IAccordDetail,
  IDropdownListAPIResponse,
  IUpdateSelectReport,
  ProfileSelectionType,
  IDropdownList,
} from 'core/models/profile';
import cloneDeep from 'lodash/cloneDeep';
import isEmpty from 'lodash/isEmpty';
import {
  IDashboardLinkedReport,
  IDashboardListData, IDashboardSection, IReportData, IReportSection, ITagsData, ListingSection,
  SourceTypes,
} from 'redux-v2/report-listing/report-listing.state';
import { groupReportBasedOnTags } from 'redux-v2/report-listing/report-listing.util';
import { isValidArrayAndNotEmpty } from 'components/feature/Report/ReportSidebar/common/helpers';
import { IProfileState } from './profile.state';

// Update the Report section on on edit flow
// @reportSections - Transformed report list data
// @selectedReports - Selected reports from API response
// @returns reportSections (Transaformed list data);
export const getUpdatedReportSection = (
  reportSections: Array<IReportSection>,
  selectedReports: Array<ISelectedReport>,
): Array<IReportSection> => {
  const selectedIDkeys = new Set([
    ...selectedReports.map(
      (report: ISelectedReport) => report.Id,
    ),
  ]);
  const linkedReportIds = new Set([
    ...selectedReports
      .filter((rep: ISelectedReport) => rep.AdjoinedByDashboard)
      .map((report: ISelectedReport) => report.Id),
  ]);
  const pinnedIDkeys = new Set([
    ...selectedReports
      .filter((rep: ISelectedReport) => rep.IsPinnedInProfile)
      .map((report: ISelectedReport) => report.Id),
  ]);
  reportSections?.forEach((repSection: IReportSection) => {
    repSection.Reports?.Data?.forEach((report: IReportData) => {
      const uniqueId = report.Id;
      if (selectedIDkeys.has(uniqueId)) {
        report.isProfileReportSelected = true;
      }

      if (pinnedIDkeys.has(uniqueId)) {
        report.IsPinnedInProfile = true;
      }

      if (linkedReportIds.has(uniqueId)) {
        report.AdjoinedByDashboard = true;
      }
    });

    if (
      repSection.Reports?.Data.every(
        (report: IReportData) => report.isProfileReportSelected,
      )
    ) {
      // eslint-disable-next-line no-param-reassign
      repSection.isProfileSectionSelected = true;
    }
  });

  return reportSections;
};

// Update the Dashboard section on on edit flow
// @dashboardSections - Transformed dashboard list data
// @selectedDashboard - Selected dashboards from API response
export const getUpdatedDashboardSection = (
  dashboardSections: Array<IDashboardSection>,
  selectedDashboard: Array<ISelectedDashboard>,
): Array<IDashboardSection> => {
  const selectedIDkeys = new Set([
    ...selectedDashboard.map((dashboard: ISelectedDashboard) => dashboard.Id),
  ]);
  const pinnedIDkeys = new Set([
    ...selectedDashboard
      .filter((item: ISelectedDashboard) => item.IsPinnedInProfile)
      .map((dashboard: ISelectedDashboard) => dashboard.Id),
  ]);
  dashboardSections?.forEach((section: IDashboardSection) => {
    section.Dashboards?.Data?.forEach((dashboard: IDashboardListData) => {
      const uniqueId = dashboard.Id;
      if (selectedIDkeys.has(uniqueId)) {
        dashboard.isDashboardSelected = true;
      }

      if (pinnedIDkeys.has(uniqueId)) {
        dashboard.IsPinnedInProfile = true;
      }
    });

    if (
      section.Dashboards?.Data?.every(
        (dashboard: IDashboardListData) => dashboard.isDashboardSelected,
      )
    ) {
      // eslint-disable-next-line no-param-reassign
      section.isProfileSectionSelected = true;
    }
  });

  return dashboardSections;
};

// Group the report list based on the tags
// @payload - List of reports
// @globalTags - List of tags
export const getReportSections = (
  payload: Array<IReportData>,
  globalTags: ITagsData[],
) => {
  let newRes: IReportSection[] = [];
  if (payload) {
    newRes = groupReportBasedOnTags(payload, globalTags, true);
  }
  return [...newRes];
};

// Convert the accord data to the support React select dropdown props
// @state - Profile state
// @payload - Visibility API response
export const updateAccordInfo = (
  state: IProfileState,
  payload: IGetVisibilityResponseValue,
) => {
  const { value, id } = payload;
  const newAccord: IAccordDetail = { ...state.profileDetailInfo.accord };
  newAccord[id] = isValidArrayAndNotEmpty(value) ? value.map((item: IDropdownListAPIResponse) => ({
    label: item.DisplayName,
    value: item.Value,
  })) : [];
  return newAccord;
};

// Returns the report section, on selection of card, section and select all (This method is majorily for report selection on dashboard)
// @state - Profile state
// @payload - Payload to hold information of selection (True/false), selectedItem (All,Card,Section), ID and linkedReports of dashboard
export const updateReportSectionBasedReport = (
  state: IProfileState,
  payload: IUpdateSelectReport,
) => {
  if (payload?.dashboardLinkedReports?.length > 0) {
    return linkedReportSelection(state, payload);
  }

  return state.profileListing.selectedProfileTab !== ListingSection.Report
      || isEmpty(payload)
    ? [...state?.profileListing?.reportSections]
    : updateProfileReportSelection(state, payload);
};

// Returns the report section, condition checked on selectedItem
// @state - Profile state
// @payload - Payload to hold information of selection (True/false), selectedItem (All,Card,Section), ID and linkedReports of dashboard
export const updateProfileReportSelection = (
  state: IProfileState,
  payload: IUpdateSelectReport,
): Array<IReportSection> => {
  const newReportSections = cloneDeep(state.profileListing.reportSections);
  const { selectedItem, selected } = payload;
  const searchkey = state.profileListing.reportSearchKey.toLocaleLowerCase();
  if (selectedItem === ProfileSelectionType.All) {
    return allReportSelection(newReportSections, selected, searchkey, state.selectedReportTypes);
  }
  if (selectedItem === ProfileSelectionType.Card) {
    return singleReportSelection(newReportSections, payload);
  }
  return sectionReportSelection(newReportSections, payload, searchkey);
};

// Returns the dashboard section, condition checked on selectedItem
// @state - Profile state
// @payload - Payload to hold information of selection (True/false), selectedItem (All,Card,Section), ID and linkedReports of dashboard
export const updateProfileDashboardSelection = (
  state: IProfileState,
  payload: IUpdateSelectReport,
): Array<IDashboardSection> => {
  const newDashboardSections = cloneDeep(
    state.profileListing.dashboardSections,
  );
  const { selectedItem, selected } = payload;
  const searchkey = state.profileListing.dashboardSearchKey.toLocaleLowerCase();
  if (selectedItem === ProfileSelectionType.All) {
    return allDashboardSelection(newDashboardSections, selected, searchkey);
  }
  if (selectedItem === ProfileSelectionType.Card) {
    return singleDashboardSelection(newDashboardSections, payload);
  }
  return sectionDashboardSelection(newDashboardSections, payload, searchkey);
};

// Returns the report section, when selectedItem is All
// @newStateFilterReportSection - Report Section
// @selected - Selection value (True/false) of Selected All in report section
// @searchKey - Search key of report section
export const allReportSelection = (
  newStateFilterReportSection: Array<IReportSection>,
  selected: boolean,
  searchKey: string,
  selectedReportTypes: string[],
) => newStateFilterReportSection.map((section: IReportSection) => ({
  ...section,
  isProfileSectionSelected: selected,
  Reports: {
    ...section.Reports,
    Data: section.Reports?.Data?.map((report: IReportData) => {
      if ((selectedReportTypes?.includes(SourceTypes.LOGI) && report?.SourceType === SourceTypes.LSQInternal) || selectedReportTypes?.includes(report?.SourceType)) {
        return {
          ...report,
          isProfileReportSelected:
                !report.Name.toLocaleLowerCase().includes(searchKey)
                || report.AdjoinedByDashboard
                  ? report.isProfileReportSelected
                  : selected,
        };
      }
      return report;
    }),
  },
}));

// Returns the report section, when selectedItem is section
// @newStateFilterReportSection - Report Section
// @selected - Selection value (True/false) of section (Tags) in report section
// @searchKey - Search key of report section
export const sectionReportSelection = (
  newStateFilterReportSection: Array<IReportSection>,
  payload: IUpdateSelectReport,
  searchKey: string,
): Array<IReportSection> => {
  const { selected, id } = payload;
  return newStateFilterReportSection.map((section: IReportSection) => ({
    ...section,
    isProfileSectionSelected:
        section.SectionId === id ? selected : section.isProfileSectionSelected,
    Reports: {
      ...section.Reports,
      Data: section.Reports?.Data?.map((report: IReportData) => ({
        ...report,
        isProfileReportSelected:
            // eslint-disable-next-line no-nested-ternary
            section.SectionId === id
            && report.Name.toLocaleLowerCase().includes(searchKey)
              ? report.AdjoinedByDashboard
                ? report.isProfileReportSelected
                : selected
              : report.isProfileReportSelected,
      })),
    },
  }));
};

// Returns the report section, when selectedItem is card
// @newStateFilterReportSection - Report Section
// @payload - Payload to hold information of selection (True/false), selectedItem (All,Card,Section), ID and linkedReports of dashboard
export const singleReportSelection = (
  newStateFilterReportSection: Array<IReportSection>,
  payload: IUpdateSelectReport,
) => {
  const { id, selected } = payload;
  return newStateFilterReportSection.map((section: IReportSection) => ({
    ...section,
    Reports: {
      ...section.Reports,
      Data: section.Reports?.Data.map((report: IReportData) => ({
        ...report,
        isProfileReportSelected:
            report.Id === id
              ? selected
              : !!report.isProfileReportSelected,
      })),
    },
  }));
};

// Returns the dashboard section, when selectedItem is All
// @newStateFilterDashboardSection - dashboard Section
// @selected - Selection value (True/false) of Selected All in dashboard section
// @searchKey - Search key of dashboard section
export const allDashboardSelection = (
  newStateFilterDashboardSection: Array<IDashboardSection>,
  selected: boolean,
  searchKey: string,
) => newStateFilterDashboardSection.map((section: IDashboardSection) => ({
  ...section,
  isProfileSectionSelected: selected,
  Dashboards: {
    ...section.Dashboards,
    Data: section.Dashboards?.Data?.map((dashboard: IDashboardListData) => ({
      ...dashboard,
      isDashboardSelected: dashboard.Name.toLocaleLowerCase().includes(
        searchKey,
      )
        ? selected
        : dashboard.isDashboardSelected,
    })),
  },
}));

// Returns the dashboard section, when selectedItem is section
// @newStateFilterDashboardSection - Dashboard Section
// @selected - Selection value (True/false) of section (Tags) in report section
// @searchKey - Search key of report section
export const sectionDashboardSelection = (
  newStateFilterDashboardSection: Array<IDashboardSection>,
  payload: IUpdateSelectReport,
  searchKey: string,
): Array<IDashboardSection> => {
  const { selected, id } = payload;
  return newStateFilterDashboardSection.map((section: IDashboardSection) => ({
    ...section,
    isProfileSectionSelected:
        section.SectionId === id ? selected : section.isProfileSectionSelected,
    Dashboards: {
      ...section.Dashboards,
      Data: section.Dashboards?.Data?.map((dashboard: IDashboardListData) => ({
        ...dashboard,
        isDashboardSelected:
            section.SectionId === id
            && dashboard.Name.toLocaleLowerCase().includes(searchKey)
              ? selected
              : dashboard.isDashboardSelected,
      })),
    },
  }));
};

// Returns the dashboard section, when selectedItem is card
// @newStateFilterDashboardSection - Dashboard Section
// @payload - Payload to hold information of selection (True/false), selectedItem (All,Card,Section), ID and linkedReports of dashboard
export const singleDashboardSelection = (
  newStateFilterDashboardSection: Array<IDashboardSection>,
  payload: IUpdateSelectReport,
) => {
  const { id, selected } = payload;

  return newStateFilterDashboardSection.map((section: IDashboardSection) => ({
    ...section,
    Dashboards: {
      ...section.Dashboards,
      Data: section.Dashboards?.Data?.map((dashboard: IDashboardListData) => ({
        ...dashboard,
        isDashboardSelected:
            dashboard.Id === id ? selected : !!dashboard.isDashboardSelected,
      })),
    },
  }));
};

// Selection of report in the report section store, based on the dashboard selected
// @state - Profile state
// @payload - On selection of any checkbox to identify the selection state, id's etc
export const linkedReportSelection = (
  state: IProfileState,
  payload: IUpdateSelectReport,
) => {
  const { selected, dashboardLinkedReports } = payload;
  const reportIds = dashboardLinkedReports.map(
    (report: IDashboardLinkedReport) => report.Id,
  );
  const selectedreportIds = new Set([...reportIds]);

  const linkedReportIds = getDashboardLinkedReportIds(state, payload);
  const setLinkedReportIds = new Set([...linkedReportIds]);

  return state.profileListing.reportSections.map((section: IReportSection) => ({
    ...section,
    Reports: {
      ...section.Reports,
      Data: section.Reports?.Data?.map((report: IReportData) => ({
        ...report,
        AdjoinedByDashboard: !!setLinkedReportIds.has(
          report.Id,
        ),
        isProfileReportSelected:
            selected
            && selectedreportIds.has(report.Id)
              ? selected
              : !!report.isProfileReportSelected,
      })),
    },
  }));
};

// Get the reports ids of the selected dashboard
// @state - Profile state
// @payload - On selection of any checkbox to identify the selection state, id's etc
export const getDashboardLinkedReportIds = (
  state: IProfileState,
  payload: IUpdateSelectReport,
) => {
  if (isEmpty(payload)) return [];
  const updatedDashboardList = updateProfileDashboardSelection(state, payload);
  let linkedDashboardReports: Array<IDashboardLinkedReport> = [];
  updatedDashboardList.forEach((section: IDashboardSection) => {
    const dashboards = section && section?.Dashboards?.Data;
    if (dashboards && dashboards?.length > 0) {
      section.Dashboards?.Data?.forEach((dashboard: IDashboardListData) => {
        if (dashboard.isDashboardSelected && dashboard?.Reports && dashboard?.Reports.length > 0) {
          linkedDashboardReports = [
            ...linkedDashboardReports,
            ...dashboard?.Reports,
          ];
        }
      });
    }
  });
  const linkedReportIds = linkedDashboardReports.map(
    (report: IDashboardLinkedReport) => report.Id,
  );
  return linkedReportIds;
};

// On Selection of the report card, check if all the report in the section is selected to mark the section as selected
// @state - Profile state
export const updateProfileReportSectionCheckbox = (state: IProfileState) => state.profileListing.reportSections.map((section: IReportSection) => ({
  ...section,
  isSectionLinkedReportsSelected: section.Reports?.Data.every(
    (repo: IReportData) => repo.AdjoinedByDashboard === true,
  ),
  isProfileSectionSelected: section.Reports?.Data?.filter((rep) => rep.Name.toLocaleLowerCase().includes(
    state.profileListing.reportSearchKey.toLocaleLowerCase(),
  )).every((item: IReportData) => {
    if ((state.selectedReportTypes?.includes(SourceTypes.LOGI)
      && (item.SourceType === SourceTypes.LOGI || item.SourceType === SourceTypes.LSQInternal)) || state.selectedReportTypes?.includes(item.SourceType)) {
      return item.isProfileReportSelected === true;
    }
    return false;
  }),
}));

// On Selection of the dashboard card, check if all the dashboard in the section is selected to mark the section as selected
// @state - Profile state
export const updateProfileDashboardSectionCheckbox = (state: IProfileState) => state.profileListing.dashboardSections.map((section: IDashboardSection) => ({
  ...section,
  isProfileSectionSelected: section?.Dashboards?.Data?.filter(
    (dashboard: IDashboardListData) => dashboard.Name.toLocaleLowerCase().includes(
      state.profileListing.dashboardSearchKey.toLocaleLowerCase(),
    ),
  ).every((dash: IDashboardListData) => dash.isDashboardSelected === true),
}));

// Returns whether Selected All is true/false based on the search key
// @state - Profile state
// @searchKey - search key of the report section
export const getIsAllSelectedReportStatusFilteredBySearchKey = (
  state: IProfileState,
  searchKey: string,
): boolean => {
  // get the sections groups available after the search applied
  const filteredSections = getFilteredReportSectionGroupsBySearchKey(
    state.profileListing.reportSections,
    searchKey,
  );

  // check if all the reports are selected from filtered section groups
  const isAnyReportNotSelected = filteredSections.filter(
    (section: IReportSection) => section.Reports?.Data?.filter((report: IReportData) => report.Name.toLocaleLowerCase().includes(searchKey)).some((report) => !!report.isProfileReportSelected === false),
  );
  return isAnyReportNotSelected.length === 0;
};

// Returns whether Selected All is true/false based on the search key in dashobard section
// @state - Profile state
// @searchKey - search key of the dashboard section
export const getIsAllSelectedDashboardStatusFilteredBySearchKey = (
  state: IProfileState,
  searchKey: string,
): boolean => {
  // get the sections groups available after the search applied
  const filteredSections = getFilteredDashboardSectionGroupsBySearchKey(
    state.profileListing.dashboardSections,
    searchKey,
  );

  // check if all the reports are selected from filtered section groups
  const isAnyDashboardNotSelected = filteredSections.filter(
    (section: IDashboardSection) => section.Dashboards?.Data?.filter((dashboard: IDashboardListData) => dashboard.Name.toLocaleLowerCase().includes(searchKey)).some((dashboard) => !!dashboard.isDashboardSelected === false),
  );
  return isAnyDashboardNotSelected.length === 0;
};

// Returns report section based on the report search key
// @sectionGroups - Report section
// @searchkey - Search key of the report Section
export const getFilteredReportSectionGroupsBySearchKey = (
  sectionGroups: IReportSection[],
  searchKey: string,
) => sectionGroups.filter((section) => section.Reports?.Data.some((report) => report.Name.toLocaleLowerCase().includes(searchKey)));

// Returns dashboard section based on the dashboard search key
// @sectionGroups - dashboard section
// @searchkey - Search key of the dashboard Section
export const getFilteredDashboardSectionGroupsBySearchKey = (
  sectionGroups: IDashboardSection[],
  searchKey: string,
) => sectionGroups.filter((section) => section.Dashboards?.Data?.some((dashboard) => dashboard.Name.toLocaleLowerCase().includes(searchKey)));

// Update the selection and return the report section based on the report search key
// @state - Profile State
// @searchkey - Search key of the report Section
export const updateReportSectionGroupsOnChangeOfFilterKey = (
  state: IProfileState,
  searchKey: string,
) => {
  const filteredSectionsBySearchKey = getFilteredReportSectionGroupsBySearchKey(
    state.profileListing.reportSections,
    searchKey,
  );

  // push all the sections group id's where all the available reports (search result) are selected
  const allReportsSelectedSectionGroups: Array<string> = [];
  filteredSectionsBySearchKey.forEach((section) => {
    const serachedReports = section.Reports?.Data?.filter((rep) => rep.Name.toLocaleLowerCase().includes(searchKey));
    if (serachedReports.every((rep) => rep.isProfileReportSelected)) {
      allReportsSelectedSectionGroups.push(section.SectionId);
    }
  });

  state.profileListing.reportSections.forEach((section) => {
    if (allReportsSelectedSectionGroups.includes(section.SectionId)) {
      // eslint-disable-next-line no-param-reassign
      section.isProfileSectionSelected = true;
    } else {
      // eslint-disable-next-line no-param-reassign
      section.isProfileSectionSelected = false;
    }
  });

  const updatedSectionGroups = [...state.profileListing.reportSections];
  return updatedSectionGroups;
};

// Update the selection and return the dashboard section based on the dashboard search key
// @state - Profile State
// @searchkey - Search key of the dashboard Section
export const updateDashboardSectionGroupsOnChangeOfFilterKey = (
  state: IProfileState,
  searchKey: string,
) => {
  const filteredSectionsBySearchKey = getFilteredDashboardSectionGroupsBySearchKey(
    state.profileListing.dashboardSections,
    searchKey,
  );

  // push all the sections group id's where all the available reports (search result) are selected
  const allDashboardsSelectedSectionGroups: Array<string> = [];
  filteredSectionsBySearchKey.forEach((section) => {
    const serachedDashboards = section.Dashboards?.Data?.filter((rep) => rep.Name.toLocaleLowerCase().includes(searchKey));
    if (serachedDashboards.every((rep) => rep.isDashboardSelected)) {
      allDashboardsSelectedSectionGroups.push(section.SectionId);
    }
  });

  state.profileListing.dashboardSections.forEach((section) => {
    if (allDashboardsSelectedSectionGroups.includes(section.SectionId)) {
      // eslint-disable-next-line no-param-reassign
      section.isProfileSectionSelected = true;
    } else {
      // eslint-disable-next-line no-param-reassign
      section.isProfileSectionSelected = false;
    }
  });

  const updatedSectionGroups = [...state.profileListing.dashboardSections];
  return updatedSectionGroups;
};

// On Profile update form, update the accord as per the form value change
// @Accord - Form value of the accord
// @state - Profile state
export const updateSelectedAccord = (
  Accord: { [key: string]: Array<string> },
  state: IProfileState,
) => {
  const newSelectedAccordList: { [key: string]: IDropdownList[] } = {};
  Object.keys(state.profileDetailInfo.accord)?.forEach((key: string) => {
    newSelectedAccordList[key] = state.profileDetailInfo.accord[key]?.filter(
      (item) => Accord[key]?.includes(item.value) && item,
    );
  });
  return newSelectedAccordList;
};
