import React from 'react';

type Props = {
  width?: number;
  height?: number;
  fill?: string;
  rotate?: string;
};

const SingleRightArrow = ({
  width, height, fill, rotate,
}: Props) => (
  <svg
    style={{
      width,
      height,
      rotate,
    }}
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path fill={fill} d="M7.15898 6.175L8.33398 5L13.334 10L8.33398 15L7.15898 13.825L10.9757 10L7.15898 6.175Z" />
  </svg>
);

SingleRightArrow.defaultProps = {
  width: 20,
  height: 20,
  fill: 'var(--secondaryColor)',
  rotate: '0deg',
};

export default SingleRightArrow;
