/** @format */

import React from 'react';

type Props = {
  width?: number;
  height?: number;
};

const EmptyDataReportSearchIcon = ({ width, height }: Props) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 80 85"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M43.5816 8.08105H15.2119V71.9127H63.8459V28.0276H43.5816V8.08105ZM55.7392 63.932H23.3174V55.9536H55.7392V63.932ZM55.7392 39.9957V47.9741H23.3174V39.9957H55.7392Z"
      fill="#DFE3E8"
    />
    <path
      d="M23.5293 56H55.5281V64H23.5293V56ZM23.5293 40H55.5281V48H23.5293V40ZM47.5287 0H15.5287C13.4062 0.00920669 11.3736 0.855704 9.87483 2.35462C8.37603 3.85354 7.53281 5.88308 7.52933 8V72C7.52355 74.1121 8.35768 76.1402 9.84884 77.6399C11.34 79.1395 13.3665 79.9882 15.4841 80H63.5275C65.6506 79.9914 67.684 79.145 69.1834 77.6458C70.6827 76.1465 71.5261 74.1163 71.5293 71.9988V24L47.5287 0ZM63.5275 71.9988H15.5287V8H43.5284V27.9994H63.5275V71.9988Z"
      fill="#919EAB"
    />
    <path
      d="M66 85C73.732 85 80 78.732 80 71C80 63.268 73.732 57 66 57C58.268 57 52 63.268 52 71C52 78.732 58.268 85 66 85Z"
      fill="white"
    />
    <path
      d="M72 66.2L70.8 65L66 69.8L61.2 65L60 66.2L64.8 71L60 75.8L61.2 77L66 72.2L70.8 77L72 75.8L67.2 71L72 66.2Z"
      fill="#919EAB"
    />
  </svg>
);

EmptyDataReportSearchIcon.defaultProps = {
  width: 80,
  height: 85,
};

export default EmptyDataReportSearchIcon;
