import React from 'react';
import Tooltip from 'rc-tooltip';
import InfoIcon from 'components/common/svg/Info/info.svg';
import {
  DateTimeBinning,
  DimensionMode,
  DimensionStatus,
  draggableItemType,
  ExpandListButtonContext,
  FieldEntitiesType,
  UserSalesGroupFieldAlias,
} from 'core/constants/report';
import { DateBinningFormatters } from 'core/constants/formatters';
import { ArrowDown, TickIcon } from 'components/common/svg';
import { components } from 'react-select';
import {
  UserSalesGroupFieldWarning,
  warningFunc,
} from 'components/common/WarningText/warning-text.helper';
import { IDimension } from 'core/models/report-redux';
import { IFilterConfig } from 'core/models/report-response';
import useLocalize from 'components/common/Localization/useLocalize.hook';
import { IDraggableItemIconTooltip } from 'components/common/DraggableItem/draggable-item.interface';
import SingleSelect from 'components/common/Dropdown/single-select-dropdown.component';
import { mouseEnterDelay } from '../common/constants';
import { PendoClassNames } from '../common/pendoClassNames';

export const getDimensionSelectedCount = (
  maxLimitReached: boolean,
  dimensionsSelected: number,
  maxDimensions: number,
) => (
  <span className={`se-grouping-counter ${maxLimitReached ? 'danger' : ''}`}>
    {`${dimensionsSelected}/${maxDimensions}`}
  </span>
);

export const getInfoIconWithTooltip = (tooltipDescription: string) => (
  <Tooltip
    placement="bottom"
    overlay={(
      <div>
        <span>{tooltipDescription}</span>
      </div>
    )}
    overlayClassName="se-grouping-tooltip"
    mouseEnterDelay={mouseEnterDelay}
  >
    <div className="se-info-icon">
      <InfoIcon width={12} height={12} fill="var(--searchBoxTextColor)" />
    </div>
  </Tooltip>
);

const DropdownIndicator = (props: any) => {
  const { selectProps } = props;
  return (
    <components.DropdownIndicator {...props}>
      {selectProps.dropdownIndicator && (
        <>
          {selectProps.menuIsOpen ? (
            <span className="se-react-select-dropdown-indicator-rotate">
              <ArrowDown width={16} height={16} fill="var(--secondaryColor)" />
            </span>
          ) : (
            <ArrowDown width={19} height={16} fill="var(--secondaryColor)" />
          )}
        </>
      )}
    </components.DropdownIndicator>
  );
};

const ValueContainer = (props: any) => {
  const { children, hasValue } = props;
  let element = null;
  if (hasValue) {
    element = (
      <div className="se-react-select-single-select-cn">
        {children[0]?.props?.data?.abbreviation
          || children[0]?.props?.data?.label}
      </div>
    );
  }
  return (
    <components.ValueContainer {...props}>
      {element}
      {children}
    </components.ValueContainer>
  );
};

const Option = (props: any) => {
  const { children, isSelected, data } = props;
  return (
    <components.Option {...props}>
      <div className="se-react-select-option-cn">
        <span className="rs-option-label-cn">{children || '(empty)'}</span>
        <div className="rs-option-example">{data.example}</div>
      </div>
      <span>{isSelected && <TickIcon width={10} height={8} />}</span>
    </components.Option>
  );
};

export const getBinningforDimensions = (
  binningType: string,
  itemType: draggableItemType,
  setBinning: Function,
  index: number,
  selectedPage: String,
) => {
  const dateBinningOptions: any = [];
  let updatedDateTimeBinning = [...DateTimeBinning];
  if (itemType === draggableItemType.ColumnDim) {
    updatedDateTimeBinning = DateTimeBinning.filter(
      (dateType: any) => dateType.label !== 'None',
    );
  }
  if (binningType) {
    dateBinningOptions.push(
      ...updatedDateTimeBinning.filter(
        (item: any) => item.value === binningType,
      ),
    ); // selected item
    dateBinningOptions.push(
      ...updatedDateTimeBinning.filter(
        (item: any) => item.value !== binningType,
      ),
    ); // non selected items
  } else {
    dateBinningOptions.push(...updatedDateTimeBinning);
  }

  return (
    <div
      className={`se-date-binning ${
        binningType !== DateBinningFormatters.NONE ? 'set-binning-border' : ''
      }${PendoClassNames.BinningChange}-${selectedPage}`}
    >
      <SingleSelect
        config={{
          Key: 'date-binning',
          Meta: {
            ClassName: 'se-date-binning',
            Options: dateBinningOptions,
            DropdownIndicator: true,
            Searchable: false,
            Clearable: false,
            AppliedOnClose: false,
            CloseOnSelect: true,
            Components: {
              Option,
              DropdownIndicator,
              ValueContainer,
            },
          },
        }}
        value={binningType}
        onChange={(key, value) => setBinning(index, value)}
      />
    </div>
  );
};

export const getDimensionTooltip = (
  isUserSalesGroupField: boolean,
  isStringField: boolean,
  partitionColumnFilters?: IFilterConfig[],
  PartitionColumnFilterDateLimit?: number,
  appliedDimensions?: IDimension[],
  activeDimensions?: IDimension[],
  showNotificationOnAdd?: boolean,
  itemType?: draggableItemType,
  hasOthersCol?: boolean,
): IDraggableItemIconTooltip => {
  const showOthersColTooltip = () => hasOthersCol
    && checkIsColGroupingApplied(appliedDimensions, activeDimensions)
    && itemType === draggableItemType.ColumnDim;
  const overlay = isUserSalesGroupField ? (
    <UserSalesGroupFieldWarning />
  ) : (
    warningFunc(
      isStringField,
      partitionColumnFilters,
      PartitionColumnFilterDateLimit,
      showOthersColTooltip(),
      itemType,
    )
  );
  const overlayClassName = isUserSalesGroupField
    ? 'se-sales-group-uesr-field'
    : 'se-string-field-warning-icon-tooltip';
  return {
    overlay,
    overlayClassName,
    defaultVisible: !isUserSalesGroupField && showNotificationOnAdd,
  };
};

export const checkIsColGroupingApplied = (
  appliedDimensions: IDimension[],
  activeDimensions: IDimension[],
) => {
  let isColGroupingApplied = false;
  const appliedColDim = appliedDimensions.find(
    (dim) => dim.Applied !== DimensionStatus.NotApplied
      && dim.DimensionMode === DimensionMode.ColumnGroup,
  );
  const activeColDim = activeDimensions.find(
    (dim) => dim.Applied !== DimensionStatus.NotApplied
      && dim.DimensionMode === DimensionMode.ColumnGroup,
  );
  if (!appliedColDim) return isColGroupingApplied;
  if (appliedColDim.BuilderConfig?.IsDynamicField) {
    isColGroupingApplied = appliedColDim.BuilderConfig?.Alias === activeColDim?.BuilderConfig?.Alias;
  } else {
    isColGroupingApplied = appliedColDim.Name === activeColDim?.Name;
  }
  return isColGroupingApplied;
};

export const checkIsUserSalesGroupingField = (dim: IDimension) => dim.BuilderConfig?.Entity === FieldEntitiesType.User
  && dim.BuilderConfig?.Alias === UserSalesGroupFieldAlias;

export const getDimensionItemIconTooltip = (
  dim: IDimension,
  source: ExpandListButtonContext,
  maxLimitReached: boolean,
  groupingLimit: number,
) => {
  if (dim?.IsMasked) {
    return `${useLocalize('pt.fltrNotAddedPT')}`;
  }
  if (maxLimitReached) {
    return `${useLocalize('psn.maxFldReached', [groupingLimit.toString()])}`;
  }
  return null;
};
