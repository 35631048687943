import React from 'react';

type Props = {
  width: number;
  height: number;
  fill?: string;
  backgroundColor?: string;
}
const ReturnIcon = ({
  width, height, fill, backgroundColor,
}: Props) => (
  <svg width={width} height={height} viewBox="0 0 26 26" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect width="26" height="26" fill={backgroundColor} />
    <path fill={fill} d="M22.75 22.1V16.445C22.75 15.275 22.3383 14.2784 21.515 13.455C20.6917 12.6317 19.695 12.22 18.525 12.22H5.00499L10.01 17.225L8.64499 18.59L1.29999 11.245L8.64499 3.90002L10.01 5.26502L5.00499 10.27H18.525C20.215 10.27 21.6667 10.8713 22.88 12.0738C24.0933 13.2763 24.7 14.7334 24.7 16.445V22.1H22.75Z" />
  </svg>
);

ReturnIcon.defaultProps = {
  fill: '#454F5B',
  backgroundColor: 'transparent',
};

export default ReturnIcon;
