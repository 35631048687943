import { IReportConfig } from 'core/models/report-builder/report-builder';
import { ObjModel, ReportResponseModel } from 'core/models';
import { DynamicMeasureResponse, IField } from 'core/models/report-response';
import { defaultPreviewPaging } from 'components/feature/Report/ReportSidebar/common/constants';
import SieraResponse from 'core/models/siera-response';
import { IEditReportProfilePayload } from 'redux-v2/global-store/global-store.state';
import {
  DrilldownColumnConfig, DrilldownColumnConfigResponse, MeasureIdentifier, MeasureIdentifierPayload,
} from 'redux-v2/report-builder/report-builder-store.state';
import { FeatureContext } from 'core/constants/common';
import API from './api';

export default class ReportBuilderService extends API {
  static baseURL: string = process.env.REACT_APP_DATA_BASE_URL || '';

  constructor() {
    super(ReportBuilderService.baseURL, true, true);
  }

  getReportTemplateList = async () => this.responsify(await this.instance.get('reports/report-templates'));

   saveReportConfig = async (data: IReportConfig, queryParams?: ObjModel.Obj) : Promise<SieraResponse<string>> => this.genericResponseWrapper(
     await this.instance.post('reports/save-report-config', data, {
       params: queryParams,
     }),
   );

  getDynamicFilters = async (
    reportId: string,
  ): Promise<Array<ReportResponseModel.IFilterConfig>> => this.responsify(
    await this.instance.get('visualisation/get-additional-filters', {
      params: {
        reportId,
      },
    }),
  );

  getDynamicDimensions = async (
    reportId: string,
  ): Promise<Array<ReportResponseModel.IColumn>> => this.responsify(
    await this.instance.get('visualisation/get-additional-dimensions', {
      params: {
        reportId,
      },
    }),
  );

  getDynamicMeasures = async (
    reportId: string,
  ): Promise<Array<DynamicMeasureResponse>> => this.responsify(
    await this.instance.get('visualisation/get-additional-measures', {
      params: {
        reportId,
      },
    }),
  );

   getFieldsRequest = async (
     reportId: string,
   ): Promise<Array<IField>> => this.responsify(
     await this.instance.get('visualisation/get-fields', {
       params: {
         reportId,
       },
     }),
   );

 getAllTags = async () => (
   this.responsify(await this.instance.get('unified-listing/tags/list'))
 );

  getDynamicDataById= async (
    reportId: string,
    featureContext: FeatureContext,
  ) => this.responsify(
    await this.instance.post('reports/fetch-report-v2', {
      DataSourceId: reportId,
      DataSourceType: 'Report',
      FeatureContext: featureContext,
      Page: {
        ...defaultPreviewPaging,
      },
    }),
  );

  getProfileList = async () => this.responsify(await this.instance.get('profiles/list'))

  editProfilesToReport = async (data: IEditReportProfilePayload) => (
    this.responsify(await this.instance.put('profiles/update-report-profiles', data))
  )

  getReportRawConfig = async (params: ObjModel.Obj): Promise<SieraResponse<IReportConfig>> => (
    this.genericResponseWrapper(await this.instance.get('reports/get-raw-config', { params }))
  );

  getReportRenderConfig = async (params: ObjModel.Obj): Promise<SieraResponse<ReportResponseModel.IReportConfig>> => (
    this.genericResponseWrapper(await this.instance.get('reports/get-report-config', { params }))
  )

  getDefaultDrilldownColumns = async (reportId: string, data: MeasureIdentifierPayload) : Promise<Array<DrilldownColumnConfigResponse>> => this.responsify(await this.instance.post('visualisation/get-default-drilldown-columns', data, {
    params: {
      reportId,
    },
  }))
  ;

  getAdditionalDrilldownColumns = async (reportId: string, data: MeasureIdentifier) : Promise<Array<ReportResponseModel.IColumn>> => (
    this.responsify(await this.instance.post('visualisation/get-additional-drilldown-columns-by-identifier', data, {
      params: {
        reportId,
      },
    }))
  )

  getDrilldownConfig = async (reportId: string): Promise<SieraResponse<any>> => (
    this.genericResponseWrapper(await this.instance.get('reports/fetch-drilldown-config', { params: { reportId } }))

  )

  saveDrilldownColumnConfig = async (reportId: string, data: DrilldownColumnConfig): Promise<SieraResponse<any>> => (
    this.responsify(await this.instance.post('reports/save-drilldown-config', data, { params: { reportId } }))
  )

  getRawTemplateDetails = async (rawTemplateId: string, namespace:string, joins:string) => this.responsify(await this.instance.get('reports/get-raw-template', {
    params: {
      rawTemplateId,
      namespace,
      joins,
    },
  }));

  getDynamicUserFilters = async (
    reportId: string,
  ): Promise<Array<ReportResponseModel.IFilterConfig>> => this.responsify(
    await this.instance.get('visualisation/get-additional-user-fields', {
      params: {
        reportId,
      },
    }),
  );

  getDynamicUserFields = async (reportId: string):Promise<Array<ReportResponseModel.IColumn>> => this.responsify(
    await this.instance.get('visualisation/get-additional-user-dimensions', {
      params: {
        reportId,
      },
    }),
  )
}
