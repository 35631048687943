import React from 'react';

type Props = {
  width?: number;
  height?: number;
  fill?: string;
  rotate?:string;
};

const DownArrow = ({
  width, height, fill, rotate,
}: Props) => (
  <svg
    style={{
      width,
      height,
      rotate,
    }}
    viewBox="0 0 10 10"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path fill={fill} d="M0 3L5 8L10 3H0Z" />
  </svg>
);

DownArrow.defaultProps = {
  width: 9,
  height: 9,
  fill: 'var(--secondaryColor)',
  rotate: '0deg',
};

export default DownArrow;
