import React from 'react';

type Props = {
  width?: number;
  height?: number;
  fill?: string;
}

const WarningIconNew = ({
  width, height, fill,
}: Props) => (
  <svg
    style={{
      width,
      height,
    }}
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M0.851562 17.4438H19.1466L9.99906 1.70508L0.851562 17.4438ZM10.8303 14.9551H9.16781V13.3026H10.8303V14.9551ZM10.8303 11.6413H9.16781V8.33008H10.8303V11.6413Z" fill={fill} />
  </svg>

);

WarningIconNew.defaultProps = {
  fill: '#DE323B',
  width: 20,
  height: 20,
};

export default WarningIconNew;
