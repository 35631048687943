import React from 'react';

type Props = {
  width: number;
  height: number;
  fill?: string;
}

const LeadIcon = ({
  width, height, fill,
}: Props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={width}
    height={height}
    viewBox="0 0 12 11.644"
  >
    <path
      id="person_FILL0_wght400_GRAD0_opsz48"
      d="M14,14.575a2.7,2.7,0,0,1-2.812-2.812A2.7,2.7,0,0,1,14,8.95a2.7,2.7,0,0,1,2.813,2.812A2.7,2.7,0,0,1,14,14.575ZM8,20.594V18.831a2.063,2.063,0,0,1,.356-1.219,2.287,2.287,0,0,1,.919-.769A14.094,14.094,0,0,1,11.684,16,9.74,9.74,0,0,1,14,15.719a9.33,9.33,0,0,1,2.306.291,15.972,15.972,0,0,1,2.4.834,2.261,2.261,0,0,1,.938.769A2.063,2.063,0,0,1,20,18.831v1.763Zm1.125-1.125h9.75v-.638a1.027,1.027,0,0,0-.178-.572,1.129,1.129,0,0,0-.441-.4,9.957,9.957,0,0,0-2.194-.8A9.7,9.7,0,0,0,14,16.844a9.965,9.965,0,0,0-2.081.216,9.523,9.523,0,0,0-2.194.8,1.128,1.128,0,0,0-.6.975ZM14,13.45a1.635,1.635,0,1,0-1.209-.478A1.639,1.639,0,0,0,14,13.45ZM14,11.763ZM14,19.469Z"
      transform="translate(-8 -8.95)"
      fill={fill}
    />
  </svg>
);

LeadIcon.defaultProps = {
  fill: 'var(--searchBoxTextColor)',
};

export default LeadIcon;
