import {
  ProductTour, ReportIssue, Feedback, SeiraTour,
} from 'components/common/svg';
import { renderButtonProps } from 'core/models/page-container';
import { SourceTypes } from 'redux-v2/report-listing/report-listing.state';
import { SIERAPage } from './page-url';

// eslint-disable-next-line import/prefer-default-export
export enum ActionIconsForAccordion {
    ADD = 1,
    REMOVE,
    MASKED,
    PREDEFINED,
    CUSTOM,
    SWITCH,
    NONE
  }

export enum ToastTypes {
  Success = 'success',
  Error = 'error',
}

export enum ToastPosition {
  TopRight ='top-right',
  TopCenter ='top-center',
  TopLeft ='top-left',
  BottomRight = 'bottom-right',
  BottomLeft = 'bottom-left',
  BottomCenter = 'bottom-center',
}

export const renderButtons: Array<renderButtonProps> = [
  {
    title: 'Product Tour',
    pendoClassName: 'se-pendo-productTour',
    icon: ProductTour,
  },
  {
    title: 'Give Feedback',
    pendoClassName: 'se-pendo-giveFeedback',
    icon: Feedback,
  },
  {
    title: 'Report an Issue',
    pendoClassName: 'se-pendo-reportAnIssue',
    icon: ReportIssue,
  },
];

export const renderSieraButtons: Array<renderButtonProps> = [
  {
    title: 'SIERA Tour',
    pendoClassName: 'se-track-siera-tour',
    icon: SeiraTour,
  },
];

export const URLRoutes = {
  reportEdit: 'report-edit',
  report: 'report',
  reportListing: 'reportv2',
  dashboard: 'dashboard',
  dashboardListing: 'dashboardv2',
  dashboardBuilder: 'dashboard-builder',
  analytics: 'analytics',
  schedules: '#reportv2/scheduled_reports',
};

export const drilldownColumnDelimiter = '|';

export enum routePaths {
  sieraReports= '/report/:reportId',
  reportBuilder= '/report-builder/report-detail/:reportBuilderId',
  dashboardBuilder= '/dashboard-builder/:dashboardID?',
  sieraDashboards= '/dashboard/:dashboardID?',
  externalReports= '/reports/viewer',
  createProfile= '/profile/createprofile',
  editProfile= '/profile/edit/:profileId',
  unauthorized= '/unauthorized',
  baseRoute= '/',
  pageNotFound= '*',
  reportEdit= '/report-edit/:reportId',
  customAnalytics='/analytics',
  noAccessRoute = '/no-access'
}

export enum FeatureList {
  reportListing = 'ReportListing',
  sieraReports= 'SieraReports',
  externalReports = 'ExternalReports',
  sieraDashboards= 'SieraDashboards',
  reportBuilder= 'ReportBuilder',
  reportEdit = 'ReportEdit',
  dashboardBuilder = 'DashboardBuilder',
  createProfile ='CreateProfile',
  editProfile = 'EditProfile',
  customAnalytics = 'CustomAnalytics',
}
export const routesWithDefaultNavBarOpen = [SIERAPage.LISTING, SIERAPage.REPORTV2];

export const savedReportUrl = 'rdPage.aspx?rdReport=_BookMarks.DisplayBookmarks';

export const defaultPageSize = 25;

export enum Rotation{
  rotateSideways = '180deg',
  neutral='0deg',
}

export const pauseDuration = 90;

export enum FeatureContext{
  Sales = 'Sales',
  Service = 'Service',
  Hybrid = 'Hybrid'
}

export enum TriggerType {
  Hover = 'hover',
}

export const leadSquaredSupportEmail = 'support@leadsquared.com';

export const ReportTypes = [
  {
    label: 'SIERA Reports',
    value: SourceTypes.SIERA,
  },
  {
    label: 'V1 Web Reports',
    value: SourceTypes.LOGI,
  },
  {
    label: 'V1 Mobile Reports',
    value: SourceTypes.LogiMobile,
  },
];

export enum PageContext{
  SAVE_AS= 'SaveAs',
  EDIT='Edit',
  BUILDER='Builder',
}

export const MobileListingURLParam = 'mobileListing';
