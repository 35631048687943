/** @format */

import { flexCenterMixin } from 'core/utils/mixins';
import styled from 'styled-components';

const ModalStyled = styled.div`
  .modal {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 999;
    ${flexCenterMixin};
  }

  .modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 998;
  }

  .modal-content {
    background-color: var(--white);
    border-radius: 2px;
    z-index: 999;
  }

  .modal-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 16px;
    border-bottom: 1px solid var(--borderColor);
    border-radius: 2px 2px 0px 0px;
  }

  .modal-footer {
    display: flex;
    align-items: flex-end;
    padding: 16px;
    border-top: 1px solid var(--borderColor);
    border-radius: 0px 0px 2px 2px;
  }

  .modal-footer {
    display: flex;
    justify-content: flex-end;
  }

  .modal-title {
    margin-top: 0;
    font-size: 16px;
    font-family: SemiBold;
    color: var(--primaryColor);
  }

  .se-modal-close-icon {
    display:flex;
    align-items:center;
    border: none;
    padding: 0px;
    :hover {
      cursor: pointer;
      .icon-stroke {
        fill: var(--secondaryColor);
      }
    }
  }
`;

export default ModalStyled;
