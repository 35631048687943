import { AccessType } from 'core/constants/report';
import {
  IEditTagPayload, ObjectActionType, PinUnpinPayload,
} from 'redux-v2/report-listing/report-listing.state';
import {
  SelectedSection,
} from 'redux-v2/sidebar-store/sidebar-store.state';
import API from './api';

export default class ReportListingService extends API {
  static baseURL = process.env.REACT_APP_DATA_BASE_URL || '';

  constructor() {
    super(ReportListingService.baseURL);
  }

  getAllReports = async (section: SelectedSection, profileId: string, tagId: string) => (
    this.responsify(await this.instance.get('unified-listing/list', {
      params: {
        section,
        profileId,
        tagId,
      },
    }))
  );

  getAllReportsByGlobalSearch = async (globalSearch: string) => (
    this.responsify(await this.instance.get('unified-listing/list', {
      params: {
        globalSearch,
      },
    }))
  );

  getAllDashboardsByGlobalSearch = async (globalSearch: string) => (
    this.responsify(await this.instance.get('unified-listing/dashboards/list-dashboards', {
      params: {
        section: 'all',
        mode: 'listing',
        globalSearch,
      },
    }))
  );

  getExternalReportDetails = async (reportId: string, sourceType: string, savedReports?: string) => (
    this.responsify(await this.instance.get('unified-listing/external-source/details', {
      params: {
        reportId,
        sourceType,
        logiRoute: savedReports,
      },
    }))
  );

  getAllTags = async () => (
    this.responsify(await this.instance.get('unified-listing/tags/list'))
  );

  getTagsByUser = async (profileId: string) => (
    this.responsify(await this.instance.get('unified-listing/tags/user', {
      params: {
        profileId,
      },
    }))

  );

  getReportsByTag = async (tagId: string) => (
    this.responsify(await this.instance.get('unified-listing/reports-by-tag', {
      params: {
        profile: 'default',
        tag: tagId,
      },
    }))
  )

  getDashboardList = async (section: SelectedSection, profileId: string, tagId: string, mode: string) => (
    this.responsify(await this.instance.get('unified-listing/dashboards/list-dashboards', {
      params: {
        section,
        profileId,
        tagId,
        mode,
      },
    }))
  );

  getDashboardsByTag = async (tagId: string) => (
    this.responsify(await this.instance.get('unified-listing/dashboards-by-tag', {
      params: {
        profile: 'default',
        tag: tagId,
      },
    }))
  )

  editTagsByObject = async (data: IEditTagPayload) => (
    this.responsify(await this.instance.post('unified-listing/tags/tag-untag', data))
  )

  getTagsByObject = async (objectId: string, objectType: ObjectActionType) => (
    this.responsify(await this.instance.get('unified-listing/tags/object', {
      params: {
        objectId,
        objectType,
      },
    }))
  )

  pinUnpinReport = async (data: PinUnpinPayload) => (
    this.responsify(await this.instance.post('reports/pin-unpin-report-listing', data))
  )

  pinUnpinDashboard = async (data: PinUnpinPayload) => (
    this.responsify(await this.instance.put('unified-listing/dashboards/personalise-pin-unpin', null, {
      params: {
        id: data.Id,
        pin: data.IsPinned,
      },
    }))
  )

  accesscontrol = async (reportId: string, type: AccessType) => (
    this.responsifyGeneric(await this.instance.get('access-control/isAccessible', {
      params: {
        id: reportId,
        type,
      },
    }))
  )

  getSavedReportDetails = async (savedReports: string) => (
    this.responsify(await this.instance.get('unified-listing/external-source/details', {
      params: {
        logiRoute: savedReports,
      },
    }))
  );
}
