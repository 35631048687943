import React from 'react';

type Props = {
  width?: number;
  height?: number;
  fill?: string;
}

const NoPinnedDashboardIcon = ({
  width, height, fill,
}: Props) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={width} height={height} viewBox="0 0 86 85" fill="none">
    <path d="M3.55371 3.4707V38.0517H33.0468V3.4707H3.55371Z" fill="#DFE3E8" />
    <path d="M4.14172 53.4707V75.6887H32.7417V53.4707H4.14172Z" fill="#DFE3E8" />
    <path d="M47.1417 3.4707V25.6887H75.7417V3.4707H47.1417Z" fill="#DFE3E8" />
    <path d="M46.5537 41.4707V76.0517H76.0468V41.4707H46.5537Z" fill="#DFE3E8" />
    <path d="M43.333 30V0H80V30H43.333ZM0 43.333V0H36.667V43.333H0ZM43.333 80V36.667H80V80H43.333ZM0 80V50H36.667V80H0ZM6.667 36.667H30V6.667H6.667V36.667ZM50 73.333H73.333V43.333H50V73.333ZM50 23.333H73.333V6.667H50V23.333ZM6.667 73.333H30V56.667H6.667V73.333Z" fill="#919EAB" />
    <path d="M72 85C79.732 85 86 78.732 86 71C86 63.268 79.732 57 72 57C64.268 57 58 63.268 58 71C58 78.732 64.268 85 72 85Z" fill="white" />
    <path fillRule="evenodd" clipRule="evenodd" d="M75.334 68.501V64.335H76.167C76.3877 64.3342 76.5991 64.2462 76.7551 64.0901C76.9112 63.9341 76.9992 63.7227 77 63.502C76.9995 63.2811 76.9116 63.0694 76.7555 62.9132C76.5994 62.7569 76.3878 62.6688 76.167 62.668H67.834C67.6133 62.6688 67.4019 62.7568 67.2458 62.9128C67.0898 63.0689 67.0018 63.2803 67.001 63.501C67.0018 63.7217 67.0898 63.9331 67.2458 64.0891C67.4019 64.2452 67.6133 64.3332 67.834 64.334H68.667V68.501C68.667 69.164 68.4036 69.7999 67.9348 70.2687C67.4659 70.7376 66.83 71.001 66.167 71.001V72.668H71.142V78.501L71.975 79.334L72.808 78.501V72.668H77.833V71.001C77.1701 71.0007 76.5345 70.7372 76.0659 70.2684C75.5972 69.7996 75.334 69.1638 75.334 68.501Z" fill="#ED7B24" />
  </svg>
);

NoPinnedDashboardIcon.defaultProps = {
  height: 86,
  width: 85,
  fill: 'var(--searchBoxTextColor)',
};

export default NoPinnedDashboardIcon;
