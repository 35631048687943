import {
  IProfileDetailInfo, IProfileForm, IProfileFormValue, IProfileItem, IProfileListing, ProfileCompletedActions,
} from 'core/models/profile';
import { LoadingStatus } from 'core/constants/dashboard';
import { APIRequestStatus } from 'core/constants/redux';
import { IDashboardListData, IReportData, ITagsData } from 'redux-v2/report-listing/report-listing.state';

export interface IProfileState {
    requestProcessing: IRequestProcessing,
    profileDetailInfo: IProfileDetailInfo;
    profileForm: IProfileForm;
    apiProfileFormValue: IProfileFormValue;
    availableProfiles: IProfileItem[];
    completedProfileAction: ProfileCompletedActions;
    profileListing: IProfileListing;
    selectedProfileId: IProfileItem;
    profileListLoading: LoadingStatus;
    isEditMode: boolean;
    profileDeleteLoading: LoadingStatus;
    deleteId: string
    selectedReportTypes: string[]
  }

export interface IRequestProcessing {
    [ProfileActions.GET_PROFILE_INFO_BY_PROFILE_ID]: APIRequestStatus;
    [ProfileActions.GET_VISIBILITY_VALUES]: APIRequestStatus;
  }

export enum ProfileActions {
    GET_REPORT_LIST = '[Profile] GET_REPORT_LIST',
    GET_REPORT_LIST_SUCCESS = '[Profile] GET_REPORT_LIST_SUCCESS',
    GET_REPORT_LIST_FAILURE = '[Profile] GET_REPORT_LIST_FAILURE',
    GET_DASHBOARD_LIST = '[Profile] GET_DASHBOARD_LIST',
    GET_DASHBOARD_LIST_SUCCESS = '[Profile] GET_DASHBOARD_LIST_SUCCESS',
    GET_DASHBOARD_LIST_FAILURE = '[Profile] GET_DASHBOARD_LIST_FAILURE',
    GET_VISIBILITY_VALUES = '[Profile] GET_VISIBILITY_VALUES',
    GET_VISIBILITY_VALUES_SUCCESS = '[Profile] GET_VISIBILITY_VALUES_SUCCESS',
    GET_VISIBILITY_VALUES_FAILURE = '[Profile] GET_VISIBILITY_VALUES_FAILURE',
    UPDATE_PROFILE_DETAIL_FORM = '[Profile] UPDATE_PROFILE_DETAIL_FORM',
    UPDATE_PROFILE_SECTION_SELECTION = 'UPDATE_PROFILE_SECTION_SELECTION',
    UPDATE_PROFILE_ALL_SECTION = 'UPDATE_PROFILE_ALL_SECTION',
    SAVE_PROFILE_DETAIL_FORM = '[Profile] SAVE_PROFILE_DETAIL_FORM',
    SAVE_PROFILE_DETAIL_FORM_SUCCESS = '[Profile] SAVE_PROFILE_DETAIL_FORM_SUCCESS',
    SAVE_PROFILE_DETAIL_FORM_FAILURE = '[Profile] SAVE_PROFILE_DETAIL_FORM_FAILURE',
    RESET_PROFILE_DETAIL_FORM = '[Profile] RESET_PROFILE_DETAIL_FORM',
    GET_VISIBILITY_CONFIG = '[Profile] GET_VISIBILITY_CONFIG',
    GET_VISIBILITY_CONFIG_SUCCESS = '[Profile] GET_VISIBILITY_CONFIG_SUCCESS',
    GET_VISIBILITY_CONFIG_FAILURE = '[Profile] GET_VISIBILITY_CONFIG_FAILURE',
    GET_PROFILE_LIST_REQUEST = 'GET_PROFILE_LIST_REQUEST',
    GET_PROFILE_LIST_SUCCESS = 'GET_PROFILE_LIST_SUCCESS',
    GET_PROFILE_LIST_FAILURE = 'GET_PROFILE_LIST_FAILURE',
    SET_COMPLETED_PROFILE_ACTION = 'SET_COMPLETED_PROFILE_ACTION',
    SET_SELECTED_PROFILE_ID = 'SET_SELECTED_PROFILE_ID',
    SET_PROFILE_REPORT_SEARCH_VALUE = 'SET_PROFILE_REPORT_SEARCH_VALUE',
    SET_PROFILE_DASHBOARD_SEARCH_VALUE = 'SET_PROFILE_DASHBOARD_SEARCH_VALUE',
    SET_PROFILE_TAB_VALUE = '[Profile] SET_PROFILE_TAB_VALUE',
    GET_PROFILE_INFO_BY_PROFILE_ID = 'GET_PROFILE_INFO_BY_PROFILE_ID',
    GET_PROFILE_INFO_BY_PROFILE_ID_SUCCESS = 'GET_PROFILE_INFO_BY_PROFILE_ID_SUCCESS',
    GET_PROFILE_INFO_BY_PROFILE_ID_FAILURE = 'GET_PROFILE_INFO_BY_PROFILE_ID_FAILURE',
    SET_EDIT_MODE_FOR_PROFILE = 'SET_EDIT_MODE_FOR_PROFILE',
    DELETE_PROFILE_REQUEST = 'DELETE_PROFILE_REQUEST',
    DELETE_PROFILE_SUCCESS = 'DELETE_PROFILE_SUCCESS',
    DELETE_PROFILE_FAILURE = 'DELETE_PROFILE_FAILURE',
    DELETE_PROFILE_ANIMATION = 'DELETE_PROFILE_ANIMATION',
    UPDATE_PROFILES_LIST = 'UPDATE_PROFILES_LIST',
    UNDO_DELETE_ACTION = 'UNDO_DELETE_ACTION',
    CLEAR_PROFILE_LIST = 'CLEAR_PROFILE_LIST',
    EDIT_PROFILE_DETAIL_FORM = '[Profile] EDIT_PROFILE_DETAIL_FORM',
    SET_SELECTED_REPORT_TYPE = '[Profile] SET_SELECTED_REPORT_TYPE',
  }

export interface IGetReportListSuccess {
  reportData: Array<IReportData>;
  globalTags: ITagsData[];
}
export interface IGetReportDashboardListSuccess {
  dashboardListData: Array<IDashboardListData>;
  globalTags: ITagsData[];
}

export interface ISaveProfile {
  isEditMode: boolean,
  profileForm: IProfileForm
}
