import { draggableItemType } from 'core/constants/report';
import { ReportResponseModel } from 'core/models';
import React from 'react';
import useLocalize from '../Localization/useLocalize.hook';
import { IRestrictedDateRangeWarningProps, IStringFieldIsAddedWarningProps } from './warning-text.interface';
import StyledDateContainer from '../DatePickerNew/date.styled';

export const RestrictedDateRangeWarning = (props: IRestrictedDateRangeWarningProps) => {
  const { stringFields, partitionColumnFilterDateLimit, partitionWidgets } = props;
  return (
    <StyledDateContainer>
      <div className={`${partitionWidgets ? 'se-dashboard-limit-tooltip' : ''}`}>
        <div className="se-warning-disclaimer-label">{useLocalize('cmn.disclamer')}</div>
        { partitionWidgets ? useLocalize('fltr.widgetsStringWarning', [`${partitionWidgets}`, `${partitionColumnFilterDateLimit}`]) : useLocalize('fltr.stringFilterWarning', [`${stringFields}`, `${partitionColumnFilterDateLimit}`])}
        <div className="se-warning-additional-info">
          {useLocalize('fltr.stringFilterDateRangeRestrictionMsg')}
        </div>
      </div>
    </StyledDateContainer>
  );
};

export const warningFunc = (isStringField?:boolean, partitionColumnFilters?:ReportResponseModel.IFilterConfig[], partitionColumnFilterDateLimit?:number, showOthersColTooltip?:boolean, itemType?: draggableItemType) => {
  if (isStringField && showOthersColTooltip && itemType === draggableItemType.ColumnDim) {
    return (
      <HasOthersColsAndStringFieldWarning
        partitionColumnFilters={partitionColumnFilters}
        partitionColumnFilterDateLimit={partitionColumnFilterDateLimit}
      />
    );
  }

  if (isStringField) {
    return (
      <StringFieldIsAddedWarning
        partitionColumnFilters={partitionColumnFilters}
        partitionColumnFilterDateLimit={partitionColumnFilterDateLimit}
      />
    );
  }

  return <HasOthersColWarning />;
};

export const StringFieldIsAddedWarning = (props: IStringFieldIsAddedWarningProps) => {
  const { partitionColumnFilters, partitionColumnFilterDateLimit } = props;
  return (
    <div className="se-string-field-tooltip-text-container">
      <div className="se-string-field-tooltip-disclaimer">
        {useLocalize('cmn.disclamer')}
      </div>
      <div className="se-string-field-tooltip-showcase">
        {`${useLocalize('fltr.stringFieldWarning')} `}
        {partitionColumnFilters && partitionColumnFilters.map((item, filterIndex) => (
          <span
            key={item?.Alias}
            className="se-string-field-filter-names"
          >
            <span className="se-string-field-double-quotations">&apos;</span>
            {item?.Label}
            <span className="se-string-field-double-quotations">
              &apos;
              {filterIndex === partitionColumnFilters.length - 1 ? ' ' : ''}
            </span>
            {filterIndex !== partitionColumnFilters.length - 1 ? ', ' : ''}
          </span>
        ))}
        {`${useLocalize('fltr.willAffectDate', [`${partitionColumnFilterDateLimit}`])} `}
      </div>
    </div>
  );
};

export const HasOthersColWarning = () => (
  <div className="se-has-others-col-text-container">
    <div className="se-has-others-col-tooltip-disclaimer">
      Disclaimer :
    </div>
    <div className="se-has-others-col-tooltip-showcase">
      Number of Columns have exceeded the maximum limit. Please refer to the &apos;
      <span className="se-highlight-in-orange">Others</span>
      &apos; column for the cumulative data outside the limit.
      <div className="se-others-col-tip">Tip : You can modify filters to reduce the column count.</div>
    </div>
  </div>
);

export const HasOthersColsAndStringFieldWarning = (props: IStringFieldIsAddedWarningProps) => {
  const { partitionColumnFilters, partitionColumnFilterDateLimit } = props;
  return (
    <div>
      <div className="se-string-field-tooltip-text-container">
        <div className="se-string-field-tooltip-disclaimer">
          Disclaimer :
        </div>
        <div className="se-string-field-tooltip-showcase">
          {'Applying this field will change '}
          {partitionColumnFilters && partitionColumnFilters?.map((item, filterIndex) => (
            <span
              key={item?.Alias}
              className="se-string-field-filter-names"
            >
              <span className="se-string-field-double-quotations">&apos;</span>
              {item?.Label}
              <span className="se-string-field-double-quotations">
                &apos;
                {filterIndex === partitionColumnFilters.length - 1 ? ' ' : ''}
              </span>
              {filterIndex !== partitionColumnFilters.length - 1 ? ', ' : ''}
            </span>
          ))}
          {`date range to the last ${partitionColumnFilterDateLimit} days only.`}
        </div>
      </div>
      <hr className="se-warning-tooltip-divider" />
      <div className="se-has-others-col-text-container">
        <div className="se-has-others-col-tooltip-showcase">
          Number of Columns have exceeded the maximum limit. Please refer to the &apos;
          <span className="se-highlight-in-orange">Others</span>
          &apos; column for the cumulative data outside the limit.
          <div className="se-others-col-tip">Tip : You can modify filters to reduce the column count.</div>
        </div>
      </div>
    </div>
  );
};

export const UserSalesGroupFieldWarning = () => (
  <div className="se-string-field-tooltip-text-container">
    <div className="se-string-field-tooltip-disclaimer">
      {useLocalize('cmn.disclamer')}
    </div>
    <div className="se-string-field-tooltip-showcase">
      {useLocalize('viz.userSalesGroupDesc1')}
    </div>
    <p>
      {useLocalize('viz.theQuotes')}
      <span className="se-string-field-filter-names">{useLocalize('viz.summary')}</span>
      &quot;
      {useLocalize('viz.userSalesGroupDesc2')}
    </p>
    <span />
  </div>
);
export const Msg = () => (
  <>
    {useLocalize('cmn.numOfColumnsLimit')}
    &apos;
    <span className="other">{useLocalize('cmn.others')}</span>
    &apos;
    {useLocalize('cmn.cumulativeLimit')}
  </>
);
