import React from 'react';

type Props = {
  width: number;
  height: number;
  fill?: string;
}

const ColumnChart = ({
  width, height, fill,
}: Props) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M2 2V22C2 22.1105 2.08954 22.2 2.2 22.2H22.2"
      stroke={fill}
      strokeWidth="1.6"
      strokeLinecap="round"
      className="icon-stroke"
    />
    <path
      d="M7.70546 14.1817V11.7745C7.70546 11.7413 7.67859 11.7145 7.64546 11.7145H6.47187H5.29828C5.26514 11.7145 5.23828 11.7413 5.23828 11.7745V14.1817V16.6488V19.056C5.23828 19.0891 5.26514 19.116 5.29828 19.116H6.47187H7.64546C7.67859 19.116 7.70546 19.0891 7.70546 19.056V16.6488V14.1817Z"
      stroke={fill}
      strokeWidth="1.6"
      strokeLinecap="round"
      className="icon-stroke"
    />
    <path
      d="M13.2562 11.8173V8.22797C13.2562 8.19483 13.2294 8.16797 13.1962 8.16797H12.0227H10.8491C10.8159 8.16797 10.7891 8.19483 10.7891 8.22797V11.8173V15.4667V19.0561C10.7891 19.0892 10.8159 19.1161 10.8491 19.1161H12.0227H13.1962C13.2294 19.1161 13.2562 19.0892 13.2562 19.0561V15.4667V11.8173Z"
      stroke={fill}
      strokeWidth="1.6"
      strokeLinecap="round"
      className="icon-stroke"
    />
    <path
      d="M18.807 9.86411V5.29816C18.807 5.26502 18.7802 5.23816 18.747 5.23816H17.5734H16.3998C16.3667 5.23816 16.3398 5.26502 16.3398 5.29816V9.86411V14.4901V19.056C16.3398 19.0892 16.3667 19.116 16.3998 19.116H17.5734H18.747C18.7802 19.116 18.807 19.0892 18.807 19.056V14.4901V9.86411Z"
      stroke={fill}
      strokeWidth="1.6"
      strokeLinecap="round"
      className="icon-stroke"
    />
  </svg>

);

ColumnChart.defaultProps = {
  fill: 'var(--labelColor)',
};

export default ColumnChart;
