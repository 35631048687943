/* eslint-disable no-console */
import moment from 'moment-timezone';
import isArray from 'lodash/isArray';
import FilterService, { IUserOptionsFetchPayload } from 'services/filter.service';
import { DatePayloadFormat, IDateRangeTypes } from 'core/constants/date';
import { FilterType, UserFilterPayloadType } from 'core/constants/filter';
import { FilterModel, ObjModel, ReportResponseModel } from 'core/models';
import VariableParser from 'core/utils/variable-parser';
import { IFilterConfig } from 'core/models/report-response';
import { FieldEntitiesType } from 'core/constants/report';
import { isValidArrayAndNotEmpty } from 'components/feature/Report/ReportSidebar/common/helpers';
import {
  IOptionsMeta, ISelectFilterOptions, ISelectFilterResponse, IUserFilterOptionResponse,
} from 'core/models/filter';

import SieraResponse from 'core/models/siera-response';
import { FeatureContext } from 'core/constants/common';
import { userStatusEnum } from 'components/feature/Report/ReportSidebar/common/constants';
import { getDateFilterValue, isValidDateRangePayload } from './date.util';

export default class FilterFetchUtility {
  _variables : ObjModel.Obj

  constructor(variables: ObjModel.Obj) {
    this._variables = variables;
  }

   isDataNotEmpty = (data: ISelectFilterResponse[]) => data?.length;

   getNewOptions = (OptionsMeta: IOptionsMeta, FilterOptions: Array<ISelectFilterOptions>) => (OptionsMeta.Page?.PageIndex > 0 ? FilterOptions : []);

   getDataCount = (data: IUserFilterOptionResponse) => data?.Count || 0;

   getDataValue = (value: any) => value || [];

  // GetSingleFilterData gives filter data based on type and key, along with linked data
  // param filterConfig: filterConfig from report config
  // param value: default value or linked filter value required to fetch data
  // returns: based on filter type return type depends. Cast post it is returned.
  // throws error when non implemented filter is requested.
  GetSingleFilterData = async (
    filterConfig: ReportResponseModel.IFilterConfig, dependentValue: any, value: any, meta?: any, filterPayload?: ObjModel.ObjGeneric<any>, featureContext?:FeatureContext,
  ): Promise<FilterModel.IFilterResponse> => {
    try {
      switch (filterConfig.Type) {
        case FilterType.PRE_APPLIED:
          return {
            FilterID: filterConfig.ID,
            FilterResponse: new VariableParser(this._variables).ReplaceVariable(filterConfig.DefaultValue),
            shouldLoad: false,
            Disabled: false,
          };
        case FilterType.GroupMultiSelect: {
          let FilterResponse: any = [];
          const FilterOptions: Array<FilterModel.ISelectFilterOptions> = [];
          const data = await this.groupMultiSelectProcessor(featureContext);
          if (isValidArrayAndNotEmpty(data)) {
            data.forEach((item) => {
              FilterOptions.push({
                label: item.DisplayName,
                value: item.Value,
              });
            });
            FilterResponse = this.getValueForMultiSelect(data, value);
          }
          return {
            FilterID: filterConfig.ID,
            FilterResponse,
            FilterOptions,
            shouldLoad: false,
            Disabled: false,
          };
        }
        case FilterType.UserMultiSelect: {
          const {
            OptionsMeta, FilterOptions,
          } = meta as FilterModel.IFilterResponse;
          let newOptions: Array<FilterModel.ISelectFilterOptions> = this.getNewOptions(OptionsMeta, FilterOptions);
          const data = await this.userMultiSelectProcessor(
            filterConfig,
            dependentValue,
            value,
            OptionsMeta.Page,
            OptionsMeta.SearchKey,
            filterPayload,
            featureContext,
          ) as FilterModel.IUserFilterOptionResponse;
          if (data?.DisplayUsers?.length) {
            newOptions = newOptions.concat(
              data.DisplayUsers.map((item) => ({
                label: item.DisplayName,
                value: item.Value,
              })),
            );
          }
          return {
            FilterID: filterConfig.ID,
            FilterResponse: value,
            FilterOptions: newOptions,
            shouldLoad: false,
            Disabled: false,
            OptionsMeta: {
              ...OptionsMeta,
              Count: this.getDataCount(data),
              HasNextPage: data?.HasNextPage || false,
            },
          };
        }
        case FilterType.LSQMetadataMultiSelect: {
          let FilterResponse: any = [];
          const FilterOptions: Array<FilterModel.ISelectFilterOptions> = [];
          const res :SieraResponse<ISelectFilterResponse[]> = await this.lsqMultiSelectProcessor(filterConfig, featureContext);
          if (isValidArrayAndNotEmpty(res?.data)) {
            res.data.forEach((item:ISelectFilterResponse) => {
              FilterOptions.push({
                label: item.DisplayName,
                value: item.Value,
              });
            });
            FilterResponse = this.getValueForMultiSelect(res.data, value);
          }
          return {
            FilterID: filterConfig.ID,
            FilterResponse,
            FilterOptions,
            shouldLoad: false,
            Disabled: false,
          };
        }
        case FilterType.MultiTextSearch: {
          return {
            FilterID: filterConfig.ID,
            FilterResponse: this.getDataValue(value),
            shouldLoad: false,
            Disabled: false,
          };
        }
        case FilterType.LSQMetadataSingleSelect: {
          let FilterResponse: any = null;
          const FilterOptions: Array<FilterModel.ISelectFilterOptions> = [];
          const res :SieraResponse<ISelectFilterResponse[]> = await this.lsqMultiSelectProcessor(filterConfig, featureContext);
          if (isValidArrayAndNotEmpty(res?.data)) {
            res?.data.forEach((item:ISelectFilterResponse) => {
              FilterOptions.push({
                label: item.DisplayName,
                value: item.Value,
              });
            });
            FilterResponse = this.getValueForSingleSelect(res?.data, value);
          }
          return {
            FilterID: filterConfig.ID,
            FilterResponse,
            FilterOptions,
            shouldLoad: false,
            Disabled: false,
          };
        }
        case FilterType.CustomDefinedMultiSelect: {
          let FilterResponse: any = [];
          const FilterOptions: Array<FilterModel.ISelectFilterOptions> = [];
          const data = await this.customDefinedMultiSelectProcessor(filterConfig);
          if (this.isDataNotEmpty(data)) {
            data.forEach((item) => {
              FilterOptions.push({
                label: item.DisplayName,
                value: item.Value,
              });
            });
            FilterResponse = this.getValueForMultiSelect(data, value);
          }
          return {
            FilterID: filterConfig.ID,
            FilterResponse,
            FilterOptions,
            shouldLoad: false,
            Disabled: false,
          };
        }

        case FilterType.CustomDefinedSingleSelect: {
          let FilterResponse: any = null;
          const FilterOptions: Array<FilterModel.ISelectFilterOptions> = [];
          const data = await this.customDefinedMultiSelectProcessor(filterConfig);
          if (this.isDataNotEmpty(data)) {
            data.forEach((item) => {
              FilterOptions.push({
                label: item.DisplayName,
                value: item.Value,
              });
            });
            FilterResponse = this.getValueForSingleSelect(data, value);
          }
          return {
            FilterID: filterConfig.ID,
            FilterResponse,
            FilterOptions,
            shouldLoad: false,
            Disabled: false,
          };
        }
        case FilterType.DateRange:
          return {
            FilterID: filterConfig.ID,
            FilterResponse: this.dateRangeProcessor(value, filterConfig?.Entity),
            shouldLoad: false,
            Disabled: false,
          };
        default:
          throw new Error('Filter Type not implemented');
      }
    } catch (error:any) {
      console.error('GetFilterData failed for ', filterConfig, dependentValue, error);
      return {
        FilterID: filterConfig.ID,
        FilterResponse: {},
        Disabled: false,
        status: error.status,
      };
    }
  }

   checkPreAppliedFilterValueEquality = (value: any, filterConfig: ReportResponseModel.IFilterConfig) => (value && value !== '' && value !== filterConfig.DefaultValue ? value : new VariableParser(this._variables).ReplaceVariable(filterConfig.DefaultValue));

    getFilterResponse = (filterConfig: IFilterConfig, value: any) => (!filterConfig.IsMasked ? value : []);

  // GetDefaultSingleFilter gives filter default data based on type and key, along with linked data
  // param filterConfig: filterConfig from report config
  // param value: default value or linked filter value required to fetch data
  // returns: based on filter type return type depends. Cast post it is returned.
  // throws error when non implemented filter is requested.
  GetDefaultSingleFilter = (filterConfig: ReportResponseModel.IFilterConfig, value: any): FilterModel.IFilterResponse => {
    try {
      switch (filterConfig.Type) {
        case FilterType.PRE_APPLIED:
          return {
            FilterID: filterConfig.ID,
            FilterResponse: this.checkPreAppliedFilterValueEquality(value, filterConfig),
            shouldLoad: false,
            Disabled: false,
          };
        case FilterType.GroupMultiSelect: {
          const FilterResponse: any = this.getFilterResponse(filterConfig, value);
          const FilterOptions: Array<FilterModel.ISelectFilterOptions> = [];
          return {
            FilterID: filterConfig.ID,
            FilterResponse,
            FilterOptions,
            shouldLoad: !filterConfig.IsMasked,
            Disabled: false,
          };
        }
        case FilterType.UserMultiSelect: {
          const FilterResponse: any = !filterConfig.IsMasked ? (value || {
            Type: UserFilterPayloadType.All,
            UserIds: [],
          } as FilterModel.IUserFilterPayload)
            : [];
          const FilterOptions: Array<FilterModel.ISelectFilterOptions> = [];
          return {
            FilterID: filterConfig.ID,
            FilterResponse,
            FilterOptions,
            shouldLoad: !filterConfig.IsMasked,
            Disabled: false,
            OptionsMeta: {
              Page: {
                PageIndex: 0,
                PageSize: 20,
              },
              SearchKey: '',
              Count: 0,
              HasNextPage: false,
            },
          };
        }
        case FilterType.LSQMetadataMultiSelect: {
          const FilterResponse: any = this.getFilterResponse(filterConfig, value);
          const FilterOptions: Array<FilterModel.ISelectFilterOptions> = [];
          return {
            FilterID: filterConfig.ID,
            FilterResponse,
            FilterOptions,
            shouldLoad: !filterConfig.IsMasked,
            Disabled: false,
          };
        }
        case FilterType.MultiTextSearch: {
          const FilterResponse: any = !filterConfig.IsMasked && value ? value : [];
          return {
            FilterID: filterConfig.ID,
            FilterResponse,
            shouldLoad: false,
            Disabled: false,
          };
        }
        case FilterType.LSQMetadataSingleSelect: {
          const FilterResponse: any = !filterConfig.IsMasked ? value : null;
          const FilterOptions: Array<FilterModel.ISelectFilterOptions> = [];
          return {
            FilterID: filterConfig.ID,
            FilterResponse,
            FilterOptions,
            shouldLoad: !filterConfig.IsMasked,
            Disabled: false,
          };
        }
        case FilterType.CustomDefinedMultiSelect: {
          const FilterResponse: any = this.getFilterResponse(filterConfig, value);
          const FilterOptions: Array<FilterModel.ISelectFilterOptions> = [];
          return {
            FilterID: filterConfig.ID,
            FilterResponse,
            FilterOptions,
            shouldLoad: !filterConfig.IsMasked,
            Disabled: false,
          };
        }
        case FilterType.CustomDefinedSingleSelect: {
          const FilterResponse: any = !filterConfig.IsMasked ? value : null;
          const FilterOptions: Array<FilterModel.ISelectFilterOptions> = [];
          return {
            FilterID: filterConfig.ID,
            FilterResponse,
            FilterOptions,
            shouldLoad: !filterConfig.IsMasked,
            Disabled: false,
          };
        }
        case FilterType.DateRange:
          return {
            FilterID: filterConfig.ID,
            FilterResponse: !filterConfig.IsMasked ? this.dateRangeProcessor(value, filterConfig?.Entity) : null,
            shouldLoad: false,
            Disabled: false,
          };
        default:
          throw new Error('Filter Type not implemented');
      }
    } catch (error) {
      console.error('GetFilterData failed for ', filterConfig, value, error);
    }
    return null;
  }

  private groupMultiSelectProcessor = async (featureContext:FeatureContext) => new FilterService().GetGroups({ featureContext })

  private userMultiSelectProcessor = async (
    filterConfig: ReportResponseModel.IFilterConfig, value: any, filterValue: FilterModel.IUserFilterPayload,
    page: FilterModel.IPage, searchKey: string, filterPayload?: ObjModel.ObjGeneric<any>, featureContext?:FeatureContext,
  ) => {
    const filterKey = filterConfig?.Metadata?.FilterKey;
    let userIds = [];
    const data = {
      Type: filterKey,
      InclusionType: filterValue?.Type,
      UserIDs: filterValue?.Type === UserFilterPayloadType.All ? [] : filterValue?.UserIds,
      Page: page,
      Search: searchKey,
      Status: filterConfig?.Metadata?.Status || userStatusEnum.Active,
    } as IUserOptionsFetchPayload;
    if (value) {
      if (Array.isArray(value)) {
        userIds = value;
      } else {
        userIds = [value];
      }
    }
    // type check if ids type is not string set to Users, else set to Groups
    if (userIds.length > 0 && !(typeof userIds[0] === 'string')) {
      data.Users = userIds;
    } else {
      data.Groups = userIds;
    }
    // Additonal filter payload will be sent to get-user API when there are dependent fields where the Entity is User
    if (Object.keys(filterPayload).length !== 0) {
      data.AdditionalFilters = filterPayload;
    }
    return new FilterService().GetUserFilter(data, { featureContext });
  }

  private lsqMultiSelectProcessor = async (filterConfig: ReportResponseModel.IFilterConfig, featureContext:FeatureContext):Promise<SieraResponse<ISelectFilterResponse[]>> => {
    const filterKey = filterConfig?.Metadata?.FilterKey;
    if (filterKey && filterKey.length) {
      return new FilterService().GetLSQDropdownValues({ key: filterKey, featureContext });
    }
    return null;
  }

  private customDefinedMultiSelectProcessor = async (filterConfig: ReportResponseModel.IFilterConfig) => {
    const filterKey = filterConfig?.Metadata?.FilterKey;
    if (filterKey && filterKey.length) {
      return new FilterService().GetCustomDefinedDropdownValues(filterKey);
    }
    return null;
  }

  private dateRangeProcessor = (value: FilterModel.IDateRangePayload, entity:string)
  : FilterModel.IDateRangePayload => {
    if (isValidDateRangePayload(value)) {
      const newValue = { ...value };
      if (newValue.Type === IDateRangeTypes.Absolute) {
        newValue.Value.From = moment(value.Value.From).startOf('day').format(DatePayloadFormat);
      }
      return newValue;
    }
    return getDateFilterValue(entity);
  }

   filterDataByValue = (data: Array<FilterModel.ISelectFilterResponse>, value: any, response: any[]) => {
     data?.forEach((item) => {
       if (value?.includes(item?.Value)) {
         response.push(item.Value);
       }
     });
   };

  private getValueForMultiSelect = (data: Array<FilterModel.ISelectFilterResponse>, value: any) => {
    const response: any[] = [];
    if (!data?.length) {
      return response;
    }
    if (value) {
      if (isArray(value)) {
        this.filterDataByValue(data, value, response);
      } else {
        const foundItem = data.find((item: ISelectFilterResponse) => item.Value === value);
        if (foundItem) {
          response.push(foundItem.Value);
        }
      }
    } else {
      data.forEach((item) => {
        response.push(item.Value);
      });
    }
    return response;
  }

  private getValueForSingleSelect = (data: Array<FilterModel.ISelectFilterResponse>, value: any) => {
    let response = null;
    if (data && data.length) {
      if (value) {
        for (let i = 0; i < data.length; i += 1) {
          if (data[i].Value === value) {
            response = value;
            break;
          }
        }
      } else {
        response = data[0].Value;
      }
    }
    return response;
  }

  getUserAdditionalFilterPayload = (appliedFilters:ObjModel.ObjGeneric<FilterModel.IFilterResponse>, filterConfig: IFilterConfig[], isResetClicked?: boolean) => {
    const filterPayload: {[key: string]: any} = {};
    if (isResetClicked) {
      Object.entries(appliedFilters).forEach(([key, value]) => {
        if (filterConfig.some((filterItem: IFilterConfig) => filterItem.IsDynamic && filterItem.ID === key && filterItem?.Entity === FieldEntitiesType.User && filterItem.Type === FilterType.DateRange)) {
          filterPayload[key] = value.FilterResponse;
        }
      });
      return filterPayload;
    }
    Object.entries(appliedFilters).forEach(([key, value]) => {
      if (filterConfig.some((filterItem: IFilterConfig) => filterItem.IsDynamic && filterItem.ID === key && filterItem?.Entity === FieldEntitiesType.User)) {
        if (value.FilterResponse !== null) {
          filterPayload[key] = value.FilterResponse;
        }
      }
    });
    return filterPayload;
  }
}
