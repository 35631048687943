import React from 'react';

type Props = {
  width?: number;
  height?: number;
  fill?: string;
}

const OptionLineChartIcon = ({
  width, height, fill,
}: Props) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect
      width="16"
      height="16"
      fill="transparent"
    />
    <path
      d="M2 2V13.85C2 13.9328 2.06716 14 2.15 14H14"
      stroke={fill}
      strokeWidth="1.5"
      strokeLinecap="round"
    />
    <path
      d="M13.7051 5.6543L10.6729 8.68649C10.38 8.97938 9.90512 8.97938 9.61222 8.68649L8.19968 7.27394C7.90678 6.98105 7.43191 6.98105 7.13902 7.27394L4.10682 10.3061"
      stroke={fill}
      strokeWidth="1.5"
      strokeLinecap="round"
    />
  </svg>

);

OptionLineChartIcon.defaultProps = {
  width: 16,
  height: 16,
  fill: 'var(--secondaryColor)',
};

export default OptionLineChartIcon;
